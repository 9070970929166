import { MlpaLaneType } from './mlpa-lane/mlpa-lane-type.enum';
import { MlpaLaneApi } from '@shared/mlpa-lane-api';
import { EmptyLane } from './mlpa-lane/lane-types/empty';
import { SmartLine } from './mlpa-lane/lane-types/smartline';
import { WebWeave } from './mlpa-lane/lane-types/webweave';
import { QrCode } from './mlpa-lane/lane-types/qrcode';
import { UnknownLane } from './mlpa-lane/lane-types/unknown';
import { MlpaLane } from './mlpa-lane/mlpa-lane';
import { Graphic } from './mlpa-lane/lane-types/graphic';
import { LaneProperties } from './mlpa-lane/lane-types/lane-properties.interface';
import { MlpaJobSettings } from './mlpa-job-settings/mlpa-job-settings';
import { AlertsService } from '@shared/alerts/alerts.service';
import { AlertType } from '@shared/alerts/alert-type.enum';
import { MlpaJob } from './mlpa-job/mlpa-job';
import { MlpaLaneView } from '../pages/home/view-mlpa-jobs/mlpa-lane-view';
import { deepCopyProperties } from '@shared/pkg-helper';
import { MlpaStateService } from '../pages/mlpa/shared/mlpa-state.service';
import { MlpaService } from '../pages/mlpa/shared/mlpa.service';
import { FormControl } from '@angular/forms';
import { MlpaJobView } from '../pages/home/view-mlpa-jobs/mlpa-job-view';
import { Stress } from './mlpa-lane/lane-types/stress';
import { ColorManagementSystemSettings } from './color-management-system-settings/color-management-system-settings';

export const createLaneFromApi = (laneType: MlpaLaneType, mlpaLaneApi: MlpaLaneApi, isClone = false):
  EmptyLane |
  SmartLine |
  WebWeave |
  QrCode |
  Stress |
  UnknownLane => {
  const laneProperties: LaneProperties = {
    initialProperties: {
      quantity: mlpaLaneApi.quantity,
      laneNumber: mlpaLaneApi.laneNumber,
      designLengthInInches: mlpaLaneApi.designLengthInInches,
      designNumberOfPages: mlpaLaneApi.designNumberOfPages,
      isClone: isClone === true ? isClone : false
    },
    mlpaLaneApi
  };

  const lane = createLane(laneType, laneProperties);
  deepCopyProperties(lane, mlpaLaneApi);

  if(lane.colorCorrectionInfo.isColorCorrectionEnabled === null || lane.colorCorrectionInfo.isColorCorrectionEnabled === undefined) {
    lane.colorCorrectionInfo.isColorCorrectionEnabled = false;
  }

  // recalculate the totalWidthInInches
  if (lane.totalWidthInInches === 0) {
    lane.setCalculatedTotalWidthInInches(mlpaLaneApi.totalWidthInInches);
  }

  return lane;
};

export const createLaneFromView = (laneType: MlpaLaneType, mlpaLaneView: MlpaLaneView, isClone?: boolean):
  EmptyLane |
  SmartLine |
  WebWeave |
  QrCode |
  Stress |
  UnknownLane => {
  const laneProperties: LaneProperties = {
    initialProperties: {
      quantity: mlpaLaneView.quantity,
      laneNumber: mlpaLaneView.laneNumber,
      designLengthInInches: mlpaLaneView.designLengthInInches,
      designNumberOfPages: mlpaLaneView.designNumberOfPages,
      isClone: isClone === true ? isClone : false
    }
  };
  const lane = createLane(laneType, laneProperties);
  deepCopyProperties(lane, mlpaLaneView);

  if(lane.colorCorrectionInfo.isColorCorrectionEnabled === null || lane.colorCorrectionInfo.isColorCorrectionEnabled === undefined) {
    lane.colorCorrectionInfo.isColorCorrectionEnabled = false;
  }

  return lane;
};

export const cloneLaneFromApi = (laneId: number, mlpaLaneApi: MlpaLaneApi): MlpaLane => {
  const mlpaLane = createLaneFromApi(mlpaLaneApi.type, mlpaLaneApi, true);
  mlpaLane.laneId = laneId;
  return mlpaLane;
};

export const recalculateMlpaJobProperties = (mlpaLanes: MlpaLane[], jobSettings: MlpaJobSettings, alerts: AlertsService): MlpaLane[] => {
  // Get last lane's index
  const lastLaneIndex = mlpaLanes.length - 1;
  // Loop through all lanes
  mlpaLanes.forEach((mlpaLane, index) => {
    // Set lane number
    mlpaLanes[index].laneNumber = index + 1;

    // If this is the first lane, use the next lane's color profile
    if (index === 0) {
      mlpaLanes[index].colorProfileId = mlpaLanes[index + 1].colorProfileId;
      mlpaLanes[index].colorProfileName = mlpaLanes[index + 1].colorProfileName;
    }

    // If this is the last lane, use the previous lane's color profile
    if (index === lastLaneIndex) {
      mlpaLanes[index].colorProfileId = mlpaLanes[index - 1].colorProfileId;
      mlpaLanes[index].colorProfileName = mlpaLanes[index - 1].colorProfileName;
    }

    // For non graphic lanes, update properties from job settings
    if (mlpaLane.isWebWeave) {

      // Get design length and number of pages of the next or previous lane
      const nearestLaneIndex = index === 0 ? index + 1 : index - 1;
      const nearestMlpaLane = mlpaLanes[nearestLaneIndex];
      const nearestDesignWidth = nearestMlpaLane.isTransposed ? nearestMlpaLane.designWidthInInches : nearestMlpaLane.designLengthInInches;

      const nearestDesignNumberOfPages = mlpaLanes[nearestLaneIndex].designNumberOfPages;
      const nearestQuantity = mlpaLanes[nearestLaneIndex].quantity;

      const laneProperties: LaneProperties = {
        initialProperties: {
          laneNumber: mlpaLane.laneNumber,
          quantity: nearestQuantity,
          designLengthInInches: nearestDesignWidth,
          designNumberOfPages: nearestDesignNumberOfPages,
          colorProfileId: mlpaLane.colorProfileId,
          colorProfileName: mlpaLane.colorProfileName,
          lineBreakInFeet: jobSettings.lineBreakLengthInFeet
        },
        nearestLane: mlpaLanes[nearestLaneIndex]
      };

      // Switch based on lane type
      switch (jobSettings.laneType) {
        case MlpaLaneType.SmartLine:
          laneProperties.initialProperties.designNumberOfPages = nearestDesignNumberOfPages === 1 ? nearestQuantity : nearestDesignNumberOfPages;
          // If it is not multipage then calculate the number of pages by dividing the number of pages by number ups
          if (mlpaLanes[nearestLaneIndex].designNumberOfPages === 1 && mlpaLanes[nearestLaneIndex].designNumberUp > 1) {
            laneProperties.initialProperties.designNumberOfPages = Math.ceil(laneProperties.initialProperties.designNumberOfPages / mlpaLanes[nearestLaneIndex].designNumberUp);
          }
          mlpaLanes[index] = new SmartLine(laneProperties);
          break;
        case MlpaLaneType.WebWeave:
          mlpaLanes[index] = new WebWeave(laneProperties);
          break;
        case MlpaLaneType.Empty:
          mlpaLanes[index] = new EmptyLane(laneProperties);
          break;
      }

    }

    const currentColorProfileName = mlpaLane.colorProfileName;
    // If id is undefined try to find based on name
    if (!!currentColorProfileName && !!jobSettings && !!jobSettings.colorProfiles) {
      const colorProfile = jobSettings.colorProfiles.find((cp) => cp.name === currentColorProfileName);
      if (mlpaLane.colorProfileId == null && colorProfile && jobSettings.verifyColorProfile(colorProfile.id)) {
        mlpaLanes[index].colorProfileId = colorProfile.id;
        mlpaLanes[index].colorProfileName = currentColorProfileName;
      }
    }
    // Recalculate lane widths
    mlpaLanes[index].recalculateWidth(jobSettings.paperWidthInInches, jobSettings.marginWidthInInches, mlpaLanes);
  });

  return mlpaLanes;
};

export const getValidLineBreakInFeet = (lineBreakLengthInFeet: number, mlpaJobView: MlpaJobView, alerts: AlertsService): number => {
  // TODO: Adjust the linebreak for multipage this needs to be revisited when we have different types of weaves
  if (mlpaJobView.mlpaLanes[0].laneType === MlpaLaneType.SmartLine || mlpaJobView.mlpaLanes[0].designNumberOfPages > 1) {
    const maxIndex = getMaxLaneIndex(mlpaJobView);

    const laneIsTransposed = mlpaJobView.mlpaLanes[maxIndex].isTransposed;

    const maxDesignLength = laneIsTransposed ? mlpaJobView.mlpaLanes[maxIndex].designWidthInInches / 12 : mlpaJobView.mlpaLanes[maxIndex].designLengthInInches / 12;

    if (maxDesignLength > lineBreakLengthInFeet && lineBreakLengthInFeet !== 0) {
      alerts.add('Line Break Adjusted', 'Line break must be greater or equal to the max design length', AlertType.Info);
      return Math.ceil(maxDesignLength);
    }

    return Math.round(lineBreakLengthInFeet / maxDesignLength) * maxDesignLength > 200 ? 200 : Math.round(lineBreakLengthInFeet / maxDesignLength) * maxDesignLength;
  }

  return lineBreakLengthInFeet;
};

export const recalculateMlpaLaneView = (previousLaneView: MlpaLaneView[], currentLaneView: MlpaLaneView[]): MlpaLaneView[] => {
  const firstLane = 0;
  const lastLane = currentLaneView.length - 1;
  if (currentLaneView[firstLane].laneType === MlpaLaneType.Empty || currentLaneView[firstLane].laneType === MlpaLaneType.WebWeave) {
    if (previousLaneView[firstLane].laneType === MlpaLaneType.SmartLine) {
      // maintain the quantity from pages
      currentLaneView[firstLane].quantity = previousLaneView[firstLane].designNumberOfPages;
      currentLaneView[lastLane].quantity = previousLaneView[lastLane].designNumberOfPages;

      // maintain the total length
      currentLaneView[firstLane].totalLengthInInches = previousLaneView[firstLane].totalLengthInInches;
      currentLaneView[lastLane].totalLengthInInches = previousLaneView[lastLane].totalLengthInInches;
    }

    if (previousLaneView[firstLane].laneType === MlpaLaneType.WebWeave || previousLaneView[firstLane].laneType === MlpaLaneType.Empty) {
      // maintain the quantity from pages
      currentLaneView[firstLane].quantity = previousLaneView[firstLane].quantity;
      currentLaneView[lastLane].quantity = previousLaneView[lastLane].quantity;

      // maintain the total length
      currentLaneView[firstLane].totalLengthInInches = previousLaneView[firstLane].totalLengthInInches;
      currentLaneView[lastLane].totalLengthInInches = previousLaneView[lastLane].totalLengthInInches;
    }
  }
  return currentLaneView;
};

export const getMaxLaneIndex = (mlpaJobView: MlpaJobView): number => {
  let maxLengthInInches = 0;
  let maxIndex = 0;
  mlpaJobView.mlpaLanes.forEach((o, index) => {
    if (o.laneType === MlpaLaneType.Graphic && o.totalLengthInInches > maxLengthInInches) {
      maxLengthInInches = o.totalLengthInInches;
      maxIndex = index;
    }
  });
  return maxIndex;
};

export const getMaxLaneLength = (mlpaLanes: MlpaLane[] | MlpaLaneView[]): number => {
  let maxLengthInInches = 0;
  mlpaLanes.forEach(o => {
    if (o.laneType === MlpaLaneType.Graphic && o.totalLengthInInches > maxLengthInInches) {
      maxLengthInInches = o.totalLengthInInches;
    }
  });
  return maxLengthInInches;
};

export const replaceSpecialCharacters = (value: string): string => {
  const specialCharacters: RegExp[] = [/\&/g, /\</g, /\>/g, /\:/g, /\"/g, /\//g, /\\/g, /\|/g, /\?/g, /\*/g];
  const specialCharactersReplacement = '_';
  if (!value) {
    return value;
  }
  for (const specialCharacter of specialCharacters) {
    value = value.replace(specialCharacter, specialCharactersReplacement);
  }
  return value;
};

export const findLongestShortestLaneLength = (mlpaLanes: (MlpaLaneView | MlpaLane)[]): any => {
  const laneValues = [] as { totalLength: number; index: number }[];
  let longestLaneInInches = 0;
  let shortestLaneInInches = 360000;
  let longestLaneIndex = 0;
  let shortestLaneIndex = 0;

  // push the values into an array if it is a graphic or qrcode.
  mlpaLanes.forEach((mlpaLane, index) => {
    if (mlpaLane.laneType === MlpaLaneType.Graphic || mlpaLane.laneType === MlpaLaneType.Stress || mlpaLane.laneType === MlpaLaneType.QrCode) {
      laneValues.push({ totalLength: mlpaLane.totalLengthInInches, index });
    }
  });

  // sort the values
  laneValues.sort((a, b) => a.totalLength - b.totalLength);

  // check if the value is 0 and if so move up the list
  // and set the shortest lane
  for (const value of laneValues) {
    shortestLaneInInches = value.totalLength;
    shortestLaneIndex = value.index;

    if (value.totalLength > 0) {
      break;
    }
  }

  // for some odd reason all lanes are 0
  if (shortestLaneInInches <= 0) {
    shortestLaneInInches = 360000;
  }

  // set the longest lanes
  longestLaneInInches = laneValues[laneValues.length - 1].totalLength;
  longestLaneIndex = laneValues[laneValues.length - 1].index;

  return { shortestLaneInInches, longestLaneInInches, shortestLaneIndex, longestLaneIndex };
};

export const checkLaneLengths = (mlpaLanes: MlpaLane[], alerts: AlertsService, mlpaStateService: MlpaStateService): void => {

  const maxVarianceInInches = 180;
  const firstLaneNumber = 1;
  const lastLaneNumber = mlpaLanes.length;

  // Only check lane lengths if more than 3 lanes
  if (mlpaLanes.length <= 3) {
    return;
  }

  // Calculate the shortest and longest lane lengths
  let shortestTotalLengthInInches = Number.MAX_VALUE;
  let longestTotalLengthInInches = 0;
  mlpaLanes.forEach(mlpaLane => {
    if (mlpaLane.laneNumber === firstLaneNumber || mlpaLane.laneNumber === lastLaneNumber) {
      return;
    }
    if (mlpaLane.totalLengthInInches < shortestTotalLengthInInches) {
      shortestTotalLengthInInches = mlpaLane.totalLengthInInches;
    }
    if (mlpaLane.totalLengthInInches > longestTotalLengthInInches) {
      longestTotalLengthInInches = mlpaLane.totalLengthInInches;
    }
  });

  // Check for warning condition
  const varianceInInches = longestTotalLengthInInches - shortestTotalLengthInInches;
  if (varianceInInches > maxVarianceInInches) {
    const message = varianceInInches.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 });
    const details = `The job contains a lane ${message}" longer than its shortest lane.`;
    alerts.add('Mlpa Lane Warning: ', details, AlertType.Info);
    mlpaStateService.addWarningMessages(details);
  }

};

export const checkPaperWidths = (mlpaJob: MlpaJob, alerts: AlertsService, mlpaStateService: MlpaStateService): void => {
  const details = `The total width of the job exceeds the paper width.`;
  if (mlpaJob.paperWidthInInches - mlpaJob.totalWidthInInches < 0) {
    alerts.add('Settings Warning: ', details, AlertType.Info);
    mlpaStateService.addWarningMessages(details);
  }
};

export const checkSourcePdfs = (mlpaJob: MlpaJob, alerts: AlertsService, mlpaService: MlpaService, mlpaStateService: MlpaStateService): void => {
  // Check if each mlpa order has a valid source PDF
  // If the PDF check goes well and there are no other errors, reactivate the save buttons
  for (const mlpaLane of mlpaJob.mlpaLanes) {

    // Get PDF ID, can be an order number or WEB WEAVE or QR Code
    const pdfId = mlpaLane.laneType !== MlpaLaneType.Graphic
      ? mlpaLane.designDescription
      : mlpaLane.designNumber;

    // Don't check for dynamically generated web weaves, they don't have a source PDF
    if (mlpaLane.laneType !== MlpaLaneType.Graphic) {
      mlpaLane.isPdfChecked = true;
      continue;
    }

    // Call the API and check for all source PDFs
    mlpaService.checkIfSourcePdfExists(mlpaJob.assetId, pdfId)
      .subscribe(data => {
        mlpaLane.isPdfChecked = true;
      }, err => {
        const details = `Lane ${pdfId} does not have a valid source PDF.`;
        mlpaLane.isPdfChecked = true;
        alerts.add('Pdf Source', details, AlertType.Info);
        mlpaStateService.addWarningMessages(details);
      });
  }
};

export const checkColorProfiles = (mlpaJob: MlpaJob, alerts: AlertsService, mlpaStateService: MlpaStateService): void => {
  const firstLaneNumber = 1;
  const lastLaneNumber = mlpaJob.mlpaLanes.length;
  mlpaJob.mlpaLanes.forEach(mlpaLane => {
    // Don't show warning for first or last lane
    if (mlpaLane.laneNumber === firstLaneNumber || mlpaLane.laneNumber === lastLaneNumber) {
      return;
    }
    // If color profile is not valid, show warning
    if (!mlpaLane.hasValidColorProfile) {
      const details = `Lane ${mlpaLane.laneNumber - 1} does not have a valid color profile.`;
      alerts.add('Color Profiles', details, AlertType.Info);
      mlpaStateService.addWarningMessages(details);
    }
  });
};

export const checkJobSettings = (jobSettings: MlpaJobSettings, alerts: AlertsService): void => {
  // Check for missing asset
  if (!jobSettings.asset) {
    alerts.add('Asset not valid', 'Please select a valid asset in job settings.', AlertType.Error);
  }

  // Check for missing paper type
  if (!jobSettings.paperType || !jobSettings.paperWidthInInches) {
    alerts.add('Paper type not valid', 'Please select a valid paper type in job settings.', AlertType.Error);
  }

  // Check for missing paper width
  if (!jobSettings.paperWidthInInches) {
    alerts.add('Paper width not valid', 'Please select a valid paper width in job settings.', AlertType.Error);
  }
};

export const updateMlpaJobProperties = (
  mlpaJob: MlpaJob,
  mlpaJobId: string,
  mlpaLanes: MlpaLane[],
  jobSettings: MlpaJobSettings,
  colorManagementSystemSettings: ColorManagementSystemSettings,
  totalWidthInInches: number,
  alerts: AlertsService,
  jobNameControl?: FormControl,
  isClone = false): MlpaJob => {

  if (jobSettings == null) {
    alerts.add('Something unexpected happened', 'Could not find Job Settings', AlertType.Error);
    return;
  }
  // An asset is required to select correct color
  if (jobSettings.asset == null) {
    alerts.add('Invalid Asset', 'Please select a valid asset', AlertType.Error);
  }
  if (colorManagementSystemSettings == null) {
    alerts.add('Something unexpected happened', 'Could not find color management systems', AlertType.Error);
    return;
  }
  if (mlpaJob.showJobCharacterizationValues && colorManagementSystemSettings.colorManagementSystem == null) {
    alerts.add('Invalid Color Management System', 'Please select a valid color management system', AlertType.Error);
  }

  // Only set the mlpa job id if this is an edit
  if (mlpaJobId) {
    mlpaJob.id = mlpaJobId;
  }

  if (jobNameControl != null && !jobNameControl.dirty && !isClone && !mlpaJob.id) {
    mlpaJob.name = mlpaJob.buildName();
  }

  mlpaJob.assetId = !!jobSettings.asset ? jobSettings.asset.id : '';
  mlpaJob.assetName = !!jobSettings.asset ? jobSettings.asset.name : '';
  mlpaJob.paperTypeId = !!jobSettings.paperType ? jobSettings.paperType.id : '';
  mlpaJob.paperTypeName = !!jobSettings.paperType ? jobSettings.paperType.name : '';
  mlpaJob.paperWidthInInches = !!jobSettings.paperWidthInInches ? jobSettings.paperWidthInInches : 0;
  mlpaJob.marginWidthInInches = !!jobSettings.marginWidthInInches ? jobSettings.marginWidthInInches : 0;
  mlpaJob.lineBreakLengthInInches = !!jobSettings.lineBreakLengthInInches ? jobSettings.lineBreakLengthInInches : 0;
  mlpaJob.mlpaLanes = recalculateMlpaJobProperties(mlpaLanes, jobSettings, alerts);
  // Determine the width and length of the various lanes.
  // Web Weaves will be set to the max lane length + line break length
  // Job total length is set to the calculated web weave length
  const maxLaneLength = mlpaJob.getMaxLaneLength();
  const webWeaveMaxLength = mlpaJob.updateWebWeaves(mlpaJob, maxLaneLength, jobSettings.lineBreakLengthInInches);
  const wasteWidth = jobSettings.paperWidthInInches - totalWidthInInches;
  mlpaJob.totalWidthInInches = totalWidthInInches;
  mlpaJob.wasteWidthInInches = wasteWidth < 0 ? 0 : wasteWidth;
  mlpaJob.totalLengthInInches = webWeaveMaxLength;
  mlpaJob.wasteLengthInInches = 1; // TODO: Need to figure out this value
  if (!!mlpaJob.colorCorrectionInfo) {
    mlpaJob.colorCorrectionInfo.colorManagementSystemId = !!colorManagementSystemSettings.colorManagementSystem ? colorManagementSystemSettings.colorManagementSystem.id : '';
    mlpaJob.colorCorrectionInfo.speed = !!colorManagementSystemSettings.jobCharacterizationValues.speed ? colorManagementSystemSettings.jobCharacterizationValues.speed : '';
    mlpaJob.colorCorrectionInfo.substrateWeight = !!colorManagementSystemSettings.jobCharacterizationValues.substrate ? colorManagementSystemSettings.jobCharacterizationValues.substrate : '';
    mlpaJob.colorCorrectionInfo.overPrintVarnish = !!colorManagementSystemSettings.jobCharacterizationValues.overPrintVarnish ?
      colorManagementSystemSettings.jobCharacterizationValues.overPrintVarnish : '';
    mlpaJob.colorCorrectionInfo.pressType = !!colorManagementSystemSettings.jobCharacterizationValues.pressType ? colorManagementSystemSettings.jobCharacterizationValues.pressType : '';
    mlpaJob.colorCorrectionInfo.printMode = !!colorManagementSystemSettings.jobCharacterizationValues.printMode ? colorManagementSystemSettings.jobCharacterizationValues.printMode : '';
    mlpaJob.colorCorrectionInfo.inkColors = !!colorManagementSystemSettings.jobCharacterizationValues.inkColors ? colorManagementSystemSettings.jobCharacterizationValues.inkColors : '';
  }
  return mlpaJob;
};

export const getTotalLengthOfLanes = (mlpaLanes: (MlpaLane | MlpaLaneView)[]): number => {
  let totalWidthInInches = 0;
  mlpaLanes.forEach(o => {
    switch (o.laneType) {
      case MlpaLaneType.Empty:
      case MlpaLaneType.SmartLine:
      case MlpaLaneType.WebWeave:
        totalWidthInInches += o.designWidthInInches;
        break;
      case MlpaLaneType.QrCode:
      case MlpaLaneType.Graphic:
      default:
        totalWidthInInches += o.totalWidthInInches;
        break;
    }
  });
  return totalWidthInInches;
};

export const getTotalLengthOfGraphics = (mlpaLanes: (MlpaLane | MlpaLaneView)[]): number => {
  let totalWidthInInches = 0;
  mlpaLanes.forEach(o => {
    switch (o.laneType) {
      case MlpaLaneType.Empty:
      case MlpaLaneType.SmartLine:
      case MlpaLaneType.WebWeave:
        break;
      case MlpaLaneType.QrCode:
      case MlpaLaneType.Graphic:
      default:
        totalWidthInInches += o.totalWidthInInches;
        break;
    }
  });
  return totalWidthInInches;
};

export const createLane = (laneType: MlpaLaneType, laneProperties: LaneProperties) => {
  let lane: EmptyLane | SmartLine | WebWeave | QrCode | UnknownLane | Stress;
  switch (laneType) {
    case MlpaLaneType.Empty:
      lane = new EmptyLane(laneProperties);
      break;
    case MlpaLaneType.SmartLine:
      lane = new SmartLine(laneProperties);
      break;
    case MlpaLaneType.WebWeave:
      lane = new WebWeave(laneProperties);
      break;
    case MlpaLaneType.QrCode:
      lane = new QrCode(laneProperties);
      break;
    case MlpaLaneType.Graphic:
      lane = new Graphic(laneProperties);
      break;
    case MlpaLaneType.Stress:
      lane = new Stress(laneProperties);
      break;
    default:
      lane = new UnknownLane(laneProperties);
  }

  return lane;
};
