import { PdfTokenTypes } from './pdf-token-types';
import { AssetAttributes } from './asset-attributes';
import { AssetType } from './asset-type.enum';

export class Asset {
  id: string;
  attributes: AssetAttributes;
  uniqueId: string;
  name: string;
  minWidthInInches: number;
  maxWidthInInches: number;
  maxNumberOfLanes: number;
  requiredPdfTokenTypes: PdfTokenTypes;
  jdfRetainTimeInMinutes: number;
  pathToStressFile: string;
  pathToMlpaLaneJdfs: string;
  pathToMlpaLaneSourcePdfs: string;
  pathToMlpaLaneOutputPdfs: string;
  pathToMlpaLaneThumbnails: string;
  pathToMlpaJobJmfs: string;
  pathToSourceClipBoxFile: string;
  pathToPressApi: string;
  isEnabled: boolean;
  isInTestMode: boolean;
  isSourceClipBoxEnabled: boolean;
  speedInFeetPerMinute: number;
  showInSchedule: boolean;
  visionSystemId: string;
  visionSystemName: string;
  assetType: AssetType;
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }
}
