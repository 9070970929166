<div *ngIf="currentHoliday?.name === 'Halloween' && showHalloween" class="witch-area">
  <div class="witch-on-broom">
    <img src="../../../assets/witch-on-broom.png" width="128" height="128" alt="witch" />
  </div>
</div>

<div *ngIf="currentHoliday?.name === 'Thanksgiving' && showThanksgivingHat" class="canvas-area">
  <div class="thanksgiving-hat">
    <img src="../../../assets/pilgrimhat.svg" width="90" height="90" alt="a hat" />
  </div>

  <div class="turkey">
    <img src="../../../assets/turkey.svg" width="90" height="90" alt="turkey" />
  </div>
  <div class="plate">
    <img src="../../../assets/plate.svg" width="90" height="90" alt="plate" />
  </div>
</div>


<div *ngIf="currentHoliday?.name === 'Christmas' && showChristmas" class="canvas-area christmas-area">
  <div class="snowman">
      <img src="../../../assets/snowman.svg" alt="snowman" />
  </div>

  <div class="snow-piles snow-pile1"></div>
  <div class="snow-piles snow-pile2"></div>
  <div class="snow-piles snow-pile3"></div>

  <div class="snowflakes" aria-hidden="true">
    <div class="snowflake">❅</div>
    <div class="snowflake">❆</div>
    <div class="snowflake">❅</div>
    <div class="snowflake">❆</div>
    <div class="snowflake">❅</div>
    <div class="snowflake">❆</div>
    <div class="snowflake">❅</div>
    <div class="snowflake">❆</div>
    <div class="snowflake">❅</div>
    <div class="snowflake">❆</div>
    <div class="snowflake">❅</div>
    <div class="snowflake">❆</div>
  </div>
</div>
