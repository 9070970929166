import { MlpaLane } from '../mlpa-lane';
import { DesignType } from '../design-type.enum';
import { LaneProperties } from './lane-properties.interface';
import { deepCopyProperties } from '@shared/pkg-helper';
import { ProductDesign } from '@app/mlpa/product-design/product-design.interface';

export class Graphic extends MlpaLane {
  constructor(laneProperties: LaneProperties) {
    super(laneProperties.mlpaLaneApi, laneProperties.design);
    // if an id exists this is an edit so just return and do no more.
    if (!!laneProperties.mlpaLaneApi && laneProperties.mlpaLaneApi.id !== '') {
      return;
    }

    // loop through properties to perform a deep copy of properties
    deepCopyProperties(this, laneProperties.initialProperties);
    // if the isClone property is null/undefined or false
    if (laneProperties.initialProperties.isClone == null || laneProperties.initialProperties.isClone === false) {
      this.setInitialLaneProperties(laneProperties.initialProperties, DesignType.GRAPHIC);

      // remap due to overrided values.
      deepCopyProperties(this, laneProperties.design);

      // override values
      this.numberOfRibbons = 1;
      this.laneNumber = 0;
      this.rotationInDegrees = 90;
      this.isTransposed = true;
      if (!!laneProperties.design) {
        this.orderDueDate = laneProperties.design.orderDueDate;
      }
      this.isProcessing = false;
      this.isProcessed = false;
    }
  }
  get totalWidthInInches(): number {
    if (this.isTransposed) {
      return this.designLengthInInches * this.numberOfRibbons;
    }
    return this.designWidthInInches * this.numberOfRibbons;
  }

  get totalLengthInInches(): number {
    let dimension = 1;

    if (this.isTransposed) {
      dimension = this.designWidthInInches;
    } else {
      dimension = this.designLengthInInches;
    }

    if (this.designNumberOfPages > 1) {
      const numberOfPages = this.originalNumberOfPages ? this.originalNumberOfPages : this.designNumberOfPages;
      // for multipage designs numberups are not included to calculate the total length
      // pages * designlength
      return numberOfPages * dimension;
    }

    return (this.quantity / (this.designNumberUp * this.numberOfRibbons)) * dimension;
  }

  protected setValuesFromProductDesign(productDesign: ProductDesign): void {
    super.setValuesFromProductDesign(productDesign);
    this.colorCorrectionInfo.isColorCorrectionEnabled = productDesign.enableColorCorrection;
    this.colorCorrectionInfo.inkColors = productDesign.inkColors;
    this.colorCorrectionInfo.inkWeight = productDesign.inkWeight;
    this.colorCorrectionInfo.overPrintVarnish = productDesign.overPrintVarnish;
    this.colorCorrectionInfo.speed = productDesign.speed;
    this.colorCorrectionInfo.substrateWeight = productDesign.substrateWeight;
    this.colorCorrectionInfo.printMode = productDesign.printMode;
    this.colorCorrectionInfo.pressType = productDesign.pressType;
    this.colorCorrectionInfo.totalAreaCoverage = productDesign.totalAreaCoverage;

    this.definedColorCorrectionInfo.inkColors = productDesign.inkColors;
    this.definedColorCorrectionInfo.overPrintVarnish = productDesign.overPrintVarnish;
    this.definedColorCorrectionInfo.pressType = productDesign.pressType;
    this.definedColorCorrectionInfo.printMode = productDesign.printMode;
    this.definedColorCorrectionInfo.speed = productDesign.speed;
    this.definedColorCorrectionInfo.substrateWeight = productDesign.substrateWeight;
    this.definedColorCorrectionInfo.totalAreaCoverage = productDesign.totalAreaCoverage;
    this.definedColorCorrectionInfo.inkWeight = productDesign.inkWeight;
  }
}
