<div class="container">
  <div class="page__header">
    <span class="material-icons">&#xE6DF;</span>
    <span>MLPA Job Stats</span>
    <span class="filler"></span>
    <span *ngIf="isLoading" class="loading"></span>
  </div>
  <div class="page__content">
    <pkg-mlpa-jobs-stats (isLoading)=setLoading($event)></pkg-mlpa-jobs-stats>
  </div>
</div>
