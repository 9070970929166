<div *ngIf="mlpaJob">
  <div class="preview-controls">
    <div class="job__name">
      {{mlpaJob.name}}-{{mlpaJob.uniqueId}}
    </div>
    <div class="preview-buttons">
      <div class="toggle">
        <span>Unicorn Mode</span>
        <label class="switch">
          <input type="checkbox" [disabled]="printing || !printable" [(ngModel)]="showBoundingBoxes">
          <span class="slider"></span>
        </label>
      </div>
      <div *ngIf="print" class="print">
        <button [disabled]="printing || !printable" class="button button--red" (click)="printPreview(mlpaJob)" type="button">
          <i *ngIf="printing" class="material-icons loading icon-loading"></i>
          <i *ngIf="!printing" class="material-icons print-icon">&#xE8AD;</i>
          <span>Print</span>
        </button>
      </div>
      <div>
        <button class="button button--green" (click)="closeJobPreview.emit()">
          <i class="material-icons">&#xE165;</i>
          <span>Details</span>
        </button>
      </div>
    </div>
  </div>
  <div [ngStyle]="{'width': '100%', 'display': 'flex', 'justify-content': 'center'}">
    <div #previewContainer class="job-preview-container">
      <div class="spacer">
        <span class="preview-dimensions" [ngStyle]="{'width': getJobWidth() }">{{mlpaJob.paperWidthInInches}}"</span>
        <div class="empty-space"></div>
      </div>
      <div *ngFor="let job of jobsToDisplay" class="job-preview">
        <div class="spacer">
          <!-- NEW method: pull prebuilt image from backend -->
          <div *ngIf="job.showPreview && job.preview + (showBoundingBoxes ? '?showBoundingBoxes=true' : '') as boundingThumbnail">
            <div *ngIf="boundingThumbnail && (boundingThumbnail | secure | async) as jobThumbnail; else jobLoading">
              <img *ngIf="!isObject(jobThumbnail)" [src]="jobThumbnail" alt="job thumbnail" class="preview-lane-outer" [ngStyle]="{'width': getJobWidth(), 'height': getJobHeight(job) }" (pkgAfterIf)="retrievedImages = $event" />
              <div *ngIf="isObject(jobThumbnail) && jobThumbnail.response.status === 404" class="preview-lane-outer" [ngStyle]="{'width': getJobWidth(), 'height': getJobHeight(job) }">
                <div class="preview-not-found">
                  <strong>We don't have a thumbnail</strong>
                  <img [ngStyle]="{ 'max-width':  getJobWidth(), 'margin': '5px' }" src="assets/ThumbnailNotFound.svg" alt="not found thumbnail" />
                  <span>but here is another one ...</span>
                </div>
              </div>
            </div>
            <ng-template #jobLoading>
              <div class="preview-lane-outer" [ngStyle]="{'width': getJobWidth(), 'height': getJobHeight(job) }">
                <div class="loading-container">
                  <div class="loading"></div>
                </div>
              </div>
            </ng-template>
          </div>
          <!-- OLD method: build preview with HTML -->
          <!-- <div class="preview-lane-outer" [ngStyle]="{'width': getJobWidth(), 'height': getJobHeight(job) }">
            <div *ngFor="let lane of job.mlpaLanes" class="preview-lane-repeat" [ngStyle]="{ 'flex-basis': getLanePercentage(lane), 'height': laneHeight(lane, job) }">
              <div *ngIf="lane.preview && (lane.preview | secure | async) as thumbnail; else loading" class="preview-lane-inner" [ngStyle]="lane.laneNumber === job.mlpaLanes.length ? { 'align-items': 'flex-end', 'height': '100%', 'flex-flow': 'column' } : lane.laneNumber === 1 ? { 'align-items': 'flex-start', 'height': '100%', 'flex-flow': 'column' } : { }">
                <div *ngIf="!isObject(thumbnail)" (pkgAfterIf)="retrievedImages = $event">
                  <img *ngFor="let each of range(laneRepeat(lane, maxDesignLength(job))); first as isFirst" [ngStyle]="{ 'max-width':  laneWidth(lane), 'height': designHeight(lane) }" [ngClass]="lane.laneType === laneType.SmartLine || lane.laneType === laneType.WebWeave ? 'preview-lane-web-weave' : 'preview-lane-image'"  [src]="lane.laneType === laneType.SmartLine && !isFirst ? '/assets/WebWeave.svg' : thumbnail" alt="thumbnail" />
                </div>
                <div *ngIf="isObject(thumbnail) && thumbnail.status === 404" class="preview-not-found">
                  <strong>We didn't find the thumbnail</strong>
                  <img *ngFor="let each of range(laneRepeat(lane, maxDesignLength(job)))" [ngStyle]="{ 'max-width':  laneWidth(lane), 'margin': '5px' }" [ngClass]="lane.laneType === laneType.SmartLine || lane.laneType === laneType.WebWeave ? 'preview-lane-web-weave' : 'preview-lane-image'"  src="assets/ThumbnailNotFound.svg" alt="thumbnail" />
                  <span>but here is another one ...</span>
                </div>
              </div>
              <ng-template #loading>
                <div class="loading-container">
                  <div class="loading"></div>
                </div>
              </ng-template>
            </div>
          </div> -->
          <span class="vertical-dimensions">
            <div class="border top"></div>
            <div class="line"></div>
            <span class="word">
              <svg viewBox="0 0 56 28">
                <text x="50%" y="70%" text-anchor="middle">{{maxDesignLength(job)}}"</text>
              </svg>
            </span>
            <div class="line"></div>
            <div class="border bottom"></div>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
