import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertType } from '@shared/alerts/alert-type.enum';
import { AlertsService } from '@shared/alerts/alerts.service';

import { Asset } from '@shared/asset';
import { AssetAttributes } from '@shared/asset-attributes';
import { AssetType } from '@shared/asset-type.enum';
import { PdfTokenTypes } from '@shared/pdf-token-types';
import { VisionSystemAsset } from '@shared/vision-system-asset';
import { Subject, of, Observable } from 'rxjs';
import { takeUntil, map, finalize } from 'rxjs/operators';
import { getAssetColorCodes } from '../shared/asset-color-code-config';
import { AssetService } from '../shared/asset.service';
import { VisionService } from '../shared/vision.service';


@Component({
  selector: 'pkg-asset-edit',
  templateUrl: './asset-edit.component.html',
  styleUrls: ['./asset-edit.component.scss']
})

export class AssetEditComponent implements OnInit, OnDestroy {
  asset: Asset;
  assetForm: FormGroup;
  visionSystems: VisionSystemAsset[];
  title = 'Edit';
  isAddAssetRoute: boolean = this.router.url === '/admin/asset/add';
  loading = true;
  assetAttributes: AssetAttributes[];
  assetType = AssetType;
  private unsubscribe$ = new Subject();
  constructor(
    private readonly assetService: AssetService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly alertsService: AlertsService,
    private readonly formBuilder: FormBuilder,
    private readonly visionService: VisionService) { }

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.unsubscribe$), map((params) => {
      if (this.isAddAssetRoute) {
        this.title = 'Add';
        return of(new Asset(null, ''));
      } else {
        return this.assetService.get(params.get('assetId'));
      }
    })).subscribe((asset) => {
        asset.subscribe((data) => {
          this.asset = data;
          this._getVisionSystemIds().pipe(finalize(() => {
            this.initializeForm();
            this.assetAttributes = this.getAssetAttributes(data);
            this.loading = false;
          })).subscribe((visionSystem) => {
            this.visionSystems = visionSystem;
          }, (err) => {
            this.alertsService.add('Failed to load vision system.', err, AlertType.Error);
          });
        });
      });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initializeForm() {
    /* tslint:disable:no-bitwise */
    // Get initial bitmask values from asset
    const isLaneLetterRequired = (this.asset.requiredPdfTokenTypes & PdfTokenTypes.LaneLetter) === PdfTokenTypes.LaneLetter;
    const isLaneIdRequired = (this.asset.requiredPdfTokenTypes & PdfTokenTypes.LaneId) === PdfTokenTypes.LaneId;
    const isDesignNumberOrderNumberRequired = (this.asset.requiredPdfTokenTypes & PdfTokenTypes.DesignNumberOrderNumber) === PdfTokenTypes.DesignNumberOrderNumber;
    const isDesignNumberOrderNumberUniqueIdRequired = (this.asset.requiredPdfTokenTypes & PdfTokenTypes.DesignNumberOrderNumberUniqueId) === PdfTokenTypes.DesignNumberOrderNumberUniqueId;
    const isCustomerPurchaseOrderNumberRequired = (this.asset.requiredPdfTokenTypes & PdfTokenTypes.CustomerPurchaseOrderNumber) === PdfTokenTypes.CustomerPurchaseOrderNumber;

    // Init form values
    this.assetForm = this.formBuilder.group({
      uniqueId: [this.asset.uniqueId, Validators.required],
      name: [this.asset.name, [Validators.required, Validators.pattern('^.{4,255}$')]],
      minWidthInInches: [!!this.asset.minWidthInInches ? this.asset.minWidthInInches : 0, [Validators.required, Validators.min(0)]],
      maxWidthInInches: [!!this.asset.maxWidthInInches ? this.asset.maxWidthInInches : 0, [Validators.required, Validators.min(0)]],
      maxNumberOfLanes: [!!this.asset.maxNumberOfLanes ? this.asset.maxNumberOfLanes : 0, [Validators.required, Validators.min(0)]],
      showInSchedule: [this.asset.showInSchedule, Validators.required],
      speedInFeetPerMinute: [!!this.asset.speedInFeetPerMinute ? this.asset.speedInFeetPerMinute : 0, [Validators.required, Validators.min(0)]],
      isLaneLetterRequired: [isLaneLetterRequired, Validators.required],
      isLaneIdRequired: [isLaneIdRequired, Validators.required],
      isDesignNumberOrderNumberRequired: [isDesignNumberOrderNumberRequired, Validators.required],
      isDesignNumberOrderNumberUniqueIdRequired: [isDesignNumberOrderNumberUniqueIdRequired, Validators.required],
      isCustomerPurchaseOrderNumberRequired: [isCustomerPurchaseOrderNumberRequired, Validators.required],
      pathToMlpaLaneJdfs: [this.asset.pathToMlpaLaneJdfs, [Validators.required, Validators.maxLength(510)]],
      pathToMlpaLaneSourcePdfs: [this.asset.pathToMlpaLaneSourcePdfs, [Validators.required, Validators.maxLength(510)]],
      pathToMlpaLaneOutputPdfs: [this.asset.pathToMlpaLaneOutputPdfs, [Validators.required, Validators.maxLength(510)]],
      pathToMlpaLaneThumbnails: [this.asset.pathToMlpaLaneThumbnails, [Validators.required, Validators.maxLength(510)]],
      pathToMlpaJobJmfs: [this.asset.pathToMlpaJobJmfs, [Validators.required, Validators.maxLength(510)]],
      pathToStressFile: [this.asset.pathToStressFile, [Validators.maxLength(510)]],
      pathToSourceClipBoxFile: [this.asset.pathToSourceClipBoxFile, [Validators.maxLength(510)]],
      pathToPressApi: [this.asset.pathToPressApi, [Validators.required, Validators.maxLength(510)]],
      isEnabled: [!!this.asset.isEnabled ? this.asset.isEnabled : false, Validators.required],
      isInTestMode: [!!this.asset.isInTestMode ? this.asset.isInTestMode : false, Validators.required],
      isSourceClipBoxEnabled: [!!this.asset.isSourceClipBoxEnabled ? this.asset.isSourceClipBoxEnabled : false, Validators.required],
      jdfRetainTimeInMinutes: [!!this.asset.jdfRetainTimeInMinutes ? this.asset.jdfRetainTimeInMinutes : 0, [Validators.required, Validators.min(0)]],
      visionSystemId: [!!this.asset.visionSystemId ? this.asset.visionSystemId : null],
      attributes: [!!this.asset.attributes ? this.asset.attributes : { lightThemeColor: '', darkThemeColor: '' }],
      assetType: [!!this.asset.assetType ? this.asset.assetType : null, Validators.required],
    });
  }

  isValid(field: string): boolean {
    return !this.assetForm.get(field).valid && this.assetForm.get(field).touched;
  }

  saveAsset() {

    // Set values from form
    this.asset = Object.assign({}, this.asset, this.assetForm.value);

    // Set bitmask value to none
    this.asset.requiredPdfTokenTypes = PdfTokenTypes.None;

    /* tslint:disable:no-bitwise */
    // Set bitmask for lane letter
    if (this.assetForm.value.isLaneLetterRequired) {
      this.asset.requiredPdfTokenTypes |= PdfTokenTypes.LaneLetter;
    }
    // Set bitmask for lane ID
    if (this.assetForm.value.isLaneIdRequired) {
      this.asset.requiredPdfTokenTypes |= PdfTokenTypes.LaneId;
    }
    // Set bitmask for design number and order number
    if (this.assetForm.value.isDesignNumberOrderNumberRequired) {
      this.asset.requiredPdfTokenTypes |= PdfTokenTypes.DesignNumberOrderNumber;
    }
    // Set bitmask for design number, order number, and unique ID
    if (this.assetForm.value.isDesignNumberOrderNumberUniqueIdRequired) {
      this.asset.requiredPdfTokenTypes |= PdfTokenTypes.DesignNumberOrderNumberUniqueId;
    }
    // Set bitmask for customer purchase order
    if (this.assetForm.value.isCustomerPurchaseOrderNumberRequired) {
      this.asset.requiredPdfTokenTypes |= PdfTokenTypes.CustomerPurchaseOrderNumber;
    }
    /* tslint:enable:no-bitwise */

    // Check for a valid asset id
    const assetIdValidator = /^(?!0{2,}$)[a-zA-Z0-9]{2,}$/;
    if (new RegExp(assetIdValidator).test(this.asset.uniqueId)) {
      if (this.isAddAssetRoute) {
        this._addAsset();
      } else {
        this._editAsset();
      }
    } else {
      this.alertsService.add(
        'Failed to save',
        `Asset Unique ID should have 2 alphanumeric characters, at least one of which must be non-zero. (Invalid entry ${this.asset.uniqueId})`,
        AlertType.Error);
    }
  }

  getAssetAttributes(asset: Asset) {
    // Get all color codes
    const assetAttributes = getAssetColorCodes();

    this.assetService.getAll().pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      // For edit asset, except editing asset's color code, exclude color codes which are already been asigned
      if (asset.id != null) {
        data.filter(a => a.id !== asset.id)
          .forEach(a => {
            const itemIndex = assetAttributes.findIndex(assetAttr => (assetAttr.lightThemeColor === a.attributes.lightThemeColor && assetAttr.darkThemeColor === a.attributes.darkThemeColor));
            if (itemIndex > -1) {
              assetAttributes.splice(itemIndex, 1);
            }
          });
      } else {
        // For add asset, exclude all the color codes which are already been assigned
        data.forEach(a => {
          const itemIndex = assetAttributes.findIndex(assetAttr => (assetAttr.lightThemeColor === a.attributes.lightThemeColor && assetAttr.darkThemeColor === a.attributes.darkThemeColor));
          if (itemIndex > -1) {
            assetAttributes.splice(itemIndex, 1);
          }
        });
      }
    },
      (err) => {
        this.alertsService.add('Failed to load asset colors.', err, AlertType.Error);
      });
    return assetAttributes;
  }

  private _editAsset(): void {
    this.assetService.editAsset(this.asset)
      .subscribe((complete) => {
        this.alertsService.add('Saved', `Asset updated.`, AlertType.Success);
        this.router.navigate(['/admin/asset']);
      }, (error) => {
        this._showError(error);
      });
  }

  private _getVisionSystemIds(): Observable<VisionSystemAsset[]> {
    return this.visionService.getAll()
      .pipe(
        takeUntil(this.unsubscribe$),
        map(result => result.results)
      );
  }
  private _addAsset(): void {
    this.assetService.addAsset(this.asset)
      .subscribe((complete) => {
        this.alertsService.add('Saved', `Asset added.`, AlertType.Success);
        this.router.navigate(['/admin/asset']);
      }, (error) => {
        this._showError(error);
      });
  }

  private _showError(error: HttpErrorResponse): void {
    this.alertsService.add('Failed to save', error.error.message, AlertType.Error);
  }

}
