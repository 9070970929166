<div class="fullscreen">
  <div class="fullscreen__container">
    <div class="fullscreen__card">
      <div class="fullscreen__header">
        Uh-oh!
      </div>
      <div class="fullscreen__content">
        The application encountered an unexpected error. Please try your request again.
      </div>
      <div class="fullscreen__footer">
        <button class="button button--primary" (click)="goHome()" type="button">
          <i class="material-icons">&#xE88A;</i>
          <span>Go home</span>
        </button>
        <div class="fullscreen__status-code">
          500 Internal Server Error
        </div>
      </div>
    </div>
    <div class="fullscreen__logo-stack">
      <div class="fullscreen__logo-container">
        <img class="fullscreen__logo" src="../assets/full-logo-dark.svg" [ngStyle]="getLogoStyle" alt="Tartan + Hummingbird Logo" />
      </div>
      <span class="fullscreen__release-number">v{{releaseNumber}}</span>
    </div>
  </div>
</div>
