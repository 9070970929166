import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';

import { Alert } from './alert';
import { AlertType } from './alert-type.enum';

@Injectable()
export class AlertsService {
  alerts = new Array<Alert>();

  constructor() { }

  add(title: string, message: string, type: AlertType): void {
    const newId = this.alerts.length + 1;
    const newAlert = new Alert(newId, title, message, type);

    this.alerts.push(newAlert);
    this._setAlertRemoveTimer(newId);
  }
  remove(id: number): void {
    const index = this.alerts.findIndex(a => a.id === id);
    if (index >= 0) {
      this.alerts.splice(index, 1);
    }
  }

  private _setAlertRemoveTimer(id: number): void {
    timer(8000).subscribe(() => {
      this.remove(id);
    });
  }
}
