import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router } from '@angular/router';

import { AuthService } from '../../auth/auth.service';
import { Role } from './shared/role';

@Injectable()
export class AdminGuard implements CanActivate, CanActivateChild {
  constructor(private readonly _router: Router, private readonly _authService: AuthService) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!this._authService.user) {
      // if there is no user, get one
      await this._authService.login().toPromise();
    }

    if (this._authService.user.role === Role.Admin
      || this._authService.user.role === Role.Support) {
      return await Promise.resolve(true);
    }

    this._router.navigate(['/home']);
    return await Promise.resolve(false);
  }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.canActivate(childRoute, state);
  }
}
