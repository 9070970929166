/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { MlpaJobState } from '../../../mlpa/mlpa-job-state';
import { MlpaLaneState } from '../../../mlpa/mlpa-lane-state';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MlpaStateService {
  private _mlpaWarningMessages = [];
  private _mlpaJobState = {} as MlpaJobState;
  private _mlpaLaneState = {} as MlpaLaneState;
  private readonly _mlpaJobStateSubject = new BehaviorSubject<MlpaJobState>(this._mlpaJobState);
  private readonly _mlpaLaneStateSubject = new BehaviorSubject<MlpaLaneState>(this._mlpaLaneState);
  private readonly _mlpaWarningMessagesStateSubject = new BehaviorSubject<string[]>(this._mlpaWarningMessages);
  readonly mlpaJobState$ = this._mlpaJobStateSubject.asObservable();
  readonly mlpaLaneState$ = this._mlpaLaneStateSubject.asObservable();
  readonly mlpaWarningMessagesState$ = this._mlpaWarningMessagesStateSubject.asObservable();
  constructor() { }

  setMlpaJobState(mlpaJobState: MlpaJobState): void {
    this._mlpaJobState = mlpaJobState;
    this._mlpaJobStateSubject.next(this._mlpaJobState);
  }
  setMlpaLaneState(mlpaLaneState: MlpaLaneState): void {
    this._mlpaLaneState = mlpaLaneState;
    this._mlpaLaneStateSubject.next(this._mlpaLaneState);
  }

  setByValue(key: string, value: any): void {
    this._mlpaJobState[key] = value;
    this._mlpaJobStateSubject.next(this._mlpaJobState);
  }

  getValueFromJob(key: string): any {
    return this._mlpaJobState[key];
  }
  getValueFromLane(key: string): any {
    return this._mlpaLaneState[key];
  }

  setLaneStateByValue(key: string, value: any): void {
    this._mlpaLaneState[key] = value;
    this._mlpaLaneStateSubject.next(this._mlpaLaneState);
  }

  resetWarningMessages(): void {
    this._mlpaWarningMessages = [];
    this._mlpaWarningMessagesStateSubject.next(this._mlpaWarningMessages);
  }

  addWarningMessages(message: string): void {
    this._mlpaWarningMessages.push(message);
    this._mlpaWarningMessagesStateSubject.next(this._mlpaWarningMessages);
  }
}
