<div class="job-container" [class.collapsed]="!!mlpaJobView"
  *pkgHasRole="[role.Operator, role.Scheduler, role.Viewer, role.Admin, role.Support, role.Structure]">
  <ng-container *ngIf="mlpaJobView">
    <pkg-card>
      <pkg-card-header>
        <div class="pkg-card-title">
          <!-- status icons-->
          <pkg-mlpa-status-icons class="status-icons-group"
            [editMode]="editMode && mlpaJobView?.mlpaLanes[1].laneType !== laneType.Stress" [job]="mlpaJobView">
          </pkg-mlpa-status-icons>

          <ng-container
            *pkgHasRole="[role.Scheduler, role.Admin, role.Support, role.Structure]; and mlpaJobView?.mlpaLanes[1].laneType !== laneType.Stress">
            <div class="content job-name" *ngIf="mlpaJobView.isNewJob && editMode">
              <div *ngIf="mlpaJobView.isNewJob && editMode" class="field gutter">
                <span class="title" [ngClass]="{'edit-title' : editMode}">Job Name</span>
                <input maxlength="20" [formControl]="jobName" minlength="3" placeholder="Enter a job name...">
                <div *ngIf="mlpaJobView?.name?.length === 0" class="settings error">Job Name is required.</div>
                <div *ngIf="mlpaJobView?.name?.indexOf(' ') >= 0" class="settings error">Job Name cannot contain spaces.</div>
                <span (click)="buildJobName()" *ngIf="jobName.dirty"
                  class="build-job-name material-icons button button-fab button--blue">&#xE863;</span>
              </div>
            </div>
          </ng-container>

          <div
            *pkgHasRole="[role.Scheduler, role.Support, role.Admin, role.Structure]; and editMode && mlpaJobView?.mlpaLanes[1].laneType !== laneType.Stress"
            class="job-edit-options">
            <ng-container *ngIf="!isSpecial">
              <span *ngIf="!jobSettings?.hasStress"
                class="button material-icons status-chip unselected status-chip--chevron" title="Enable Stress File"
                (click)="toggleChevron()"></span>
              <span *ngIf="jobSettings?.hasStress"
                class="button material-icons status-chip selected status-chip--chevron" title="Disable Stress File"
                (click)="toggleChevron()"></span>

              <span *ngIf="!jobSettings?.hasQrCode" class="button material-icons status-chip unselected status-chip--qr"
                title="Enable QR" (click)="toggleQrCode()"></span>
              <span *ngIf="jobSettings.hasQrCode" class="button material-icons status-chip selected status-chip--qrcode"
                title="Disable QR" (click)="toggleQrCode()">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 -1 30 30"
                  style="enable-background:new 0 -1 30 30;" xml:space="preserve">
                  <use href="#qr-code"></use>
                </svg>
              </span>

              <span *ngIf="isProcessingEnabled"
                class="button material-icons unselected status-chip status-chip--cloud_upload"
                title="Disable All Preprocessing" (click)="disableProcessing()"></span>
              <span *ngIf="!isProcessingEnabled"
                class="button material-icons selected status-chip status-chip--cloud-off"
                title="Enable All Preprocessing" (click)="enableProcessing()"></span>

              <span *ngIf="!mlpaJobView?.isColorCorrectionEnabled"
                class="button material-icons unselected status-chip status-chip--format_paint"
                title="Enable All Color Correction" (click)="enableColorCorrection()"></span>
              <span *ngIf="mlpaJobView?.isColorCorrectionEnabled"
                class="button material-icons selected status-chip status-chip--format_paint_selected"
                title="Disable All Color Correction" (click)="disableColorCorrection()"></span>
            </ng-container>

            <ng-container *pkgHasRole="[role.Scheduler, role.Support, role.Admin]" ;>
              <span *ngIf="!isSpecial" class="button material-icons unselected status-chip status-chip--weblayout"
                title="Disable Web Layout" (click)="isSpecial = !isSpecial"></span>
              <span *ngIf="isSpecial" class="button material-icons selected status-chip status-chip--weblayout"
                title="Enable Web Layout" (click)="isSpecial = !isSpecial"></span>
            </ng-container>
          </div>

          <span class="filler"></span>
          <div class="action-group">
            <pkg-mlpa-job-action-group [mlpaJobView]="mlpaJobView" [mlpaJobActionOptions]="{
                showSubmit: this.showSubmit,
                showEdit: this.showEdit,
                showDelete: this.canDelete,
                editMode: this.editMode,
                isMlpaPage: this.isMlpaPage,
                isClonePage: this.isClonePage,
                assetDetails: this.assetDetails,
                paperTypeDetails: this.paperTypeDetails,
                hasValidColorProfile: this.hasValidColorProfile,
                jobSettings: this.jobSettings,
                isSpecial: this.isSpecial,
                inkColorDetails:this.inkColorDetails,
                printModeDetails:this.printModeDetails,
                speedDetails:this.speedDetails,
                substrateWeightDetails:this.subsrateDetails,
                overPrintVarnishDetails:this.overPrintVarnishDetails
              }" (preClone)="preClone(mlpaJobView)" (preSubmit)="preSubmit(mlpaJobView)"
              (preDelete)="preDelete(mlpaJobView)" (preCancelEdit)="preCancelEdit(mlpaJobView)"
              (setPreview)="setPreview(mlpaJobView)" (edit)="edit(mlpaJobView)" (save)="save(mlpaJobView)"
              (cancelNewJob)="cancelNewJob(mlpaJobView)"></pkg-mlpa-job-action-group>
          </div>
        </div>
      </pkg-card-header>
      <div [ngStyle]="lanePreviewStyles" pkg-card-media class="pkg-card-media">
        <pkg-lane-preview *ngIf="mlpaJobView.isProcessed || isMlpaPage; else not_found" [mlpaJobView]="mlpaJobView"
          [source]="currentPreview" [print]="!this.isMlpaPage" [bounds]="true" [backgroundImage]="true"
          [animateBackgroundImage]="true">
        </pkg-lane-preview>

        <ng-template #not_found>
          <pkg-lane-preview [source]="emptyPreview" [print]="!this.isMlpaPage" [bounds]="true" [backgroundImage]="true"
            [animateBackgroundImage]="false">
          </pkg-lane-preview>
        </ng-template>
      </div>
      <pkg-card-body>
        <div class="mlpa-job-info__section">
          <div class="mlpa-job-details__section">
            <div class="mlpa-job-details__left-section">
              <div class="content-body">
                <div *pkgHasRole="[role.Admin, role.Scheduler, role.Support]; or !isSpecial" class="content">
                  <ng-container *ngIf="!editMode || isStressJob">
                    <span *pkgHasRole="[role.Admin, role.Scheduler, role.Support];" class="title"
                      [ngClass]="{'edit-title' : editMode && !isStressJob}">Program Number</span>
                    <span *ngIf="mlpaJobView?.programNumber"
                      class="info highlight">{{mlpaJobView?.programNumber}}</span>
                    <span *ngIf="!mlpaJobView?.programNumber" class="info">N/A</span>
                  </ng-container>

                  <ng-container *pkgHasRole="[role.Operator, role.Viewer]; and editMode">
                    <span class="title">Program Number</span>
                    <span *ngIf="mlpaJobView?.programNumber"
                      class="info highlight">{{mlpaJobView?.programNumber}}</span>
                    <span *ngIf="!mlpaJobView?.programNumber" class="info">N/A</span>
                  </ng-container>

                  <ng-container *pkgHasRole="[role.Scheduler, role.Admin, role.Support] and !isStressJob">
                    <span *ngIf="mlpaJobView.isNewJob && editMode" class="title edit-title">Program Number</span>
                    <div *ngIf="mlpaJobView.isNewJob && editMode" class="field gutter">
                      <input [(ngModel)]="mlpaJobView.programNumber" placeholder="Enter a program number...">
                      <div *ngIf="mlpaJobView.programNumber.length === 0" class="settings error">Program number is
                        required. </div>
                    </div>
                  </ng-container>
                </div>
                <div *pkgHasRole="[role.Admin, role.Scheduler, role.Support]; or !isSpecial" class="content">
                  <ng-container *ngIf="!editMode || isStressJob">
                    <span *pkgHasRole="[role.Admin, role.Scheduler, role.Support];" class="title"
                      [ngClass]="{'edit-title' : editMode && !isStressJob}">Setup Number</span>
                    <span *ngIf="mlpaJobView?.setupNumber" class="info highlight">{{mlpaJobView?.setupNumber}}</span>
                    <span *ngIf="!mlpaJobView?.setupNumber" class="info">N/A</span>
                  </ng-container>

                  <ng-container *pkgHasRole="[role.Operator, role.Viewer]; and editMode">
                    <span class="title">Setup Number</span>
                    <span *ngIf="mlpaJobView?.setupNumber" class="info highlight">{{mlpaJobView?.setupNumber}}</span>
                    <span *ngIf="!mlpaJobView?.setupNumber" class="info">N/A</span>
                  </ng-container>
                  <ng-container *pkgHasRole="[role.Scheduler, role.Admin, role.Support] and !isStressJob">
                    <span *ngIf="mlpaJobView.isNewJob && editMode" class="title edit-title">Setup Number</span>
                    <div *ngIf="mlpaJobView.isNewJob && editMode" class="field gutter">
                      <input [(ngModel)]="mlpaJobView.setupNumber" placeholder="Enter a setup number...">
                      <div *ngIf="mlpaJobView.setupNumber.length === 0" class="settings error">Setup number is required.
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div *pkgHasRole="[role.Admin, role.Scheduler, role.Support]; or !isSpecial" class="content">
                  <span class="title">Blanket Number</span>
                  <span *ngIf="mlpaJobView?.blanketNumber" title="{{mlpaJobView?.blanketNumber}}"
                    class="info highlight">{{mlpaJobView?.blanketNumber}}</span>
                  <span *ngIf="!mlpaJobView?.blanketNumber" class="info">N/A</span>
                </div>
                <div class="content">
                  <span *pkgHasRole="[role.Admin, role.Scheduler, role.Support, role.Structure]; or !editMode"
                    class="title" [ngClass]="{'edit-title' : editMode && !isStressJob}">Asset Name</span>
                  <span *ngIf="!editMode || isStressJob" class="info">{{mlpaJobView.assetName}}</span>

                  <ng-container *pkgHasRole="[role.Operator, role.Viewer] and editMode">
                    <span class="title">Asset Name</span>
                    <span class="info">{{mlpaJobView.assetName}}</span>
                  </ng-container>
                  <ng-container
                    *pkgHasRole="[role.Scheduler, role.Admin, role.Support, role.Structure] and !isStressJob">
                    <div *ngIf="mlpaJobView.isNewJob && editMode" class="field gutter">
                      <pkg-search-auto-complete [disableLabel]="true" [isDropDown]="true" [disableType]="true"
                        [details]="assetDetails"
                        (detailsEventEmitter)="jobSettings.selectAsset($event.source.name, jobSettings?.paperType?.name, jobSettings?.paperWidthInInches); updateDropDowns();updatePressType($event.source.name)">
                      </pkg-search-auto-complete>
                      <div class="settings error"
                        [hidden]="selectedItemExistsInOptions(assetDetails?.textValue, assetDetails)">Select a valid
                        asset. </div>
                    </div>
                  </ng-container>
                </div>
                <div class="content">
                  <span *pkgHasRole="[role.Admin, role.Scheduler, role.Support, role.Structure]; or !editMode"
                    class="title" [ngClass]="{'edit-title' : editMode && !isStressJob}">Paper Type</span>
                  <span *ngIf="!editMode || isStressJob" class="info">{{mlpaJobView.paperTypeName}}</span>

                  <ng-container *pkgHasRole="[role.Operator, role.Viewer]; and editMode">
                    <span class="title">Paper Type</span>
                    <span class="info">{{mlpaJobView.paperTypeName}}</span>
                  </ng-container>
                  <ng-container
                    *pkgHasRole="[role.Scheduler, role.Admin, role.Support, role.Structure] and !isStressJob">
                    <div *ngIf="mlpaJobView.isNewJob && editMode" class="field gutter">
                      <pkg-search-auto-complete [disableLabel]="true" [isDropDown]="true" [disableType]="true"
                        [details]="paperTypeDetails"
                        (detailsEventEmitter)="jobSettings.selectPaperType($event?.source?.name, jobSettings.paperWidthInInches); updateDropDowns()">
                      </pkg-search-auto-complete>
                      <div class="settings error"
                        [hidden]="selectedItemExistsInOptions(paperTypeDetails?.textValue, paperTypeDetails)">Select a
                        valid paper type. </div>
                    </div>
                  </ng-container>
                </div>
                <div *ngIf="isHomePage" class="content end">
                  <span *ngIf="!mlpaJobView.isNewJob" class="title">Updated Date</span>
                  <span *ngIf="!mlpaJobView.isNewJob"
                    class="info">{{mlpaJobView.updatedDate | dateReplace: mlpaJobView.createdDate | date: 'medium'}}</span>
                </div>
                <div class="content">
                  <span class="title" [ngClass]="{'edit-title' : editMode && !isStressJob}">Paper Width (in)</span>
                  <span *ngIf="!editMode || isStressJob" class="info">{{mlpaJobView.paperWidthInInches | inches}}</span>

                  <div *ngIf="mlpaJobView.isNewJob && editMode && !isStressJob" class="field gutter">
                    <pkg-search-auto-complete #paperwidth [disableLabel]="true" [isDropDown]="true" [disableType]="true"
                      [details]="paperWidthDetails" (detailsEventEmitter)="selectPaperWidth($event); updateState();">
                    </pkg-search-auto-complete>
                    <div class="settings error"
                      [hidden]="selectedNumberExistsInOptions(paperWidthDetails?.textValue, paperWidthDetails) || paperWidthDetails.results.length === 0">
                      Select a valid paper width. </div>
                    <div class="settings error" [hidden]="paperWidthDetails.results.length !== 0">Select a valid paper
                      type first. </div>
                  </div>
                </div>
                <div class="content" *ngIf="enablePrintMode()">
                  <span *pkgHasRole="[role.Admin, role.Scheduler, role.Support, role.Structure]; or !editMode"
                    class="title" [ngClass]="{'edit-title' : editMode && !isStressJob}">Print Mode</span>
                  <span *ngIf="!editMode || isStressJob" class="info">{{printMode}}</span>

                  <ng-container *pkgHasRole="[role.Operator, role.Viewer] and editMode">
                    <span class="title">Print Mode</span>
                    <span class="info">{{printMode}}</span>
                  </ng-container>
                  <ng-container
                    *pkgHasRole="[role.Scheduler, role.Admin, role.Support, role.Structure] and !isStressJob">
                    <div *ngIf="mlpaJobView.isNewJob && editMode" class="field gutter">
                      <pkg-search-auto-complete [disableLabel]="true" [isDropDown]="true" [disableType]="true"
                        [details]="printModeDetails"
                        (detailsEventEmitter)="colorManagementSystemSettings.selectPrintMode($event?.value); updatePrintModeToLanes($event?.value)">
                      </pkg-search-auto-complete>
                      <div class="settings error"
                        [hidden]="selectedItemExistsInOptions(printModeDetails?.textValue, printModeDetails)">Select a
                        valid print mode. </div>
                    </div>
                  </ng-container>
                </div>
                <div *pkgHasRole="[role.Admin, role.Scheduler, role.Support]; or !isSpecial" class="content">
                  <span *pkgHasRole="[role.Admin, role.Scheduler, role.Support]; or !editMode" class="title"
                    [ngClass]="{'edit-title' : editMode && !isStressJob}">Margin (in)</span>
                  <span *ngIf="!editMode || isStressJob"
                    class="info">{{mlpaJobView.marginWidthInInches | inches}}</span>

                  <ng-container *pkgHasRole="[role.Operator, role.Viewer]; and editMode">
                    <span class="title">Margin (in)</span>
                    <span class="info">{{mlpaJobView.marginWidthInInches | inches}}</span>
                  </ng-container>

                  <ng-container *pkgHasRole="[role.Scheduler, role.Admin, role.Support]  and !isStressJob">
                    <div *ngIf="mlpaJobView.isNewJob && editMode" class="field gutter">
                      <input [(ngModel)]="jobSettings.marginWidthInInches" (ngModelChange)="onMarginChanged($event)"
                        type="number" id="jobMargin" min="0">
                      <div *ngIf="jobSettings.isMarginInvalid()" class="settings error">Margin should be 0" or >= 0.40"
                        for 110" paper width.</div>
                      <div *ngIf="!jobSettings.isMarginWithinLimits()" class="settings error">Margin should be between
                        0" & {{jobSettings.paperWidthInInches}}" (paper width).</div>
                    </div>
                  </ng-container>
                </div>
                <div *pkgHasRole="[role.Admin, role.Scheduler, role.Support, role.Operator]; or !isSpecial"
                  class="content">
                  <span *pkgHasRole="[role.Admin, role.Scheduler, role.Support, role.Operator]; or !editMode"
                    class="title" [ngClass]="{'edit-title' : editMode && !isStressJob}">Line Break (ft)</span>
                  <span *ngIf="!editMode || isStressJob"
                    class="info">{{mlpaJobView.lineBreakLengthInInches / 12 | feet}}</span>
                  <ng-container *pkgHasRole="[role.Viewer]; and editMode">
                    <span class="title">Line Break (ft)</span>
                    <span class="info">{{mlpaJobView.lineBreakLengthInInches / 12 | feet}}</span>
                  </ng-container>

                  <ng-container *pkgHasRole="[role.Scheduler, role.Admin, role.Support, role.Operator]">
                    <div *ngIf="mlpaJobView.isNewJob && editMode && !isStressJob" class="field gutter">
                      <input [(ngModel)]="jobSettings.lineBreakLengthInFeet" [disabled]="!isLineBreakEnabled"
                        type="number" id="lineBreak" max="200">
                      <div *ngIf="jobSettings.lineBreakLengthInFeet > 200" class="settings error">Line break must be
                        less than 200 ft.</div>
                    </div>
                  </ng-container>
                </div>
                <div class="content">
                  <span class="title">Total Width (in)</span>
                  <span class="info">{{mlpaJobView.totalWidthInInches | inches}}</span>
                </div>
                <div class="content">
                  <span class="title">Total Length (ft)</span>
                  <span class="info">{{mlpaJobView.lengthInFeet | feet}}</span>
                </div>
                <div class="content">
                  <span *ngIf="!mlpaJobView.isNewJob" class="title">Created Date</span>
                  <span *ngIf="!mlpaJobView.isNewJob" class="info">{{mlpaJobView.createdDate | date: 'medium'}}</span>
                </div>
              </div>
              <ng-container *ngIf="showJobCharacterizationValues">
                <div class="characterization-values-header">
                  Color Management
                  <small>Job Characterization Values</small>
                </div>
                <div class="characterization-values-body">
                  <div class="content" style="width: max-content;">
                    <span *pkgHasRole="[role.Admin, role.Scheduler, role.Support, role.Structure]; or !editMode"
                      class="title" [ngClass]="{'edit-title' : editMode && !isStressJob}">Color Management System</span>
                    <span *ngIf="!editMode || isStressJob" class="info">{{mlpaJobView.colorManagementSystemName}}</span>

                    <ng-container *pkgHasRole="[role.Operator, role.Viewer] and editMode">
                      <span class="title">Color Management System</span>
                      <span class="info">{{mlpaJobView.colorManagementSystemName}}</span>
                    </ng-container>
                    <ng-container
                      *pkgHasRole="[role.Scheduler, role.Admin, role.Support, role.Structure] and !isStressJob">
                      <div *ngIf="mlpaJobView.isNewJob && editMode" class="field gutter">
                        <pkg-search-auto-complete [disableLabel]="true" [isDropDown]="true" [disableType]="true"
                          [details]="colorManagementSystemDetails"
                          (detailsEventEmitter)="colorManagementSystemSettings.selectColorManagementSystem($event?.source?.id)">
                        </pkg-search-auto-complete>
                        <div class="settings error"
                          [hidden]="selectedItemExistsInOptions(colorManagementSystemDetails?.textValue, colorManagementSystemDetails)">
                          Select a valid color management system. </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="content" style="width: max-content;">
                    <span class="title">Print Mode</span>
                    <span class="info">{{printMode}}</span>
                  </div>
                  <div class="content" style="width: max-content;">
                    <span *pkgHasRole="[role.Admin, role.Scheduler, role.Support, role.Structure]; or !editMode"
                      class="title" [ngClass]="{'edit-title' : editMode && !isStressJob}">Ink Colors</span>
                    <span *ngIf="!editMode || isStressJob" class="info">{{mlpaJobView.colorCorrectionInfo.inkColors}}</span>

                    <ng-container *pkgHasRole="[role.Operator, role.Viewer] and editMode">
                      <span class="title">Ink Colors</span>
                      <span class="info">{{mlpaJobView.colorCorrectionInfo.inkColors}}</span>
                    </ng-container>
                    <ng-container
                      *pkgHasRole="[role.Scheduler, role.Admin, role.Support, role.Structure] and !isStressJob">
                      <div *ngIf="mlpaJobView.isNewJob && editMode" class="field gutter">
                        <pkg-search-auto-complete [disableLabel]="true" [isDropDown]="true" [disableType]="true"
                          [details]="inkColorDetails"
                          (detailsEventEmitter)="colorManagementSystemSettings.selectInkColors($event?.value); updateInkColorsToLanes()">
                        </pkg-search-auto-complete>
                        <div class="settings error"
                          [hidden]="selectedItemExistsInOptions(inkColorDetails?.textValue, inkColorDetails)">Select a
                          valid ink color. </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="content" style="width: max-content;">
                    <span *pkgHasRole="[role.Admin, role.Scheduler, role.Support, role.Structure]; or !editMode"
                      class="title" [ngClass]="{'edit-title' : editMode && !isStressJob}">Over Print Varnish</span>
                    <span *ngIf="!editMode || isStressJob" class="info">{{mlpaJobView.colorCorrectionInfo.overPrintVarnish}}</span>

                    <ng-container *pkgHasRole="[role.Operator, role.Viewer] and editMode">
                      <span class="title">Over Print Varnish</span>
                      <span class="info">{{mlpaJobView.colorCorrectionInfo.overPrintVarnish}}</span>
                    </ng-container>
                    <ng-container
                      *pkgHasRole="[role.Scheduler, role.Admin, role.Support, role.Structure] and !isStressJob">
                      <div *ngIf="mlpaJobView.isNewJob && editMode" class="field gutter">
                        <pkg-search-auto-complete [disableLabel]="true" [isDropDown]="true" [disableType]="true"
                          [details]="overPrintVarnishDetails"
                          (detailsEventEmitter)="colorManagementSystemSettings.selectOverPrintVarnish($event?.value); updateOverPrintVarnishToLanes()">
                        </pkg-search-auto-complete>
                        <div class="settings error"
                          [hidden]="selectedItemExistsInOptions(overPrintVarnishDetails?.textValue, overPrintVarnishDetails)">
                          Select a valid over print varnish. </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="content" style="width: max-content;">
                    <span *pkgHasRole="[role.Admin, role.Scheduler, role.Support, role.Structure]; or !editMode"
                      class="title" [ngClass]="{'edit-title' : editMode && !isStressJob}">Substrate</span>
                    <span *ngIf="!editMode || isStressJob" class="info">{{mlpaJobView.colorCorrectionInfo.substrateWeight}}</span>

                    <ng-container *pkgHasRole="[role.Operator, role.Viewer] and editMode">
                      <span class="title">Substrate</span>
                      <span class="info">{{mlpaJobView.colorCorrectionInfo.substrateWeight}}</span>
                    </ng-container>
                    <ng-container
                      *pkgHasRole="[role.Scheduler, role.Admin, role.Support, role.Structure] and !isStressJob">
                      <div *ngIf="mlpaJobView.isNewJob && editMode" class="field gutter">
                        <pkg-search-auto-complete [disableLabel]="true" [isDropDown]="true" [disableType]="true"
                          [details]="subsrateDetails"
                          (detailsEventEmitter)="colorManagementSystemSettings.selectSubstrate($event?.value); updateSubstrateToLanes()">
                        </pkg-search-auto-complete>
                        <div class="settings error"
                          [hidden]="selectedItemExistsInOptions(subsrateDetails?.textValue, subsrateDetails)">Select a
                          valid subsrate weight. </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="content" style="width: max-content;">
                    <span *pkgHasRole="[role.Admin, role.Scheduler, role.Support, role.Structure]; or !editMode"
                      class="title" [ngClass]="{'edit-title' : editMode && !isStressJob}">Speed (fpm)</span>
                    <span *ngIf="!editMode || isStressJob" class="info">{{mlpaJobView.colorCorrectionInfo.speed}}</span>

                    <ng-container *pkgHasRole="[role.Operator, role.Viewer] and editMode">
                      <span class="title">Speed</span>
                      <span class="info">{{mlpaJobView.colorCorrectionInfo.speed}}</span>
                    </ng-container>
                    <ng-container
                      *pkgHasRole="[role.Scheduler, role.Admin, role.Support, role.Structure] and !isStressJob">
                      <div *ngIf="mlpaJobView.isNewJob && editMode" class="field gutter">
                        <pkg-search-auto-complete [disableLabel]="true" [isDropDown]="true" [disableType]="true"
                          [details]="speedDetails"
                          (detailsEventEmitter)="colorManagementSystemSettings.selectSpeed($event?.value); updateSpeedToLanes()">
                        </pkg-search-auto-complete>
                        <div class="settings error"
                          [hidden]="selectedItemExistsInOptions(speedDetails?.textValue, speedDetails)">Select a valid
                          speed value. </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="content" style="width: max-content;">
                    <span class="title">Press Type</span>
                    <span class="info">{{mlpaJobView.colorCorrectionInfo.pressType}}</span>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="mlpa-job-details__right-section">
              <div *pkgHasRole="[role.Admin, role.Scheduler, role.Support, role.Operator]; and editMode"
                class="job-edit-options">
                <button *ngIf="mlpaJobView.mlpaLanes.length > 3" class="button button--small button-fab"
                  title="Optimize All Up" (click)="optimizeAllUp()">
                  <span class="material-icons">&#xE5D8;</span>
                </button>
                <button *ngIf="mlpaJobView.mlpaLanes.length > 3" class="button button--small button-fab"
                  title="Optimize All Down" (click)="optimizeAllDown()">
                  <span class="material-icons">&#xE5DB;</span>
                </button>
              </div>
              <div class="toggle" title="Enable/Disable Lanes" (click)="toggleLanes()">
                <label class="switch switch-small">
                  <input type="checkbox" [(ngModel)]="toggleLaneState">
                  <span class="slider slider-small"></span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <pkg-mlpa-lane-info [mlpaJobView]="mlpaJobView" [isSpecial]="isSpecial" [mlpaLanes]="mlpaJobView?.mlpaLanes"
          [jobSettings]="jobSettings" [editMode]="editMode" [printMode]="printMode" [assetType]="assetType" (deleteEmitter)="deleteLane($event)"
          (setPreviewEmitter)="setPreview($event)" (selectWebWeaveEmitter)="selectWebWeave($event)"
          (enableColorCorrectionEmitter)="refreshJobCharacterizationDropDownSelections()" (selectLockEmitter)="enableLockFromJob($event)">
        </pkg-mlpa-lane-info>
      </pkg-card-body>
    </pkg-card>
  </ng-container>
</div>

<div *ngIf="showConfirm || showDelete || showCancelConfirm" class="modal-container">
  <div class="modal modal--small">
    <div class="modal__header">
      <h3 *ngIf="showConfirm && !mlpaJobView.isNewJob && !isSpecial">Confirm Submit</h3>
      <h3 *ngIf="showConfirm && !mlpaJobView.isNewJob && isSpecial">Confirm Submit For Web Layout</h3>
      <h3 *ngIf="showConfirm && mlpaJobView.isNewJob && isSpecial">Confirm Save For Web Layout</h3>
      <h3 *ngIf="showConfirm && mlpaJobView.isNewJob && !isSpecial">Confirm Save</h3>
      <h3 *ngIf="showDelete">Confirm Delete</h3>
      <h3 *ngIf="showCancelConfirm">Confirm Cancel</h3>
      <button class="icon-button" (click)="closeSubmit()">
        <i class="material-icons">&#xE5CD;</i>
      </button>
    </div>
    <div class="modal__content">
      <div *ngIf="warningMessages.length > 0 && showConfirm && mlpaJobView.isNewJob" class="modal__messages"
        [class.messages--error]="warningMessages.length > 0">
        <ul>
          <li *ngFor="let message of warningMessages" class="message-warning">
            <i class="material-icons">&#xE002;</i>
            <span>{{message}}</span>
          </li>
        </ul>
      </div>
      <p *ngIf="showConfirm && !mlpaJobView.isNewJob" class="submit-details">Are you sure you want to submit the MLPA
        Job,
        <strong>{{mlpaJobView.name}} <ng-container *ngIf="!mlpaJobView.isNewJob">-{{mlpaJobView.uniqueId}}
          </ng-container></strong>
        to the press?</p>
      <p *ngIf="showConfirm && mlpaJobView.isNewJob" class="submit-details">Are you sure you want to save the MLPA Job,
        <strong>{{mlpaJobView.name}} <ng-container *ngIf="!mlpaJobView.isNewJob">-{{mlpaJobView.uniqueId}}
          </ng-container></strong>?</p>
      <p *ngIf="showDelete" class="submit-details">Are you sure you want to delete the MLPA Job,
        <strong>{{mlpaJobView.name}}-{{mlpaJobView.uniqueId}}</strong>?</p>
      <p *ngIf="showCancelConfirm" class="submit-details">Are you sure you want to cancel your edit for this MLPA Job?
      </p>
    </div>
    <div class="modal__controls">
      <button *ngIf="showConfirm && !mlpaJobView.isNewJob" [disabled]="isSubmitting"
        [hidden]="mlpaJobView.isLinkedMlpaJob" class="button button--green confirm-submit__button-ga"
        (click)="confirmSubmit()" type="button">
        <i class="material-icons">&#xE2C6;</i>
        <span>Submit</span>
      </button>
      <button
        *pkgHasRole="[role.Admin, role.Scheduler, role.Support, role.Operator]; and showConfirm && mlpaJobView.isNewJob && isMlpaPage && !isSpecial"
        [disabled]="isSubmitting" [hidden]="mlpaJobView.isLinkedMlpaJob"
        class="button button--green save-finish__button-ga" (click)="saveFinishOrSearch(mlpaJob, true)" type="button">
        <i class="material-icons">&#xE2C6;</i>
        <span>Save and Finish</span>
      </button>
      <button
        *pkgHasRole="[role.Admin, role.Scheduler, role.Support]; and showConfirm && mlpaJobView.isNewJob && isMlpaPage && !isSpecial"
        [disabled]="isSubmitting" [hidden]="mlpaJobView.isLinkedMlpaJob"
        class="button button--green save-search__button-ga" (click)="saveFinishOrSearch(mlpaJob)" type="button">
        <i class="material-icons">&#xE2C6;</i>
        <span>Save and Search</span>
      </button>
      <button
        *pkgHasRole="[role.Admin, role.Scheduler, role.Support, role.Structure]; and showConfirm && mlpaJobView.isNewJob && isMlpaPage && isSpecial"
        [disabled]="isSubmitting" [hidden]="mlpaJobView.isLinkedMlpaJob" class="button button--green"
        (click)="saveSpecialJob(mlpaJob, true)" type="button">
        <i class="material-icons">&#xE2C6;</i>
        <span>Save and Finish</span>
      </button>
      <button
        *pkgHasRole="[role.Admin, role.Scheduler, role.Support, role.Structure]; and showConfirm && mlpaJobView.isNewJob && isMlpaPage && isSpecial"
        [disabled]="isSubmitting" [hidden]="mlpaJobView.isLinkedMlpaJob" class="button button--green"
        (click)="saveSpecialJob(mlpaJob)" type="button">
        <i class="material-icons">&#xE2C6;</i>
        <span>Save and Search</span>
      </button>
      <button *ngIf="showDelete" [disabled]="isDeleting" [hidden]="mlpaJobView.isLinkedMlpaJob"
        class="button button--green confirm-delete__button-ga" (click)="confirmDelete()" type="button">
        <i class="material-icons">&#xE2C6;</i>
        <span>Yes</span>
      </button>
      <button *ngIf="showCancelConfirm" [hidden]="mlpaJobView.isLinkedMlpaJob" class="button button--green"
        (click)="confirmCancelEdit()" type="button">
        <i class="material-icons">&#xE2C6;</i>
        <span>Yes</span>
      </button>
      <button class="button" [disabled]="isSubmitting || isDeleting" (click)="closeSubmit()" type="button">
        <i class="material-icons">&#xE5C9;</i>
        <span>Cancel</span>
      </button>
      <div *ngIf="isSubmitting || isDeleting" class="modal__loading">
        <div class="loading loading--small"></div>
        <span *ngIf="isSubmitting">Saving MLPA</span>
        <span *ngIf="isDeleting">Deleting MLPA</span>
      </div>
    </div>
  </div>
</div>
<pkg-clone-mlpa-jobs (cloneJob)="clone()" (closeModal)="cancelClone()"></pkg-clone-mlpa-jobs>
