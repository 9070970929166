import { Component, OnInit, HostBinding } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Role } from '../../pages/admin/shared/role';
import { environment } from '../../../environments/environment';
import { ConfigService } from '../config/config.service';
import { UserPreference } from '../config/config.interface';
import { ConfigStrategy } from '../config/config-strategy.enum';
import { Router } from '@angular/router';
import { PkgThemeService } from '../theme/pkg-theme.service';
import { Theme } from '../theme/theme.enum';

@Component({
  selector: 'pkg-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  @HostBinding('class.collapsed') isCollapsed: boolean;
  navMenuWidth = 192;
  userPreference: UserPreference;
  showGeneratedProfile: boolean;
  showConfirm: boolean;
  darkModeToggle: boolean;
  tempPhotoColor: string;
  toggleLaneState: boolean;

  get showMlpa(): boolean {
    if (this.authService.user.role === Role.Scheduler
      || this.authService.user.role === Role.Support
      || this.authService.user.role === Role.Structure) {
      return true;
    }
    return false;
  }

  get getLogoStyle() {
    const backgroundStyles = {
      filter: this._pkgThemeService.getTheme() === 'light-theme' ?
      'invert(17%) sepia(40%) saturate(2919%) hue-rotate(173deg) brightness(90%) contrast(103%)' :
      'invert(100%) sepia(47%) saturate(21%) hue-rotate(102deg) brightness(105%)'
    };
    return backgroundStyles;
  }

  get showSchedule(): boolean {
    if (
      this.authService.user.role === Role.Scheduler ||
      this.authService.user.role === Role.Operator ||
      this.authService.user.role === Role.Viewer ||
      this.authService.user.role === Role.Support
    ) {
      return true;
    }
    return false;
  }
  get showAdmin(): boolean {
    if (
      this.authService.user.role === Role.Admin ||
      this.authService.user.role === Role.Support
    ) {
      return true;
    }
    return false;
  }

  get showRollMap() {
    if (
      this.authService.user.role === Role.Admin ||
      this.authService.user.role === Role.Support ||
      this.authService.user.role === Role.Scheduler ||
      this.authService.user.role === Role.Viewer ||
      this.authService.user.role === Role.Operator
    ) {
      return true;
    }
    return false;
  }

  get showPrintProcessor() {
    return this.authService.user.role === Role.Support;
  }

  get isTesting(): boolean {
    if (environment.environmentName) {
      const envName = environment.environmentName.toLowerCase();
      if (envName.includes('prod')) {
        return false;
      }
    }
    return true;
  }
  get versionNumber(): string {
    return environment.releaseNumber;
  }

  constructor(
    public readonly authService: AuthService,
    private readonly _configService: ConfigService,
    public readonly _pkgThemeService: PkgThemeService,
    private readonly _router: Router
  ) {
    this.userPreference = {} as UserPreference;

    // Get the user preference from localstorage
    this.isCollapsed = localStorage.getItem('navigationCollapsed') === 'true';
    this.setMenuWidth();
    this.userPreference.navigationCollapsed = this.isCollapsed;
    this._configService.setByValue(
      'navigationCollapsed',
      this.userPreference.navigationCollapsed,
      ConfigStrategy.LOCAL_STORAGE
    );
  }
  ngOnInit(): void {
    this.tempPhotoColor = this.randomHSL();
    this.darkModeToggle = false;

    this.toggleLaneState = this._configService.getConfigValue('currentTheme', ConfigStrategy.LOCAL_STORAGE) === Theme.DARK_THEME;
  }
  randomHSL(): string {
    return `hsla(${360 * Math.random()},70%,70%,0.8)`;
  }

  toggleDarkMode(): void {
    this.darkModeToggle = !this.darkModeToggle;
  }
  toggleMenu(): void {
    this.isCollapsed = !this.isCollapsed;
    this.setMenuWidth();
    this.userPreference.navigationCollapsed = this.isCollapsed;
    this._configService.setByValue(
      'navigationCollapsed',
      this.isCollapsed,
      ConfigStrategy.LOCAL_STORAGE
    );
  }

  setMenuWidth(): void {
    this.navMenuWidth = this.isCollapsed ? 60 : 192;
  }

  logout(): void {
    this.authService.logOut();
    this._router.navigate(['/login']);
  }

  toggleNavigation(): void {
    if (window.screen.width <= 768) {
      this.toggleMenu();
    }
  }
}
