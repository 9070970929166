<div *ngIf="service.alerts.length > 0" class="alerts-container">
  <ul class="alerts">
    <li *ngFor="let alert of service.alerts">
      <div class="alert" [ngClass]="alert.typeClass">
        <div class="alert__icon">
          <i *ngIf="alert.showInfo" class="material-icons">&#xE88E;</i>
          <i *ngIf="alert.showSuccess" class="material-icons">&#xE86C;</i>
          <i *ngIf="alert.showError" class="material-icons">&#xE160;</i>
        </div>
        <div class="alert__details">
          <div class="alert__details-title">{{alert.title}}</div>
          <div class="alert__details-message" [innerHTML]="alert.message"></div>
        </div>
        <div class="alert__controls">
          <button (click)="close(alert.id)">
            <i class="material-icons">&#xE5CD;</i>
          </button>
        </div>
      </div>
    </li>
  </ul>
</div>
