import { Component, OnInit, Input } from '@angular/core';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'pkg-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() containerStyle?: NgStyle;
  @Input() headerStyle?: NgStyle;
  @Input() bodyStyle?: NgStyle;
  @Input() footerStyle?: NgStyle;
  constructor() { }

  ngOnInit(): void {
  }

}
