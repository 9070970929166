import { Component, Input, OnDestroy, Output, EventEmitter, OnInit } from '@angular/core';
import { Subscription, Observable, timer } from 'rxjs';

import { ProductDesignSearchService } from '../mlpa/product-design-search/product-design-search.service';
import { SearchAutoComplete } from './search-auto-complete';
import { SelectItem } from './select-item';
import { PagedResult } from '@shared/paged-result';
import { KeyCode } from '@shared/keycode.enum';
import { SearchType } from './search-type.enum';
import { AssetType } from '@shared/asset-type.enum';
import { MlpaService } from '@app/pages/mlpa/shared/mlpa.service';

@Component({
  selector: 'pkg-search-auto-complete',
  templateUrl: './search-auto-complete.component.html',
  styleUrls: ['./search-auto-complete.component.scss']
})
export class SearchAutoCompleteComponent implements OnDestroy, OnInit {
  @Input() details: SearchAutoComplete;
  @Input() valueLength = 1;
  @Input() isDropDown?: boolean;
  @Input() disableType?: boolean;
  @Input() disableAutoComplete?: boolean;
  @Input() tag?: string;
  @Input() disableLabel?: boolean;
  @Output() detailsEventEmitter?: EventEmitter<SelectItem> = new EventEmitter<SelectItem>();
  @Input() assetType: AssetType;
  findResultSubscription: Subscription;
  loadingSubscription: Subscription;
  autoCompleteResultSubscription: Subscription;
  showResults: boolean;
  resultsCount: number;
  totalCount: number;
  loading: boolean;
  selectedIndex = -1;
  staticStore: SelectItem[];
  isEventTriggered = false;

  get showType(): boolean {
    if (this.details.textValue && !this.disableType) {
      return true;
    }
    return false;
  }
  get typeClass(): string {
    if (this.details.searchType === 'is') {
      return 'autocomplete__tag autocomplete__tag--green';
    }
    return 'autocomplete__tag autocomplete__tag--purple';
  }

  constructor(private readonly _service: ProductDesignSearchService, private readonly _mlpaJobService: MlpaService) { }

  ngOnInit(): void {
    // set the static store if there is no url endpoint.
    if (this.details.url == null) {
      this.staticStore = this.details.results;
    }
  }

  ngOnDestroy(): void {
    // Clean up subscriptions.
    this._unsubscribeFromLoading();
    this._unsubscribeFromFindResults();
    this._unsubscribeFromAutoComplete();
  }

  keydown(e): void {
    // Set isEventTriggered to true, to prevent blur event method from executing as well
    this.isEventTriggered = true;

    // Capture Tab on keydown as on keyup it is on the next field/event
    if (e.keyCode === KeyCode.Tab) {
      this._selectItem();
    }
  }

  keyup(e): void {
    switch (e.keyCode) {
      case KeyCode.Esc: // ESC - should hide dropdown
        this.close();
        break;
      case KeyCode.Up: // Up - select previous item
        this.up();
        break;
      case KeyCode.Down: // Down - select next item
        this.down();
        break;
      case KeyCode.Left:  // Ignore Left, Right, Tab, and Shift
      case KeyCode.Right:
      case KeyCode.Enter: // Enter - pick item
        this._selectItem();
        break;
      case KeyCode.Shift:
        break;
      default:
        if (this.details.textValue.length >= this.valueLength) {
          if (this.details.url != null) {
            // Wait for the person to stop typing for 300ms. After that, go find the autocomplete results
            this._unsubscribeFromFindResults();
            this.findResultSubscription = timer(300).subscribe(() => this._findResults());
          } else {
            // Find the results
            if (this.details.textValue.length >= 1) {
              const pagedResult = new PagedResult<SelectItem>();
              pagedResult.results = this._findResulsFromStaticStore();
              pagedResult.pageSize = this.staticStore.length;
              pagedResult.resultsCount = pagedResult.results.length;
              pagedResult.totalCount = pagedResult.results.length;
              this._setPagedResult(pagedResult);
            } else {
              this.details.results = this.staticStore;
            }
          }
        } else {
          this.details.results = this.staticStore;
        }
        break;
    }
    this.isEventTriggered = false;
  }
  focus(): void {
    // If results already exist, show the dropdown list when the user focuses on the input
    // If nothing is set, go and get an "initial set" of results
    if (this.disableAutoComplete) {
      return;
    }

    if (this.details.results && this.details.results.length > 0) {
      this.showResults = true;
    } else {
      this._unsubscribeFromLoading();
      this.loadingSubscription = timer(500).subscribe(() => this.loading = true);

      if (this.details.options.type != null && this.details.options.type === SearchType.MlpaJobs) {
        this.autoCompleteResultSubscription = this._mlpaJobService.getJobsAutoComplete('').subscribe(
          pagedResult => this._setPagedResult(pagedResult),
          err => this._clearPagedResult(err));
      } else if (this.details.options.type != null && this.details.options.type === SearchType.Offline) {
        this.autoCompleteResultSubscription = this._service.findProductDesignsAutoComplete('').subscribe(
          pagedResult => this._setPagedResult(pagedResult),
          err => this._clearPagedResult(err));
      } else if (typeof (this.details.url) !== 'undefined' && this.details.url !== null) {
        this.autoCompleteResultSubscription = this._service.findAutoCompleteResults('', this.details.url, this.assetType).subscribe(
          pagedResult => this._setPagedResult(pagedResult),
          err => this._clearPagedResult(err));
      } else {
        this._unsubscribeFromLoading();
      }
    }
  }
  close(): void {
    this.showResults = false;
    if (this.details.textValue.length < this.valueLength) {
      this.details.term = '';
      this.details.textValue = '';
    }
    this._unsubscribeFromFindResults();
    this._unsubscribeFromLoading();
    this._unsubscribeFromAutoComplete();
  }
  up(): void {
    if (this.selectedIndex > 0) {
      this.selectedIndex--;
    }
    if (this.selectedIndex < this.details.results.length) {
      const oldResult = this.details.results[this.selectedIndex + 1];
      oldResult.selected = false;
    }
    const result = this.details.results[this.selectedIndex];
    result.selected = true;
  }
  down(): void {
    if (this.selectedIndex < this.details.results.length - 1) {
      this.selectedIndex++;
    }
    if (this.selectedIndex > 0) {
      const oldResult = this.details.results[this.selectedIndex - 1];
      oldResult.selected = false;
    }
    const result = this.details.results[this.selectedIndex];
    result.selected = true;
  }
  select(result: SelectItem, index: number, isEventTriggered: boolean): void {
    // If the triggered event called this method, set isEventTriggered to true, to prevent blur event method from executing as well
    if (isEventTriggered) {
      this.isEventTriggered = isEventTriggered;
    }

    // Set selectedIndex to the current selected index (duh)
    this.selectedIndex = index;

    // Clear all existing selected values
    this.details.results.forEach(r => {
      r.selected = false;
    });
    result.selected = true;
    this.details.term = result.id;
    this.details.textValue = result.value;
    this.details.isExactSearch = true;
    this.showResults = false;

    // emit the value if emitter is present.
    if (this.detailsEventEmitter) {
      this.detailsEventEmitter.emit(this.details.results[this.selectedIndex]);
    }
  }
  selectOnBlur(): void {
    // If isEventTriggered is true, don't do anything
    if (this.isEventTriggered) {
      this.isEventTriggered = false;
    } else {
      this._selectItem();
    }
  }
  private _selectItem() {
    let index = this.selectedIndex;

    // See if the exact value matches an item in the array, if so, get its index
    if (index < 0) {
      index = this.details.results.findIndex(r => r.value.toLowerCase() === this.details.textValue.toLowerCase());
    }

    if (index >= 0) {
      this.select(this.details.results[index], index, false);
    } else {
      // emit the value if emitter is present.
      if (this.detailsEventEmitter) {
        this.detailsEventEmitter.emit({ id: '-1', ['value']: this.details.textValue } as SelectItem);
      }
    }
  }
  private _findResulsFromStaticStore(): SelectItem[] {
    // Find the result
    return this.staticStore.filter((result) => {
      if (typeof result.value === 'number') {
        return result.value === this.details.textValue;
      } else {
        return result.value.toLowerCase().includes(this.details.textValue.toString().toLowerCase());
      }
    });
  }
  private _findResults(): void {
    this._unsubscribeFromFindResults();
    this._unsubscribeFromLoading();

    this.details.isExactSearch = false;
    // Only showing the loading spinner after 500ms
    this.loadingSubscription = timer(500).subscribe(() => this.loading = true);

    if (this.details.options.type != null && this.details.options.type === SearchType.MlpaJobs) {
      this.autoCompleteResultSubscription = this._mlpaJobService.getJobsAutoComplete(this.details.textValue).subscribe(
        pagedResult => this._setPagedResult(pagedResult),
        err => this._clearPagedResult(err));
    } else if (this.details.options.type != null && this.details.options.type === SearchType.Offline) {
      this.autoCompleteResultSubscription = this._service.findProductDesignsAutoComplete(this.details.textValue).subscribe(
        pagedResult => this._setPagedResult(pagedResult),
        err => this._clearPagedResult(err));
    } else {
      this.autoCompleteResultSubscription = this._service.findAutoCompleteResults(this.details.textValue, this.details.url, this.assetType).subscribe(
        pagedResult => this._setPagedResult(pagedResult),
        err => this._clearPagedResult(err));
    }
  }
  private _setPagedResult(pagedResult: PagedResult<SelectItem>): void {
    this.details.results = pagedResult.results;
    this.resultsCount = pagedResult.resultsCount;
    this.totalCount = pagedResult.totalCount;
    this.showResults = true;
    this.selectedIndex = -1;
    this._unsubscribeFromLoading();
  }
  private _clearPagedResult(err): void {
    this.details.results = [];
    this.resultsCount = 0;
    this.totalCount = 0;
    this.showResults = true;
    this.selectedIndex = -1;
    this._unsubscribeFromLoading();
  }
  private _unsubscribeFromFindResults(): void {
    if (this.findResultSubscription) {
      this.findResultSubscription.unsubscribe();
    }
  }
  private _unsubscribeFromLoading(): void {
    this.loading = false;
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
  }
  private _unsubscribeFromAutoComplete(): void {
    if (this.autoCompleteResultSubscription) {
      this.autoCompleteResultSubscription.unsubscribe();
    }
  }
}
