import { Component, OnInit, OnDestroy } from '@angular/core';
import { StatusService } from './status.service';
import { StatusResults } from './status-results';
import { of, Subscription, interval, forkJoin } from 'rxjs';
import { catchError, startWith } from 'rxjs/operators';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AlertsService } from '../alerts/alerts.service';
import { AlertType } from '../alerts/alert-type.enum';
import loadingMessages from '../loading-messages';
import { ActiveEvents } from './active-events';
import { PkgThemeService } from '@shared/theme/pkg-theme.service';

@Component({
  selector: 'pkg-status-page',
  templateUrl: './status-page.component.html',
  styleUrls: ['./status-page.component.scss'],
  animations: [
    trigger('accordion', [
      state('close', style({
        opacity: '0',
        overflow: 'hidden',
        height: '0px',
      })),
      state('open', style({
        height: '*',
      })),
      transition('close <=> open', animate('200ms ease-out')),
    ])
  ]
})
export class StatusPageComponent implements OnInit, OnDestroy {
  refreshedDateTime: Date;
  apiStatusResults: StatusResults[] = [];
  activeEvents: ActiveEvents[] = [];
  loading = true;
  health: string;
  titleName: string;
  activeEventsTitle: string;
  activeStatusImages: [];
  showMore = {};
  loadingMessage: string;
  timedSubscription: Subscription;

  constructor(
    private readonly _statusService: StatusService,
    private readonly _alertService: AlertsService,
    private readonly _pkgThemeService?: PkgThemeService
    ) { }

  ngOnDestroy(): void {
    if (this.timedSubscription) {
      this.timedSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.timedSubscription = interval(120000).pipe(startWith(0)).subscribe(() => this.loadStatus());
  }

  loadStatus(): void {
    this.setLoadingMessage();
    this.loading = true;
    this.getActiveEventsTitle();

    // Tartan.Api status
    const tartanApiStatus = this._statusService.getTartanApiStatus().pipe(catchError((error) => {
      this._alertService.add('Status Page Error', 'Failed to get Tartan Health status information', AlertType.Error);
      return of(undefined);
    }));

    // Warp status
    const warpApiStatus = this._statusService.getWarpApiStatus().pipe(catchError((error) => {
      this._alertService.add('Status Page Error', 'Failed to get Warp Health status information', AlertType.Error);
      return of(undefined);
    }));

    // Owl status
    const owlApiStatus = this._statusService.getOwlApiStatus().pipe(catchError((error) => {
      this._alertService.add('Status Page Error', 'Failed to get Owl Health status information', AlertType.Error);
      return of(undefined);
    }));

    // Magpie status
    const magpieApiStatus = this._statusService.getMagpieApiStatus().pipe(catchError((error) => {
      this._alertService.add('Status Page Error', 'Failed to get Magpie Health status information', AlertType.Error);
      return of(undefined);
    }));

    // Hoopoe status
    const hoopoeApiStatus = this._statusService.getHoopoeApiStatus().pipe(catchError((error) => {
      this._alertService.add('Status Page Error', 'Failed to get Hoopoe Health status information', AlertType.Error);
      return of(undefined);
    }));

    // Todo: Add Parsec status
    // Parsec is inaccesible from Tartan.Web, connectivity issue between AWS legacy and Ng accounts
    // Parsec health endpoint is also inaccessible from Tartan.Api, recieving 403 forbidden response

    // Todo: Add Wagtail status

    forkJoin([tartanApiStatus, warpApiStatus, owlApiStatus, magpieApiStatus, hoopoeApiStatus])
      .subscribe((results) => {
        if (results != null) {
          const filteredResults = results.filter(element => element);
          this.apiStatusResults = [];
          filteredResults.forEach(element => {
            this.apiStatusResults.push(new StatusResults(element));
          });
          this.loading = false;
          this.refreshedDateTime = new Date();
          this.activeEvents = this.getActiveEvents(filteredResults);
          this.getActiveEventsTitle(filteredResults);
        }
      });
  }

  getHealthStatus(status: StatusResults): string {
    const healthySet = new Set(status?.dependencies?.map(x => x.isHealthy));
    // if status = .isHealthy will return healthy status icon
    if (status.isHealthy) {
      return 'healthy';
    }
    // if all are unhealthy return unhealthy
    if (healthySet[0] === false) {
      return 'unhealthy';
    // if status is not healthy and >=1 is healthy, return degraded
    } else {
      return 'degraded';
    }
  }

  getActiveEvents(statusResults: StatusResults[]): { statusName: string; dependencyName: string; message: string }[] {
    // Clear the events
    const activeEvents = [];
    // loop through status and loop through their dependencies and if isHealthy
    // is false push it to the array.
    statusResults.forEach((status) => {
      status.dependencies.forEach((dependency) => {
        if (dependency.isHealthy === false) {
          const activeStatus = {
            statusName: status.name, dependencyName: dependency.name, message: dependency.message
          };
          activeEvents.push(activeStatus);
        }
      });
    });
    return activeEvents;
  }

  get getLogoStyle() {
    const backgroundStyles = {
      filter: this._pkgThemeService.getTheme() === 'light-theme' ?
      'invert(17%) sepia(40%) saturate(2919%) hue-rotate(173deg) brightness(90%) contrast(103%)' :
      'invert(100%) sepia(47%) saturate(21%) hue-rotate(102deg) brightness(105%)'
    };
    return backgroundStyles;
  }

  getActiveEventsTitle(statusResults: StatusResults[] = []): void {
      if (!statusResults || this.loading) {
        this.titleName = 'Loading...';
        return;
      }
      const eventSet = new Set(statusResults?.map(x => x.isHealthy));
      if (eventSet.size === 1 && eventSet.has(true)) {
        this.titleName = 'Everything looks good';
      } else if (eventSet.size === 1 && eventSet.has(false)) {
        this.titleName = 'Everything is broken';
      } else {
        this.titleName = 'Experiencing a service degradation';
      }
  }

  toggleShowMore(i): void {
    this.showMore[i] = !this.showMore[i];
  }

  // Sets a random loading message from the shared loading messages file
  setLoadingMessage(): void {
    const min = 0;
    const max = loadingMessages.length - 1;
    const index = Math.floor(Math.random() * (max - min + 1)) + min;
    this.loadingMessage = loadingMessages[index];
  }

}
