<div *ngIf="showConfirm || showDelete" class="modal-container">
    <div class="modal modal--small">
      <div class="modal__header">
        <h3 *ngIf="showConfirm">Confirm Submit</h3>
        <h3 *ngIf="showDelete">Confirm Delete</h3>
        <button class="icon-button" (click)="closeSubmit()" [disabled]="isSubmitting || isDeleting">
          <i class="material-icons">&#xE5CD;</i>
        </button>
      </div>
      <div class="modal__content">
        <p *ngIf="showConfirm" class="submit-details">Are you sure you want to submit the MLPA Job,
          <strong>{{mlpaJob.name}}-{{mlpaJob.uniqueId}}</strong>
          to the press?</p>
        <p *ngIf="showDelete" class="submit-details">Are you sure you want to delete the MLPA Job,
          <strong>{{mlpaJob.name}}-{{mlpaJob.uniqueId}}</strong>?</p>
      </div>
      <div class="modal__controls">
        <button *ngIf="showConfirm" [disabled]="isSubmitting" [hidden]="mlpaJob.isLinkedMlpaJob" class="button button--green confirm-submit__button-ga" (click)="confirmSubmit()" type="button">
          <i class="material-icons">&#xE2C6;</i>
          <span>Submit</span>
        </button>
        <button *ngIf="showDelete" [disabled]="isDeleting" [hidden]="mlpaJob.isLinkedMlpaJob" class="button button--green confirm-delete__button-ga" (click)="confirmDelete()" type="button">
          <i class="material-icons">&#xE2C6;</i>
          <span>Yes</span>
        </button>
        <button class="button" [disabled]="isSubmitting || isDeleting" (click)="closeSubmit()" type="button">
          <i class="material-icons">&#xE5C9;</i>
          <span>Cancel</span>
        </button>
        <div *ngIf="isSubmitting || isDeleting" class="modal__loading">
          <div class="loading loading--small"></div>
          <span *ngIf="isSubmitting">Saving MLPA</span>
          <span *ngIf="isDeleting">Deleting MLPA</span>
        </div>
      </div>
    </div>
  </div>
