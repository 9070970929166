<div class="container" #jobContainer>
  <div class="page__header--container">
    <div class="page__header" #mlpaHeader>
      <div [style.display]="'flex'">
     <h2 class="page__header-title">{{title}}</h2>
     <div class="plex-open-order" [style.display]="isClonePage || isEditPage ? 'none' : 'flex'"> <span class="plex-open-order-title">Asset Type&nbsp;</span>
            <select [(ngModel)]="selectedAssetType" [disabled]="isDesignAddedToJob" class="search-by-select text-text dark:text-dark-titlevariant dark:bg-dark-surface plex-open-order-dropdown" (change)="onAssetTypeChange(mlpaHeader,selectedAssetType)">
              <option *ngFor="let option of assetType | enum" [ngValue]="option.id">{{option.name}}
              </option>
            </select>
     </div>
    </div>
      <div class="page__header-controls" [style.display]="isClonePage || isEditPage ? 'none' : 'block'">
        <pkg-product-design-search (search)="searchProductDesigns($event)" [assetType]="selectedAssetType">
        </pkg-product-design-search>
      </div>
    </div>
  </div>
  <div class="ruler-container">
    <div (click)="toggleJobBuilder()" class="mlpa-job-summary" *ngIf="mlpaLanes.length > 0 && !loadingJobDetails" [ngClass]="{'normal-cursor' : isClonePage}">
      <pkg-mlpa-job-ruler [jobSettings]="jobSettings" [mlpaLanes]="mlpaLanes" [totalWidthInInches]="totalWidthInInches"></pkg-mlpa-job-ruler>
      <span class="material-icons">&#xE5CF;</span>
    </div>
  </div>
  <div class="page__content">
    <div *ngIf="showWelcome && !showBuilder && !isClonePage && !isEditPage" class="mlpa-message">
      <i class="material-icons">&#xE880;</i>
      <div>
        <div class="mlpa-message__title">Enter search criteria</div>
        <div>Results will display here after you have searched</div>
      </div>
    </div>
    <div *ngIf="searching" class="searching-designs">
      <div class="searching-designs__text">Searching for product designs...</div>
      <div class="loading"></div>
    </div>
    <div class="search-results-container">
      <div *ngIf="!showBuilder && !isClonePage" class="design-list">
        <div *ngIf="(productDesigns.length === 0 && productDesignsOffline.length === 0) && !searching && hasSearched" class="mlpa-message">
          <i class="material-icons">&#xE645;</i>
          <div>
            <div class="mlpa-message__title">No Results</div>
            <div>No open orders were found that match the search criteria.</div>
            <div>The PDF files may not exist or their dimensions have not been determined yet.</div>
          </div>
        </div>
        <ng-container *ngIf="productDesigns.length > 0">
          <pkg-product-design [class]="designStyle" *ngFor="let design of productDesigns" [assets]="jobSettings.assets" [design]="design" (designEmitter)="addProductDesignToJob($event)">
          </pkg-product-design>
        </ng-container>
        <ng-container *ngIf="productDesignsOffline.length > 0">
          <pkg-product-design [class]="designStyle" *ngFor="let design of productDesignsOffline" [assets]="jobSettings.assets" [offlineDesign]="design" (designEmitter)="addProductOfflineDesignToJob($event)">
          </pkg-product-design>
        </ng-container>
      </div>
      <div [hidden]="!showBuilder" class="mlpa-job-container" [ngStyle]="{ 'hide-builder' : !showBuilder }">
        <div *ngIf="isLoading" class="loader-container">
          <span> One moment loading job information ...</span>
          <div class="loading"></div>
        </div>

        <pkg-mlpa-job-details *ngIf="!isLoading"
        [redirectUrl]="redirectUrl"
        [mlpaJobView]="mlpaJobView"
        [jobSettings]="jobSettings"
        [colorManagementSystemSettings]="colorManagementSystemSettings"
        [isMultiPage]="isMultiPage"
        [isSpecial]="isSpecial"
        [assetType]="selectedAssetType"
        (deleteFromEditEmitter)="removeLaneFromJob($event)"
        (saveEmitter)="updateFromJobDetails($event)"
        (setPreviewEmitter)="scrollToTop(jobContainer)"
        (cancelEmitter)="cancelAndRestore()"
        ></pkg-mlpa-job-details>
      </div>
    </div>
    <button *ngIf="showLoadMore && (productDesigns.length !== 0 || productDesignsOffline.length != 0) && !showBuilder" [disabled]="searching || hasLoadedAllLanes" [style.margin]='"auto"' class="button button--primary" (click)="loadMoreProductDesigns()">
      <span>Load more designs</span>
    </button>
  </div>
  <pkg-loader *ngIf="loadingJobDetails"></pkg-loader>
</div>
