<div class="pkg-card-container" [ngStyle]="containerStyle">
  <div class="pkg-card-header" [ngStyle]="headerStyle">
    <ng-content select="pkg-card-header"></ng-content>
  </div>
  <div class="pkg-card-progress-bar">
    <ng-content select="pkg-card-progress-bar"></ng-content>
  </div>

  <ng-content select="[pkg-card-media]"></ng-content>

  <div class="pkg-card-body" [ngStyle]="bodyStyle">
    <ng-content select="pkg-card-body"></ng-content>
  </div>

  <div class="pkg-card-footer" [ngStyle]="footerStyle">
    <ng-content select="pkg-card-footer"></ng-content>
  </div>
</div>
