import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminComponent } from './admin.component';
import { UserListComponent } from './user-list/user-list.component';
import { AuthGuard } from '../../auth/auth.guard';
import { AdminGuard } from './admin.guard';
import { AssetListComponent } from './asset-list/asset-list.component';
import { AssetEditComponent } from './asset-edit/asset-edit.component';
import { VisionSystemEditComponent } from './vision-system-edit/vision-system-edit.component';
import { MaterialManagementComponent } from './material-management/material-management.component';
import { DataLoggerEditComponent } from './data-logger-edit/data-logger-edit.component';
import { ColorManagementSystemListComponent } from './color-management-system/color-management-system-list/color-management-system-list/color-management-system-list.component';
import { ColorManagementSystemEditComponent } from './color-management-system/color-management-system-edit/color-management-system-edit/color-management-system-edit.component';
import { PrintProcessorListComponent } from './print-processor/print-processor-list/print-processor-list.component';
import { PrintProcessorEditComponent } from './print-processor/print-processor-edit/print-processor-edit.component';
import { TempPrintProcessorGuard } from './temp-print-processor.guard';


const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard, AdminGuard],
    children: [
      {
        path: '',
        canActivateChild: [AuthGuard, AdminGuard],
        children: [
          { path: 'user', component: UserListComponent },
          { path: 'user/:id', component: UserListComponent },
          { path: 'asset', component: AssetListComponent },
          { path: 'asset/:assetId', component: AssetEditComponent },
          { path: 'asset/add', component: AssetEditComponent },
          { path: 'vistionsystem/add', component: VisionSystemEditComponent },
          { path: 'visionsystem/:visionsystemId', component: VisionSystemEditComponent },
          { path: 'papertypes', component: MaterialManagementComponent },
          { path: 'datalogger/add', component: DataLoggerEditComponent },
          { path: 'datalogger/:dataLoggerId', component: DataLoggerEditComponent },
          { path: 'colormanagementsystem', component: ColorManagementSystemListComponent },
          { path: 'colormanagementsystem/add', component: ColorManagementSystemEditComponent },
          { path: 'colormanagementsystem/:colorManagementSystemId', component: ColorManagementSystemEditComponent },
          { path: 'printprocessor', component: PrintProcessorListComponent, canActivate: [AuthGuard, TempPrintProcessorGuard] },
          { path: 'printprocessor/add', component: PrintProcessorEditComponent, canActivate: [AuthGuard, TempPrintProcessorGuard] },
          { path: 'printprocessor/:printProcessorId', component: PrintProcessorEditComponent, canActivate: [AuthGuard, TempPrintProcessorGuard] }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
