import { Component, OnInit, Input, Output, ChangeDetectionStrategy, EventEmitter } from '@angular/core';
import { MenuItem } from './menu-item';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'pkg-menu-option',
  templateUrl: './menu-option.component.html',
  styleUrls: ['./menu-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuOptionComponent implements OnInit {
  @Input() placeholder = 'Filter...';
  @Input() title: string;
  @Input() menuOptionContainerStyle?: NgStyle;
  @Input() menuItems: MenuItem[];
  @Output() menuItemsOut = new EventEmitter<MenuItem[]>();
  searchTerm = '';

  get isFiltered(): boolean {
    if (this.showAllAssets) {
      return false;
    }

    return true;
  }

  get showAllAssets(): boolean {
    for (const item of this.menuItems) {
      if (item.selected === false) {
        return false;
      }
    }
    return true;
  }
  constructor() { }

  ngOnInit(): void { }

  onChange(): void {
    this.menuItemsOut.emit(this.menuItems);
  }

  clear(): void {
    this.searchTerm = '';
  }

  setFiltersToTrue(): void {
    this.menuItems.forEach((item, index) => {
      this.menuItems[index].selected = true;
    });
    this.menuItemsOut.emit(this.menuItems);
  }

  setFiltersToFalse(): void {
    this.menuItems.forEach((item, index) => {
      this.menuItems[index].selected = false;
    });
    this.menuItemsOut.emit(this.menuItems);
  }

  toggleSelected(): void {
    if (this.showAllAssets) {
      this.setFiltersToFalse();
    } else {
      this.setFiltersToTrue();
    }
  }
}
