export class DateAggregate {
  date: Date;
  value: number;
  constructor(data: Record<string, unknown> | DateAggregate) {
    Object.assign(this, data);
  }
}
export class MlpaJobsStats {
  numberOfJobs: number;
  numberOfQueuedJobs: number;
  get percentageOfQueuedJobs(): number {
    if (!this.numberOfJobs || this.numberOfJobs === 0) {
      return 0;
    }
    return Math.round((this.numberOfQueuedJobs / this.numberOfJobs) * 100);
  }
  numberOfProcessingJobs: number;
  get percentageOfProcessingJobs(): number {
    if (!this.numberOfJobs || this.numberOfJobs === 0) {
      return 0;
    }
    return Math.round((this.numberOfProcessingJobs / this.numberOfJobs) * 100);
  }
  numberOfProcessedJobs: number;
  get percentageOfProcessedJobs(): number {
    if (!this.numberOfJobs || this.numberOfJobs === 0) {
      return 0;
    }
    return Math.round((this.numberOfProcessedJobs / this.numberOfJobs) * 100);
  }
  numberOfGeneratedJobs: number;
  get percentageOfGeneratedJobs(): number {
    if (!this.numberOfJobs || this.numberOfJobs === 0) {
      return 0;
    }
    return Math.round((this.numberOfGeneratedJobs / this.numberOfJobs) * 100);
  }
  numberOfSubmittedJobs: number;
  get percentageOfSubmittedJobs(): number {
    if (!this.numberOfJobs || this.numberOfJobs === 0) {
      return 0;
    }
    return Math.round((this.numberOfSubmittedJobs / this.numberOfJobs) * 100);
  }
  numberOfFailedJobs: number;
  get percentageOfFailedJobs(): number {
    if (!this.numberOfJobs || this.numberOfJobs === 0) {
      return 0;
    }
    return Math.round((this.numberOfFailedJobs / this.numberOfJobs) * 100);
  }
  numberOfJobsWithSmartLines: number;
  get percentageOfJobsWithSmartLines(): number {
    if (!this.numberOfJobs || this.numberOfJobs === 0) {
      return 0;
    }
    return Math.round((this.numberOfJobsWithSmartLines / this.numberOfJobs) * 100);
  }
  numberOfJobsWithWebWeaves: number;
  get percentageOfJobsWithWebWeaves(): number {
    if (!this.numberOfJobs || this.numberOfJobs === 0) {
      return 0;
    }
    return Math.round((this.numberOfJobsWithWebWeaves / this.numberOfJobs) * 100);
  }
  numberOfJobsWithoutWebWeaves: number;
  get percentageOfJobsWithoutWebWeaves(): number {
    if (!this.numberOfJobs || this.numberOfJobs === 0) {
      return 0;
    }
    return Math.round((this.numberOfJobsWithoutWebWeaves / this.numberOfJobs) * 100);
  }
  get numberOfSuccessfulJobs(): number {
    if (!this.numberOfJobs || this.numberOfJobs === 0) {
      return 0;
    }
    return this.numberOfJobs - this.numberOfFailedJobs;
  }
  get percentageOfSuccessfulJobs(): number {
    if (!this.numberOfJobs || this.numberOfJobs === 0) {
      return 0;
    }
    return Math.round((this.numberOfSuccessfulJobs / this.numberOfJobs) * 100);
  }

  numberOfJobsCreatedOverLast30Days: DateAggregate[];
  numberOfJobsGeneratedOverLast30Days: DateAggregate[];
  numberOfJobsSubmittedOverLast30Days: DateAggregate[];

  constructor(data: Record<string, unknown> | MlpaJobsStats) {
    Object.assign(this, data);
  }
}
