<div class="fullscreen">
  <div class="fullscreen__container">
    <div class="fullscreen__card">
      <div class="fullscreen__header">
        Unauthorized
      </div>
      <div class="fullscreen__content">
        You appear to be trying to login with an account that is not authorized to use this application.
      </div>
      <div class="fullscreen__footer">
        <button class="button button--primary" (click)="goLogin()" type="button">
          <i class="material-icons">&#xE897;</i>
          <span>Login</span>
        </button>
        <div class="fullscreen__status-code">
          401 Unauthorized
        </div>
      </div>
    </div>
    <div class="fullscreen__logo-stack">
      <div class="fullscreen__logo-container">
        <img class="fullscreen__logo" src="../assets/full-logo-dark.svg" [ngStyle]="getLogoStyle" alt="Tartan + Hummingbird Logo" />
      </div>
      <span class="fullscreen__release-number">v{{releaseNumber}}</span>
    </div>
  </div>
</div>
