<div class="action-options-slider" (click)="onToggleJobOptions($event, job)" [class.expanded]="showActionOptions && job === mlpaJob">
  <button (click)="onToggleJobOptions($event, job)" title="Toggle Options" class="button button--small button-fab">
    <span class="material-icons">&#xE5D3;</span>
  </button>
  <ng-container *ngIf="showSubmit && !job?.isSpecial">
    <button *ngIf="job.isEligibleForSubmit" (click)="onPreSubmit(job)" title="Submit" class="button button--green button--small button-fab presubmit-view__button-ga">
      <span class="material-icons">&#xE2C6;</span>
    </button>
  </ng-container>
  <ng-container *ngIf="showEdit">
    <button *ngIf="job.isEligibleForEdit" (click)="onSelectJob(job); onEdit(job); onScrollToJob($event, job.id)" title="Edit" class="button button--small button-fab edit-view__button-ga">
      <span [id]="job.id" class="material-icons">&#xE3C9;</span>
    </button>
  </ng-container>
  <ng-container *ngIf="!job?.isSpecial">
    <button (click)="onPreClone(job)" title="Clone" class="button button--small button-fab clone-view__button-ga">
      <span class="material-icons">&#xE173;</span>
    </button>
  </ng-container>
  <ng-container *ngIf="showDelete">
    <button *ngIf="job.isEligibleForDelete" (click)="onPreDelete(mlpaJob)" title="Delete" class="button button--red button--small button-fab delete-view__button-ga">
      <span class="material-icons">&#xE92B;</span>
    </button>
  </ng-container>
</div>
<ng-container *pkgHasRole="[role.Support, role.Admin, role.Operator, role.Scheduler]">
  <button (click)="onToggleJobOptions($event, job)" title="Toggle Options" class="button button--small button-fab">
    <span class="material-icons">&#xE5D3;</span>
  </button>
</ng-container>
<ng-container *pkgHasRole="[role.Structure] and job?.isSpecial">
  <button (click)="onToggleJobOptions($event, job)" title="Toggle Options" class="button button--small button-fab">
    <span class="material-icons">&#xE5D3;</span>
  </button>
</ng-container>

