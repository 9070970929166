import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataLoggerAsset } from 'src/app/shared/data-logger-asset';
import { AlertType } from '@shared/alerts/alert-type.enum';
import { AlertsService } from '@shared/alerts/alerts.service';
import { Asset } from '@shared/asset';
import { VisionSystemAsset } from '@shared/vision-system-asset';
import { AssetService } from '../shared/asset.service';
import { DataLoggerService } from '../shared/data-logger.service';
import { VisionService } from '../shared/vision.service';



@Component({
  selector: 'pkg-asset-list',
  templateUrl: './asset-list.component.html',
  styleUrls: ['./asset-list.component.scss']
})
export class AssetListComponent implements OnInit, OnDestroy {

  assets: Asset[] = [];
  visionSystems: VisionSystemAsset[] = [];
  assetsLoading = true;
  visionSystemsLoading = true;
  dataLoggers: DataLoggerAsset[] = [];
  dataLoggersLoading = true;
  keyword: string;
  private unsubscribe$ = new Subject();

  get filteredAssets(): Asset[] {
    if (!this.keyword || this.keyword.trim().length === 0) {
      return this.assets;
    }
    return this.assets.filter(u => u.name.toLowerCase().includes(this.keyword.toLowerCase()));
  }

  get filteredVisionSystems(): VisionSystemAsset[] {
    if (!this.keyword || this.keyword.trim().length === 0) {
      return this.visionSystems;
    }
    return this.visionSystems.filter(u => u.name.toLowerCase().includes(this.keyword.toLowerCase()));
  }

  get filteredDataLoggers() {
    if (!this.keyword || this.keyword.trim().length === 0) {
      return this.dataLoggers;
    }
    return this.dataLoggers.filter(u => u.name.toLowerCase().includes(this.keyword.toLowerCase()));
  }

  constructor(
    private readonly assetService: AssetService,
    private readonly visionSevice: VisionService,
    private readonly dataLoggerService: DataLoggerService,
    private readonly router: Router,
    private readonly alertService: AlertsService) { }

  ngOnInit(): void {
    this.assetService.getAll().pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.assets = data;
      this.assetsLoading = false;
    },
      (err) => {
        this.alertService.add('Failed to load printers', 'Please try again as we could not get any printers', AlertType.Error);
        this.assetsLoading = false;
      });

    this.visionSevice.getAll().pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.visionSystems = data.results;
      this.visionSystemsLoading = false;
      if (this.visionSystems.length > 0) {
          this.assets.map(x => {
          if (x.visionSystemId != null) {
               const visionSystem = this.visionSystems.filter(y => y.id === x.visionSystemId);
               x.visionSystemName = visionSystem.length > 0 ? visionSystem[0].name : 'No Vision System Attached';
            }
          });
        }
    }, (err: HttpErrorResponse) => {
      if (err.status !== 404) {
        this.alertService.add('Failed to load vision systems', 'Please try again as we could not get any vision systems', AlertType.Error);
      }
      this.visionSystemsLoading = false;
    });

    this.dataLoggerService.getAll().pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.dataLoggers = data.results;
      this.dataLoggersLoading = false;
    }, (err) => {
      if (err.status !== 404) {
        this.alertService.add('Failed to load data logger', 'Please try again as we could not get any data loggers', AlertType.Error);
      }
      this.dataLoggersLoading = false;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  editAsset(asset: Asset): void {
    this.router.navigate([`admin/asset/${asset.id}`]);
  }

  editVisionSystem(visionSystem: VisionSystemAsset): void {
    this.router.navigate([`admin/visionsystem/${visionSystem.id}`]);
  }

  createDataLogger() {
    this.router.navigate([`admin/datalogger/add`]);
  }

  editDataLogger(dataLogger: DataLoggerAsset) {
    this.router.navigate([`admin/datalogger/${dataLogger.id}`]);
  }

  createAsset() {
    this.router.navigate([`admin/asset/add`]);
  }

  createVisionSystem(): void {
    this.router.navigate([`admin/visionsystem/add`]);
  }

  createRoll(): void {
    this.router.navigate([`admin/roll/add`]);
  }

  testModeClass(asset: Asset): string {
    if (asset.isInTestMode) {
      return 'test-mode--enabled';
    }
    return 'test-mode--disabled';
  }

}
