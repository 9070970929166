import { DesignType } from './design-type.enum';
import { MlpaLaneType } from './mlpa-lane-type.enum';
import { ProductDesign } from '../product-design/product-design.interface';
import { MlpaLaneApi } from '@shared/mlpa-lane-api';
import { Convert } from '@shared/convert';
import { InitialLaneProperties, LaneProperties } from './lane-types/lane-properties.interface';
import { deepCopyProperties } from '@shared/pkg-helper';
import { MlpaLaneColorCorrectionInfo } from './mlpa-lane-color-correction-info';
import { DesignCharacterizationResponse } from '@shared/mlpa-job-options/mlpa-job-option-characterization-value';

export abstract class MlpaLane {
  laneId: number;
  colorProfileId: string;
  colorProfileName: string;
  designColorProfileName: string;
  laneNumber: number;
  numberOfRibbons: number;
  quantity: number;
  isTransposed: boolean;
  laneType: MlpaLaneType;
  rotationInDegrees: number;
  sourcePdfSizeInBytes: number;
  processedPdfSizeInBytes: number;
  programNumber: string;
  setupNumber: string;
  orderNumber: string;
  designNumber: string;
  designDescription: string;
  shortDesignDescription: string;
  // designDueDate: Date;
  orderDueDate: Date;
  assetName: string;
  customerName: string;
  customerAddress: string;
  customerCity: string;
  customerZip: string;
  customerOrderNumber: string;
  customerPartNumber: string;
  customerPurchaseOrderNumber: string;
  designWidthInInches: number;
  designLengthInInches: number;
  designNumberUp: number;
  designNumberOfPages: number;
  originalNumberOfPages: number;
  orderQuantity: number;
  orderQuantityProduced: number;
  corrugatorName: string;
  paperDescription: string;
  fluteDescription: string;
  linerDescription: string;
  mediumDescription: string;
  productDescription: string;
  coatingDescription: string;
  isPdfChecked: boolean;
  isOk: boolean;
  errors: string;
  isProcessing: boolean;
  isProcessed: boolean;
  isProcessingEnabled: boolean;
  calculatedTotalWidthInInches: number;
  // value is never used just to help calculate the same total length.
  multiPageNumberUp?: number;
  colorCorrectionInfo: MlpaLaneColorCorrectionInfo = new MlpaLaneColorCorrectionInfo();
  definedColorCorrectionInfo: DesignCharacterizationResponse = new DesignCharacterizationResponse();

  get isMultiPage(): boolean {
    return this.designNumberOfPages > 1;
  }
  get totalWidthInInches(): number {
    if (this.isTransposed) {
      return this.designLengthInInches * this.numberOfRibbons;
    }
    return this.designWidthInInches * this.numberOfRibbons;
  }

  get totalLengthInInches(): number {
    if (this.laneType === MlpaLaneType.SmartLine || this.designNumberOfPages > 1) {
      return this.designNumberOfPages * this.designLengthInInches;
    }
    return this.quantity * this.designLengthInInches;
  }
  get pdfSizeNumber(): number {
    return this.sourcePdfSizeInBytes
      ? Convert.getConvertedBytesNumber(this.sourcePdfSizeInBytes, 2)
      : Convert.getConvertedBytesNumber(0, 2);
  }

  get pdfSizeUnit(): string {
    return this.sourcePdfSizeInBytes
      ? Convert.getConvertedBytesUnit(this.sourcePdfSizeInBytes, 2)
      : Convert.getConvertedBytesUnit(0, 2);
  }

  get isWebWeave(): boolean {
    return this.laneType !== MlpaLaneType.Graphic && this.laneType !== MlpaLaneType.QrCode && this.laneType !== MlpaLaneType.Stress;
  }

  get hasValidColorProfile(): boolean {
    if (this.colorProfileId && this.colorProfileName) {
      return true;
    }
    return false;
  }

  constructor(mlpaLaneApi?: MlpaLaneApi, productDesign?: ProductDesign) {
    if (!!mlpaLaneApi) {
      deepCopyProperties(this, mlpaLaneApi);
      this.setValuesFromMlpaLane(mlpaLaneApi);
    }

    if (!!productDesign) {
      deepCopyProperties(this, productDesign);
      this.setValuesFromProductDesign(productDesign);
    }

    this.setUndefinedValues();
  }

  fixInvalidQuantity(): boolean {
    // skip this method for multipage designs
    if (this.isMultiPage) {
      return false;
    }
    // determine if we need to modify the order based on the n-ups and order quantity
    const isQuantityLegal = (this.quantity % this.designNumberUp) === 0;
    if (!isQuantityLegal) {
      // determine the amount of legal eaches we can do given the quantity
      this.quantity = (Math.floor(this.quantity / this.designNumberUp) + 1) * this.designNumberUp;
      return true;
    }
    return false;
  }

  setCalculatedTotalWidthInInches(newValue: number): void {
    // Only for non-graphic and non-qr code lanes
    if (this.laneType === MlpaLaneType.Graphic || this.laneType === MlpaLaneType.QrCode) {
      return;
    }
    this.calculatedTotalWidthInInches = newValue;
  }

  recalculateWidth(paperWidthInInches: number, marginWidthInInches: number, mlpaLanes: MlpaLane[]): void {
    // Only for non-graphic and non-qr code lanes
    if (this.laneType === MlpaLaneType.Graphic || this.laneType === MlpaLaneType.Stress || this.laneType === MlpaLaneType.QrCode) {
      return;
    }
    // Loop through all graphic and qr code lanes and add up the total width
    let totalGraphicWidthInInches = 0;
    mlpaLanes.forEach(o => {
      if (o.laneType === MlpaLaneType.Graphic || o.laneType === MlpaLaneType.Stress || o.laneType === MlpaLaneType.QrCode) {
        totalGraphicWidthInInches += o.totalWidthInInches;
      }
    });
    // Get remaining width on each side of the job
    let calculatedTotalWidthInInches = (paperWidthInInches - marginWidthInInches * 2 - totalGraphicWidthInInches) / 2;
    calculatedTotalWidthInInches = calculatedTotalWidthInInches >= 0 ? calculatedTotalWidthInInches : 0;
    // Set calculated width
    this.calculatedTotalWidthInInches = calculatedTotalWidthInInches;
  }

  editLength(newLength: number): void {
    // figure out how many are printed with one unit of width
    const howManyWeCanPrintInOneDesignWidth = this.numberOfRibbons * this.designNumberUp;
    // figure out how many can be printed *completely* (as opposed to partially) given the users' requested length
    const numberOfWholeDesigns = Math.floor((newLength * 12) / this.designLengthInInches);

    // Only update the numbers if the new calculated quantity has changed
    const newQuantity = numberOfWholeDesigns * howManyWeCanPrintInOneDesignWidth;
    this.quantity = newQuantity;
  }

  setTransposedValues(): void {
    if (this.isTransposed) {
      const transposedWidth = this.designLengthInInches;
      const transposedLength = this.designWidthInInches;
      this.designWidthInInches = transposedWidth;
      this.designLengthInInches = transposedLength;
    }
  }
  toggleTransposedValues(): void {
    const transposedWidth = this.designLengthInInches;
    const transposedLength = this.designWidthInInches;
    this.designWidthInInches = transposedWidth;
    this.designLengthInInches = transposedLength;
  }

  calculateNumberOfPages(laneProperties: LaneProperties): void {
    if (this.isMultiPage && laneProperties.nearestLane != null) {
      this.designNumberOfPages = this.originalNumberOfPages;
    }
  }

  clearColorCharacterizationVariables(): void {
    this.colorCorrectionInfo.inkColors = null;
    this.colorCorrectionInfo.inkWeight = null;
    this.colorCorrectionInfo.substrateWeight = null;
    this.colorCorrectionInfo.printMode = null;
    this.colorCorrectionInfo.pressType = null;
    this.colorCorrectionInfo.overPrintVarnish = null;
    this.colorCorrectionInfo.speed = null;
    this.colorCorrectionInfo.totalAreaCoverage = null;
  }

  protected setUndefinedValues(): void {
    if (!this.calculatedTotalWidthInInches) {
      this.calculatedTotalWidthInInches = 0;
    }
    if (!this.designNumberUp) {
      this.designNumberUp = 1;
    }
    if (!this.numberOfRibbons) {
      this.numberOfRibbons = 1;
    }
  }

  protected setValuesFromMlpaLane(mlpaLaneApi: MlpaLaneApi): void {
    this.quantity = this.designNumberOfPages > 1 ? 1 : mlpaLaneApi.quantity; // #34937
    this.rotationInDegrees = mlpaLaneApi.rotationInDegrees === 0 ? 90 : mlpaLaneApi.rotationInDegrees;
    this.laneType = mlpaLaneApi.type;
    this.setTransposedValues();
  }

  protected setValuesFromProductDesign(productDesign: ProductDesign): void {
    deepCopyProperties(this, productDesign);
    this.quantity = this.designNumberOfPages > 1 ? 1 : productDesign.orderQuantity; // #34937
    this.setTransposedValues();
  }

  protected setInitialLaneProperties(laneProperties: InitialLaneProperties, designValue: DesignType): void {
    deepCopyProperties(this, laneProperties);
    this.quantity = laneProperties.designNumberOfPages > 1 ? 1 : laneProperties.quantity;
    this.programNumber = '';
    this.setupNumber = '';
    this.orderNumber = '';
    this.designNumber = designValue;
    this.designDescription = designValue;
    this.laneType = MlpaLaneType[designValue];
    this.designLengthInInches = laneProperties.designLengthInInches;
    this.rotationInDegrees = 0;
    this.isProcessingEnabled = true;

    if (laneProperties.isSpecial) {
      this.isTransposed = true;
      this.setTransposedValues();
    }
  }
}
