import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TokenService } from './token.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private readonly _tokenService: TokenService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authRequest = request;
    if (!this._tokenService.isValid() || request.url.includes('auth.kochid')) {
      return next.handle(request)
        .pipe(tap(data => { }, (err: HttpErrorResponse) => {
          console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
        }));
    }

    // if x-api key doesn't exist then run clone.
    if (request.headers.get('x-api-key') == null) {
      authRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this._tokenService.get()}`,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });
    }
    return next.handle(authRequest)
      .pipe(tap(data => { }, (err: HttpErrorResponse) => {
        console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
      }));
  }
}
