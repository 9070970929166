import { Component, ViewChild, Input, Output, EventEmitter, OnInit, AfterViewInit, OnDestroy, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { MlpaJobView } from '../../pages/home/view-mlpa-jobs/mlpa-job-view';
import { AuthService } from '../../auth/auth.service';
import { AlertsService } from '@shared/alerts/alerts.service';
import { Router } from '@angular/router';
import { MlpaService } from '../../pages/mlpa/shared/mlpa.service';
import { CloneMlpaJobsComponent } from '../../pages/home/clone-mlpa-jobs/clone-mlpa-jobs.component';
import { MlpaLaneType } from '../mlpa-lane/mlpa-lane-type.enum';
import { Preview } from '@shared/lane-preview/preview.interface';
import { MlpaLaneView } from '../../pages/home/view-mlpa-jobs/mlpa-lane-view';
import { ViewMlpaJobsComponent } from '../../pages/home/view-mlpa-jobs/view-mlpa-jobs.component';
import { deepCopyProperties } from '@shared/pkg-helper';
import { SearchAutoComplete } from '../../search-auto-complete/search-auto-complete';
import { MlpaJobSettings } from '../mlpa-job-settings/mlpa-job-settings';
import { SelectItem } from '../../search-auto-complete/select-item';
import {
  replaceSpecialCharacters, findLongestShortestLaneLength, recalculateMlpaJobProperties,
  getTotalLengthOfLanes, recalculateMlpaLaneView, getValidLineBreakInFeet
} from '../mlpa-helper';
import { delay, distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { AlertType } from '@shared/alerts/alert-type.enum';
import { MlpaStateService } from '../../pages/mlpa/shared/mlpa-state.service';
import { MlpaJob } from '../mlpa-job/mlpa-job';
import { forkJoin, Observable } from 'rxjs';

import { MlpaLaneInfoComponent } from './mlpa-lane-info/mlpa-lane-info.component';
import { FormControl } from '@angular/forms';
import { Role } from '../../pages/admin/shared/role';
import { AnalyticsService } from '@shared/analytics.service';
import { MlpaJobService } from '../mlpa-job/mlpa-job.service';
import { NgStyle } from '@angular/common';
import { SearchAutoCompleteComponent } from '../../../app/search-auto-complete/search-auto-complete.component';
import { ColorManagementSystemSettings } from '../color-management-system-settings/color-management-system-settings';
import { CharacterizationType, MlpaCharacterizationValue, MlpaJobCharacterizationValue } from '@shared/mlpa-job-options/mlpa-job-option-characterization-value';
import { MlpaJobOptionsService } from '@shared/mlpa-job-options/mlpa-job-options.service';
import { MlpaJobActionGroupComponent } from './mlpa-job-action-group/mlpa-job-action-group.component';
import { AssetType } from '@shared/asset-type.enum';
import { getQualityModes } from '@shared/quality-mode-config';
import { MlpaJobOptionColorProfile } from '@shared/mlpa-job-options/mlpa-job-option-color-profile';

@Component({
  selector: 'pkg-mlpa-job-details',
  templateUrl: './mlpa-job-details.component.html',
  styleUrls: ['./mlpa-job-details.component.scss'],
})


export class MlpaJobDetailsComponent extends ViewMlpaJobsComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @ViewChild(CloneMlpaJobsComponent, { static: true }) cloneMlpaJobComponent: CloneMlpaJobsComponent;
  @ViewChild(MlpaLaneInfoComponent) mlpaLaneInfoComponent: MlpaLaneInfoComponent;
  @ViewChild(MlpaJobActionGroupComponent) mlpaJobActionGroupComponent: MlpaJobActionGroupComponent;
  @ViewChild('paperwidth') paperWidthComponent: SearchAutoCompleteComponent;
  @Input() mlpaJobView: MlpaJobView;
  @Input() isSpecial;
  @Input() isMultiPage = false;
  @Input() jobSettings?: MlpaJobSettings;
  @Input() colorManagementSystemSettings?: ColorManagementSystemSettings;
  @Input() redirectUrl: string;
  @Input() actionStyles?: NgStyle;
  @Input() lanePreviewStyles?: NgStyle;
  @Input() assetType: AssetType;
  @Output() saveEmitter = new EventEmitter();
  @Output() deleteFromEditEmitter = new EventEmitter();
  @Output() setPreviewEmitter = new EventEmitter();
  @Output() cancelEmitter = new EventEmitter();
  paperTypeDetails: SearchAutoComplete;
  assetDetails: SearchAutoComplete;
  colorManagementSystemDetails: SearchAutoComplete;
  speedDetails: SearchAutoComplete;
  overPrintVarnishDetails: SearchAutoComplete;
  subsrateDetails: SearchAutoComplete;
  printModeDetails: SearchAutoComplete;
  inkColorDetails: SearchAutoComplete;
  paperWidthDetails: SearchAutoComplete;
  showConfirm: boolean;
  showClone: boolean;
  isCloning: boolean;
  showDelete: boolean;
  currentPreview: Preview = {} as any;
  copyMlpaJobView: MlpaJobView[] = [];
  copyJobSettings: MlpaJobSettings[] = [];
  isLineBreakEnabled: boolean;
  hasValidColorProfile = true;
  isMlpaPage: boolean;
  isClonePage: boolean;
  isHomePage: boolean;
  linkedMlpaJobsToDelete: MlpaJobView[];
  toggleLaneState = true;
  warningMessages = [];
  jobName = new FormControl();
  role = Role;
  isEnableLock: boolean;
  user = this.authServiceDetail.user;
  printMode: string;
  colorProfile: MlpaJobOptionColorProfile;
  emptyPreview: Preview = {
    preview: null,
    shouldFlash: true
  } as Preview;

  laneType = MlpaLaneType;

  get isStressJob(): boolean {
    return this.mlpaJobView?.mlpaLanes[1]?.laneType === MlpaLaneType.Stress;
  }

  thumbnailTyp = MlpaLaneType;

  get isProcessingEnabled(): boolean {
    for (const mlpaLane of this.mlpaJobView.mlpaLanes) {
      if (mlpaLane.laneType === MlpaLaneType.Graphic && !mlpaLane.isProcessingEnabled) {
        return false;
      }
    }
    return true;
  }


  get showJobCharacterizationValues(): boolean {
    for (const mlpaLane of this.mlpaJobView.mlpaLanes) {
      if (mlpaLane.laneType === MlpaLaneType.Graphic && mlpaLane.colorCorrectionInfo.isColorCorrectionEnabled) {
        return true;
      }
    }
    return false;
  }

  constructor(
    private readonly authServiceDetail: AuthService,
    private readonly alertsServiceDetail: AlertsService,
    private readonly routerDetail: Router,
    private readonly mlpaServiceDetail: MlpaService,
    private readonly mlpaJobOptionsService: MlpaJobOptionsService,
    private readonly mlpaStateServiceDetail: MlpaStateService,
    private readonly analyticsServiceDetail: AnalyticsService,
    private readonly mlpaJobServiceDetail: MlpaJobService,
    private readonly cd: ChangeDetectorRef) {
    super(mlpaStateServiceDetail,
      authServiceDetail,
      alertsServiceDetail,
      routerDetail,
      mlpaServiceDetail,
      analyticsServiceDetail,
      mlpaJobServiceDetail);
  }


  ngOnInit(): void {
    this.isMlpaPage = this.routerDetail.url === '/mlpa' || this.routerDetail.url.startsWith('/mlpa');
    this.isClonePage = this.routerDetail.url.includes('clone');
    this.isHomePage = this.routerDetail.url.includes('home');

    if (!!this.mlpaJobView && this.mlpaJobView?.mlpaLanes[1]?.laneType !== MlpaLaneType.QrCode && this.isMlpaPage) {
      this.editMode = true;
      this.mlpaStateServiceDetail.setByValue('mlpaJobView', this.mlpaJobView);
      this.mlpaStateServiceDetail.mlpaJobState$.pipe(distinctUntilChanged()).subscribe((results) => {
        this.jobSettings = results.jobSettings;
        this.colorManagementSystemSettings = results.colorManagementSystemSettings;
        this.mlpaJob = results.mlpaJob;
        this.mlpaJobView = results.mlpaJobView;
        this.editMode = results.editMode != null ? results.editMode : this.editMode;
        this.printMode = Boolean(results.colorManagementSystemSettings?.jobCharacterizationValues?.printMode)
        ? results.colorManagementSystemSettings?.jobCharacterizationValues?.printMode : this.mlpaLaneInfoComponent?.getPrintModeFromQualityMode(results.mlpaJobView?.qualityMode);
        if (this.mlpaJobView != null) {
          this.setPreview(this.mlpaJobView, true, false);
          this.initializeForEdit();
        }

        if (this.mlpaJobView == null) {
          this.jobName.reset();
        }
        this.cd.detectChanges();
      });
    } else {
      this.printMode = Boolean(this.mlpaJobView?.colorCorrectionInfo?.printMode)
      ? this.mlpaJobView?.colorCorrectionInfo?.printMode : this.mlpaLaneInfoComponent?.getPrintModeFromQualityMode(this.mlpaJobView?.qualityMode);
      this.setPreview(this.mlpaJobView);
    }

    // Set color management system name
    if (!!this.mlpaJobView && this.mlpaJobView.colorCorrectionInfo?.colorManagementSystemId && this.colorManagementSystemSettings.colorManagementSystems.length > 0) {
      this.mlpaJobView.colorManagementSystemName = this.colorManagementSystemSettings.colorManagementSystems.find(a => a.id === this.mlpaJobView.colorCorrectionInfo.colorManagementSystemId).name;
    }

    // Listen to this field and set the view when changed.
    this.jobName.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(value => {
      if (!!this.mlpaJobView) {
        this.mlpaJobView.name = value;
      }
    });
    this.cd.detectChanges();

    this.initializeSettings();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.mlpaJobView && !!changes.mlpaJobView.currentValue) {
      // For the homepage when we select different jobs we want to change the preview.
      if (!this.isMlpaPage) {
        this.setPreview(this.mlpaJobView, false);
      }

      if (!this.jobName.dirty && this.mlpaJob != null && !this.isClonePage && !this.mlpaJob.id) {
        this.buildJobName();
      } else {
        this.jobName.patchValue(this.mlpaJobView.name);
      }
    }

    if (this.isSpecial == null) {
      this.isSpecial = this.mlpaJobView.isSpecial;
    }

    if (this.mlpaJobView != null && !!changes.mlpaJobView && changes.mlpaJobView.previousValue != null) {
      if (changes.mlpaJobView.previousValue.id !== changes.mlpaJobView.currentValue.id) {
        this.toggleLaneState = true;
      }
    }
    this.cd.detectChanges();
  }
  ngOnDestroy(): void {
    // clear job characterization values
    this.colorManagementSystemSettings?.clearJobCharacterizationValues();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.mlpaStateServiceDetail.mlpaLaneState$.pipe(delay(0)).subscribe((results) => {
        this.hasValidColorProfile = results.hasValidColorProfiles;
      });
    }, 0);

    if (this.isClonePage) {
      this.mlpaLaneInfoComponent.selectWebWeave(this.mlpaJobView.mlpaLanes[0].laneType);
      this.save(this.mlpaJobView);
      this.toggleEdit();
    }
  }

  initializeSettings(): void {
    if (this.mlpaJobView?.mlpaLanes[1]?.laneType === MlpaLaneType.Stress) {
      this.toggleLaneState = false;
    }

    if (this.isSpecial == null) {
      this.isSpecial = this.mlpaJobView?.isSpecial;
    }

    if (this.mlpaJobView?.hasStressFile && this.jobSettings) {
      this.jobSettings.hasStress = true;
    }

    if (this.mlpaJobView?.hasQrCode && this.jobSettings) {
      this.jobSettings.hasQrCode = true;
    }

    if (this.mlpaJobView?.mlpaLanes[1]?.laneType === MlpaLaneType.Stress) {
      this.jobSettings.hasStress = false;
      this.jobSettings.hasQrCode = false;
    }
  }
  initializeForEdit(): void {
    this.copyMlpaJob();
    this.copyMlpaJobSettings();

    if (!!this.jobSettings) {
      this.selectCorrectJobSettings();
      this.setAssetDetails();
      this.setPaperTypeDetails();
      this.setPaperWidthDetails();
      this.isLineBreakEnabled = true;
    }

    if (!!this.colorManagementSystemSettings) {
      this.selectCorrectColorManagementSystemSettings();
      this.setColorManagementSystemDetails();
      this.setSubtrateDetails();
      this.setOverPrintVarnishDetails();
      this.setSpeedDetails();
      this.setPrintModeDetails();
      this.setInkCOlorDetails();
    }
  }

  updateSpeedToLanes() {
    this.mlpaJobView.mlpaLanes.forEach(mlpaLane => {
      if (mlpaLane.laneType === this.laneType.Graphic && mlpaLane.colorCorrectionInfo.isColorCorrectionEnabled) {
        mlpaLane.colorCorrectionInfo.speed = this.colorManagementSystemSettings.jobCharacterizationValues.speed;
      }
    });
  }

  updateSubstrateToLanes() {
    this.mlpaJobView.mlpaLanes.forEach(mlpaLane => {
      if (mlpaLane.laneType === this.laneType.Graphic && mlpaLane.colorCorrectionInfo.isColorCorrectionEnabled) {
        mlpaLane.colorCorrectionInfo.substrateWeight = this.colorManagementSystemSettings.jobCharacterizationValues.substrate;
      }
    });
  }

  updateOverPrintVarnishToLanes() {
    this.mlpaJobView.mlpaLanes.forEach(mlpaLane => {
      if (mlpaLane.laneType === this.laneType.Graphic && mlpaLane.colorCorrectionInfo.isColorCorrectionEnabled) {
        mlpaLane.colorCorrectionInfo.overPrintVarnish = this.colorManagementSystemSettings.jobCharacterizationValues.overPrintVarnish;
      }
    });
  }

  updatePressTypeToLanes() {
    this.mlpaJobView.mlpaLanes.forEach(mlpaLane => {
      if (mlpaLane.laneType === this.laneType.Graphic && mlpaLane.colorCorrectionInfo.isColorCorrectionEnabled) {
        mlpaLane.colorCorrectionInfo.pressType = this.colorManagementSystemSettings.jobCharacterizationValues.pressType;
      }
    });
  }

  updatePrintModeToLanes(printMode: string) {
    if (Boolean(this.printMode)) {
        this.mlpaJobActionGroupComponent.enableLock = true;
    }
    this.printMode = printMode;
    let alertMessage = '';
    this.mlpaJobView.mlpaLanes.forEach(mlpaLane => {
      if (mlpaLane.laneType === this.laneType.Graphic && mlpaLane.colorCorrectionInfo.isColorCorrectionEnabled) {
          mlpaLane.colorCorrectionInfo.printMode = this.colorManagementSystemSettings.jobCharacterizationValues.printMode;
      }
      if (mlpaLane.laneType === this.laneType.Graphic && this.assetType === AssetType.T1195) {
        this.colorProfile = this.mlpaJobView.jobSettings.colorProfiles.find(colorProfile => colorProfile.name === mlpaLane.colorProfileName);
        const qualityMode = this.colorProfile.qualityModes.some(modes=> modes === this.mlpaLaneInfoComponent?.getQualityModeFromPrintMode(this.printMode));
        if (!qualityMode) {
          alertMessage += `Lane# <b>${mlpaLane.laneNumber}</b></br>
          Color Profile <b>${mlpaLane.colorProfileName}</b> supports:&nbsp;`;
          this.colorProfile.qualityModes.forEach(qualitymode => {
          alertMessage += `&#8226;&nbsp;<b>${this.mlpaLaneInfoComponent?.getPrintModeFromQualityMode(qualitymode)}</b>&nbsp;`;
          });
          alertMessage += `</br>`;
      }
      }
    });
    if (alertMessage.length > 0) {
       this.mlpaJobActionGroupComponent.enableLock = false;
       this.alertsService.add(`Please select valid Color Profile / Print Mode`, `${alertMessage}`, AlertType.Error);
    } else {
       this.mlpaJobActionGroupComponent.enableLock = true;
    }
  }

  updateInkColorsToLanes() {
    this.mlpaJobView.mlpaLanes.forEach(mlpaLane => {
      if (mlpaLane.laneType === this.laneType.Graphic && mlpaLane.colorCorrectionInfo.isColorCorrectionEnabled) {
        mlpaLane.colorCorrectionInfo.inkColors = this.colorManagementSystemSettings.jobCharacterizationValues.inkColors;
      }
    });
  }

  selectCorrectJobSettings(): void {
    this.jobSettings.selectAsset(this.mlpaJobView.assetName, this.mlpaJobView.paperTypeName, this.mlpaJobView.paperWidthInInches);
  }
  selectCorrectColorManagementSystemSettings(): void {
    this.colorManagementSystemSettings.selectColorManagementSystem(this.mlpaJobView.colorCorrectionInfo.colorManagementSystemId);
  }
  setPreview(view: MlpaJobView | MlpaLaneView, shouldFlash: boolean = true, shouldEmit: boolean = true): void {
    let previousPreview; let width; let height; let designDescription; let designNumber;

    if (view == null) {
      this.alertsServiceDetail.add('Something unexpected happened', 'For some reason there isn\'t a view', AlertType.Error);
      return;
    }
    if (this.currentPreview) {
      previousPreview = this.currentPreview.preview;
    }

    if (this.mlpaJobView.designNumber == null) {
      this.mlpaJobView.designNumber = this.mlpaJobView.mlpaLanes[1].designNumber;
    }

    if (view instanceof MlpaJobView) {
      width = view.totalWidthInInches;
      height = this.maxDesignLength(view);
      designDescription = view.name;
      view.isCurrentPreviewButton = true;
      this.mlpaJobView.mlpaLanes.forEach((lane, index) => {
        this.mlpaJobView.mlpaLanes[index].isCurrentPreviewButton = false;
      });
    } else {
      width = view.designWidthInInches;
      height = view.designLengthInInches;
      designDescription = view.designDescription;
      designNumber = view.designNumber;
      this.mlpaJobView.isCurrentPreviewButton = false;
    }

    this.currentPreview = {
      previousPreview,
      shouldFlash,
      preview: view.preview,
      id: view.id,
      width,
      height,
      designDescription,
      designNumber
    };

    if (shouldEmit) {
      this.setPreviewEmitter.emit();
    }
  }

  async saveFinishOrSearch(mlpaJobView: MlpaJobView, isFinish?: boolean): Promise<void> {
    const mlpaJob = new MlpaJob(mlpaJobView);
    if(this.assetType === AssetType.T1195) {
        mlpaJob.qualityMode = this.mlpaLaneInfoComponent?.getQualityModeFromPrintMode(this.printMode);
    } else{
        mlpaJob.qualityMode = null;
    }
    let deleteParentJob$: Observable<any>;
    this.isSubmitting = true;
    if (this.showSaving) {
      return;
    }

    this.showSaving = true;

    // Clean color correction properties
    const colorCorrectionDisabeldLanes = mlpaJob.mlpaLanes.filter(lane => lane.laneType === MlpaLaneType.Graphic && !lane.colorCorrectionInfo.isColorCorrectionEnabled);

    // If all lanes are disabled for color correction, reset Color Management System Id
    if (colorCorrectionDisabeldLanes.length === mlpaJob.mlpaLanes.length - 2) {
      mlpaJob.colorCorrectionInfo.colorManagementSystemId = null;
    }

    // Reset characterization variables values if a lane is disabled for color correction
    colorCorrectionDisabeldLanes.forEach(lane => {
      lane.clearColorCharacterizationVariables();
    });

    // Build up observables to delete jobs
    const deleteJobObservables: Observable<any>[] = [];

    // Do not delete if the main parent is not a stress job.
    if (mlpaJob?.mlpaLanes[1]?.laneType !== MlpaLaneType.Stress) {
      if (this.linkedMlpaJobsToDelete.length > 0 && !this.isClonePage) {
        this.linkedMlpaJobsToDelete.forEach(linkedMlpaJob => {
          deleteJobObservables.push(this.mlpaServiceDetail.deleteMlpaJob(linkedMlpaJob.id));
        });
      }

      // Wait on all delete job observables to finish
      forkJoin(deleteJobObservables).pipe(
        map(data => {
          this.alertsService.add('Linked jobs deleted successfully', `Linked jobs have been deleted`, AlertType.Success);
        }, err => {
          this.errorAndAlert(err);
        }));
    }

    // clear the old parent id.
    if (mlpaJob.parentMlpaJobId != null && mlpaJob.parentMlpaJobId !== '') {
      // delete parent
      deleteParentJob$ = this.mlpaServiceDetail.deleteMlpaJob(mlpaJob.parentMlpaJobId);
      mlpaJob.parentMlpaJobId = null;
    }

    // clear the old clone parent id to ensure color correction is performed
    mlpaJob.parentClonedMlpaJobId = null;

    if (this.jobSettings.hasStress) {
      const chevronJob: MlpaJob = this.mlpaJob.createLinkedStressJob(this.jobSettings);
      chevronJob.id = mlpaJob.parentMlpaJobId != null ? mlpaJob.parentMlpaJobId : null;
      chevronJob.programNumber = mlpaJob.programNumber;
      chevronJob.setupNumber = mlpaJob.setupNumber;
      chevronJob.name = mlpaJob.name;
      chevronJob.parentMlpaJobId = null;

      await this.mlpaServiceDetail.saveMlpaJob(chevronJob).toPromise().then((result) => {
        // set the mlpaJob's parent id to the chevron's id.
        mlpaJob.parentMlpaJobId = result.id;
      }).catch((err) => {
        this.resetError(err);
      });
    }

    // Create or update the job
    this.mlpaServiceDetail.saveMlpaJob(mlpaJob).subscribe(async data => {
      // Set ID and unique ID
      mlpaJob.id = data.id;
      mlpaJob.uniqueId = data.uniqueId;
      this.alertsServiceDetail.add('MLPA job saved', `The MLPA job, ${this.mlpaJob.name}-${mlpaJob.uniqueId}, has been saved`, AlertType.Success);

      // delete the parent job after we updated our job to remove parent stress job.
      if (deleteParentJob$ && !this.isClonePage) {
        await deleteParentJob$.toPromise();
      }

      // If there are no linked jobs, navigate away
      if (mlpaJob.linkedMlpaJobs.length === 0) {
        this.mlpaStateServiceDetail.resetWarningMessages();
        this.closeSubmit();
        if (isFinish) {
          // send data to google analytics
          this.analyticsServiceDetail.sendJobEvent(mlpaJob, 'Job Payload', 'Job was submitted');

          // If there is a redirect url redirect
          if (this.redirectUrl != null) {
            this.routerDetail.navigate([this.redirectUrl]);
          } else {
            this.routerDetail.navigate(['/home']);
          }
        } else {
          this.isSubmitting = false;
          this.showSaving = false;
          this.deleteFromEditEmitter.emit();
        }
        return;
      }

      // Only supporting 1 linked job right now
      mlpaJob.linkedMlpaJobs[0].parentMlpaJobId = mlpaJob.id;
      mlpaJob.linkedMlpaJobs[0].programNumber = mlpaJob.programNumber;
      mlpaJob.linkedMlpaJobs[0].setupNumber = mlpaJob.setupNumber;
      mlpaJob.linkedMlpaJobs[0].blanketNumber = mlpaJob.blanketNumber;

      this.mlpaServiceDetail.saveMlpaJob(mlpaJob.linkedMlpaJobs[0]).subscribe(linkedData => {
        const linkedJobType = mlpaJob.linkedMlpaJobs[0]?.mlpaLanes[1]?.laneType === MlpaLaneType.QrCode ? 'QR code' : 'Linked';
        mlpaJob.linkedMlpaJobs[0].id = linkedData.id;
        mlpaJob.linkedMlpaJobs[0].uniqueId = linkedData.uniqueId;
        this.alertsServiceDetail.add(`${linkedJobType} job saved`, `The ${linkedJobType} job,
        ${mlpaJob.linkedMlpaJobs[0].name}-${mlpaJob.linkedMlpaJobs[0].uniqueId}, has been saved`, AlertType.Success);
        this.mlpaStateServiceDetail.resetWarningMessages();
        this.closeSubmit();
        if (isFinish) {
          // send data to google analytics
          this.analyticsServiceDetail.sendJobEvent(mlpaJob, 'Job Payload', 'Job was submitted');
          if (this.redirectUrl != null) {
            this.routerDetail.navigate([this.redirectUrl]);
          } else {
            this.routerDetail.navigate(['/home']);
          }
        } else {
          this.isSubmitting = false;
          this.showSaving = false;
          this.deleteFromEditEmitter.emit();
        }
        return;

      }, err => {
        this.resetError(err);
      });

    }, async err => {
      // Delete the parent job if an error occurs.
      if (mlpaJob?.parentMlpaJobId != null) {
        await this.mlpaServiceDetail.deleteMlpaJob(mlpaJob.parentMlpaJobId).toPromise();
      }
      this.resetError(err);
    });
  }

  saveSpecialJob(mlpaJob: MlpaJob, isFinish?: boolean): void {
    this.isSubmitting = true;
    if (this.showSaving) {
      return;
    }

    this.showSaving = true;

    // Build up observables to delete jobs
    const deleteJobObservables: Observable<any>[] = [];
    if (this.linkedMlpaJobsToDelete.length > 0) {
      this.linkedMlpaJobsToDelete.forEach(linkedMlpaJob => {
        deleteJobObservables.push(this.mlpaServiceDetail.deleteMlpaJob(linkedMlpaJob.id));
      });
    }

    // Wait on all delete job observables to finish
    forkJoin(deleteJobObservables).subscribe(data => {
      this.alertsServiceDetail.add('Linked jobs deleted successfully', `Linked jobs have been deleted`, AlertType.Success);
    }, err => {
      this.errorAndAlert(err);
    });


    // Disable preprocessing
    this.disableProcessing();
    // Ensure that this is a special job.
    mlpaJob.isSpecial = true;

    // Save the job
    this.mlpaServiceDetail.saveSpecialMlpaJob(mlpaJob).subscribe(data => {
      // Set ID and unique ID
      mlpaJob.id = data.id;
      mlpaJob.uniqueId = data.uniqueId;
      this.alertsServiceDetail.add('MLPA Web Layout saved', `The MLPA Web Layout job, ${this.mlpaJob.name}-${this.mlpaJob.uniqueId}, has been saved`, AlertType.Success);

      // If there are no linked jobs, navigate away
      if (mlpaJob.linkedMlpaJobs.length === 0) {
        this.mlpaStateServiceDetail.resetWarningMessages();
        this.closeSubmit();
        if (isFinish) {

          // If there is a redirect url redirect
          if (this.redirectUrl != null) {
            this.routerDetail.navigate([this.redirectUrl]);
          } else {
            this.routerDetail.navigate(['/home']);
          }
        } else {
          this.isSubmitting = false;
          this.showSaving = false;
          this.deleteFromEditEmitter.emit();
        }
        return;
      }

      // Only supporting 1 linked job right now
      mlpaJob.linkedMlpaJobs[0].parentMlpaJobId = mlpaJob.id;
      mlpaJob.linkedMlpaJobs[0].programNumber = mlpaJob.programNumber;
      mlpaJob.linkedMlpaJobs[0].setupNumber = mlpaJob.setupNumber;
      mlpaJob.linkedMlpaJobs[0].blanketNumber = mlpaJob.blanketNumber;
      mlpaJob.linkedMlpaJobs[0].isSpecial = true;

      this.mlpaServiceDetail.saveMlpaJob(mlpaJob.linkedMlpaJobs[0]).subscribe(linkedData => {
        mlpaJob.linkedMlpaJobs[0].id = linkedData.id;
        mlpaJob.linkedMlpaJobs[0].uniqueId = linkedData.uniqueId;
        this.alertsServiceDetail.add('WebLayout QR code job saved', `The WebLayout QR code job,
        ${mlpaJob.linkedMlpaJobs[0].name}-${mlpaJob.linkedMlpaJobs[0].uniqueId}, has been saved`, AlertType.Success);
        this.mlpaStateServiceDetail.resetWarningMessages();
        this.closeSubmit();
        if (isFinish) {
          // send data to google analytics
          this.analyticsServiceDetail.sendJobEvent(mlpaJob, 'Job Payload', 'Job was submitted');
          if (this.redirectUrl != null) {
            this.routerDetail.navigate([this.redirectUrl]);
          } else {
            this.routerDetail.navigate(['/home']);
          }
        } else {
          this.isSubmitting = false;
          this.showSaving = false;
          this.deleteFromEditEmitter.emit();
        }
        return;

      }, err => {
        this.resetError(err);
      });
    });
  }

  resetError(err): void {
    this.mlpaStateServiceDetail.resetWarningMessages();
    this.closeSubmit();
    this.errorAndAlert(err);
    this.isSubmitting = false;
    this.showSaving = false;
  }

  refreshJobCharacterizationDropDownSelections() {
    // when color correction is enabled manually then assign print mode value from color management.
    this.printMode = this.colorManagementSystemSettings?.jobCharacterizationValues?.printMode;
    this.setSpeedDetails();
    this.setSubtrateDetails();
    this.setOverPrintVarnishDetails();
    this.setPrintModeDetails();
    this.setInkCOlorDetails();
    this.mlpaJobView.colorCorrectionInfo.pressType = this.colorManagementSystemSettings.jobCharacterizationValues.pressType;
  }

  selectWebWeave(value: MlpaLaneType): void {
    const jobViewBeforeChange = {} as MlpaJobView;
    let laneViewBeforeChange: MlpaLaneView[] = [];

    deepCopyProperties(jobViewBeforeChange, this.mlpaJobView);
    laneViewBeforeChange = [...this.mlpaJobView.mlpaLanes];
    this.jobSettings.laneType = value;

    // Modify the lanes
    this.mlpaJob.mlpaLanes = recalculateMlpaJobProperties(this.mlpaJob.mlpaLanes, this.jobSettings, this.alertsServiceDetail);
    this.mlpaJobView = new MlpaJobView(this.mlpaJob);

    this.mlpaJobView.mlpaLanes = recalculateMlpaLaneView(laneViewBeforeChange, this.mlpaJobView.mlpaLanes);

    // Recopy settings
    this.mlpaJobView.name = jobViewBeforeChange.name;
    this.mlpaJobView.jobSettings = this.jobSettings;
    this.mlpaJobView.programNumber = jobViewBeforeChange.programNumber;
    this.mlpaJobView.setupNumber = jobViewBeforeChange.setupNumber;
    this.mlpaJobView.mlpaLanes.forEach((lane, index) => {
      if (lane.laneType === MlpaLaneType.Graphic) {
        this.mlpaJobView.mlpaLanes[index].quantity = jobViewBeforeChange.mlpaLanes[index].quantity;
        this.mlpaJobView.mlpaLanes[index].designNumberUp = jobViewBeforeChange.mlpaLanes[index].designNumberUp;
        this.mlpaJobView.mlpaLanes[index].numberOfRibbons = jobViewBeforeChange.mlpaLanes[index].numberOfRibbons;
        this.mlpaJobView.mlpaLanes[index].totalLengthInInches = jobViewBeforeChange.mlpaLanes[index].totalLengthInInches;
        this.mlpaJobView.mlpaLanes[index].isTransposed = jobViewBeforeChange.mlpaLanes[index].isTransposed;
        this.mlpaJobView.mlpaLanes[index].isProcessingEnabled = jobViewBeforeChange.mlpaLanes[index].isProcessingEnabled;
        this.mlpaJobView.mlpaLanes[index].colorCorrectionInfo.isColorCorrectionEnabled = jobViewBeforeChange.mlpaLanes[index].colorCorrectionInfo.isColorCorrectionEnabled;
        this.mlpaJobView.mlpaLanes[index].rotationInDegrees = jobViewBeforeChange.mlpaLanes[index].rotationInDegrees;
        this.mlpaJobView.mlpaLanes[index].colorProfileId = jobViewBeforeChange.mlpaLanes[index].colorProfileId;
        this.mlpaJobView.mlpaLanes[index].colorProfileName = jobViewBeforeChange.mlpaLanes[index].colorProfileName;
      }
    });
    this.mlpaJobView.isNewJob = true;
    this.mlpaJobView.totalWidthInInches = getTotalLengthOfLanes(this.mlpaJobView.mlpaLanes);
    this.mlpaStateService.setByValue('totalWidthInInches', getTotalLengthOfLanes(this.mlpaJob.mlpaLanes));
  }
  save(mlpaJobView: MlpaJobView): void {
    this.mlpaStateServiceDetail.mlpaWarningMessagesState$.subscribe(results => this.warningMessages = results);

    // If color correction is enabled for any of the lanes, then validate its characterization values
    if (this.showJobCharacterizationValues) {
      const checkCharValuesObserbales: Observable<any>[] = [];

      // Construct observables of design char values for validation
      this.mlpaJobView.mlpaLanes.forEach(mlpaLane => {
        this.mlpaJobActionGroupComponent.updateValidatingStatus(true);
        if (mlpaLane.colorCorrectionInfo.isColorCorrectionEnabled && mlpaLane.laneType === MlpaLaneType.Graphic) {
          const mlpaCharacterizationValue: MlpaCharacterizationValue = {
            inkColors: mlpaLane.colorCorrectionInfo.inkColors,
            inkWeight: mlpaLane.colorCorrectionInfo.inkWeight,
            speed: mlpaLane.colorCorrectionInfo.speed,
            overPrintVarnish: mlpaLane.colorCorrectionInfo.overPrintVarnish,
            pressType: mlpaLane.colorCorrectionInfo.pressType,
            printMode: mlpaLane.colorCorrectionInfo.printMode,
            substrate: mlpaLane.colorCorrectionInfo.substrateWeight,
            totalAreaCoverage: mlpaLane.colorCorrectionInfo.totalAreaCoverage
          };
          checkCharValuesObserbales.push(this.mlpaJobOptionsService.checkCharacterizationValues(
            this.mlpaJobView.colorCorrectionInfo.colorManagementSystemId,
            mlpaLane.designNumber,
            mlpaLane.laneNumber,
            mlpaCharacterizationValue));
        }
      });

      // disable save button
      this.mlpaJobActionGroupComponent.UpdateCharacterizationValueStatus(false);

      // enable spinner
      this.mlpaJobActionGroupComponent.updateValidatingStatus(true);

      forkJoin(checkCharValuesObserbales).subscribe((results) => {
        const checkCharValuesFlag: boolean[] = [];

        // Loop through the results and validate
        results.forEach(result => {
          if (result.isValid) {
            checkCharValuesFlag.push(true);
          } else {
            checkCharValuesFlag.push(false);
            this.alertsService.add('Invalid characterization values',
              `Characterization values for design number ${result.designNumber} and lane# ${result.laneNumber} are invalid.
                Please edit the characterization values or disable color correction.`,
              AlertType.Error);
          }
        });

        // Validate Ink Colors and Weight
        let isValid = false;

        for (const lane of mlpaJobView.mlpaLanes) {
          if(lane.laneType === MlpaLaneType.Graphic){
            // Validate Color Profile
            isValid = lane.colorProfileName.includes('inkLimitsOnly');

            if(!isValid){
              this.alertsService.add('Invalid Color Profile',
                `The color profile selected is not valid for a job with color correction.`,
                AlertType.Error);
              break;
            } else {
              // Validate Ink Colors
              switch (mlpaJobView.colorCorrectionInfo.inkColors) {
                case '6C':
                  isValid = lane.colorProfileName.includes('cmykov');
                  break;

                case '4C':
                  // Only validate 4C ink colors for non T1100 Assets
                  isValid = this.mlpaJobView.assetName === 'T1100 Digital Roll Printer' || lane.colorProfileName.includes('_cmyk_');
                  break;

                default:
                  break;
              }

              // Validate Weight
              if(isValid){
                isValid = lane.colorProfileName.includes(lane.colorCorrectionInfo.inkWeight);
              }

              if(!isValid){
                this.alertsService.add('Invalid Color Profile',
                  `The color profile selected does not match the ink colors or ink weight.`,
                  AlertType.Error);
                break;
              }
            }
          }
        }

        // enable or disable save button
        this.mlpaJobActionGroupComponent.UpdateCharacterizationValueStatus(checkCharValuesFlag.every(Boolean));
        // disable spinner
        this.mlpaJobActionGroupComponent.updateValidatingStatus(false);
      }, (err) => {
        // disable save button
        this.mlpaJobActionGroupComponent.UpdateCharacterizationValueStatus(false);
        // disable spinner
        this.mlpaJobActionGroupComponent.updateValidatingStatus(false);
        this.alertsService.add('Could not validate lane characterization values', 'Please try after some time', AlertType.Error);
      });
    }

    // Check for errors
    mlpaJobView = this.checkNames(mlpaJobView);
    // store which jobs to delete
    this.linkedMlpaJobsToDelete = mlpaJobView.linkedMlpaJobs;
    // copy job settings to view.
    this.mlpaJobView.name = this.jobName.value;
    this.mlpaJobView.assetName = this.jobSettings.asset.name;
    this.mlpaJobView.paperTypeName = this.jobSettings.paperType.name;
    this.mlpaJobView.paperWidthInInches = this.jobSettings.paperWidthInInches;

    if (this.showJobCharacterizationValues) {
      this.mlpaJobView.colorCorrectionInfo.colorManagementSystemId = this.colorManagementSystemSettings.colorManagementSystem.id;
      this.mlpaJobView.colorManagementSystemName = this.colorManagementSystemSettings.colorManagementSystem.name;
      this.mlpaJobView.colorCorrectionInfo.substrateWeight = this.colorManagementSystemSettings.jobCharacterizationValues.substrate;
      this.mlpaJobView.colorCorrectionInfo.speed = this.colorManagementSystemSettings.jobCharacterizationValues.speed;
      this.mlpaJobView.colorCorrectionInfo.overPrintVarnish = this.colorManagementSystemSettings.jobCharacterizationValues.overPrintVarnish;
      this.mlpaJobView.colorCorrectionInfo.pressType = this.colorManagementSystemSettings.jobCharacterizationValues.pressType;
      this.mlpaJobView.colorCorrectionInfo.printMode = this.colorManagementSystemSettings.jobCharacterizationValues.printMode;
      this.mlpaJobView.colorCorrectionInfo.inkColors = this.colorManagementSystemSettings.jobCharacterizationValues.inkColors;
    }

    this.jobSettings.lineBreakLengthInFeet = getValidLineBreakInFeet(this.jobSettings.lineBreakLengthInFeet, mlpaJobView, this.alertsServiceDetail);
    this.copyMlpaJob();
    this.copyMlpaJobSettings();
    this.saveEmitter.emit({ mlpaJobView, jobSettings: this.jobSettings, colorManagementSystemSettings: this.colorManagementSystemSettings, jobNameControl: this.jobName });
    this.toggleEdit();
  }
  updateDropDowns(): void {
    this.updateState();
    this.setPaperTypeDetails();
    this.setPaperWidthDetails();
  }
  updatePressType(assetName: string): void {
    this.colorManagementSystemSettings.selectPressType(assetName.split(' ')[0]);
    this.mlpaJobView.colorCorrectionInfo.pressType = this.colorManagementSystemSettings.jobCharacterizationValues.pressType;
    this.updatePressTypeToLanes();
  }

  selectPaperWidth(selectedItem: SelectItem) {
    const oldPaperWidth = this.jobSettings.paperWidthInInches;

    // Use real value instead of rounded value for calculations
    this.jobSettings.paperWidthInInches = parseFloat(selectedItem.source);

    // Adjust the margin based on paper width selection
    this.jobSettings.setMarginWidth(oldPaperWidth);

  }
  selectedItemExistsInOptions(selectedItem: string, source: SearchAutoComplete): boolean {
    const value = typeof selectedItem === 'string' ? selectedItem : parseFloat(selectedItem).toFixed(2);
    if (!!selectedItem && !!source) {
      return source.results.filter(result => (result.value === value)).length > 0;
    }

    return false;
  }

  selectedNumberExistsInOptions(selectedItem: string, source: SearchAutoComplete) {
    const value = parseFloat(selectedItem).toFixed(3);
    // filter and see if there is an exact match
    if (!!selectedItem && !!source) {
      const results = source.results.filter(result => (result.value === value));
      if (results.length > 0) {
        this.selectPaperWidth(results.filter(item => item.value === value)[0]);
        return true;
      }
    }

    return false;
  }

  maxDesignLength(job: MlpaJobView): number {
    return job.mlpaLanes.reduce((a, b) => a.designLengthInInches >= b.designLengthInInches ? a : b).designLengthInInches;
  }

  maxLaneLength(job: MlpaJobView): number {
    // (OLD) Accounts for WebWeave length since it could be up to 11.99 inches longer than the longest graphic lane
    // const jobWebWeaveLength =  job.mlpaLanes[0].designLengthInInches;
    // return Math.ceil(this.maxDesignLength(job) / jobWebWeaveLength) * jobWebWeaveLength;
    return this.maxDesignLength(job);
  }

  cancelNewJob($event): void {
    if (this.isClonePage) {
      if (this.redirectUrl != null) {
        this.routerDetail.navigate([this.redirectUrl]);
      } else {
        this.routerDetail.navigate(['/home']);
      }
    }

    deepCopyProperties(this.jobSettings, this.copyJobSettings[0]);
    this.copyJobSettings = [];
    this.copyMlpaJobView = [];
    this.deleteFromEditEmitter.emit();
  }

  deleteLane($event): void {
    this.mlpaJobView.isNewJob = false;
    this.deleteFromEditEmitter.emit($event);
  }
  edit(job: MlpaJobView): void {
    if (this.isMlpaPage) {
      if (job.id) {
        this.mlpaJobView.isNewJob = true;
      }

      this.toggleEdit();
      this.initializeForEdit();
      return;
    }

    if (this.userRole === Role.Operator) {
      this.routerDetail.navigate([`mlpa/${job.id}/edit`]);
    } else {
      this.routerDetail.navigate([`mlpa/${job.id}`]);
    }

  }
  preCancelEdit(): void {
    this.showCancelConfirm = true;
  }
  confirmCancelEdit(): void {
    const length = this.copyJobSettings.length - 1;
    // recopy the old mlpaJob
    deepCopyProperties(this.mlpaJobView, this.copyMlpaJobView[length - 1]);
    // recopy the old settings.
    deepCopyProperties(this.jobSettings, this.copyJobSettings[length - 1]);
    this.toggleEdit();
    this.showCancelConfirm = false;
    this.setAssetDetails();
    this.setPaperTypeDetails();
    this.setPaperWidthDetails();

    if (!!this.mlpaJobView.id) {
      this.mlpaJobView.isNewJob = false;
    }

    if (this.mlpaJob) {
      this.mlpaJob.mlpaLanes = recalculateMlpaJobProperties(this.mlpaJob.mlpaLanes, this.jobSettings, this.alertsServiceDetail);
      this.mlpaJobView = new MlpaJobView(this.mlpaJob);
      this.mlpaJobView.jobSettings = this.jobSettings;
      this.mlpaJobView.isNewJob = true;


      // To update the ruler.
      this.mlpaStateServiceDetail.setLaneStateByValue('mlpaLanes', this.mlpaJob.mlpaLanes);
      this.mlpaStateServiceDetail.setLaneStateByValue('totalWidthInInches', getTotalLengthOfLanes(this.mlpaJob.mlpaLanes));
    }
    this.cancelEmitter.emit();
  }

  toggleQrCode(): void {
    this.jobSettings.hasQrCode = !this.jobSettings.hasQrCode;
    if (this.jobSettings.laneType === MlpaLaneType.SmartLine) {
      this.selectWebWeave(MlpaLaneType.WebWeave);
    }
  }

  toggleChevron(): void {
    this.jobSettings.hasStress = !this.jobSettings.hasStress;
  }

  setColorManagementSystemDetails(): void {
    this.colorManagementSystemDetails = new SearchAutoComplete(
      'colorManagementSystemType',
      '',
      'Enter a color management system...',
      { data: this.mapColorManagementSystemsToDropDownSelectItem() }
    );
    this.colorManagementSystemDetails.textValue = this.colorManagementSystemSettings.colorManagementSystem != null ? this.colorManagementSystemSettings.colorManagementSystem.name : '';
  }

  setSpeedDetails(): void {
    this.speedDetails = new SearchAutoComplete(
      'speed',
      '',
      'Enter a speed...',
      { data: this.mapSpeedToDropDownSelectItem() }
    );
    this.speedDetails.textValue = this.colorManagementSystemSettings.jobCharacterizationValues?.speed ? this.colorManagementSystemSettings.jobCharacterizationValues.speed : '';
  }

  setSubtrateDetails(): void {
    this.subsrateDetails = new SearchAutoComplete(
      'substrate',
      '',
      'Enter a substrate...',
      { data: this.mapSubstratesToDropDownSelectItem() }
    );

    this.subsrateDetails.textValue = this.colorManagementSystemSettings.jobCharacterizationValues?.substrate ? this.colorManagementSystemSettings.jobCharacterizationValues.substrate : '';
  }

  setOverPrintVarnishDetails(): void {
    this.overPrintVarnishDetails = new SearchAutoComplete(
      'overPrintVarnish',
      '',
      'Enter an over print varnish...',
      { data: this.mapOverPrintVarnishToDropDownSelectItem() }
    );

    this.overPrintVarnishDetails.textValue = this.colorManagementSystemSettings.jobCharacterizationValues?.overPrintVarnish ?
      this.colorManagementSystemSettings.jobCharacterizationValues.overPrintVarnish : '';
  }

  setPrintModeDetails(): void {
    this.printModeDetails = new SearchAutoComplete(
      'printMode',
      '',
      'Enter a print mode...',
      { data: this.mapPrintModesToDropDownSelectItem() }
    );
    this.printModeDetails.textValue = this.printMode;
    if (Boolean(this.printModeDetails.textValue) || (this.assetType === AssetType.T1100 && !this.mlpaJobView.isColorCorrectionEnabled)) {
        this.mlpaJobActionGroupComponent.enableLock = true;
    } else {
        this.mlpaJobActionGroupComponent.enableLock = false;
    }
  }

  setInkCOlorDetails(): void {
    this.inkColorDetails = new SearchAutoComplete(
      'inkColors',
      '',
      'Enter a value for ink color...',
      { data: this.mapInkColrsToDropDownSelectItem() }
    );

    this.inkColorDetails.textValue = this.colorManagementSystemSettings.jobCharacterizationValues?.inkColors ? this.colorManagementSystemSettings.jobCharacterizationValues.inkColors : '';
  }

  setAssetDetails(): void {
    this.assetDetails = new SearchAutoComplete(
      'assetType',
      '',
      'Enter an asset...',
      { data: this.mapAssetTypesToDropDownSelectItem() }
    );
    // set the asset type if there is one
    this.assetDetails.textValue = this.jobSettings.asset != null ? this.jobSettings.asset.name : '';
  }

  setPaperTypeDetails(): void {
    this.paperTypeDetails = new SearchAutoComplete(
      'paperType',
      '',
      'Enter a paper type...',
      { data: this.mapPaperTypesToDropDownSelectItem() }
    );
    // set the paper type if there is one.
    this.paperTypeDetails.textValue = this.jobSettings.paperType != null ? this.jobSettings.paperType.name : '';
  }

  setPaperWidthDetails(): void {
    this.paperWidthDetails = new SearchAutoComplete(
      'paperWidth',
      '',
      'Enter a paper width...',
      { data: this.mapPaperWidthToDropDownSelectItem() }
    );
    // set the paper width if there is one.
    this.paperWidthDetails.textValue = this.jobSettings.paperWidthInInches != null ? this.jobSettings.paperWidthInInches.toFixed(3) : '';
  }

  updateState(): void {
    this.mlpaStateServiceDetail.setMlpaLaneState(
      {
        hasValidColorProfiles: this.mlpaStateServiceDetail.getValueFromLane('hasValidColorProfiles'),
        jobSettings: this.jobSettings
      });

    this.paperWidthComponent.staticStore = this.mapPaperWidthToDropDownSelectItem();
    this.setPaperWidthDetails();
    this.cd.detectChanges();
  }

  optimizeAllUp(): void {
    const values = findLongestShortestLaneLength(this.mlpaJobView.mlpaLanes);
    this.optimizeAll(values.longestLaneInInches, values.longestLaneIndex);

    this.alertsServiceDetail.add('Optimize Up', 'Lanes have been optimized up.', AlertType.Success);
  }

  optimizeAllDown(): void {
    const values = findLongestShortestLaneLength(this.mlpaJobView.mlpaLanes);
    this.optimizeAll(values.shortestLaneInInches, values.shortestLaneInInches);

    this.alertsServiceDetail.add('Optimize Down', 'Lanes have been optimized down.', AlertType.Success);
  }

  optimizeAll(lengthInInches, laneIndex): void {
    // If there is only one graphic don't do anything.
    if (this.mlpaJobView.mlpaLanes.length === 3) {
      return;
    }

    this.mlpaJobView.mlpaLanes.forEach((lane, index) => {
      if (index !== laneIndex) {
        this.mlpaLaneInfoComponent.editLength(lengthInInches / 12, lane);
      }
    });
  }
  disableProcessing(): void {
    this.mlpaJobView.mlpaLanes.forEach((lane, index) => {
      if (lane.laneType === MlpaLaneType.Graphic) {
        this.mlpaLaneInfoComponent.mlpaLanes[index].isProcessingEnabled = false;
      }
    });
  }

  enableProcessing(): void {
    this.mlpaJobView.mlpaLanes.forEach((lane, index) => {
      if (lane.laneType === MlpaLaneType.Graphic) {
        this.mlpaLaneInfoComponent.mlpaLanes[index].isProcessingEnabled = true;
      }
    });
  }

  disableColorCorrection(): void {
    if((this.assetType === AssetType.T1100 && this.mlpaJobView.isColorCorrectionEnabled)) {
      this.mlpaJobActionGroupComponent.enableLock = true;
    }
    this.mlpaJobView.mlpaLanes.forEach((lane, index) => {
      if (lane.laneType === MlpaLaneType.Graphic) {
        this.mlpaLaneInfoComponent.mlpaLanes[index].colorCorrectionInfo.isColorCorrectionEnabled = false;
        this.mlpaLaneInfoComponent.setColorProfileFromProductDesign(this.mlpaLaneInfoComponent.mlpaLanes[index]);
      }
    });
     this.colorManagementSystemSettings.clearJobCharacterizationValues();
  }

  enableColorCorrection(): void {
    this.mlpaJobView.mlpaLanes.forEach((lane, index) => {
      if (lane.laneType === MlpaLaneType.Graphic) {
        // check and set job and lane characterization values
        this.mlpaLaneInfoComponent.mlpaLanes[index].colorCorrectionInfo.isColorCorrectionEnabled = true;
      }
    });
    this.mlpaLaneInfoComponent.setMlpaJobAndAllLanesCharacterizationValues();
  }
  toggleLanes(): void {
    this.mlpaLaneInfoComponent.toggleLanes(this.toggleLaneState);
  }

  buildJobName(): void {
    this.jobName.patchValue(this.mlpaJob.buildName());
  }

  onMarginChanged($event) {
    this.mlpaStateService.setByValue('jobSettings', this.jobSettings);
  }

  enableLockFromJob(enablelock?: boolean): void {
       this.mlpaJobActionGroupComponent.enableLock = enablelock;
    }

  enablePrintMode(): boolean {
      return this.assetType === AssetType.T1195 || (this.assetType === AssetType.T1100 && this.mlpaJobView?.isColorCorrectionEnabled);
    }

  private mapColorManagementSystemsToDropDownSelectItem(): SelectItem[] {
    // creating an array of select items for the auto complete
    if (!!this.colorManagementSystemSettings.colorManagementSystems && this.colorManagementSystemSettings.colorManagementSystems.length > 0) {
      return this.colorManagementSystemSettings.colorManagementSystems.map(result => ({ id: result.id, value: result.name, source: result } as SelectItem));
    }

    // if there isn't an asset we just want to return an empty array
    return [];
  }

  private mapPrintModesToDropDownSelectItem(): SelectItem[] {
    // creating an array of select items for the auto complete
    const printModes = this.colorManagementSystemSettings.characterizationValues?.find(result => result.characterizationType === CharacterizationType.PrintMode);
    if (!!printModes) {
      return printModes.values.map(result => ({ id: result, value: result, source: result } as SelectItem));
    }

    // if there isn't an asset we just want to return an empty array
    return [];
  }

  private mapInkColrsToDropDownSelectItem(): SelectItem[] {
    // creating an array of select items for the auto complete
    const inkColors = this.colorManagementSystemSettings.characterizationValues?.find(result => result.characterizationType === CharacterizationType.InkColors);
    if (!!inkColors) {
      return inkColors.values.map(result => ({ id: result, value: result, source: result } as SelectItem));
    }

    // if there isn't an asset we just want to return an empty array
    return [];
  }

  private mapOverPrintVarnishToDropDownSelectItem(): SelectItem[] {
    // creating an array of select items for the auto complete
    const overPrintVarnish = this.colorManagementSystemSettings.characterizationValues?.find(result => result.characterizationType === CharacterizationType.OverPrintVarnish);
    if (!!overPrintVarnish && overPrintVarnish.values.length > 0) {
      return overPrintVarnish.values.map(result => ({ id: result, value: result, source: result } as SelectItem));
    }

    // if there isn't an asset we just want to return an empty array
    return [];
  }

  private mapSpeedToDropDownSelectItem(): SelectItem[] {
    // creating an array of select items for the auto complete
    const speed = this.colorManagementSystemSettings.characterizationValues?.find(result => result.characterizationType === CharacterizationType.Speed);
    if (!!speed && speed.values.length > 0) {
      return speed.values.map(result => ({ id: result, value: result, source: result } as SelectItem));
    }

    // if there isn't an asset we just want to return an empty array
    return [];
  }

  private mapSubstratesToDropDownSelectItem(): SelectItem[] {
    // creating an array of select items for the auto complete
    const subtrates = this.colorManagementSystemSettings.characterizationValues?.find(result => result.characterizationType === CharacterizationType.SubstrateWeight);
    if (!!subtrates && subtrates.values.length > 0) {
      return subtrates.values.map(result => ({ id: result, value: result, source: result } as SelectItem));
    }

    // if there isn't an asset we just want to return an empty array
    return [];
  }

  private mapAssetTypesToDropDownSelectItem(): SelectItem[] {
    // creating an array of select items for the auto complete
    if (!!this.jobSettings.assets && this.jobSettings.assets.length > 0) {
      return this.jobSettings.assets.filter(asset => asset.assetType === this.jobSettings?.asset?.assetType).map(result => ({ id: result.id, value: result.name, source: result } as SelectItem));
    }

    // if there isn't an asset we just want to return an empty array
    return [];
  }

  private mapPaperTypesToDropDownSelectItem(): SelectItem[] {
    // creating an array of select items for the auto complete
    return this.jobSettings.paperTypes.map(result => ({ id: result.id, value: result.name, source: result } as SelectItem));
  }

  private mapPaperWidthToDropDownSelectItem(): SelectItem[] {
    // creating an array of select items for the auto complete
    if (!!this.jobSettings.paperWidthInInches) {
      return this.jobSettings.paperWidthsInInches.map(result =>
      ({
        id: parseFloat(result.toString()).toFixed(3),
        value: parseFloat(result.toString()).toFixed(3), source: result
      } as SelectItem));
    }

    // if there isn't paperwidths we just want to return an empty array
    return [];
  }

  private checkNames(mlpaJob: MlpaJobView): MlpaJobView {
    // Replace special characters
    mlpaJob.programNumber = replaceSpecialCharacters(mlpaJob.programNumber);
    mlpaJob.setupNumber = replaceSpecialCharacters(mlpaJob.setupNumber);
    mlpaJob.name = replaceSpecialCharacters(mlpaJob.name);

    return mlpaJob;
  }

  private copyMlpaJobSettings(): void {
    if (!!this.jobSettings) {
      this.copyJobSettings.push(new MlpaJobSettings(this.jobSettings.assets));
      const length = this.copyJobSettings.length - 1;
      deepCopyProperties(this.copyJobSettings[length], this.jobSettings);
    }
  }

  private copyMlpaJob(): void {
    if (!!this.mlpaJobView) {
      this.copyMlpaJobView.push(new MlpaJobView());
      const length = this.copyMlpaJobView.length - 1;
      deepCopyProperties(this.copyMlpaJobView[length], this.mlpaJobView);
    }
  }
}
