import { Orientation } from './orientation.enum';

/**
 *
 *
 * Converts blob to base 64 data uri. See more from link below:
 *
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URIs
 *
 *
 *
 * @export
 * @param blob
 * @returns
 */
export const blobToBase64 = async (blob: Blob): Promise<string | ArrayBuffer> => new Promise<string | ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = async (): Promise<void> => {
      const result = typeof reader.result === typeof '' ? reader.result : String.fromCharCode.apply(null, reader.result);
      resolve(result);
    };
    reader.onerror = async (): Promise<void> => {
      reject(reader.error);
    };
  });


/**
 *
 *
 * Returns the width and height of a blob. Loads the image then
 *
 * returns the width and height.
 *
 *
 *
 * @export
 * @param blob
 * @returns
 */
export async function getHeightAndWidthOfImage(blob: Blob | string): Promise<{ width: number; height: number }> {
  return new Promise<any>((resolve, reject) => {
    const image = new Image();

    if (blob instanceof Blob) {
      image.src = URL.createObjectURL(blob);
    } else {
      image.src = blob;
    }


    // wait for file to load to get dimensions.
    image.onload = ((): void => {
      if (image.width <= 0 || image.height <= 0) {
        this.onerror();
      }
      resolve({ width: image.width, height: image.height });
    });
    image.onerror = ((): void => {
      reject('Error loading image');
    });
  });
}


/**
 *
 *
 * Gets adjusted scaled height, to maintain aspect ratio of
 *
 * original dimensions.
 *
 *
 *
 * @export
 * @param width
 * @param originalWidth
 * @param originalHeight
 * @returns
 */
export const getAdjustedHeight = (width: number, originalWidth: number, originalHeight: number): number => {
  if (width <= 0 || originalWidth <= 0 || originalHeight <= 0) {
    throw new Error('Dimensions cannot be 0');
  }

  return width * (originalHeight / originalWidth);
};


/**
 *
 *
 * Returns Landscape if the width is larger than height.
 *
 * Returns Portrait if height is larger then width.
 *
 * Returns Square if width and height are equal.
 *
 *
 *
 * @export
 * @param width
 * @param height
 * @returns
 */
export const getOrientation = (width: number, height: number): Orientation => {
  if (width <= 0 || height <= 0) {
    throw new Error('width/height cannot be 0');
  }

  if (width > height) {
    return Orientation.Landscape;
  } else if (height > width) {
    return Orientation.Portrait;
  } else {
    return Orientation.Square;
  }
};
