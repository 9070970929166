import { Component, OnInit } from '@angular/core';
import { Browser } from './browser-type.enum';
import { Subscription } from 'rxjs';
import { AuthService } from '../../auth/auth.service';


@Component({
  selector: 'pkg-compatibility-warning',
  templateUrl: './compatibility-warning.component.html',
  styleUrls: ['./compatibility-warning.component.scss']
})
export class CompatibilityWarningComponent implements OnInit {
  showMessage: boolean;
  isIE: boolean;
  isTokenValid: boolean;
  subscription: Subscription;
  constructor(private readonly _authService: AuthService) { }

  ngOnInit(): void {
    this.showMessage = true;
    this._checkBrowserType();
  }

  close(): void {
    this.showMessage = false;
  }
  private _checkBrowserType(): void {
    const ua = navigator.userAgent;
    // check if browser is Internet Explorer.
    this.isIE = ua.indexOf(Browser.OldIE) > -1 || ua.indexOf(Browser.NewIE) > -1;
  }
}
