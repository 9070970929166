import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SearchAutoComplete } from '../../search-auto-complete/search-auto-complete';
import { SelectItem } from '../../search-auto-complete/select-item';
import { PagedResult } from '@shared/paged-result';
import { ProductDesignOffline } from '../product-design/product-design-offline.interface';
import { ProductDesign } from '../product-design/product-design.interface';
import { AssetType } from '@shared/asset-type.enum';


@Injectable({
  providedIn: 'root'
})
export class ProductDesignSearchService {
  params: HttpParams;

  constructor(private readonly _http: HttpClient) { }

  saveSearchCriteria(details: SearchAutoComplete[], maxDueDate: Date, maxWidth: number): void {
    let params = new HttpParams();

    details.forEach(d => {
      let termUrlName = '';
      let isExactUrlName = '';
      if (d.textValue) {
        switch (d.id) {
          case 'programNumber':
            termUrlName = 'programNumberTerm';
            isExactUrlName = 'isProgramNumberTermExact';
            break;
          case 'setupNumber':
            termUrlName = 'setupNumberTerm';
            isExactUrlName = 'isSetupNumberTermExact';
            break;
          case 'orderNumber':
            termUrlName = 'orderNumberTerm';
            isExactUrlName = 'isOrderNumberTermExact';
            break;
          case 'schedule':
            termUrlName = 'scheduleTerm';
            isExactUrlName = 'isScheduleNumberTermExact';
            break;
          case 'designNumber':
            termUrlName = 'designNumberTerm';
            isExactUrlName = 'isDesignNumberTermExact';
            break;
          case 'designDescription':
            termUrlName = 'designDescriptionTerm';
            isExactUrlName = 'isDesignDescriptionTermExact';
            break;
          case 'customer':
            termUrlName = 'customerTerm';
            isExactUrlName = 'isCustomerTermExact';
            break;
          case 'corrugator':
            termUrlName = 'corrugatorTerm';
            isExactUrlName = 'isCorrugatorTermExact';
            break;
          case 'paperType':
            termUrlName = 'paperTypeTerm';
            isExactUrlName = 'isPaperTypeTermExact';
            break;
        }

        if (d.isExactSearch) {
          params = params.set(`${termUrlName}`, `${d.term}`);
        } else {
          params = params.set(`${termUrlName}`, `${d.textValue}`);
        }

        params = params.set(`${isExactUrlName}`, `${d.isExactSearch}`);
      }
    });

    if (maxDueDate) {
      params = params.set(`maxDueDateTerm`, `${maxDueDate}`);
    }
    if (maxWidth && maxWidth > 0) {
      params = params.set(`maxWidthInInchesTerm`, `${maxWidth}`);
    }

    this.params = params;
  }

  findAutoCompleteResults(value: string, itemUrl: string, assetType: AssetType): Observable<PagedResult<SelectItem>> {
    const params = new HttpParams().set('term', value).set('assetType', assetType.toString());
    return this._http.get<PagedResult<SelectItem>>(`${environment.tartanApiUrl}open-orders/${itemUrl}/autocomplete`, { params });
  }

  findOpenOrders(pageNumber: number, assetType: AssetType): Observable<PagedResult<ProductDesign>> {
    this.params = this.params.set('pageNumber', `${pageNumber}`).set('assetType', assetType.toString());
    return this._http.get<PagedResult<ProductDesign>>(`${environment.tartanApiUrl}open-orders/search`, { params: this.params });
  }

  findProductDesignsAutoComplete(value: string): Observable<PagedResult<SelectItem>> {
    const params = new HttpParams().set('term', value);
    return this._http.get<PagedResult<SelectItem>>(`${environment.tartanApiUrl}product-designs/autocomplete`, { params });
  }

  findProductDesigns(pageNumber: number): Observable<PagedResult<ProductDesignOffline>> {
    this.params = this.params.set('pageNumber', `${pageNumber}`);

    return this._http.get<PagedResult<ProductDesignOffline>>(`${environment.tartanApiUrl}product-designs/search`, { params: this.params });
  }

  clear(): void {
    this.params = new HttpParams();
  }
}
