import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '@shared/analytics.service';
import { HolidayService } from '@shared/holiday/holiday.service';
import { Holiday } from '@shared/holiday/holiday.interface';
import { PkgThemeService } from '@shared/theme/pkg-theme.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'pkg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  currentHoliday: Holiday;
  currentTheme: string;
  styleClass: string;
  constructor(
    private route: ActivatedRoute,
    private readonly _analyticsService: AnalyticsService,
    private readonly _holidayService: HolidayService,
    public readonly pkgThemeService: PkgThemeService
    ) { }

  ngOnInit(): void {
    this._analyticsService.configure();
    this.currentHoliday = this._holidayService.getCurrentHoliday();
    if (this.currentHoliday) {
      this.styleClass = this.currentHoliday.name.toLowerCase();
    }
  }
}
