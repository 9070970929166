<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <metadata>Tartan Icon set</metadata>
  <defs>
    <svg id="solid-line" class="solid-line" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve">
      <rect x="2.5" width="25" height="10" />
    </svg>
    <svg id="smart-line" class="barcode-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve">
      <rect x="27.7" width="2.6" height="10" />
      <rect x="20.4" width="4.7" height="10" />
      <rect x="13.1" width="4.7" height="10" />
      <rect width="10.6" height="10" />
    </svg>

    <svg id="qr-code" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve">
      <rect x="5" y="5" width="5" height="5" fill="transparent" />
      <rect x="5" y="20" width="5" height="5" fill="transparent" />
      <rect x="20" y="5" width="5" height="5" fill="transparent" />
      <rect x="20" y="20" width="5" height="5" fill="transparent" />

      <rect x="7" y="7" width="1" height="1" />
      <rect x="7" y="22" width="1" height="1" />
      <rect x="22" y="7" width="1" height="1" />
      <rect x="22" y="22" width="1" height="1" />

      <rect x="12" y="13" width="7" height="1" />
      <rect x="15" y="14" width="1" height="5" />

      <rect x="5" y="13" width="1" height="1" />
      <rect x="7" y="16" width="1" height="1" />

      <rect x="13" y="7" width="1" height="1" />
      <rect x="16" y="5" width="1" height="1" />

      <rect x="22" y="13" width="1" height="1" />
      <rect x="22" y="16" width="1" height="1" />

      <rect x="13" y="22" width="1" height="1" />
      <rect x="16" y="24" width="1" height="1" />
    </svg>
    <svg id="double-arrow" version="1.1" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g class="st2" id="layer">
        <g class="st0">
          <polyline class="st1" points="411,256 260,407 411,256 260,105   " />
          <polyline class="st1" points="252,256 101,407 252,256 101,105   " />
        </g>
      </g>
      <g id="layer_copy">
        <g>
          <path d="M260,415c-2.048,0-4.095-0.781-5.657-2.343C252.781,411.095,252,409.048,252,407s0.781-4.095,2.343-5.657L399.687,256    L254.343,110.657c-3.125-3.124-3.125-8.189,0-11.313c3.124-3.124,8.189-3.124,11.314,0l151,151    c1.562,1.562,2.343,3.609,2.343,5.657c0,2.048-0.781,4.095-2.343,5.657l-151,151C264.095,414.219,262.048,415,260,415z" />
        </g>
        <g>
          <path d="M101,415c-2.047,0-4.095-0.781-5.657-2.343C93.781,411.095,93,409.048,93,407s0.781-4.095,2.343-5.657L240.686,256    L95.343,110.657c-3.125-3.124-3.125-8.189,0-11.313c3.124-3.124,8.189-3.124,11.313,0l151,151    c1.562,1.562,2.343,3.609,2.343,5.657c0,2.048-0.781,4.095-2.343,5.657l-151,151C105.095,414.219,103.047,415,101,415z" />
        </g>
      </g>
    </svg>
    <svg id="roll-map" data-name="roll-map" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89.83 80.51"><defs></defs><title>roll-map</title><circle class="roll-map-circle-1" cx="40.25" cy="40.25" r="40.25"/><circle class="roll-map-inner-circle-1" cx="40.25" cy="40.25" r="8.5"/><line class="roll-map-line" x1="40.51" y1="77.62" x2="89.81" y2="77.85"/></svg>
    <svg id="roll-map-activated" data-name="roll-map-activated" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89.83 80.51"><defs></defs><title>roll-map</title><circle class="roll-map-circle-1-activated" cx="40.25" cy="40.25" r="40.25"/><circle class="roll-map-inner-circle-1-activated" cx="40.25" cy="40.25" r="8.5"/><line class="roll-map-line-activated" x1="40.51" y1="77.62" x2="89.81" y2="77.85"/></svg>
  </defs>
</svg>
