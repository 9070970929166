import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../auth.service';
import { TokenService } from '../token.service';
import { environment } from '../../../environments/environment';
import { HolidayService } from '@shared/holiday/holiday.service';
import { Holiday } from '@shared/holiday/holiday.interface';
import { switchMap } from 'rxjs/operators';
import { PkgThemeService } from '@shared/theme/pkg-theme.service';

@Component({
  selector: 'pkg-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hideLogin: boolean;
  showConfirm = false;
  kochIdEnabled = false;
  hotKeyIsPressed = false;
  currentHoliday: Holiday;
  styleClass: string;
  isLoading = false;

  get releaseNumber(): string {
    return environment.releaseNumber;
  }

  get getLogoStyle() {
    const backgroundStyles = {
      filter: this._pkgThemeService.getTheme() === 'light-theme' ?
      'invert(17%) sepia(40%) saturate(2919%) hue-rotate(173deg) brightness(90%) contrast(103%)' :
      'invert(100%) sepia(47%) saturate(21%) hue-rotate(102deg) brightness(105%)'
    };
    return backgroundStyles;
  }

  constructor(
    private readonly _router: Router,
    private readonly _authService: AuthService,
    private readonly _tokenService: TokenService,
    private readonly _holidayService: HolidayService,
    private readonly _pkgThemeService?: PkgThemeService) {

    this._authService.isAuthenticating$.subscribe((result) => this.isLoading = result);
    // Once KochId returns a token, proceed with login
    this._authService.tokenReady$.subscribe(() => {
      this.hideLogin = true;
      this._router.navigate([this._authService.redirectUrl]);
      localStorage.removeItem('kochIdStarted');
    });
    // Once KochID returns the well-known document, enable KochID
    this._authService.oAuthReady$.subscribe(observer => {
      this.kochIdEnabled = observer;
    });
    // If KochID has already been tested,
    // use the current value while waiting on verification
    this.kochIdEnabled = this._authService.oAuthReady$.value;
  }

  @HostListener('document:keydown.control.shift.f')
  windowsAuthEnabled(event: KeyboardEvent): void {
    this.hotKeyIsPressed = true;
  }

  @HostListener('document:keyup')
  windowsAuthDisabled(event: KeyboardEvent): void {
    this.hotKeyIsPressed = false;
  }

  ngOnInit(): void {
    if (this._tokenService.isValid()) {
      this.hideLogin = true;
      this._router.navigate([this._authService.redirectUrl]);
    }
    switch (this._authService.autoLogin()) {
      case 'Koch':
        this.loginWithKochId();
        break;
      case 'Windows':
        this.loginWithWindows();
        break;
      case 'Valid Token':
        this._router.navigate([this._authService.redirectUrl]);
    }

    this.currentHoliday = this._holidayService.getCurrentHoliday();
    if (this.currentHoliday) {
      this.styleClass = this.currentHoliday.name.toLowerCase();
    }
  }


  loginWithKochId(): void {
    // Start external login process
    if (this.kochIdEnabled) {
      this._authService.getKochId();
    } else {
      this._authService.oAuthReady$.subscribe(() => {
        this._authService.getKochId();
      });
    }
  }

  loginWithWindows(): void {
    // Old (Windows Auth) login process
    this._authService.getToken().pipe(
      switchMap(() => this._authService.login()))
      .subscribe(() => {
        this.hideLogin = true;
        this._authService.sendStatus(true);
        this._router.navigate([this._authService.redirectUrl]);
      });
  }
}
