export enum MlpaLaneType {
  /// A lane of an unknown type (should not be allowed)
  Unknown = 0,

  /// An empty lane with (fixed length and variable width)
  Empty = 10,

  /// A lane with a graphic (fixed length and fixed width)
  Graphic = 20,

  /// A lane with an E+L SmartLine barcode (fixed length and variable width)
  SmartLine = 30,

  /// A lane with a solid web weave line (fixed length and variable width)
  WebWeave = 40,

  /// A lane with an EFI QR code (fixed length and variable width)
  QrCode = 50,

  /// A job with a chevron for stressing the press.
  Stress = 60
}
