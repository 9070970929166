import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertType } from '@shared/alerts/alert-type.enum';
import { AlertsService } from '@shared/alerts/alerts.service';
import { PagedResult } from '@shared/paged-result';
import { MaterialTypes } from '../shared/material-type';
import { PaperType } from '../shared/paper-type';
import { PaperTypeService } from '../shared/paper-type.service';
import { PkgThemeService } from '@shared/theme/pkg-theme.service';

@Component({
  selector: 'pkg-material-management',
  templateUrl: './material-management.component.html',
  styleUrls: ['./material-management.component.scss']
})

export class MaterialManagementComponent {
  paperTypes: PaperType[] = [];
  updatedPaperTypes: PaperType[] = [];
  currentPageNumber: number;
  hasLoadedAllPaperTypes: boolean;
  searchResults: PagedResult<PaperType>;
  paperTypesLoading = true;
  materialTypes = MaterialTypes;
  showFilter = false;
  term = '';
  isFiltered = false;
  materialFilterValues: MaterialTypes[] = [];
  foundPaperTypes = true;
  isSaving = false;

  get filteredPaperTypes() {
    return this.paperTypes;
  }

  get getLogoStyle() {
    const backgroundStyles = {
      filter: this._pkgThemeService.getTheme() === 'light-theme' ?
      'invert(17%) sepia(40%) saturate(2919%) hue-rotate(173deg) brightness(90%) contrast(103%)' :
      'invert(100%) sepia(47%) saturate(21%) hue-rotate(102deg) brightness(105%)'
    };
    return backgroundStyles;
  }

  private unsubscribe$ = new Subject();

  constructor(
    private readonly alertService: AlertsService,
    private readonly paperTypesService: PaperTypeService,
    private readonly _pkgThemeService?: PkgThemeService) {
  }

  /**
   * Save modified Paper types
   */
  savePaperType() {
    this.isSaving = true;
    const newPaperTypes = [...this.updatedPaperTypes];
    this.updatedPaperTypes = [];
    this.paperTypesService.updatePaperTypes(newPaperTypes).subscribe((complete) => {
      this.isSaving = false;
      this.alertService.add('Saved', `Paper type updated.`, AlertType.Success);
    }, (error) => {
      this.isSaving = false;
      this.alertService.add('Error', 'Failed to save', AlertType.Error);
    });
  }

  /**
   * Close filter card
   */
  closeFilter() {
    this.showFilter = false;
  }

  /**
   * Loading next page of Page types
   */
  loadMorePaperTypes() {
    if (!this.hasLoadedAllPaperTypes && !this.paperTypesLoading) {
      this.getPaperTypes(this.currentPageNumber + 1, true);
    }
  }

  /**
   * Get Paper types
   *
   * @param pageNumber Current page number
   * @param isLoadMore Flag to denote if invoked to load more pages.
   */
  getPaperTypes(pageNumber: number = 1, isLoadMore: boolean = false) {
    this.paperTypesLoading = true;
    this.paperTypesService.getPaperTypes(pageNumber, this.term ? this.term.trim() : '', this.materialFilterValues.reduce((total, current) => total + current, 0)).pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.setResults(data, isLoadMore);
      }, () => {
      });
  }

  /**
   * Set paginated data to angular property
   *
   * @param data Data retrieved from server
   * @param isLoadMore Flag to denote if invoked to load more pages.
   */
  setResults(data: PagedResult<PaperType>, isLoadMore: boolean = false) {
    this.paperTypesLoading = false;
    // Check for valid server response.
    if (data === null || data.results === null || data.results.length === 0) {
      this.foundPaperTypes = false;
      this.paperTypes = [];
      return;
    }

    this.foundPaperTypes = true;
    this.currentPageNumber = data.pageNumber;
    this.searchResults = data;

    // Reset angular property if not load more pages
    if (!isLoadMore) {
      this.paperTypes = [];
    }

    data.results.forEach(r => this.paperTypes.push(r));
    // Check if more pages should be loaded in future
    this.hasLoadedAllPaperTypes = data.pageNumber === data.totalPages;
  }

  /**
   * Track modifications done in UI
   *
   * @param paperType Paper type that is modified.
   */
  materialTypeChanged(paperType: PaperType) {
    let updatedPaperType = true;
    this.updatedPaperTypes.map((x) => {
      // Check if paper type has already been modified.
      if (x.id === paperType.id) {
        x.materialType = paperType.materialType;
        updatedPaperType = false;
      }
    });

    // If paper type has already been modified, do not add that again.
    if (updatedPaperType) {
      this.updatedPaperTypes.push(paperType);
    }
  }

  setSearchedTerm(searchTerm: string) {
    this.term = searchTerm;
  }

  setMaterialTypesSelection(materialTypeSelection: MaterialTypes[]) {
    this.materialFilterValues = materialTypeSelection;
  }
}
