<div class="paper-type-search">
  <div class="paper-type-search__inner">
    <span *ngIf="(!searchTerm || searchTerm == '') && !isLoading" class="material-icons search-icon"
      title="Not quite 100 zeroes">&#xE8B6;</span>
    <span *ngIf="(searchTerm && searchTerm !== '')" (click)="clear(); "
      class="material-icons clear-icon" title="Clear">&#xE14C;</span>
    <input [(ngModel)]="searchTerm" (keydown.enter)="keyUp.next($event)" (keyup)="keyUp.next($event)" type="text"
      id="term" placeholder="Search for Paper Type..." />
    <span class="loading" [class.visible]="isLoading"></span>
    <span class="result-status visible" *ngIf="(searchTerm && searchTerm !== '')">{{results | number}} Results</span>
    <span class="filter-status visible" *ngIf="!statusAllOn || (searchTerm && searchTerm !== '')">filtered</span>
    <span class="filter-icon material-icons" (click)="toggleFilter()">&#xE429;</span>
  </div>

  <div class="filter-options" *ngIf="showFilter">
    <div class="material-type">
      <span class="title">
        Material Type:
      </span>
      <div class="status-toggle" (click)="toggleStatus();">
        <i *ngIf="!statusAllOn" class="material-icons off">&#xE9F5;</i>
        <i *ngIf="statusAllOn" class="material-icons on">&#xE9F6;</i>
      </div>
      <div class="material-type-default"
        (click)="materialTypeSearchMap['default'] = !materialTypeSearchMap['default']; toggleMaterialTypeSelection('default');">
        <div class="toggle">
          <span class="toggle-section left"></span>
          <span class="toggle-section right">
            <span title="Default material type">
              <span [ngClass]="{'collapsed-status': !materialTypeSearchMap['default']}"
                class="material-icons status-icon status-icon--queued"></span>
            </span>
          </span>
        </div>
        <label class="label">Default</label>
      </div>
      <div class="material-type-kraft"
        (click)="materialTypeSearchMap['kraft'] = !materialTypeSearchMap['kraft']; toggleMaterialTypeSelection('kraft');">
        <div class="toggle">
          <span class="toggle-section left"></span>
          <span class="toggle-section right">
            <span title="Kraft material type">
              <span [ngClass]="{'collapsed-status': !materialTypeSearchMap['kraft']}"
                class="material-icons status-icon status-icon--processing"></span>
            </span>
          </span>
        </div>
        <label class="label">Kraft</label>
      </div>
    </div>

    <div class="button-group">
      <button class="button" (click)="closeFilter()">
        <i class="material-icons">&#xE316;</i>
        <span>Close</span>
      </button>
    </div>
  </div>
</div>
