import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StatusResults } from './status-results';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  constructor(private readonly _http: HttpClient) { }

  getTartanApiStatus(): Observable<StatusResults> {
    const headerOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.tartanApiKey
      })
    };

    return this._http.get<StatusResults>(`${environment.tartanApiUrl}health/tartan`, headerOptions);
  }

  getWarpApiStatus(): Observable<StatusResults> {
    const headerOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.warpApiKey
      })
    };

    return this._http.get<StatusResults>(`${environment.warpApiUrl}health`, headerOptions);
  }

  getOwlApiStatus(): Observable<StatusResults> {
    const headerOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.tartanApiKey
      })
    };
    return this._http.get<StatusResults>(`${environment.tartanApiUrl}health/owl`, headerOptions);
  }

  getMagpieApiStatus(): Observable<StatusResults> {
    const headerOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.tartanApiKey
      })
    };
    return this._http.get<StatusResults>(`${environment.tartanApiUrl}health/magpie`, headerOptions);
  }

  getHoopoeApiStatus(): Observable<StatusResults> {
    const headerOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.tartanApiKey
      })
    };
    return this._http.get<StatusResults>(`${environment.tartanApiUrl}health/hoopoe`, headerOptions);
  }
}

