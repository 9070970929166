import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { PdfService } from '../pdf/pdf.service';
import { AlertsService } from '../alerts/alerts.service';
import { AlertType } from '../alerts/alert-type.enum';
import { Preview } from './preview.interface';
import { isObject } from 'rxjs/internal/util/isObject';
import { getHeightAndWidthOfImage, getAdjustedHeight } from '../image/image.util';
import { SecureThumbnailPayload } from '../secure.pipe';
import { Router } from '@angular/router';
import { MlpaJobView } from '../../pages/home/view-mlpa-jobs/mlpa-job-view';

@Component({
  selector: 'pkg-lane-preview',
  templateUrl: './lane-preview.component.html',
  styleUrls: ['./lane-preview.component.scss']
})
export class LanePreviewComponent implements OnInit, OnChanges {
  @Input() source: Preview;
  @Input() weblayout = false;
  @Input() print: boolean;
  @Input() bounds: boolean;
  @Input() backgroundImage: boolean;
  @Input() animateBackgroundImage: boolean;
  @Input() mlpaJobView: MlpaJobView = null;
  @ViewChild('previewImage') loadingElement: ElementRef;
  printing: boolean;
  printable: boolean;
  showBoundingBoxes: boolean;
  showPreview: boolean;
  previewHeight: string;
  showBoundingBoxesQuery: string;
  shouldFlash: boolean;
  initialPreviewHeight = '80px';
  previewThumbnail: SecureThumbnailPayload;
  isMlpaPage = false;
  constructor(private readonly _http: HttpClient, private _router: Router, private readonly _pdfService: PdfService, private readonly _alertService: AlertsService) {
    this.showBoundingBoxes = false;
  }

  ngOnInit(): void {
    this.isMlpaPage = this._router.url === '/mlpa' || this._router.url.startsWith('/mlpa');
    this.previewHeight = this.weblayout ? '700px' : this.initialPreviewHeight;
    if (!!this.source && !!this.source.preview) {
      const hasUrlParams = this.source.preview.indexOf('?') !== -1;
      this.showBoundingBoxesQuery = hasUrlParams
        ? '&showBoundingBoxes=true'
        : '?showBoundingBoxes=true';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes?.source?.currentValue) {
      const current = changes.source.currentValue ? changes.source.currentValue.id : null;
      const previous = changes.source.previousValue ? changes.source.previousValue.id : null;
      if (current !== previous) {
        this.previewHeight = this.initialPreviewHeight;
        this.showPreview = false;
      }
    }
  }

  printPreview($event: Event): void {
    if (!this.isMlpaPage) {
      this._router.navigate([`print/${this.mlpaJobView.id}`]);
    } else {
      const heightInPixels = parseInt(this.previewThumbnail.response.headers.get('x-content-height-in-pixels'), 10);
      const widthInPixels = parseInt(this.previewThumbnail.response.headers.get('x-content-width-in-pixels'), 10);
      $event.stopPropagation();
      this.printing = true;
      const source = this.source.preview + (this.showBoundingBoxes ? this.showBoundingBoxesQuery : '');
      this._http
        .get(source, { responseType: 'blob' })
        .pipe(finalize(() => (this.printing = false)))
        .subscribe(
          async data => {
            try {
              const pdf = await this._pdfService.getJspdfFromImage(
                data,
                widthInPixels,
                heightInPixels
              );
              this._pdfService.printImage(pdf);
            } catch (e) {
              this._alertService.add(
                `Failed to print.`,
                `Failed to print: ${this.source.designNumber} ${this.source.designDescription}`,
                AlertType.Error
              );
            } finally {
              this.printing = false;
            }
          },
          error => {
            this._alertService.add(
              `Failed to print.`,
              `Failed to print: ${this.source.designNumber} ${this.source.designDescription}`,
              AlertType.Error
            );
          }
        );
    }
  }

  isObject(arg: any): boolean {
    return isObject(arg);
  }

  evalFlash(): void {

    // Should flash
    if (this.source.shouldFlash) {

      // This is the first load
      if ((!this.source.previousPreview) || (this.source.preview !== this.source.previousPreview)) {
        this.shouldFlash = false;
        setTimeout(() => {
          this.shouldFlash = true;
        }, 100);
      } else {
        this.shouldFlash = false;
      }

      // Should not flash
    } else {
      this.shouldFlash = false;
    }
  }

  async togglePreview(thumbnailPayload: SecureThumbnailPayload): Promise<void> {
    if (this.weblayout) {
      return;
    }

    let originalHeight = parseInt(thumbnailPayload.response.headers.get('x-content-height-in-pixels'), 10);
    let originalWidth = parseInt(thumbnailPayload.response.headers.get('x-content-width-in-pixels'), 10);
    if (originalHeight <= 0 || originalWidth <= 0) {
      // Fallback
      this._alertService.add('Image Problem','unable to get original image dimensions falling back to client side', AlertType.Info);
      const dimensions = await getHeightAndWidthOfImage(thumbnailPayload.preview as string);
      originalHeight = dimensions.height;
      originalWidth = dimensions.width;
    }

    const width = document.querySelector('.background-media').clientWidth;
    const height = getAdjustedHeight(width, originalWidth, originalHeight);
    this.showPreview = !this.showPreview;
    if (this.showPreview) {
      this.previewHeight = `${height}px`;
    } else {
      this.previewHeight = this.initialPreviewHeight;
    }
  }

  setThumbnail(thumbnail: SecureThumbnailPayload): boolean {
    this.previewThumbnail = thumbnail;
    return true;
  }
}
