import { AssetAttributes } from 'src/app/shared/asset-attributes';

export const getAssetColorCodes = () => {
  const assetAttributes = new Array<AssetAttributes>();

  const assetAttribute1 = new AssetAttributes();
  assetAttribute1.lightThemeColor = '#ee7623';
  assetAttribute1.darkThemeColor = '#f39959';

  const assetAttribute2 = new AssetAttributes();
  assetAttribute2.lightThemeColor = '#ffd100';
  assetAttribute2.darkThemeColor = '#ffde4d';

  const assetAttribute3 = new AssetAttributes();
  assetAttribute3.lightThemeColor = '#43329a';
  assetAttribute3.darkThemeColor = '#8778d3';

  const assetAttribute4 = new AssetAttributes();
  assetAttribute4.lightThemeColor = '#cc0000';
  assetAttribute4.darkThemeColor = '#ff4d4d';

  const assetAttribute5 = new AssetAttributes();
  assetAttribute5.lightThemeColor = '#008996';
  assetAttribute5.darkThemeColor = '#00d2e6';

  const assetAttribute6 = new AssetAttributes();
  assetAttribute6.lightThemeColor = '#e577cb';
  assetAttribute6.darkThemeColor = '#efa9de';

  const assetAttribute7 = new AssetAttributes();
  assetAttribute7.lightThemeColor = '#80bc00';
  assetAttribute7.darkThemeColor = '#beff33';

  const assetAttribute8 = new AssetAttributes();
  assetAttribute8.lightThemeColor = '#0067a6';
  assetAttribute8.darkThemeColor = '#33b1ff';

  const assetAttribute9 = new AssetAttributes();
  assetAttribute9.lightThemeColor = '#ff0066';
  assetAttribute9.darkThemeColor = '#ff4d94';

  const assetAttribute10 = new AssetAttributes();
  assetAttribute10.lightThemeColor = '#85807a';
  assetAttribute10.darkThemeColor = '#b6b3af';

  assetAttributes.push(assetAttribute1);
  assetAttributes.push(assetAttribute2);
  assetAttributes.push(assetAttribute3);
  assetAttributes.push(assetAttribute4);
  assetAttributes.push(assetAttribute5);
  assetAttributes.push(assetAttribute6);
  assetAttributes.push(assetAttribute7);
  assetAttributes.push(assetAttribute8);
  assetAttributes.push(assetAttribute9);
  assetAttributes.push(assetAttribute10);

  return assetAttributes;
};
