import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pkg-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss']
})
export class RangeSliderComponent implements OnInit {
  @Output() valueEmitted = new EventEmitter<number>();
  @Input() value = 100;
  @Input() max = 100;
  @Input() min = 0;
  @Input() step = 1;
  @Input() multiplier = 1;
  percentage = 100;
  constructor() { }

  ngOnInit(): void {
    this.multiplier = this.multiplier > 0 ? this.multiplier : 1;
    this.percentage = this.value * this.multiplier;
  }

  valueChanged(value: number) {
    this.value = value;
    this.percentage = value * this.multiplier;
    this.valueEmitted.emit(this.value);
  }
}
