export enum PdfTokenTypes {
  None = 0,
  // [L]
  LaneLetter = 1,
  // [00000000-0000-0000-0000-000000000000]
  LaneId = 2,
  // [XXXXXXXXXX-XXXXXXXXXX]
  DesignNumberOrderNumber = 4,
  // [XXXXXXXXXX-XXXXXXXXXX-XXXX]
  DesignNumberOrderNumberUniqueId = 8,
  // [YYYYYYYY]
  CustomerPurchaseOrderNumber = 16,
  // tslint:disable-next-line: no-bitwise
  All = LaneLetter |
        LaneId |
        DesignNumberOrderNumber |
        DesignNumberOrderNumberUniqueId |
        CustomerPurchaseOrderNumber
}
