export class MultiSelectDropDownSetting {

  // To set the dropdown for single item selection only.
  singleSelection: boolean;

  // Text to be show in the dropdown, when no items are selected.
  text: string;

  // Text to display as the label of select all option
  selectAllText = 'Select All';

  // Text to display as the label of unSelect option
  unSelectAllText = 'UnSelect All';

  // Enable filter option for the list.
  enableSearchFilter: boolean;

  // The property name which should be rendered as label in the dropdown
  labelKey: string;

  // The property by which the object is identified. Default is 'id'.
  primaryKey: string;

  // Label text when no data is available in the list
  noDataLabel = 'No Data Available';

  // Custom text for the search placeholder text. Default value would be 'Search'
  searchPlaceholderText: string;

  autoPosition = false;

  // Press excape key to close the dropdown
  escapeToClose = true;

  // Custom classes to the dropdown component. Classes are added to the dropdown selector tag. To add multiple classes, the value should be space separated class names.
  classes: string;

  badgeShowLimit: number ;
}
