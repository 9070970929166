import { NgModule, ModuleWithProviders, Output } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';

import { EnumPipe } from './enum.pipe';
import { InchesPipe } from './inches.pipe';
import { FeetPipe } from './feet.pipe';
import { AlertsComponent } from './alerts/alerts.component';
import { AlertsService } from './alerts/alerts.service';
import { AnalyticsService } from './analytics.service';
import { SecurePipe } from './secure.pipe';
import { JobPreviewComponent } from './job-preview/job-preview.component';
import { PdfService } from './pdf/pdf.service';
import { LanePreviewComponent } from './lane-preview/lane-preview.component';
import { SmoothHeightComponent } from './smooth-height/smooth-height.component';
import { AfterIfDirective } from './after-if.directive';
import { CardComponent } from './card/card.component';
import { CardHeaderComponent } from './card/card-header/card-header.component';
import { CardBodyComponent } from './card/card-body/card-body.component';
import { CardFooterComponent } from './card/card-footer/card-footer.component';
import { MlpaJobsSearchComponent } from './mlpa-jobs-search/mlpa-jobs-search.component';
import { MlpaJobsSearchService } from './mlpa-jobs-search/mlpa-jobs-search.service';
import { MlpaJobsStatsComponent } from './mlpa-jobs-stats/mlpa-jobs-stats.component';
import { CompatibilityWarningComponent } from './compatibility-warning/compatibility-warning.component';
import { ChartsModule } from 'ng2-charts';
import { SvgIconsComponent } from './svg-icons/svg-icons.component';
import { MlpaJobRulerComponent } from './mlpa-job-ruler/mlpa-job-ruler.component';
import { StatusService } from './status-page/status.service';
import { StatusPageComponent } from './status-page/status-page.component';
import { HasRoleDirective } from './has-role/has-role.directive';
import { HolidayComponent } from './holiday/holiday.component';
import { DateReplacePipe } from './date-replace.pipe';
import { MlpaBaseBodyComponent } from './mlpa-card/mlpa-body/mlpa-base-body/mlpa-base-body.component';
import { MlpaStatusIconsComponent } from './mlpa-card/mlpa-header/mlpa-status-icons/mlpa-status-icons.component';
import { ToggleActionComponent } from './mlpa-card/mlpa-header/toggle-action/toggle-action.component';
import { MlpaJobModalComponent } from './modals/mlpa-job-modal/mlpa-job-modal.component';
import { UnitOfTimePipe } from './time/unit-of-time.pipe';
import { MenuOptionComponent } from './menu-option/menu-option.component';
import { FilterPipe } from './filter/filter.pipe';
import { MlpaStatusNumberedIconComponent } from './mlpa-card/mlpa-header/mlpa-status-numbered-icon/mlpa-status-numbered-icon.component';
import { ModalComponent } from './modals/modal/modal.component';
import { PkgThemeService } from './theme/pkg-theme.service';
import { RollPreviewComponent } from './lane-preview/roll-preview/roll-preview.component';
import { RangeSliderComponent } from './range-slider/range-slider.component';
import { ProgressCircleComponent } from './progress-circle/progress-circle.component';
import { SearchComponent } from './search/search.component';
import { GetMainJobPipe } from './get-main-job.pipe';
import { InfiniteDirective } from '../pages/mlpa/shared/infinite.directive';
import { JobSelectorComponent } from './job-selector/job-selector.component';
import { LoaderComponent } from './loader/loader.component';
import { MultiSelectDropdownComponent } from './multi-select-dropdown/multi-select-dropdown.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { SearchAutoCompleteComponent } from '../search-auto-complete/search-auto-complete.component';
import { EnumAsStringPipe } from './enum-as-string.pipe';
import { TwoDigitDecimalNumberDirective } from './two-decimal-number.directive';
import { CardProgressBarComponent } from './card/card-progress-bar/card-progress-bar.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ChartsModule,
    AngularMultiSelectModule
  ],
  declarations: [
    EnumPipe,
    EnumAsStringPipe,
    InchesPipe,
    FeetPipe,
    AlertsComponent,
    SecurePipe,
    JobPreviewComponent,
    LanePreviewComponent,
    SmoothHeightComponent,
    AfterIfDirective,
    CardComponent,
    CardHeaderComponent,
    CardBodyComponent,
    CardFooterComponent,
    MlpaJobsSearchComponent,
    CompatibilityWarningComponent,
    MlpaJobsStatsComponent,
    SvgIconsComponent,
    MlpaJobRulerComponent,
    StatusPageComponent,
    HasRoleDirective,
    HolidayComponent,
    DateReplacePipe,
    MlpaBaseBodyComponent,
    MlpaStatusIconsComponent,
    ToggleActionComponent,
    MlpaJobModalComponent,
    UnitOfTimePipe,
    MenuOptionComponent,
    FilterPipe,
    MlpaStatusNumberedIconComponent,
    RollPreviewComponent,
    RangeSliderComponent,
    ProgressCircleComponent,
    ModalComponent,
    GetMainJobPipe,
    InfiniteDirective,
    SearchComponent,
    JobSelectorComponent,
    LoaderComponent,
    MultiSelectDropdownComponent,
    SearchAutoCompleteComponent,
    CardProgressBarComponent,
    TwoDigitDecimalNumberDirective
  ],
  exports: [
    AfterIfDirective,
    EnumPipe,
    EnumAsStringPipe,
    InchesPipe,
    FeetPipe,
    AlertsComponent,
    SecurePipe,
    DateReplacePipe,
    GetMainJobPipe,
    JobPreviewComponent,
    LanePreviewComponent,
    SmoothHeightComponent,
    CardComponent,
    CardHeaderComponent,
    CardBodyComponent,
    CardFooterComponent,
    MlpaJobsSearchComponent,
    CompatibilityWarningComponent,
    MlpaJobsStatsComponent,
    SvgIconsComponent,
    MlpaJobRulerComponent,
    HasRoleDirective,
    HolidayComponent,
    ModalComponent,
    MlpaBaseBodyComponent,
    MlpaStatusIconsComponent,
    ToggleActionComponent,
    MlpaJobModalComponent,
    UnitOfTimePipe,
    MenuOptionComponent,
    FilterPipe,
    MlpaStatusNumberedIconComponent,
    InfiniteDirective,
    RollPreviewComponent,
    RangeSliderComponent,
    ProgressCircleComponent,
    SearchComponent,
    JobSelectorComponent,
    LoaderComponent,
    MultiSelectDropdownComponent,
    SearchAutoCompleteComponent,
    CardProgressBarComponent,
    TwoDigitDecimalNumberDirective
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        Title,
        DecimalPipe,
        AlertsService,
        AnalyticsService,
        SecurePipe,
        PdfService,
        MlpaJobsSearchService,
        StatusService,
        DateReplacePipe,
        PkgThemeService,
        TwoDigitDecimalNumberDirective
      ]
    };
  }
}
