<div class="sticky">
  <div class="page__header header--admin">
    <div class="page__header-actions">
      <h2 class="page__header-title">Print Processor</h2>
    </div>
  </div>
  <div class="page__content">
    <ul class="print-processor-list">
      <ng-container *ngIf="printProcessors.length > 0 && !printProcessorLoading">
        <li *ngFor="let labelPrintProcessor of printProcessors">
          <div class="card print-processor">
            <div class="print-processor__details"
              [class.print-processor__details--disabled]="!labelPrintProcessor.isEnabled">
              <div class="print-processor__icon">
                <i *ngIf="!labelPrintProcessor.isEnabled" class="material-icons matrial-icons--disabled">&#xE612;</i>
                <i class="material-icons">&#xEF6E;</i>
              </div>
              <div class="print-processor__name">{{labelPrintProcessor.name}}</div>
              <section class="status-icons__container">
                <span title="mode" class="print-processor-mode status-chip"
                  [ngClass]="testModeClass(labelPrintProcessor)"
                  [class.print-processor__test-mode--disabled]="!labelPrintProcessor.isEnabled">
                  <ng-container *ngIf="labelPrintProcessor.isInTestMode">TEST</ng-container>
                  <ng-container *ngIf="!labelPrintProcessor.isInTestMode">PRODUCTION</ng-container>
                </span>
                <span *ngIf="labelPrintProcessor.healthStatus?.isHealthy; else unhealthyStatus" title="Healthy"  class="material-icons status-chip status-chip--healthy">
                </span>
                <ng-template #unhealthyStatus>
                  <span [title]="'Unhealthy: '+ labelPrintProcessor.healthStatus?.dependencies[0].message" class="material-icons status-chip status-chip--unhealthy">
                  </span>
                </ng-template>
              </section>
            </div>
            <div class="print-processor__controls">
              <button class="button button--primary button--small"
                (click)="editPrintProcessor(labelPrintProcessor)" type="button">
                <i class="material-icons">&#xE3C9;</i>
                <span>Edit</span>
              </button>
            </div>
          </div>
        </li>
      </ng-container>
      <div *ngIf="printProcessorLoading" class="loading"></div>
      <li title="Add Print Processor">
        <div class="card print-processor placeholder" (click)="createPrintProcessor()">
          <div class="print-processor__details">
            <div class="print-processor__icon">
              <i class="material-icons">&#xE148;</i>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
