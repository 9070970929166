import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { PrintProcessor } from '@shared/print-processor.model';
import { environment } from '../../../../environments/environment';
import { PagedResult } from '@shared/paged-result';
import { StatusResults } from '@shared/status-page/status-results';

@Injectable({
  providedIn: 'root'
})
export class PrintProcessorService {

  constructor(private readonly _http: HttpClient) { }

  getAll(): Observable<PagedResult<PrintProcessor>> {
    return this._http.get<PagedResult<PrintProcessor>>(`${environment.tartanApiUrl}print-processors`);
  }

  getHealth(printProcessorId: string): Observable<StatusResults> {
    return this._http.get<StatusResults>(`${environment.tartanApiUrl}print-processors/${printProcessorId}/health`);
  }

  get(printProcessorId: string): Observable<PrintProcessor> {
    return this._http.get<PrintProcessor>(`${environment.tartanApiUrl}print-processors/${printProcessorId}`);
  }

  editPrintProcessor(printProcessor: PrintProcessor): Observable<PrintProcessor> {
    const payload = printProcessor.buildForSave();
    return this._http.put<PrintProcessor>(`${environment.tartanApiUrl}print-processors/${printProcessor.id}`, payload);
  }

  addPrintProcessor(printProcessor: PrintProcessor): Observable<PrintProcessor> {
    const payload = printProcessor.buildForSave();
    return this._http.post<PrintProcessor>(`${environment.tartanApiUrl}print-processors`, payload);
  }

  searchPrintProcessor(printProcessorIds: string[], pageNumber: number, pageSize: number): Observable<PagedResult<PrintProcessor>> {
    const params = new HttpParams()
      .set('printProcessorIds', `${printProcessorIds}`)
      .set('pageSize', `${pageSize}`)
      .set('pageNumber', `${pageNumber}`);

    return this._http.get<PagedResult<PrintProcessor>>(`${environment.tartanApiUrl}print-processors/search`, { params });
  }

  deletePrintProcessor(id: string): Observable<unknown> {
    return this._http.delete(`${environment.tartanApiUrl}print-processors/${id}`);
  }
}
