<div class="menu-option-container" [ngStyle]="menuOptionContainerStyle">
  <div class="menu-option--header">
    <div class="menu-option-search">
      <span *ngIf="(!searchTerm || searchTerm == '')" class="material-icons search-icon" title="Not quite 100 zeroes">&#xE8B6;</span>
      <span *ngIf="(searchTerm && searchTerm !== '')" class="material-icons clear-icon" (click)="clear();" title="Clear">&#xE14C;</span>
      <input [(ngModel)]="searchTerm" type="text" id="term" [placeholder]="placeholder" />
      <span *ngIf="isFiltered" class="filter-status" [class.visible]="isFiltered">filtered</span>
    </div>
  </div>
  <div class="menu-option-title">
    <span class="title">{{title}} </span>
    <div class="filter-toggle" (click)="toggleSelected(); ">
      <i *ngIf="!showAllAssets" class="material-icons off">&#xE9F5;</i>
      <i *ngIf="showAllAssets" class="material-icons on">&#xE9F6;</i>
    </div>
  </div>
  <div class="menu-items-container">
    <div (change)="onChange()" *ngFor="let item of menuItems | filter: searchTerm:'name'; let i = index" class="menu-items">
      <label class="checkbox">
        <span>{{item?.name}}</span>
        <input type="checkbox" [(ngModel)]="menuItems[i].selected">
        <span class="checkmark"></span>
      </label>
    </div>
    <div *ngIf="(menuItems | filter: searchTerm:'name').length === 0">Unable to find result...</div>
  </div>
</div>
