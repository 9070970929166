<div class="wrapper-dropdown" tabindex="0" (blur)="onTouch(); dropDownActive=false;">
  <div class="wrapper-select" (click)="toggleDropDown()">
    <div *ngIf="selectedAssetAttribute.lightThemeColor && selectedAssetAttribute.darkThemeColor; else defaultSelect"
      class="dropdown">
      <div class="lightBackground">
        <span [style.background]="selectedAssetAttribute.lightThemeColor" class="color-style">
        </span>
        <span class="text-style">
          Light
        </span>
      </div>
      <div class="darkBackground">
        <span [style.background]="selectedAssetAttribute.darkThemeColor" class="color-style">
        </span>
        <span style="color:'#acacac;'" class="text-style">
          Dark
        </span>
      </div>
    </div>
    <ng-template #defaultSelect>
      <div class="select-label">
        <span>None</span>
      </div>
    </ng-template>
    <div class="dropdown-arrow">
      <span>
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 612 612" xml:space="preserve" style="enable-background: new 0 0 612 612;" class="ng-star-inserted"><g><g id="_x31_0_34_"><g><path d="M604.501,134.782c-9.999-10.05-26.222-10.05-36.221,0L306.014,422.558L43.721,134.782
          c-9.999-10.05-26.223-10.05-36.222,0s-9.999,26.35,0,36.399l279.103,306.241c5.331,5.357,12.422,7.652,19.386,7.296
          c6.988,0.356,14.055-1.939,19.386-7.296l279.128-306.268C614.5,161.106,614.5,144.832,604.501,134.782z"></path></g></g></g></svg>
      </span>
    </div>
  </div>
  <div *ngIf="dropDownActive" class="list-area">
    <ul>
      <li (click)="onNoneSelect()">
        <span class="default-option">None</span>
      </li>
      <li *ngFor="let assetAttribute of assetAttributes" [ngStyle]="getStyles(assetAttribute)"
        (click)="onAssetAttributeSelect(assetAttribute)">
        <div class="lightBackground">
          <span [style.background]="assetAttribute.lightThemeColor" class="color-style">
          </span>
          <span class="text-style">
            Light
          </span>
        </div>
        <div class="darkBackground">
          <span [style.background]="assetAttribute.darkThemeColor" class="color-style">
          </span>
          <span style="color:'#acacac;'" class="text-style">
            Dark
          </span>
        </div>
      </li>
    </ul>
  </div>
</div>