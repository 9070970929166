<div class="container-preview">
  <ng-container *ngIf="source?.preview != null; else previewNotFound">
    <div *ngIf="(source?.preview + (showBoundingBoxes ? showBoundingBoxesQuery : '') | secure | async) as thumbnail; else loading">
      <div *ngIf="isObject(thumbnail) && thumbnail.response.status === 200 && setThumbnail(thumbnail)">
        <ng-container *ngIf="!backgroundImage">
          <img [style.margin-bottom]="'0px'" class="lane-design-thumbnail" (pkgAfterIf)="printable = true" [src]="thumbnail.preview" alt="thumbnail" />
        </ng-container>
        <ng-container *ngIf="backgroundImage">
          <ng-container *ngIf="animateBackgroundImage">
            <div (click)="togglePreview(thumbnail)" class="background-media preview" [style.height]="previewHeight" [style.background-image]="'url(' + thumbnail.preview + ')'" (pkgAfterIf)="printable = true">
              <div class="ripple" [class.flash]="shouldFlash"></div>
            </div>
          </ng-container>
          <ng-container *ngIf="!animateBackgroundImage">
            <div class="background-media preview" [style.height]="previewHeight" [style.background-image]="'url(' + thumbnail.preview + ')'" (pkgAfterIf)="printable = true"></div>
          </ng-container>
        </ng-container>
      </div>
      <div *ngIf="isObject(thumbnail) && thumbnail.response.status === 404" class="preview-not-found">
        <i class="material-icons">&#xE3F4;</i>
        <div class="ripple" [class.flash]="shouldFlash"></div>
      </div>
    </div>
  </ng-container>
  <ng-template #previewNotFound>
    <div class="preview-not-found">
      <i class="material-icons">&#xE3F4;</i>
      <div class="ripple" [class.flash]="shouldFlash"></div>
    </div>
  </ng-template>
  <ng-container *ngIf="print || bounds">
    <div class="button-group background-image">
      <div *ngIf="bounds" class="bounds bounds-small">
        <div class="toggle" title="Yedinorog">
          <label class="switch switch-small">
            <input type="checkbox" [disabled]="!printable" [(ngModel)]="showBoundingBoxes">
            <span class="slider slider-small"></span>
          </label>
        </div>
      </div>
      <div *ngIf="print" class="print">
        <button [disabled]="printing || !printable" class="button button--red button--small button-fab" title="Print" (click)="printPreview($event)" type="button">
          <i *ngIf="printing" class="material-icons loading icon-loading "></i>
          <i *ngIf="!printing" class="material-icons print-icon">&#xE8AD;</i>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-template #loading>
    <div #previewImage (pkgAfterIf)="evalFlash()">
      <div class="loading thumbnail-loading"></div>
    </div>
  </ng-template>
</div>
