<pkg-card>
  <pkg-card-header>
    <div class="pkg-card-title">
      <span class="title" [title]="user?.displayName">{{user.displayName}}</span>
    </div>
  </pkg-card-header>
  <pkg-card-body>
    <div class="user-details">
      <div class="image-container">
        <div class="content">
          <img class="user__image" [src]="user.photoUrl" alt="User Photo">
        </div>
      </div>
      <div class="user-details-container">
        <div class="user-details-line">
          <div class="content">
            <div class="title">Email Address</div>
            <div class="info" [title]="user?.emailAddress">{{user?.emailAddress? user.emailAddress:'N/A'}}</div>
          </div>
          <div class="content">
            <div class="title">NT Login</div>
            <div class="info">{{user.ntLogin}}</div>
          </div>
          <div class="content">
            <div class="title">Phone Number</div>
            <div class="info">{{user.phoneNumber?user.phoneNumber:'N/A'}}</div>
          </div>
        </div>
        <div class="edit-details-container">
          <div class="content">
            <div class="title">Role</div>
            <select [(ngModel)]="userRole" (change)="onRoleSelect($event)" id="role">
              <option *ngFor="let role of roles | enum" [ngValue]="role.id">{{role.name}}</option>
            </select>
          </div>
          <div class="content">
            <div class="title">Assets</div>
            <pkg-multi-select-dropdown [dropdownList]="assets" [selectedItems]="userAssets"
              [dropdownSettings]="userAssetsDropdownSettings" (itemSelectEmitter)="onAssetSelect($event)"
              (itemDeSelectEmitter)="onAssetDeSelect($event)" (selectAllEmitter)="onAssetSelectAll($event)"
              (deselectAllEmitter)="onAssetDeSelectAll($event)">
            </pkg-multi-select-dropdown>
          </div>
        </div>
      </div>
    </div>
  </pkg-card-body>
</pkg-card>
