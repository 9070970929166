import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  // list: source array to search
  // searchterm: the value you are searching for
  // optional identifier to specify what key you are looking for in your object.
  // make sure to flatten the object before using this.
  transform(list: any[], searchterm: string, identifier = null): any {
    if (list == null || list.length === 0 || searchterm == null || searchterm === '') {
      return list;
    }

    searchterm = searchterm.toLowerCase();

    // convert the list to string
    if (identifier == null) {
      list = Array.from(list.toString().split(','));
    } else {
      list.forEach((item, index) => {
        list[index][identifier] = item[identifier].toString();
      });
    }

    if (identifier == null) {
      return list.filter(item => item.toLowerCase().includes(searchterm));
    }

    return list.filter(item => item[identifier].toLowerCase().includes(searchterm));
  }
}
