import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'inches'
})
export class InchesPipe implements PipeTransform {
  constructor(private _decimalPipe: DecimalPipe) { }

  transform(value: any, args?: any): any {
    const number = this._decimalPipe.transform(value, '1.2-3');
    return `${number}''`;
  }
}
