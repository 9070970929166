import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ChangeDetectorRef, ApplicationRef, OnDestroy } from '@angular/core';

import { AuthService } from '../../../auth/auth.service';
import { MlpaJobView } from './mlpa-job-view';
import { Role } from '../../admin/shared/role';
import { Router } from '@angular/router';

import { CloneMlpaJobsComponent } from '../clone-mlpa-jobs/clone-mlpa-jobs.component';
import { MlpaLaneType } from '../../../mlpa/mlpa-lane/mlpa-lane-type.enum';
import { MlpaLaneView } from './mlpa-lane-view';
import { environment } from '../../../../environments/environment';
import { Preview } from '@shared/lane-preview/preview.interface';
import { MlpaService } from '../../mlpa/shared/mlpa.service';
import { AlertsService } from '@shared/alerts/alerts.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertType } from '@shared/alerts/alert-type.enum';
import { MlpaJob } from '../../../mlpa/mlpa-job/mlpa-job';
import { MlpaStateService } from '../../mlpa/shared/mlpa-state.service';
import { deepCopyProperties } from '@shared/pkg-helper';
import { AnalyticsService } from '@shared/analytics.service';
import { MlpaJobService } from '../../../mlpa/mlpa-job/mlpa-job.service';
// import { DragulaService } from 'ng2-dragula';
import { takeUntil } from 'rxjs/operators';
// import { ScheduleItem } from '../../schedule/schedule';
import { Subject } from 'rxjs';
// import { ScheduleService } from '../../schedule/schedule.service';
import { PkgThemeService } from 'src/app/shared/theme/pkg-theme.service';
import { ToggleEvent } from 'src/app/shared/mlpa-card/mlpa-header/toggle-action/toggle-action.component';

@Component({
  selector: 'pkg-view-mlpa-jobs',
  templateUrl: './view-mlpa-jobs.component.html',
  styleUrls: ['./view-mlpa-jobs.component.scss'],
})
export class ViewMlpaJobsComponent implements OnInit, OnDestroy {
  @ViewChild(CloneMlpaJobsComponent, { static: true }) cloneMlpaJobComponent: CloneMlpaJobsComponent;
  @Input() mlpaJobs: MlpaJobView[];
  @Input() selectedJob: MlpaJobView;
  @Input() draggable = false;
  @Input() redirectUrl: string;
  @Output() selectedJobEmitter = new EventEmitter<MlpaJobView>();
  @Output() deleteEmitter = new EventEmitter();
  @Output() editEmitter = new EventEmitter<boolean>();
  mlpaJob: MlpaJob;
  showClone: boolean;
  isCloning: boolean;
  showThumbnail: boolean;
  showConfirm = false;
  thumbnail: string;
  printing: boolean;
  isSubmitting: boolean;
  isDeleting: boolean;
  showActionOptions: boolean;
  actionDimension: string;
  showDelete = false;
  showCancelConfirm: boolean;
  editMode = false;
  previousSelectedJob: MlpaJobView;
  userRole: Role;
  showSaving: any;
  role = Role;
  // droppedItem: ScheduleItem;
  thumbnailType = MlpaLaneType;


  get showSubmit(): boolean {
    if (this.authService.user.role === Role.Operator
      || this.authService.user.role === Role.Support) {
      return true;
    }
    return false;
  }
  get showEdit(): boolean {
    if (this.authService.user.role === Role.Scheduler
      || this.authService.user.role === Role.Support
      || this.authService.user.role === Role.Structure
      || this.authService.user.role === Role.Operator) {
      return true;
    }
    return false;
  }

  get canDelete(): boolean {
    if (this.authService.user.role === Role.Scheduler
      || this.authService.user.role === Role.Support
      || this.authService.user.role === Role.Structure) {
      return true;
    }
    return false;
  }

  get lanePreview(): Preview {
    return this._lanePreview;
  }

  set lanePreview(lane: Preview) {
    this._lanePreview = lane;
  }

  private _lanePreview: Preview;
  private _unsubscribe$ = new Subject();
  constructor(
    public readonly mlpaStateService?: MlpaStateService,
    private readonly authService?: AuthService,
    public readonly alertsService?: AlertsService,
    private readonly router?: Router,
    private readonly mlpaService?: MlpaService,
    private readonly analyticsService?: AnalyticsService,
    private readonly mlpaJobService?: MlpaJobService,
    // private readonly dragulaService?: DragulaService,
    // private readonly scheduleService?: ScheduleService,
    private readonly pkgThemeService?: PkgThemeService) {
    if (this.authService.user$ != null) {
      this.authService.user$.subscribe(user => this.userRole = user?.role);
    }
  }

  ngOnInit(): void {
    if (!!this.mlpaJobs) {
      this.generateAllPreviews();
    }

    // this.dragulaService.dropModel('JOBS_ENQUEUE').pipe(takeUntil(this._unsubscribe$))
    //   .subscribe(({ name, el, target, source, sibling, sourceModel, targetModel, item }) => {
    //     this.droppedItem = item;
    //   });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  edit(job: MlpaJobView): void {
    let jobInfo = job;
    // if the parent job is a stress job then get its first child and edit that one.
    if (jobInfo.mlpaLanes[1].laneType === MlpaLaneType.Stress) {
      jobInfo = jobInfo.linkedMlpaJobs[0];
    }
    // send data to google analytics
    this.analyticsService.sendJobEvent(jobInfo, 'Job Payload', 'Edit button was clicked (Pre - Edit)');

    if (this.redirectUrl) {
      this.router.navigate([`mlpa/${jobInfo.id}`], { queryParams: { redirect: this.redirectUrl } });

    } else {
      if (this.userRole === Role.Operator) {
        this.router.navigate([`mlpa/${jobInfo.id}/edit`]);
      } else {
        this.router.navigate([`mlpa/${jobInfo.id}`]);
      }
    }
  }

  selectJob(job: MlpaJobView): void {
    if (this.isCloning) {
      return;
    }

    if (this.selectedJob === job) {
      this.previousSelectedJob = this.selectedJob;
      this.selectedJob = null;
      this.selectedJobEmitter.emit(this.selectedJob);
      return;
    }
    this.previousSelectedJob = this.selectedJob;
    this.selectedJob = job;
    this.selectedJobEmitter.emit(this.selectedJob);
  }

  scrollToJob(element: Event, id?: string): void {
    if (!!element) {
      setTimeout(() => {
        const el = element.srcElement as HTMLInputElement;
        if (el == null) {
          return;
        }

        if (el.closest('pkg-card') != null) {
          el.closest('pkg-card').scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        } else {
          el.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
      }, 0);
    }
  }

  toggleLanes(job: MlpaJobView): void {
    job.showLanes = !job.showLanes;
    if (job.orderState === 'hide') {
      job.orderState = 'show';
    } else {
      job.orderState = 'hide';
    }
  }

  getDimension(element: HTMLElement): string {
    const width = element.clientWidth;
    return (width - 28) * -1 + 'px';
  }
  makePreview(job: MlpaJobView): void {
    job.linkedMlpaJobs.forEach(linkedJob => this.makePreview(linkedJob));
    job.mlpaLanes.forEach(lane => {
      this.setPreview(lane);
    });
    this.toggleJobPreview(job);
  }

  toggleJobOptions(payload: ToggleEvent): void {
    payload?.event.stopPropagation();
    if (payload.job === this.mlpaJob && this.showActionOptions) {
      this.showActionOptions = !this.showActionOptions;
    } else {
      this.showActionOptions = true;
    }

    this.mlpaJob = payload.job as MlpaJob;
  }
  toggleJobPreview(job: MlpaJobView): void {
    if (!job.isLinkedMlpaJob) {
      job.showPreview = !job.showPreview;
      job.previewState = job.previewState === 'hide' ? 'show' : 'hide';
    }
  }

  jobDetailState(previewState: string): string {
    return previewState === 'hide' ? 'show' : 'hide';
  }

  preSubmit(job: MlpaJob): void {
    event.stopPropagation();
    this.mlpaJob = job;
    this.showConfirm = true;
    this.toggleJobOptions({event, job});
  }
  confirmSubmit(): void {
    this.isSubmitting = true;
    this.mlpaJobService.submit(this.mlpaJob).subscribe((result) => {
      // if completed
      if (result) {
        this.isSubmitting = false;
      }

      this.showConfirm = false;
    },
      () => {
        this.isSubmitting = false;
        this.showConfirm = false;
      });
  }

  confirmDelete() {
    this.isDeleting = true;
    this.mlpaJobService.deleteJob(this.mlpaJob).subscribe((result) => {
      if (result) {
        if (this.redirectUrl != null) {
          this.router.navigate([this.redirectUrl]);
        } else {
          this.router.navigate(['/']);
        }
      }

      this.deleteEmitter.emit();
      this.isDeleting = false;
      this.showDelete = false;
    });
  }
  closeSubmit(): void {
    this.showConfirm = false;
    this.showDelete = false;
    this.showCancelConfirm = false;
  }
  preClone(job: MlpaJobView) {
    this.isCloning = true;
    if (job.getJobType() === MlpaLaneType.QrCode) {
      this.mlpaService.getMlpaJob(job.parentMlpaJobId.toString()).subscribe(mlpaJob => {
        const jobView = new MlpaJobView(mlpaJob);
        this.prepareCloneJob(jobView);
      });
    } else {
      this.prepareCloneJob(job);
    }
  }

  cancelClone() {
    this.showClone = false;
    this.isCloning = false;
    this.showConfirm = false;
  }
  clone(): void {
    this.showClone = false;
    this.isCloning = false;
    this.router.navigate(['/']);
  }

  preDelete(job: MlpaJob): void {
    event.stopPropagation();
    // send data to google analytics
    this.analyticsService.sendJobEvent(job, 'Job Payload', 'PreDelete button was clicked');
    this.mlpaJob = job;
    this.showDelete = true;
  }

  save(mlpaJob: MlpaJobView): void {
    this.toggleEdit();
  }
  setPreview(lane: MlpaLaneView): void {
    switch (lane.laneType) {
      case MlpaLaneType.QrCode:
        lane.preview = '/assets/QrCode.svg';
        break;
      case MlpaLaneType.SmartLine:
        lane.preview = '/assets/SmartLine.svg';
        break;
      case MlpaLaneType.WebWeave:
        lane.preview = '/assets/WebWeave.svg';
        break;
      case MlpaLaneType.Graphic:
        lane.preview = `${environment.tartanApiUrl}mlpa-lanes/${lane.id}/thumbnail`;
        break;
      case MlpaLaneType.Empty:
        lane.preview = 'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA=';
        break;
      default:
        lane.preview = '/assets/ThumbnailNotFound.svg';
        break;
    }
  }
  toggleEdit(): void {
    this.editMode = !this.editMode;
    this.mlpaStateService.setByValue('editMode', this.editMode);
    this.editEmitter.emit(this.editMode);
  }
  showError(error: HttpErrorResponse): void {
    this.alertsService.add('Failed to submit', error.error.message, AlertType.Error);
  }

  generateAllPreviews(): void {
    for (const job of this.mlpaJobs) {
      this.makePreview(job);
    }
  }
  errorAndAlert(err: HttpErrorResponse): void {
    this.showSaving = false;
    this.alertsService.add('Failed to save', err.error.message, AlertType.Error);
  }

  getAssetId(job: MlpaJob): string{
    return job.assetId;
  }

  updateQueue($event): void {
    /*if (!(this.droppedItem instanceof MlpaJobView)) {
      // access the mlpajobview
      const index = this.mlpaJobs.findIndex(job => !(job instanceof MlpaJobView));
      // remove the incorrect type
      this.mlpaJobs.splice(index, 1);
      // replace it with the correct type
      this.mlpaJobs.splice(index, 0, this.droppedItem.view);

      if (this.droppedItem.id !== null) {
        this.scheduleService.deleteSchedules(this.droppedItem.id).subscribe(() => {
          this.alertsService.add('Removed Schedule', `Successfully removed ${this.droppedItem.view.name} from schedule`, AlertType.Success);
        }, err => {
          this.alertsService.add('Failed to remove', `Failed to remove schedule ${this.droppedItem.view.name}`, AlertType.Error);
        });
      }
    }*/
  }

  getBackgroundStyles(job: MlpaJobView) {
    let backgroundStyles = {};
    if (job.assetAttributes.lightThemeColor || job.assetAttributes.darkThemeColor) {
      backgroundStyles = {
        'border-left': this.pkgThemeService.getTheme() === 'light-theme' ?
          `3px solid ${job.assetAttributes.lightThemeColor}` :
          `3px solid ${job.assetAttributes.darkThemeColor}`
      };
    }
    return backgroundStyles;
  }

  private prepareCloneJob(job: MlpaJobView) {
    const jobView = job;
    this.mlpaService.getMlpaJob(jobView.id).subscribe(mlpaJob => {
      const newMlpaJob = new MlpaJob(mlpaJob);

      // recalculate the lanes
      newMlpaJob._mlpaLanes.forEach((lane, index) => {
        newMlpaJob._mlpaLanes[index].setCalculatedTotalWidthInInches(jobView.mlpaLanes[index].totalWidthInInches);
      });

      // recalculate the lanes in linkedJobs
      if (newMlpaJob.linkedMlpaJobs.length > 0) {
        newMlpaJob.linkedMlpaJobs.forEach((jobs, jobIndex) => {
          const linkedJobLanes = newMlpaJob.linkedMlpaJobs[jobIndex]._mlpaLanes;
          const viewLinkedJobLanes = jobView.linkedMlpaJobs[jobIndex].mlpaLanes;

          linkedJobLanes.forEach((lane, laneIndex) => {
            newMlpaJob.linkedMlpaJobs[jobIndex]._mlpaLanes[laneIndex].setCalculatedTotalWidthInInches(viewLinkedJobLanes[laneIndex].totalWidthInInches);
          });
        });

      }

      // recopy the values.
      newMlpaJob._mlpaLanes.forEach((lane, index) => {
        deepCopyProperties(newMlpaJob._mlpaLanes[index], jobView.mlpaLanes[index]);
      });

      this.cloneMlpaJobComponent.open(newMlpaJob);
      this.showClone = true;
    });
  }
}
