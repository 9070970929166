<div class="container">
  <i class="material-icons control-button__icon">&#xE41c;</i>
  <div class="display-settings__line display-settings__line--full">
    <div class="display-settings__width">
      <div class="width-container">
        <div *ngIf="hasWebWeave" [ngClass]="webwWeaveClass + ' lane--1'" [style.width.%]="getWidthPercentage(mlpaLanes[0]?.designWidthInInches)"></div>
        <div class="width__measurements">
          <span class="info__header">Design(s)</span>
          <span class="info__text">{{totalWidthInInchesWithoutWebWeave | inches}}</span>
        </div>
        <div *ngIf="!isOverflowed" class="graphic__section">
          <ng-container *ngFor="let lane of mlpaLanes; index as i">
            <div *ngIf="!lane.isWebWeave" class="width__percentage lane--{{lane.laneNumber}}" [style.width.%]="getWidthPercentage(lane.totalWidthInInches)"></div>
          </ng-container>
        </div>
        <div *ngIf="isOverflowed" class="width__percentage" [ngClass]="widthColorClass" [style.width.%]="getWidthPercentage(totalWidthInInchesWithoutWebWeave)">
        </div>
        <div *ngIf="hasWebWeave" [ngClass]="webwWeaveClass  + ' lane--' + mlpaLanes.length" [style.width.%]="getWidthPercentage(mlpaLanes[mlpaLanes?.length-1]?.designWidthInInches)"></div>
      </div>
    </div>
    <div class="display-settings-stats">
      <div class="display-settings_stats--item">
        <span class="info__header">{{mlpaLanes[mlpaLanes?.length-1]?.designNumber}}</span>
        <span class="info__text">{{mlpaLanes[0]?.designWidthInInches| inches}}</span>
      </div>
      <div class="display-settings_stats--item">
        <span class="info__header">Available Space</span>
        <span class="info__text">{{availableSpace | inches}}</span>
        <span class="info__header" [style.margin-left.px]="5"> of </span>
        <span class="info__text">{{jobSettings?.paperWidthInInches - (jobSettings?.marginWidthInInches * 2) | inches}}</span>
      </div>
      <div class="display-settings_stats--item">
        <span class="info__header">{{mlpaLanes[mlpaLanes?.length-1]?.designNumber}}</span>
        <span class="info__text">{{mlpaLanes[0]?.designWidthInInches| inches}}</span>
      </div>
    </div>
  </div>
  <div class="display-stats">
    <div class="display-settings-stats">
      <div class="display-settings_stats--item">
        <span class="number-of-jobs">Lanes: {{mlpaLanes?.length-2}}</span>
      </div>
    </div>
  </div>
</div>
