import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Holiday } from './holiday.interface';
import { Today } from './today.interface';

@Injectable({
  providedIn: 'root'
})
export class HolidayService {
  today = {} as Today;
  currentHoliday: Holiday;
  now = moment(new Date().toISOString(), 'YYYY/MM/DD');
  holidays: Holiday[] = [
    { name: 'Halloween', month: '10', day: '31' } as Holiday,
    { name: 'Thanksgiving', month: '11', day: '28' } as Holiday,
    { name: 'Christmas', month: '12', day: '25' } as Holiday
  ];
  constructor() { }


  closestHoliday(now: moment.Moment, holidays: Holiday[]): Holiday {
    let closest;
    let closestHoliday = null;

    for (const holiday of holidays) {
      const holidayMoment = moment(`${now.format('YYYY')} ${holiday.month} ${holiday.day}`, 'YYYY MM DD');

      if (closest === undefined) {
        closest = holidayMoment.diff(now, 'days');
        closestHoliday = holiday;
      }

      if (Math.abs(closest) > Math.abs(holidayMoment.diff(now, ('days')))) {
        closest = holidayMoment.diff(now, ('days'));
        closestHoliday = holiday;
      }
    }

    return closestHoliday;
  }

  holidayIsInCurrentWeek(now: moment.Moment, holiday: Holiday, holidays: Holiday[]): boolean {
    // create a holiday moment
    const holidayMoment = moment(`${now.format('YYYY')} ${holiday.month} ${holiday.day}`, 'YYYY MM DD');

    const isSame = holidayMoment.isSame(now);

    // is the same day.
    if (isSame) {
      return true;
    }

    // new now Moment
    let nowMoment = moment(now, 'YYYY/MM/DD');
    const startOfWeek = nowMoment.startOf('isoWeek');

    // reset moment
    nowMoment = moment(now, 'YYYY/MM/DD');
    const endOfWeek = moment(nowMoment, 'YYYY/MM/DD').endOf('isoWeek');

    // is the holiday currently between the start of the week and the end of the week?
    const isBetween = holidayMoment.isBetween(startOfWeek, endOfWeek);

    if (isBetween) {
      return true;
    }

    return false;
  }

  getCurrentHoliday(): Holiday {
    this.today.month = this.now.format('M');
    this.today.day = this.now.format('D');
    this.today.dayOfWeek = this.now.weekday();

    const closetHoliday = this.closestHoliday(this.now, this.holidays);
    const isCurrentWeek = this.holidayIsInCurrentWeek(this.now, closetHoliday, this.holidays);

    if (isCurrentWeek) {
      this.currentHoliday = closetHoliday;
    } else {
      this.currentHoliday = null;
    }

    return this.currentHoliday;
  }
}
