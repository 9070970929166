import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from '../user';
import { Role } from '../role';
import { MultiSelectDropDownSetting } from 'src/app/shared/multi-select-dropdown/multi-select-dropdown-settings';
import { getMultiSelectDropdownSettings } from '../user-assets-dropdown-settings-helper';

@Component({
  selector: 'pkg-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

  @Input() user: User;
  @Input() assets = [];
  @Input() userAssets = [];
  @Input() userRole: Role;

  @Output() roleSelectEmitter = new EventEmitter<Role>();
  @Output() assetSelectEmitter = new EventEmitter<any>();
  @Output() assetDeSelectEmitter = new EventEmitter<any>();
  @Output() assetSelectAllEmitter = new EventEmitter<any>();
  @Output() assetDeSelectAllEmitter = new EventEmitter<any>();

  roles = Role;
  userAssetsDropdownSettings: MultiSelectDropDownSetting;

  constructor() { }

  ngOnInit(): void {
    this.userAssetsDropdownSettings = getMultiSelectDropdownSettings('user-management-screen-select-assets', 'No Assets Found', 2);
  }

  onRoleSelect(role: any) {
    this.roleSelectEmitter.emit(this.userRole);
  }

  onAssetSelect(assets: any[]) {
    this.assetSelectEmitter.emit(assets);
  }
  onAssetDeSelect(assets: any[]) {
    this.assetDeSelectEmitter.emit(assets);
  }
  onAssetSelectAll(assets: any[]) {
    this.assetSelectAllEmitter.emit(assets);
  }
  onAssetDeSelectAll(assets: any[]) {
    this.assetDeSelectAllEmitter.emit(assets);
  }

}
