<div class="container">
  <div class="page__header">
    <span class="material-icons">&#xE8AD;</span>
    <span class="page-heading">Web Layout Print</span>
    <span class="filler"></span>
    <span *ngIf="loading" class="loading"></span>
  </div>
</div>

<ng-container *ngIf="!loading">
  <p class="instructions">To print this page, use Control + P on the keyboard and enable background graphics in the print settings/more settings.</p>
  <div class="page">
    <pkg-lane-preview class="preview" *ngIf="mlpaJobView.isProcessed" [weblayout]="true" [source]="source" [print]="false" [bounds]="true" [backgroundImage]="true" [animateBackgroundImage]="true">
    </pkg-lane-preview>
  </div>

  <div class="page">
    <div class="ruler" *ngIf="jobSettings">
      <pkg-mlpa-job-ruler [jobSettings]="jobSettings" [mlpaLanes]="mlpaJob?.mlpaLanes" [totalWidthInInches]="mlpaJob?.totalWidthInInches"></pkg-mlpa-job-ruler>
    </div>
    <pkg-mlpa-job-details [actionStyles]="{'display': 'none'}" [lanePreviewStyles]="{'display': 'none'}" [mlpaJobView]="mlpaJobView"></pkg-mlpa-job-details>
  </div>

  <div class="page">
    <pkg-mlpa-job-details [actionStyles]="{'display': 'none'}" [lanePreviewStyles]="{'display': 'none'}" *ngFor="let job of mlpaJobView?.linkedMlpaJobs" [mlpaJobView]="job"></pkg-mlpa-job-details>
  </div>
</ng-container>
