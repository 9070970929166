import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { MlpaJobsSearchService } from './mlpa-jobs-search.service';
import { map, distinctUntilChanged, debounce, debounceTime, switchMap, retryWhen, tap } from 'rxjs/operators';
import { Subscription, Subject, Observable, of, timer } from 'rxjs';
import { PagedResult } from '../paged-result';
import { MlpaJobApi } from '../mlpa-job-api';
import { KeyCode } from '../keycode.enum';
import { SortOrder } from './sort-order.enum';
import { SortBy } from './sort-by.enum';
import { SearchFilter } from './search-filter.interface';
import { StatusFlags, MlpaJobPropertiesFlag, OrderFlag } from './filter-flags';
import { NgStyle } from '@angular/common';
import { MenuItem } from '../menu-option/menu-item';
import { UserService } from '../../pages/admin/shared/user.service';
import { AlertsService } from '../alerts/alerts.service';
import { AlertType } from '../alerts/alert-type.enum';
import { UserAsset } from './user-asset';
import { MultiSelectDropDownSetting } from '../multi-select-dropdown/multi-select-dropdown-settings';
import { getMultiSelectDropdownSettings } from '../../../app/pages/admin/shared/user-assets-dropdown-settings-helper';

@Component({
  selector: 'pkg-mlpa-jobs-search',
  templateUrl: './mlpa-jobs-search.component.html',
  styleUrls: ['./mlpa-jobs-search.component.scss']
})
export class MlpaJobsSearchComponent implements OnInit, OnDestroy {
  @Input() filter: SearchFilter = null;
  @Input() jobPropertiesStyle?: NgStyle;
  @Input() mlpaJobStatusStyle?: NgStyle;
  @Input() showAssetsFilter = true;

  @Output() menuItemsOut?: EventEmitter<MenuItem[]> = new EventEmitter();
  @Output() mlpaJobsEventEmitter: EventEmitter<PagedResult<MlpaJobApi>> = new EventEmitter();
  @Output() termSearchedEmitter: EventEmitter<string> = new EventEmitter();

  term: string;
  isLoading: boolean;
  keyUp = new Subject<KeyboardEvent>();
  results = 0;
  showFilter = false;
  selectionChange$ = new Subject<Event>();
  selectedAssets: UserAsset[] = [];
  userAssets: UserAsset[] = [];
  userAssetsDropdownSettings: MultiSelectDropDownSetting;

  statusState = {
    status: {
      queued: true,
      processing: true,
      processed: true,
      generated: true,
      submitted: true,
      failed: true,
      special: true,
      stress: true
    },
    jobProperties: {
      programNumber: true,
      orderNumber: true,
      setupNumber: true,
      designNumber: true,
      name: true,
      uniqueId: true,
      designDescription: true,
      colorProfile: true,
      paperType: true
    },
    sortOrder: SortOrder.DSC,
    sortBy: SortBy.CREATED_DATE
  };

  get isFiltered(): boolean {
    const toggleStates = this.statusAllOn && this.jobPropertiesAllOn;
    const sorted = this.statusState.sortOrder === SortOrder.DSC && this.statusState.sortBy === SortBy.CREATED_DATE;
    // Either no assets are selected or all assets are selected
    const noAssetsSelected = this.selectedAssets.length === 0 || this.selectedAssets.length === this.userAssets.length;

    if (toggleStates && sorted && noAssetsSelected) {
      return false;
    }

    return true;
  }

  get statusAllOn(): boolean {
    return this.statusState.status.queued &&
      this.statusState.status.processing &&
      this.statusState.status.processed &&
      this.statusState.status.generated &&
      this.statusState.status.submitted &&
      this.statusState.status.failed;
  }

  get jobPropertiesAllOn(): boolean {
    return this.statusState.jobProperties.programNumber &&
      this.statusState.jobProperties.orderNumber &&
      this.statusState.jobProperties.setupNumber &&
      this.statusState.jobProperties.designNumber;
  }

  SortOrder = SortOrder;
  SortBy = SortBy;

  readonly $search: Subscription;
  readonly $assetsSelection: Subscription;

  private _$search: Subscription;
  private _$filter: Subscription;
  private $filter: Subscription;

  constructor(private readonly mlpaJobSearchService: MlpaJobsSearchService,
    private userService: UserService,
    private alertService: AlertsService) {
    this.$search = this.keyUp.pipe(
      map(event => event),
      debounce((event) => {
        const value = (event.target as HTMLInputElement).value;
        if (event.keyCode === KeyCode.Enter || value === '') {
          return timer(0);
        } else {
          return timer(500);
        }
      }),
      distinctUntilChanged(),
      switchMap(term => {
        this.isLoading = true;
        const searchTerm = (term.target as HTMLInputElement).value;
        if (!!searchTerm && searchTerm !== '') {
          let filter = this.isFiltered ? this._generateFilter() : null;
          this.termSearchedEmitter.emit(searchTerm);

          // injected filter from component
          if (this.filter != null) {
            filter = this.filter;
          }

          return this.search(searchTerm, filter);
        }
        this.results = 0;
        this.isLoading = false;
        this.termSearchedEmitter.emit(null);
        this.mlpaJobsEventEmitter.emit(null);
        return of(null);
      }),
      retryWhen((error) => error.pipe(
        tap(() => {
          this.results = 0;
          this.isLoading = false;
          this.mlpaJobsEventEmitter.emit(null);
        }))))
      .subscribe((result: PagedResult<MlpaJobApi>) => {
        this.isLoading = false;
        this.results = !!result ? result.totalCount : 0;
        this.mlpaJobsEventEmitter.emit(result);
      });

    this.$assetsSelection = this.selectionChange$
      .pipe(debounceTime(500))
      .subscribe(() => this.searchWithFilter());

    this.setAssetDropdownSettings('No Assets Found');
  }

  ngOnInit() {
    this.userService.getUserAssets().subscribe((data) => {
      if (!!data && data.length) {
        data.forEach((asset) => this.userAssets.push(asset));
        if (data.length === 1) {
          this.selectedAssets.push(this.userAssets[0]);
        }
        this.setAssetDropdownSettings('');
      } else {
        this.alertService.add('No Assets!', 'No Assets are associated to user.', AlertType.Info);
        this.setAssetDropdownSettings('No Assets Found');
      }
    }, (err) => {
      this.alertService.add('Failed to load associated Assets.', err?.error?.message, AlertType.Error);
      this.setAssetDropdownSettings('No Assets Found');
    });

  }

  clear(): void {
    this.term = '';
    this.termSearchedEmitter.emit(null);
    this.mlpaJobsEventEmitter.emit(null);
    this.mlpaJobSearchService.searchFilterSubject$.next(null);
  }

  ngOnDestroy(): void {
    if (this._$search != null) {
      this._$search.unsubscribe();
    }

    if (this._$filter != null) {
      this._$filter.unsubscribe();
    }
    if (this.$assetsSelection != null) {
      this.$assetsSelection.unsubscribe();
    }
  }

  toggleFilter(): void {
    this.showFilter = !this.showFilter;
  }

  close(): void {
    this.showFilter = false;
  }

  search(searchTerm, filter: SearchFilter): Observable<PagedResult<MlpaJobApi>> {

    return this.mlpaJobSearchService.searchMlpaJob(searchTerm, 1, 20, filter);
  }

  searchWithFilter(): void {
    this.isLoading = true;
    const filter = this._generateFilter();

    if (this._$filter != null) {
      this._$filter.unsubscribe();
    }

    this.mlpaJobSearchService.searchFilterSubject$.next(filter);

    this._$filter = this.search(this.term, filter).pipe(debounce(() => timer(500)))
      .subscribe((pagedResult: PagedResult<MlpaJobApi>) => {
        this.isLoading = false;
        this.results = !!pagedResult ? pagedResult.totalCount : 0;
        this.mlpaJobsEventEmitter.emit(pagedResult);
      },
        (err) => {
          this.results = 0;
          this.isLoading = false;
          this.mlpaJobsEventEmitter.emit();
          this.termSearchedEmitter.emit('');
        });
  }

  reset(): void {
    if (this.isFiltered) {
      this.setFiltersToTrue();
    } else {
      this.setFiltersToFalse();
    }
  }

  setFiltersToTrue(): void {
    this.setStatusToTrue();
    this.setJobPropertiesToTrue();
    this.statusState.sortOrder = SortOrder.DSC;
    this.statusState.sortBy = SortBy.CREATED_DATE;
  }

  setFiltersToFalse(): void {
    this.setStatusToFalse();
    this.setJobPropertiesToFalse();
    this.statusState.sortOrder = SortOrder.DSC;
    this.statusState.sortBy = SortBy.CREATED_DATE;
    this.termSearchedEmitter.emit('');
  }

  toggleStatus(): void {
    if (this.statusAllOn) {
      this.setStatusToFalse();
    } else {
      this.setStatusToTrue();
    }
  }

  toggleJobProperties(): void {
    if (this.jobPropertiesAllOn) {
      this.setJobPropertiesToFalse();
    } else {
      this.setJobPropertiesToTrue();
    }
  }

  setJobPropertiesToFalse(): void {
    this.statusState.jobProperties = {
      programNumber: false,
      orderNumber: false,
      setupNumber: false,
      designNumber: false,
      name: false,
      uniqueId: false,
      designDescription: false,
      colorProfile: false,
      paperType: false
    };
  }

  setStatusToFalse(): void {
    this.statusState.status = {
      queued: false,
      processing: false,
      processed: false,
      generated: false,
      submitted: false,
      failed: false,
      special: false,
      stress: false
    };
  }

  setStatusToTrue(): void {
    this.statusState.status = {
      queued: true,
      processing: true,
      processed: true,
      generated: true,
      submitted: true,
      failed: true,
      special: true,
      stress: false
    };
  }

  setJobPropertiesToTrue(): void {
    this.statusState.jobProperties = {
      programNumber: true,
      orderNumber: true,
      setupNumber: true,
      designNumber: true,
      name: true,
      uniqueId: true,
      designDescription: true,
      colorProfile: true,
      paperType: true
    };
  }

  onAssetSelect(assets: UserAsset[]) {
    this.mapAndSearch(assets);
  }

  onAssetDeSelect(assets: UserAsset[]) {
    this.mapAndSearch(assets);
  }

  onAssetSelectAll(assets: UserAsset[]) {
    this.mapAndSearch(assets);
  }

  onAssetDeSelectAll(assets: any[]) {
    this.mapAndSearch(assets);
  }

  private mapAndSearch(assets: UserAsset[]) {
    this.selectedAssets = assets;
    this.selectionChange$.next();
  }

  private setAssetDropdownSettings(noAssetsText: string): void {
    this.userAssetsDropdownSettings = getMultiSelectDropdownSettings('mlpa-job-search-select-assets', noAssetsText, 2, 'Select Assets...');
  }

  private _generateFilter(): SearchFilter {
    let status = 0;
    let jobProperties = 0;
    const orderBy = OrderFlag[this.statusState.sortOrder.toUpperCase()];
    const sortBy = MlpaJobPropertiesFlag[this.statusState.sortBy.toUpperCase()];
    const assetIds = this.selectedAssets.map(x => x.id).filter(x => x !== '0');
    Object.keys(this.statusState.status).forEach((key) => {
      if (this.statusState.status[key]) {
        status += StatusFlags[key.toUpperCase()];
      }
    });


    Object.keys(this.statusState.jobProperties).forEach((key) => {
      if (this.statusState.jobProperties[key]) {
        jobProperties += MlpaJobPropertiesFlag[key.toUpperCase()];
      }
    });

    return { status, jobProperties, orderBy, sortBy, assetIds };
  }
}
