import { Pipe, PipeTransform } from '@angular/core';
import { MlpaJobView } from '../pages/home/view-mlpa-jobs/mlpa-job-view';
import { MlpaLaneType } from '../mlpa/mlpa-lane/mlpa-lane-type.enum';

@Pipe({
  name: 'getMainJob'
})
export class GetMainJobPipe implements PipeTransform {

  transform(value: MlpaJobView, args?: any): MlpaJobView {
    if (value?.mlpaLanes[1].laneType === MlpaLaneType.Stress) {
      return value.linkedMlpaJobs[0];
    }
    return value;
  }

}
