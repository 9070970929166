import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unitOfTime'
})
export class UnitOfTimePipe implements PipeTransform {
  private readonly _minutesInHour = 60;
  private readonly _minutesInDay = 1440;
  private _minutes = 0;

  transform(minutes: number): any {
    this._minutes = minutes;

    if (this._minutes < 0) {
      throw new Error('minutes cannot be negative');
    }

    if (this._minutes < 1) {
      return '< 1 min';
    }

    if (this._isLessThanHour()) {
      return `${Math.floor(minutes)} mins`;
    }

    if (this._isLessThanDay()) {
      return this._formatMinutesForHours(this._minutes);
    }

    return this._formatMinutesForDays();
  }

  private _isLessThanHour(): boolean {
    return this._minutes < this._minutesInHour;
  }

  private _isLessThanDay(): boolean {
    return this._minutes < this._minutesInDay;
  }

  private _formatMinutesForHours(mins: number): string {
    const hours = Math.floor(mins / this._minutesInHour);
    const minutes = (mins - (hours * this._minutesInHour));

    if (minutes === 0) {
      return `${Math.floor(hours)} hr`;
    }
    return `${Math.floor(hours)} hr ${Math.floor(minutes)} mins`;
  }

  private _formatMinutesForDays(): string {
    const days = Math.floor(this._minutes / this._minutesInDay);
    const minutes = this._minutes - this._minutesInDay;
    const hours = this._formatMinutesForHours(minutes);

    if (minutes === 0) {
      return `${days} day`;
    }

    return `${days} day ${hours}`;
  }
}
