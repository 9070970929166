<div class="row">

  <pkg-card>
    <pkg-card-header>
      <div class="pkg-card-header">
        <span class="title">Total</span>
        <span class="filler"></span>
        <span
          class="info">{{mlpaJobsStats?.numberOfSuccessfulJobs + mlpaJobsStats?.numberOfFailedJobs | number }}</span>
      </div>
    </pkg-card-header>
    <pkg-card-body>
      <div class="pkg-card-body">
        <div class="stat-header">
          <span class="material-icons status-icon status-icon--successful"></span>
          <span class="title" [style.color]="colors.successful">OK</span>
          <span class="filler"></span>
          <span class="info"
            [style.color]="colors.successful">{{mlpaJobsStats?.numberOfSuccessfulJobs | number }}</span>
        </div>
        <div class="stat-bar">
          <div class="percentage" title="{{mlpaJobsStats?.percentageOfSuccessfulJobs}}%">
            <div class="bar" [style.background-color]="colors.successful"
              [style.width.%]="mlpaJobsStats?.percentageOfSuccessfulJobs"></div>
          </div>
        </div>
        <div class="stat-header">
          <span class="material-icons status-icon status-icon--failed"></span>
          <span class="title" [style.color]="colors.failed">Failed</span>
          <span class="filler"></span>
          <span class="info" [style.color]="colors.failed">{{mlpaJobsStats?.numberOfFailedJobs | number }}</span>
        </div>
        <div class="stat-bar">
          <div class="percentage" title="{{mlpaJobsStats?.percentageOfFailedJobs}}%">
            <div class="bar" [style.background-color]="colors.failed"
              [style.width.%]="mlpaJobsStats?.percentageOfFailedJobs"></div>
          </div>
        </div>
      </div>
    </pkg-card-body>
  </pkg-card>

  <pkg-card>
    <pkg-card-header>
      <div class="pkg-card-header">
        <span class="title">Web Weave</span>
        <span class="filler"></span>
        <span
          class="info">{{mlpaJobsStats?.numberOfJobsWithWebWeaves + mlpaJobsStats?.numberOfJobsWithSmartLines + mlpaJobsStats?.numberOfJobsWithoutWebWeaves | number }}</span>
      </div>
    </pkg-card-header>
    <pkg-card-body>
      <div class="pkg-card-body">
        <div class="stat-header">
          <span class="material-icons status-icon status-icon--webweave">
            <svg viewBox="0 0 30 10">
              <use href="#solid-line"></use>
            </svg>
          </span>
          <span class="title" [style.color]="colors.webWeave">Solid</span>
          <span class="filler"></span>
          <span class="info"
            [style.color]="colors.webWeave">{{mlpaJobsStats?.numberOfJobsWithWebWeaves | number }}</span>
        </div>
        <div class="stat-bar">
          <div class="percentage" title="{{mlpaJobsStats?.percentageOfJobsWithWebWeaves}}%">
            <div class="bar" [style.background-color]="colors.webWeave"
              [style.width.%]="mlpaJobsStats?.percentageOfJobsWithWebWeaves"></div>
          </div>
        </div>
        <div class="stat-header">
          <span class="material-icons status-icon status-icon--smartline">
            <svg viewBox="0 0 30 10">
              <use href="#smart-line"></use>
            </svg>
          </span>
          <span class="title" [style.color]="colors.smartLine">SmartLine</span>
          <span class="filler"></span>
          <span class="info"
            [style.color]="colors.smartLine">{{mlpaJobsStats?.numberOfJobsWithSmartLines | number }}</span>
        </div>
        <div class="stat-bar">
          <div class="percentage" title="{{mlpaJobsStats?.percentageOfJobsWithSmartLines}}%">
            <div class="bar" [style.background-color]="colors.smartLine"
              [style.width.%]="mlpaJobsStats?.percentageOfJobsWithSmartLines"></div>
          </div>
        </div>
        <div class="stat-header">
          <span class="material-icons status-icon status-icon--empty"></span>
          <span class="title" [style.color]="colors.empty">Empty</span>
          <span class="filler"></span>
          <span class="info"
            [style.color]="colors.empty">{{mlpaJobsStats?.numberOfJobsWithoutWebWeaves | number }}</span>
        </div>
        <div class="stat-bar">
          <div class="percentage" title="{{mlpaJobsStats?.percentageOfJobsWithoutWebWeaves}}%">
            <div class="bar" [style.background-color]="colors.empty"
              [style.width.%]="mlpaJobsStats?.percentageOfJobsWithoutWebWeaves"></div>
          </div>
        </div>
      </div>
    </pkg-card-body>
  </pkg-card>


  <pkg-card>
    <pkg-card-header>
      <div class="pkg-card-header">
        <span class="title">Processing</span>
        <span class="filler"></span>
        <span
          class="info">{{mlpaJobsStats?.numberOfQueuedJobs + mlpaJobsStats?.numberOfProcessingJobs + mlpaJobsStats?.numberOfProcessedJobs | number }}</span>
      </div>
    </pkg-card-header>
    <pkg-card-body>
      <div class="pkg-card-body">
        <div class="stat-header">
          <span class="material-icons status-icon status-icon--queued"></span>
          <span class="title" [style.color]="colors.queued">Queued</span>
          <span class="filler"></span>
          <span class="info" [style.color]="colors.queued">{{mlpaJobsStats?.numberOfQueuedJobs | number }}</span>
        </div>
        <div class="stat-bar">
          <div class="percentage" title="{{mlpaJobsStats?.percentageOfQueuedJobs}}%">
            <div class="bar" [style.background-color]="colors.queued"
              [style.width.%]="mlpaJobsStats?.percentageOfQueuedJobs"></div>
          </div>
        </div>
        <div class="stat-header">
          <span class="material-icons status-icon status-icon--processing"
            [class.spin]="mlpaJobsStats?.numberOfProcessingJobs > 0"></span>
          <span class="title" [style.color]="colors.processing">Processing</span>
          <span class="filler"></span>
          <span class="info"
            [style.color]="colors.processing">{{mlpaJobsStats?.numberOfProcessingJobs | number }}</span>
        </div>
        <div class="stat-bar">
          <div class="percentage" title="{{mlpaJobsStats?.percentageOfProcessingJobs}}%">
            <div class="bar" [style.background-color]="colors.processing"
              [style.width.%]="mlpaJobsStats?.percentageOfProcessingJobs"></div>
          </div>
        </div>
        <div class="stat-header">
          <span class="material-icons status-icon status-icon--processed"></span>
          <span class="title" [style.color]="colors.processed">Processed</span>
          <span class="filler"></span>
          <span class="info" [style.color]="colors.processed">{{mlpaJobsStats?.numberOfProcessedJobs | number }}</span>
        </div>
        <div class="stat-bar">
          <div class="percentage" title="{{mlpaJobsStats?.percentageOfProcessedJobs}}%">
            <div class="bar" [style.background-color]="colors.processed"
              [style.width.%]="mlpaJobsStats?.percentageOfProcessedJobs"></div>
          </div>
        </div>
      </div>
    </pkg-card-body>
  </pkg-card>

  <pkg-card>
    <pkg-card-header>
      <div class="pkg-card-header">
        <span class="title">Submission</span>
        <span class="filler"></span>
        <span
          class="info">{{mlpaJobsStats?.numberOfGeneratedJobs + mlpaJobsStats?.numberOfSubmittedJobs | number }}</span>
      </div>
    </pkg-card-header>
    <pkg-card-body>
      <div class="pkg-card-body">
        <div class="stat-header">
          <span class="material-icons status-icon status-icon--generated"></span>
          <span class="title" [style.color]="colors.generated">Generated</span>
          <span class="filler"></span>
          <span class="info" [style.color]="colors.generated">{{mlpaJobsStats?.numberOfGeneratedJobs | number }}</span>
        </div>
        <div class="stat-bar">
          <div class="percentage" title="{{mlpaJobsStats?.percentageOfGeneratedJobs}}%">
            <div class="bar" [style.background-color]="colors.generated"
              [style.width.%]="mlpaJobsStats?.percentageOfGeneratedJobs"></div>
          </div>
        </div>
        <div class="stat-header">
          <span class="material-icons status-icon status-icon--submitted"></span>
          <span class="title" [style.color]="colors.submitted">Submitted</span>
          <span class="filler"></span>
          <span class="info" [style.color]="colors.submitted">{{mlpaJobsStats?.numberOfSubmittedJobs | number }}</span>
        </div>
        <div class="stat-bar">
          <div class="percentage" title="{{mlpaJobsStats?.percentageOfSubmittedJobs}}%">
            <div class="bar" [style.background-color]="colors.submitted"
              [style.width.%]="mlpaJobsStats?.percentageOfSubmittedJobs"></div>
          </div>
        </div>
      </div>
    </pkg-card-body>
  </pkg-card>

</div>

<div class="row">

  <pkg-card class="chart">
    <pkg-card-header>
      <div class="pkg-card-header">
        <span class="material-icons status-icon status-icon--submitted"></span>
        <span class="title">Submission</span>
        <span class="filler"></span>
        <span class="status-chip status-chip--text">30 Days</span>
      </div>
    </pkg-card-header>
    <pkg-card-body>
      <div class="pkg-card-body">
        <canvas baseChart height="250px" [datasets]="chartData" [labels]="chartLabels" [options]="chartOptions"
          [legend]="chartLegend" [chartType]="chartType" [plugins]="chartPlugins">
        </canvas>
      </div>
    </pkg-card-body>
  </pkg-card>

</div>
