<div class="content">
  <span class="title">Program Number</span>
  <span *ngIf="job?.programNumber" class="info highlight">{{job?.programNumber}}</span>
  <span *ngIf="!job?.programNumber" class="info">N/A</span>
</div>
<div class="content">
  <span class="title">Setup Number</span>
  <span *ngIf="job?.setupNumber" class="info highlight">{{job?.setupNumber}}</span>
  <span *ngIf="!job?.setupNumber" class="info">N/A</span>
</div>
<div class="content">
  <span class="title">Blanket Number</span>
  <span *ngIf="job?.blanketNumber" title="{{job?.blanketNumber}}" class="info highlight">{{job?.blanketNumber | slice:0:20}}</span>
  <span *ngIf="!job?.blanketNumber" class="info">N/A</span>
</div>
<div class="content">
  <span class="title">Asset</span>
  <span class="info">{{job?.assetName | slice:0:20}}</span>
</div>
<div class="content">
  <span class="title">Paper Type</span>
  <span class="info">{{job?.paperTypeName | slice:0:20}}</span>
</div>
<div class="content">
  <span class="title">Paper Width (in)</span>
  <span class="info">{{job?.paperWidthInInches | inches}}</span>
</div>
<div class="content">
  <span class="title">Total Width (in)</span>
  <span class="info">{{job?.totalWidthInInches | inches}}</span>
</div>
<div class="content">
  <span class="title">Total Length (ft)</span>
  <span class="info">{{job?.lengthInFeet | feet}}</span>
</div>
<div class="content">
  <span class="title">Margin (in)</span>
  <span class="info">{{job?.marginWidthInInches | inches}}</span>
</div>
