import { MlpaJobApi } from '@shared/mlpa-job-api';
import { mergeWith, has, cloneDeep } from 'lodash';
import { MlpaLaneApi } from '@shared/mlpa-lane-api';
import { MlpaLane } from '../mlpa-lane/mlpa-lane';
import { MlpaLaneType } from '../mlpa-lane/mlpa-lane-type.enum';
import { cloneLaneFromApi, createLaneFromApi, createLaneFromView } from '../mlpa-helper';
import { QrCode } from '../mlpa-lane/lane-types/qrcode';
import { LaneProperties, InitialLaneProperties } from '../mlpa-lane/lane-types/lane-properties.interface';
import { MlpaJobSettings } from '../mlpa-job-settings/mlpa-job-settings';
import { WebWeave } from '../mlpa-lane/lane-types/webweave';
import { MlpaJobView } from '../../pages/home/view-mlpa-jobs/mlpa-job-view';
import * as _ from 'lodash';
import { deepCopyProperties } from '@shared/pkg-helper';
import { MlpaLaneView } from '../../../app/pages/home/view-mlpa-jobs/mlpa-lane-view';
import { EmptyLane } from '../mlpa-lane/lane-types/empty';
import { Stress } from '../mlpa-lane/lane-types/stress';
import { MlpaJobColorcorrectionInfo } from './mlpa-job-color-correction-info';

export class MlpaJob {
  id: string;
  parentMlpaJobId: string;
  parentClonedMlpaJobId: string;
  isParentMlpaJob: boolean;
  isLinkedMlpaJob: boolean;
  name: string;
  uniqueId: string;
  programNumber: string;
  setupNumber: string;
  blanketNumber: string;
  assetId: string;
  assetName: string;
  paperTypeId: string;
  paperTypeName: string;
  paperWidthInInches: number;
  totalWidthInInches: number;
  wasteWidthInInches: number;
  totalLengthInInches: number;
  wasteLengthInInches: number;
  marginWidthInInches: number;
  lineBreakLengthInInches: number;
  isOk: boolean;
  errors: string;
  isProcessed: boolean;
  IsColorCorrected: boolean;
  isGenerated: boolean;
  generatedDate: Date;
  generatedBy: string;
  isSubmitted: boolean;
  submittedDate: Date;
  submittedBy: string;
  isSpecial: boolean;
  warnings: string;
  hasWarnings: boolean;
  colorCorrectionInfo: MlpaJobColorcorrectionInfo = new MlpaJobColorcorrectionInfo();
  _mlpaLanes: MlpaLane[] = [];
  qualityMode: string;
  get mlpaLanes(): MlpaLane[] {
    return this._mlpaLanes;
  }
  set mlpaLanes(mlpaLanes: MlpaLane[]) {
    mlpaLanes.forEach((lane, index) => lane.laneNumber = index + 1);
    this._mlpaLanes = mlpaLanes;
  }
  linkedMlpaJobs: MlpaJob[] = [];
  createdDate: Date;
  createdBy: string;
  updatedDate: Date;
  updatedBy: string;

  get showJobCharacterizationValues(): boolean {
    for (const mlpaLane of this.mlpaLanes) {
      if (mlpaLane.laneType === MlpaLaneType.Graphic && mlpaLane.colorCorrectionInfo.isColorCorrectionEnabled) {
        return true;
      }
    }
    return false;
  }

  constructor(mlpaJobApi?: MlpaJobApi | MlpaJobView) {
    if (mlpaJobApi instanceof MlpaJobView) {
      mergeWith(this, mlpaJobApi, (objectValue, sourceValue, key, object, source) => {
        if (has(source, key) && typeof objectValue !== typeof (Function)) {
          switch (key) {
            // Add linked jobs
            case 'linkedMlpaJobs':
              return sourceValue.map(job => new MlpaJob(job));
            // Add lanes
            case 'mlpaLanes':
              return sourceValue.map((lane: MlpaLaneView, index) => createLaneFromView(lane.laneType, lane));
            default:
              return cloneDeep(sourceValue);
          }
        }
      });
    } else {
      // loop through properties to perform a deep copy of properties
      mergeWith(this, mlpaJobApi, (objectValue, sourceValue, key, object, source) => {
        if (has(source, key) && typeof objectValue !== typeof (Function)) {
          switch (key) {
            // Add linked jobs
            case 'linkedMlpaJobs':

              return sourceValue.map(job => new MlpaJob(job));
            // Add lanes
            case 'mlpaLanes':
              return sourceValue.map((lane: MlpaLaneApi, index) => createLaneFromApi(lane.type, lane));
            default:
              return cloneDeep(sourceValue);
          }
        }
      });

      // Linked job's id is not cloned. Hence manually set the Job Id
      if (!this.id && !!mlpaJobApi && !!mlpaJobApi.id) {
        this.id = mlpaJobApi.id;
      }
    }
  }
  static cloneFromApi(mlpaJobApi: MlpaJobApi): MlpaJob {
    const mlpaJob = new MlpaJob(mlpaJobApi);

    delete mlpaJob.id;
    delete mlpaJob.uniqueId;
    mlpaJob.isGenerated = false;
    mlpaJob.isSubmitted = false;

    // Add lanes
    mlpaJob._mlpaLanes = [];
    mlpaJobApi.mlpaLanes.forEach((lane, index) => {
      mlpaJob._mlpaLanes.push(cloneLaneFromApi(index + 1, lane));
    });

    // Add linked jobs
    mlpaJob.linkedMlpaJobs = [];
    mlpaJobApi.linkedMlpaJobs.forEach(job => {
      job.parentMlpaJobId = mlpaJob.id;
      mlpaJob.linkedMlpaJobs.push(MlpaJob.cloneFromApi(job));
    });

    return mlpaJob;
  }

  static createCloneFromJob(mlpaJob: MlpaJob): MlpaJob {
    const newMlpaJob = {} as MlpaJob;
    const firstLane = 0;
    const lastLane = mlpaJob.mlpaLanes.length - 1;
    deepCopyProperties(newMlpaJob, mlpaJob);

    delete newMlpaJob.id;
    delete newMlpaJob.uniqueId;
    newMlpaJob.parentClonedMlpaJobId = mlpaJob.id;
    newMlpaJob.isGenerated = false;
    newMlpaJob.isSubmitted = false;

    newMlpaJob.linkedMlpaJobs.forEach(job => {
      job.parentMlpaJobId = null;
    });

    // Recalculate lane widths
    newMlpaJob._mlpaLanes.forEach((lane, index) => {
      if (lane.laneType === MlpaLaneType.SmartLine) {
        newMlpaJob._mlpaLanes[firstLane].multiPageNumberUp = newMlpaJob._mlpaLanes[firstLane + 1].designNumberUp;
        newMlpaJob._mlpaLanes[lastLane].multiPageNumberUp = newMlpaJob._mlpaLanes[lastLane - 1].designNumberUp;
      }

      newMlpaJob._mlpaLanes[index].recalculateWidth(newMlpaJob.paperWidthInInches, newMlpaJob.marginWidthInInches, newMlpaJob._mlpaLanes);
    });

    return newMlpaJob;
  }

  buildName(): string {
    // Auto-generate a name from the list of mlpa orders
    let name = '';
    this.mlpaLanes.forEach(o => {
      if (o.laneType === MlpaLaneType.Graphic) {
        name += `${o.designNumber.trim()}-`;
      }
    });
    // Remove trailing '-' character from the generated name
    name = name.substring(0, name.length - 1);

    // Truncates a string to 25 characters
    return name.substring(0, 25);
  }

  copyLanesFromView(mlpaJobView: MlpaJobView): void {
    if (!!mlpaJobView.mlpaLanes && mlpaJobView.mlpaLanes.length > 0) {
      this._mlpaLanes = [];
      mlpaJobView.mlpaLanes.forEach((lane, index) => {
        this._mlpaLanes.push(createLaneFromView(lane.laneType, lane));
      });
    }

    if (!!mlpaJobView.linkedMlpaJobs && mlpaJobView.linkedMlpaJobs.length > 0) {
      mlpaJobView.linkedMlpaJobs.forEach((jobs, jobIndex) => {
        this.linkedMlpaJobs[jobIndex]._mlpaLanes = [];
        jobs.mlpaLanes.forEach((lane, laneIndex) => {
          this.linkedMlpaJobs[jobIndex]._mlpaLanes.push(createLaneFromView(lane.laneType, lane));
        });
      });
    }

  }
  getMaxLaneLength(): number {
    let maxLengthInInches = 0;
    this.mlpaLanes.forEach(o => {
      if ((o.laneType === MlpaLaneType.Graphic || o.laneType === MlpaLaneType.Stress) && o.totalLengthInInches > maxLengthInInches) {
        maxLengthInInches = o.totalLengthInInches;
      }
    });
    return maxLengthInInches;
  }

  getMaxLaneIndex(): number {
    let maxLengthInInches = 0;
    let maxIndex = 0;
    this.mlpaLanes.forEach((o, index) => {
      if (o.laneType === MlpaLaneType.Graphic && o.totalLengthInInches > maxLengthInInches) {
        maxLengthInInches = o.totalLengthInInches;
        maxIndex = index;
      }
    });
    return maxIndex;
  }

  updateWebWeaveQuantity(mlpaJob: MlpaJob, mlpaLane: MlpaLane, maxLengthInInches: number, lineBreakLengthInInches: number): number {
    const index = _.findIndex(this.mlpaLanes, mlpaLane);
    const dimension = mlpaJob._mlpaLanes[index].isTransposed ? mlpaLane.designWidthInInches : mlpaLane.designLengthInInches;

    if (mlpaLane.designNumberOfPages > 1) {
      return mlpaLane.totalLengthInInches + lineBreakLengthInInches;
    } else {
      // Count the number of whole web weave PDFs that fit in the specified length
      const numberOfPdfs = Math.ceil(maxLengthInInches / dimension);
      // Get the total length of this web weave + line break
      const totalLengthInInches = (dimension * numberOfPdfs) + lineBreakLengthInInches;
      // Return the total length
      return totalLengthInInches;
    }
  }
  updateWebWeaves(mlpaJob: MlpaJob, maxLengthInInches: number, lineBreakLengthInInches: number): number {

    // Get indexes for first and last web weaves
    const firstIndex = 0;
    const lastIndex = this.mlpaLanes.length - 1;

    // Update each web weave's quantity and get total lengths
    const firstWebWeaveLengthInInches = this.updateWebWeaveQuantity(mlpaJob, this.mlpaLanes[firstIndex], maxLengthInInches, lineBreakLengthInInches);
    const lastWebWeaveLengthInInches = this.updateWebWeaveQuantity(mlpaJob, this.mlpaLanes[lastIndex], maxLengthInInches, lineBreakLengthInInches);

    // Return the max length of the web weave lanes
    return firstWebWeaveLengthInInches > lastWebWeaveLengthInInches
      ? firstWebWeaveLengthInInches
      : lastWebWeaveLengthInInches;
  }

  createLinkedQrCodeJob(jobSettings: MlpaJobSettings): MlpaJob {
    const qrCodeLengthInInches = 24;
    const linkedJob = new MlpaJob();
    linkedJob.parentMlpaJobId = this.id;
    linkedJob.name = 'QrCode';
    linkedJob.assetId = jobSettings.asset.id;
    linkedJob.paperTypeId = jobSettings.paperType.id;
    linkedJob.paperWidthInInches = jobSettings.paperWidthInInches;
    linkedJob.totalLengthInInches = qrCodeLengthInInches;
    linkedJob.totalWidthInInches = this.totalWidthInInches;
    linkedJob.wasteLengthInInches = 0;
    linkedJob.wasteWidthInInches = 0;
    linkedJob.marginWidthInInches = jobSettings.marginWidthInInches;
    linkedJob.lineBreakLengthInInches = 0;

    // Create 1st lane as webweave, set color profile from parent job's first graphic lane
    const webWeaveProperties: LaneProperties = {
      initialProperties: {
        laneNumber: 1,
        quantity: 1,
        designLengthInInches: qrCodeLengthInInches,
        designNumberOfPages: 1,
        colorProfileId: this.mlpaLanes[1].colorProfileId,
        colorProfileName: this.mlpaLanes[1].colorProfileName,
        isForQrCode: true,
        totalLengthInInches: qrCodeLengthInInches
      } as InitialLaneProperties
    };
    const webWeave1 = new WebWeave(webWeaveProperties);

    // Get the width of parent job's minus the first and last lane
    // Account for max paper width in case the parent job has no web weave
    const parentJobWidth = this.totalWidthInInches - this.mlpaLanes[0].designWidthInInches - this.mlpaLanes[this.mlpaLanes.length - 1].designWidthInInches;
    const maxQrCodeWidth = linkedJob.paperWidthInInches - (webWeave1.designWidthInInches * 2);
    const qrCodeDesignWidthInInches = parentJobWidth < maxQrCodeWidth ? parentJobWidth : maxQrCodeWidth;

    // Create 2nd lane as QR code, set color profile from parent job's first graphic lane
    const laneProperties = {
      initialProperties: {
        laneNumber: 2,
        quantity: 1,
        designLengthInInches: qrCodeLengthInInches,
        designNumberOfPages: 1,
        designWidthInInches: qrCodeDesignWidthInInches,
        colorProfileId: this.mlpaLanes[1].colorProfileId,
        colorProfileName: this.mlpaLanes[1].colorProfileName
      } as InitialLaneProperties,
    } as LaneProperties;
    const qrCode = new QrCode(laneProperties);

    // Create 3rd lane as webweave, set color profile from parent job's first graphic lane
    const webWeaveProperties2: LaneProperties = {
      initialProperties: {
        laneNumber: 3,
        quantity: 1,
        designLengthInInches: qrCodeLengthInInches,
        designNumberOfPages: 1,
        colorProfileId: this.mlpaLanes[1].colorProfileId,
        colorProfileName: this.mlpaLanes[1].colorProfileName,
        isForQrCode: true,
        totalLengthInInches: qrCodeLengthInInches
      } as InitialLaneProperties
    };
    const webWeave2 = new WebWeave(webWeaveProperties2);

    // Add the lanes to the linked job
    linkedJob.mlpaLanes.push(webWeave1);
    linkedJob.mlpaLanes.push(qrCode);
    linkedJob.mlpaLanes.push(webWeave2);

    // Recalculate first and last lane widths
    linkedJob.mlpaLanes[0].recalculateWidth(jobSettings.paperWidthInInches, jobSettings.marginWidthInInches, linkedJob.mlpaLanes);
    linkedJob.mlpaLanes[linkedJob.mlpaLanes.length - 1].recalculateWidth(jobSettings.paperWidthInInches, jobSettings.marginWidthInInches, linkedJob.mlpaLanes);
    // Return QR code job
    return linkedJob;
  }

  createLinkedStressJob(jobSettings: MlpaJobSettings): MlpaJob {
    const linkedJob = new MlpaJob();
    linkedJob.parentMlpaJobId = this.id;
    linkedJob.name = 'Stress File';
    linkedJob.assetId = jobSettings.asset.id;
    linkedJob.paperTypeId = jobSettings.paperType.id;
    linkedJob.paperWidthInInches = jobSettings.paperWidthInInches;
    linkedJob.totalLengthInInches = 1;
    linkedJob.totalWidthInInches = jobSettings?.paperWidthInInches;
    linkedJob.wasteLengthInInches = 0;
    linkedJob.wasteWidthInInches = 0;
    linkedJob.marginWidthInInches = 0;
    linkedJob.lineBreakLengthInInches = 0;

    // Get the width of parent job's minus the first and last lane
    // Account for max paper width in case the parent job has no web weave
    const stressDesignWidthInInches = jobSettings?.paperWidthInInches;

    // Create 2nd lane as stress file, set color profile from parent job's first graphic lane
    const laneProperties = {
      initialProperties: {
        laneNumber: 2,
        designLengthInInches: 24,
        designNumberOfPages: 1,
        designWidthInInches: stressDesignWidthInInches,
        colorProfileId: this.mlpaLanes[1].colorProfileId,
        colorProfileName: this.mlpaLanes[1].colorProfileName,
      } as InitialLaneProperties,
    } as LaneProperties;
    const chevron = new Stress(laneProperties);

    // Create 1st lane as webweave, set color profile from parent job's first graphic lane
    const emptyProperties1: LaneProperties = {
      initialProperties: {
        laneNumber: 1,
        quantity: 1,
        designLengthInInches: 100,
        designNumberOfPages: 1,
        colorProfileId: this.mlpaLanes[1].colorProfileId,
        colorProfileName: this.mlpaLanes[1].colorProfileName,
        isForQrCode: false,
        totalLengthInInches: chevron.totalLengthInInches
      } as InitialLaneProperties
    };
    const empty1 = new EmptyLane(emptyProperties1);


    // Create 3rd lane as webweave, set color profile from parent job's first graphic lane
    const emptyProperties2: LaneProperties = {
      initialProperties: {
        laneNumber: 3,
        quantity: 1,
        designLengthInInches: 100,
        designNumberOfPages: 1,
        colorProfileId: this.mlpaLanes[1].colorProfileId,
        colorProfileName: this.mlpaLanes[1].colorProfileName,
        isForQrCode: false,
        totalLengthInInches: chevron.totalLengthInInches
      } as InitialLaneProperties
    };

    const empty2 = new EmptyLane(emptyProperties2);

    // Add the lanes to the linked job
    linkedJob.mlpaLanes.push(empty1);
    linkedJob.mlpaLanes.push(chevron);
    linkedJob.mlpaLanes.push(empty2);


    linkedJob.totalLengthInInches = 24 * chevron.quantity;

    // Recalculate first and last lane widths
    linkedJob.mlpaLanes[0].recalculateWidth(jobSettings.paperWidthInInches, jobSettings.marginWidthInInches, linkedJob.mlpaLanes);
    linkedJob.mlpaLanes[linkedJob.mlpaLanes.length - 1].recalculateWidth(jobSettings.paperWidthInInches, jobSettings.marginWidthInInches, linkedJob.mlpaLanes);

    // Return chevron job
    return linkedJob;
  }

  optimizeAll(lengthInInches): void {
    // This is called from the parent when optimizing all mlpa orders up or down
    this._mlpaLanes.forEach((lane, index) => {
      this._mlpaLanes[index].editLength(lengthInInches / 12);
    });
  }

  getJobType(): MlpaLaneType {
    return this.mlpaLanes[1]?.laneType;
  }
}
