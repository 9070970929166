import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MlpaJobView } from '../../../../pages/home/view-mlpa-jobs/mlpa-job-view';

@Component({
  selector: 'pkg-mlpa-status-numbered-icon',
  templateUrl: './mlpa-status-numbered-icon.component.html',
  styleUrls: ['./mlpa-status-numbered-icon.component.scss']
})
export class MlpaStatusNumberedIconComponent implements OnInit {
  @Input() toggleState = false;
  @Input() job: MlpaJobView;
  @Input() index: number;
  @Output() toggleEmitter: EventEmitter<boolean> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
    this.toggleState = this.toggleState != null ? this.toggleState : false;
  }

  toggleLane(): void {
    this.toggleState = !this.toggleState;
    this.toggleEmitter.emit(this.toggleState);
  }
}
