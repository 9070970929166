<div class="mlpa-lane-info" *ngFor="let info of mlpaLanes; let i = index">
  <div class="mlpa-lane-info__color-label lane--{{info.laneNumber}}"
    [ngClass]="{'empty-lane-label': info.laneType === 10}"></div>
  <ng-container>
    <div class="design-section" [ngClass]="{'design-edit-mode': editMode}">
      <div class="design-section--top">
        <div class="lane-title">
          <div class="lane-number-container" (click)="laneToggle[i] = !laneToggle[i]">
            <span *ngIf="!info.isNewJob" class="lane-number left">{{info.laneNumber}}</span>
            <span *ngIf="info.isNewJob" class="lane-number left"></span>
            <span class="lane-number right">
              <span *ngIf="!info.isNewJob" [ngClass]="{'collapsed-lane': laneToggle[i]}"
                title="{{info.status}} {{info.errors}}" class="material-icons status-icon {{info.statusClass}}"></span>
              <span *ngIf="info.isNewJob" [ngClass]="{'collapsed-lane': laneToggle[i]}" title="Collapse"
                class="status-icon lane--{{info.laneNumber}}" [style.display]="'inline-block'"
                [style.line-height.px]="28">
                {{info?.laneNumber}}
              </span>
            </span>
          </div>
          <span class="title" [ngClass]="{'title-edit-mode' : editMode}"
            [title]="info?.designDescription">{{info?.designDescription}}</span>
        </div>

        <ng-container *pkgHasRole="[role.Scheduler, role.Admin, role.Support, role.Operator, role.Structure]">
          <div *ngIf="editMode && mlpaLanes[1]?.laneType !== mlpaLaneType.Stress" class="status-edit">
            <div *ngIf="!info.isWebWeave" class="mlpa-lane-rotate button material-icons status-chip"
              [ngClass]="info.rotationInDegrees === 270 ? 'selected' : 'unselected'" title="Rotated 180 deg"
              (click)="toggleRotation(info)">
              <div class="mlpa-lane-rotate__icon" [ngClass]="getRotateClass(info)">
                <div class="mlpa-lane-rotate__icon-letter" [ngClass]="{'selected': info.rotationInDegrees === 270}">R
                  <i *ngIf="info.rotationInDegrees === 270" class="material-icons rotate-270-icon">&#xE419;</i>
                </div>
              </div>
            </div>

            <span *pkgHasRole="[role.Scheduler, role.Admin, role.Support]; and info.isTransposed && !info.isWebWeave"
              class="button material-icons status-chip selected status-chip--transposed" title="Transposed"
              (click)="toggleTransposed(info)"></span>
            <span *pkgHasRole="[role.Scheduler, role.Admin, role.Support]; and !info.isTransposed && !info.isWebWeave"
              class="button material-icons status-chip unselected status-chip--shuffle" title="Transposed"
              (click)="toggleTransposed(info)"></span>

            <span
              *pkgHasRole="[role.Scheduler, role.Admin, role.Support]; and !info.isProcessingEnabled && !info.isWebWeave"
              class="button material-icons selected status-chip status-chip--cloud-off"
              title="Disable Preprocessing of PDF" (click)="togglePreprocessing(info)"></span>
            <span
              *pkgHasRole="[role.Scheduler, role.Admin, role.Support]; and info.isProcessingEnabled && !info.isWebWeave"
              class="button material-icons unselected status-chip status-chip--cloud_upload"
              title="Disable Preprocessing of PDF" (click)="togglePreprocessing(info)"></span>

            <span
              *pkgHasRole="[role.Scheduler, role.Admin, role.Support]; and !info.colorCorrectionInfo.isColorCorrectionEnabled && !info.isWebWeave"
              class="button material-icons unselected status-chip status-chip--format_paint"
              title="Enable Color Correction of PDF" (click)="toggleColorCorrection(info)"></span>
            <span
              *pkgHasRole="[role.Scheduler, role.Admin, role.Support]; and info.colorCorrectionInfo.isColorCorrectionEnabled && !info.isWebWeave"
              class="button material-icons selected status-chip status-chip--format_paint_selected"
              title="Disable Color Correction of PDF" (click)="toggleColorCorrection(info)"></span>

            <ng-container
              *pkgHasRole="[role.Scheduler, role.Admin, role.Support, role.Structure]; and info.isWebWeave && mlpaLanes[1]?.laneType !== mlpaLaneType.Stress">
              <span class="button material-icons status-chip status-chip--cancel_presentation"
                [ngClass]="info?.laneType == 10 ? 'selected' : 'unselected'" title="Empty"
                (click)="selectWebWeave(10)"></span>

              <span class="button material-icons status-chip status-chip--solidline-job"
                [ngClass]="info?.laneType == 40 ? 'selected' : 'unselected'" title="Solid Line"
                (click)="selectWebWeave(40)">
                <svg class="barcode-icon" version="1.1" id="web-weave" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 10"
                  style="enable-background:new 0 0 30 10;" xml:space="preserve">
                  <use href="#solid-line"></use>
                </svg>
              </span>

              <span *ngIf="!jobSettings?.hasQrCode" class="button material-icons status-chip status-chip--smartline-job"
                [ngClass]="info?.laneType == 30 ? 'selected' : 'unselected'" title="Smart Line"
                (click)="selectWebWeave(30)">
                <svg class="barcode-icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 10"
                  style="enable-background:new 0 0 30 10;" xml:space="preserve">
                  <use href="#smart-line"></use>
                </svg>
              </span>
            </ng-container>
          </div>
        </ng-container>

        <div class="status-display">

          <div *ngIf="!editMode" class="status-readonly">
            <span *ngIf="info.rotationInDegrees === 270 && !info.isWebWeave"
              class="material-icons status-chip status-chip--rotated" title="Rotated"></span>
            <span *ngIf="info.isTransposed && !info.isWebWeave"
              class="material-icons status-chip status-chip--transposed" title="Transposed"></span>
            <span *ngIf="!info.isProcessingEnabled && !info.isWebWeave"
              class="material-icons status-chip status-chip--cloud-off" title="Disable Preprocessing of PDF"></span>
            <span *ngIf="info.colorCorrectionInfo.isColorCorrectionEnabled && !info.isWebWeave"
              class="material-icons status-chip status-chip--format_paint_selected"
              title="Color Correction enabled"></span>

            <ng-container *ngIf="info.isWebWeave">
              <span *ngIf="info?.laneType == 30 && !editMode"
                class="material-icons status-chip status-chip--smartline-job" title="Smart Line">
                <svg class="barcode-icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 10"
                  style="enable-background:new 0 0 30 10;" xml:space="preserve">
                  <use href="#smart-line"></use>
                </svg>
              </span>
              <span *ngIf="info?.laneType == 40 && !editMode"
                class="material-icons status-chip status-chip--solidline-job" title="Web Weave">
                <svg class="barcode-icon" version="1.1" id="web-weave" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 10"
                  style="enable-background:new 0 0 30 10;" xml:space="preserve">
                  <use href="#solid-line"></use>
                </svg>
              </span>
            </ng-container>

          </div>
          <div *ngIf="info?.designNumberOfPages > 1" class="multipage--status">
            <span class="material-icons status-chip status-chip--multipage" title="Multipage"></span>
          </div>
        </div>

        <div class="button-group">
          <button *pkgHasRole="[role.Admin, role.Scheduler, role.Support, role.Structure]"
            [style.visibility]="info.laneType === thumbnailType.Graphic && info.isNewJob ? 'visibile' : 'hidden'"
            class="button button--red button--small button-fab" (click)="deleteLane(info)" title="Delete Lane">
            <span class="material-icons order-icon">&#xE92B;</span>
          </button>
          <button [style.visibility]="info.laneType === thumbnailType.Graphic ? 'visibile' : 'hidden'"
            class="button button--muted-lightBlue button--small button-fab" [disabled]="info.isCurrentPreviewButton"
            (click)="setPreview(info)" title="Preview">
            <span class="material-icons order-icon">&#xE43F;</span>
          </button>
        </div>
      </div>
      <div class="design-section--center">
        <div class="content-body-container design-body" [ngClass]="{'collapsed-body' : laneToggle[i]}">
          <div class="content-body-left">
            <ng-container
              *pkgHasRole="[role.Viewer, role.Scheduler, role.Operator, role.Admin, role.Support, role.Structure]">
              <div class="web-weave-section" *ngIf="info.isWebWeave">
                <div class="content-body">
                  <div class="content">
                    <span class="title">Color Profile</span>
                    <span *ngIf="info?.colorProfileName && info.isWebWeave && !editMode"
                      class="info">{{info?.colorProfileName}}</span>
                    <span *ngIf="!info?.colorProfileName && (!editMode || info.isWebWeave)" class="info">N/A</span>
                    <span *ngIf="info?.colorProfileName && info.isWebWeave && editMode && i === 0"
                      class="info">{{mlpaLanes[1]?.colorProfileName}}</span>
                    <span *ngIf="info?.colorProfileName && info.isWebWeave && editMode && i === mlpaLanes.length - 1"
                      class="info">{{mlpaLanes[i - 1]?.colorProfileName}}</span>
                    <div *ngIf="(info.isNewJob && editMode) && !info.isWebWeave" class="field gutter">
                      <pkg-search-auto-complete [disableLabel]="true" [isDropDown]="true" [disableType]="true"
                        [details]="getColorProfileDetails(info)"
                        (detailsEventEmitter)="selectColorProfile($event, info)"></pkg-search-auto-complete>
                      <div class="settings error"
                        [hidden]="selectedItemExistsInOptions(getColorProfileDetails(info).textValue, getColorProfileDetails(info))">
                        Select a valid color profile. </div>
                    </div>
                  </div>
                  <div class="content" *ngIf="jobSettings?.asset?.assetType === assetTypes.T1195 || assetType === assetTypes.T1195">
                    <span *pkgHasRole="[role.Admin, role.Scheduler, role.Support, role.Structure];"
                      class="title" >Print Mode</span>
                    <span class="info">{{printMode}}</span>
                  </div>
                  <div class="content">
                    <ng-container *ngIf="info?.designNumberOfPages === 1; else multipage">
                      <span class="title">Eaches</span>
                      <span *ngIf="!editMode || info.isWebWeave" class="info">{{info?.quantity | number}}</span>
                      <div *ngIf="(info.isNewJob && editMode) && !info.isWebWeave" class="field gutter">
                        <input [ngModel]="info.quantity" (ngModelChange)="editQuantity($event, info)"
                          [ngModelOptions]="{updateOn: 'blur'}" type="number" id="eaches" min="1">
                      </div>
                    </ng-container>
                    <ng-template #multipage>
                      <span class="title">Pages</span>
                      <span class="info">{{info?.designNumberOfPages | number}}</span>
                    </ng-template>
                  </div>
                  <div class="content">
                    <span class="title">Total Width (in)</span>
                    <span class="info">{{info?.displayTotalWidthInInches | inches }}</span>
                  </div>
                  <div class="content">
                    <span class="title">Total Length (ft)</span>
                    <span *ngIf="!editMode || info.isWebWeave" class="info">{{info?.lengthInFeet | feet }}</span>
                    <div *ngIf="(info.isNewJob && editMode) && !info.isWebWeave" class="field gutter">
                      <input [ngModel]="info.lengthInFeet" (ngModelChange)="editLength($event, info)"
                        [ngModelOptions]="{updateOn: 'blur'}" type="number" id="lengthInFeet" min="1">
                    </div>
                  </div>
                  <div class="content"
                    *ngIf="mlpaJobView?.lineBreakLengthInInches > 0 && info?.designNumberOfPages > 1">
                    <span title="Extra Web Weave" class="title">WW/LB (ft)</span>
                    <span class="info">{{getRemainingWebWeave(info.lengthInFeet) | feet }}</span>
                  </div>
                  <div class="content">
                    <span class="title">Design Width (in)</span>
                    <span class="info">{{info?.designWidthInInches | inches }}</span>
                  </div>
                  <div class="content">
                    <span class="title">Design Length (in)</span>
                    <span class="info">{{info?.designLengthInInches | inches }}</span>
                  </div>
                  <div class="content">
                    <span class="title">Pdf Size</span>
                    <span class="info">{{info?.pdfSizeNumber}} {{info?.pdfSizeUnit}}</span>
                  </div>

                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="!info.isWebWeave">
              <div class="progress-bar-container-outer" *ngIf="info.colorCorrectionInfo.isColorCorrectionProcessing">
                <div class="progress-bar-container-inner"
                  title="{{info.colorCorrectionInfo.colorCorrectionProcessingPercentage * 100}}%">
                  <div class="progress-bar"
                    [style.width.%]="info.colorCorrectionInfo.colorCorrectionProcessingPercentage * 100"> </div>
                </div>
              </div>
              <div class="content-body">
                <div *ngIf="!isSpecial" class="content">
                  <span class="title">Program Number</span>
                  <span *ngIf="!info.isWebWeave && info?.programNumber"
                    class="info highlight">{{info?.programNumber}}</span>
                  <span *ngIf="info.isWebWeave || !info?.programNumber" class="info">N/A</span>
                </div>
                <div *ngIf="!isSpecial" class="content">
                  <span class="title">Setup Number</span>
                  <span *ngIf="!info.isWebWeave && info?.setupNumber"
                    class="info highlight">{{info?.setupNumber}}</span>
                  <span *ngIf="info.isWebWeave || !info?.setupNumber" class="info">N/A</span>
                </div>
                <div *ngIf="!isSpecial" class="content">
                  <span class="title">Order Number</span>
                  <span *ngIf="!info.isWebWeave && info?.orderNumber"
                    class="info highlight">{{info?.orderNumber}}</span>
                  <span *ngIf="info.isWebWeave || !info?.orderNumber" class="info">N/A</span>
                </div>
                <div class="content">
                  <span class="title">Design Number</span>
                  <span *ngIf="!info.isWebWeave && info?.designNumber"
                    class="info highlight">{{info?.designNumber}}</span>
                  <span *ngIf="info.isWebWeave || !info?.designNumber" class="info">N/A</span>
                </div>
                <div *ngIf="!isSpecial" class="content">
                  <span class="title">Total Width (in)</span>
                  <span class="info">{{info?.displayTotalWidthInInches | inches }}</span>
                </div>
                <div class="content">
                  <span class="title">Design Width (in)</span>
                  <span
                    class="info">{{info?.isTransposed ? (info?.designLengthInInches | inches ) : (info.designWidthInInches | inches )}}</span>
                </div>
                <div class="content" [ngClass]="{'special-column color-profile': isSpecial}">
                  <span class="title" [ngClass]="{'edit-title' : editMode && !isStressJob }">Color Profile</span>
                  <span *ngIf="info?.colorProfileName && (!editMode || info.isWebWeave || isStressJob)"
                    class="info">{{info?.colorProfileName}}</span>
                  <span *ngIf="!info?.colorProfileName && (!editMode || info.isWebWeave || isStressJob)"
                    class="info">N/A</span>
                  <div [hidden]="isStressJob" *ngIf="(info.isNewJob && editMode) && !info.isWebWeave"
                    class="field gutter">
                    <pkg-search-auto-complete [disableLabel]="true" [isDropDown]="true" [disableType]="true"
                      [details]="getColorProfileDetails(info)" (detailsEventEmitter)="selectColorProfile($event, info)">
                    </pkg-search-auto-complete>
                    <div class="settings error"
                      [hidden]="selectedItemExistsInOptions(getColorProfileDetails(info).textValue, getColorProfileDetails(info))">
                      Select a valid color profile. </div>
                  </div>
                </div>
                <div class="content" *ngIf="jobSettings?.asset?.assetType === assetTypes.T1195 || assetType === assetTypes.T1195">
                  <span *pkgHasRole="[role.Admin, role.Scheduler, role.Support, role.Structure];"
                    class="title" >Print Mode</span>
                  <span class="info">{{printMode}}</span>
                </div>
                <div class="content" [ngClass]="{'special-column eaches': isSpecial}">
                  <ng-container *ngIf="info?.designNumberOfPages === 1; else multipage">
                    <span class="title" [ngClass]="{'edit-title' : editMode}">Eaches</span>
                    <span *ngIf="!editMode || info.isWebWeave" class="info">{{info?.quantity | number}}</span>
                    <div *ngIf="(info.isNewJob && editMode) && !info.isWebWeave" class="field gutter">
                      <input [ngModel]="info.quantity" (ngModelChange)="editQuantity($event, info)"
                        [ngModelOptions]="{updateOn: 'blur'}" type="number" id="eaches" min="1">
                    </div>
                  </ng-container>
                  <ng-template #multipage>
                    <span class="title">Pages</span>
                    <span class="info">{{info?.designNumberOfPages | number}}</span>
                  </ng-template>
                </div>
                <!-- Number Up -->
                <div class="content">
                  <ng-container *pkgHasRole="[role.Admin, role.Scheduler, role.Support]; or !editMode && !isSpecial">
                    <!-- only show this input style title if not a multipage -->
                    <span class="title"
                      [ngClass]="{'edit-title' : info?.designNumberOfPages < 2 && editMode && !isStressJob}">Number
                      Up</span>
                    <span *ngIf="info?.designNumberOfPages > 1 || !editMode || info.isWebWeave || isStressJob"
                      class="info">{{info?.designNumberUp}}</span>
                  </ng-container>

                  <ng-container *pkgHasRole="[role.Operator, role.Viewer]; and editMode">
                    <span class="title">Number Up</span>
                    <span class="info">{{info?.designNumberUp}}</span>
                  </ng-container>

                  <div
                    *pkgHasRole="[role.Scheduler, role.Admin, role.Support]; and (info.isNewJob && this.editMode && info?.designNumberOfPages < 2) && !info.isWebWeave && !isStressJob"
                    class="field gutter">
                    <input [ngModel]="info.designNumberUp" (ngModelChange)="setNumberUp($event, info)"
                      [ngModelOptions]="{updateOn: 'blur'}" type="number" id="numberUp" min="1">
                  </div>
                </div>
                <!-- Ribbons -->
                <div class="content">
                  <span class="title">Ribbons</span>
                  <span class="info">{{info?.numberOfRibbons}}</span>
                </div>

                <!-- Total Length -->
                <div *ngIf="!isSpecial" class="content">
                  <span class="title"
                    [ngClass]="{'edit-title' : info?.designNumberOfPages < 2 && editMode && !isStressJob}">Total Length
                    (ft)</span>
                  <span *ngIf="info?.designNumberOfPages > 1 || !editMode || info.isWebWeave || isStressJob"
                    class="info">{{info?.lengthInFeet | feet }}</span>

                  <div
                    *pkgHasRole="[role.Scheduler, role.Admin, role.Support, role.Operator]; and (info.isNewJob && this.editMode && info?.designNumberOfPages < 2) && !info.isWebWeave && !isStressJob"
                    class="field gutter">
                    <input [ngModel]="info.lengthInFeet" (ngModelChange)="editLength($event, info)"
                      [ngModelOptions]="{updateOn: 'blur'}" type="number" id="lengthInFeet" min="1">
                  </div>
                </div>
                <div class="content">
                  <span class="title">Design Length (in)</span>
                  <span
                    class="info">{{info?.isTransposed ? (info?.designWidthInInches | inches ) : (info?.designLengthInInches | inches) }}</span>
                </div>
                <div class="content" [ngClass]="{'special-column pdf': isSpecial}">
                  <span class="title">Pdf Size</span>
                  <span class="info">{{info?.pdfSizeNumber}} {{info?.pdfSizeUnit}}</span>
                </div>
              </div>
              <ng-container *ngIf="info.colorCorrectionInfo.isColorCorrectionEnabled">
                <div class="characterization-values-header">
                  Color Management
                  <small>Lane Characterization Values</small>
                </div>
                <div class="content-body">
                  <div class="content tooltip" id="ink-weight-tooltip-wrapper">
                    <span class="title" [ngClass]="{'edit-title' : editMode && !isStressJob }">Ink Weight
                      <i *ngIf="info?.colorCorrectionInfo?.inkWeight && info?.colorCorrectionInfo?.inkWeight!==info?.definedColorCorrectionInfo?.inkWeight" class="material-icons warning-icon">&#xf083;</i>
                    </span>
                    <span *ngIf="info?.colorCorrectionInfo.inkWeight && (!editMode || info.isWebWeave || isStressJob)" class="info">
                      {{info?.colorCorrectionInfo.inkWeight}}
                    </span>
                    <span *ngIf="!info?.colorCorrectionInfo.inkWeight && (!editMode || info.isWebWeave || isStressJob)" class="info">
                      N/A
                    </span>
                    <div [hidden]="isStressJob" *ngIf="(info.isNewJob && editMode) && !info.isWebWeave" class="field gutter">
                      <pkg-search-auto-complete [disableLabel]="true" [isDropDown]="true" [disableType]="true"
                        [details]="getInkWeightDetails(info)" (detailsEventEmitter)="selectInkWeight($event, info)">
                      </pkg-search-auto-complete>
                      <div class="settings error"
                        [hidden]="selectedItemExistsInOptions(getInkWeightDetails(info).textValue, getInkWeightDetails(info))">
                        Select a valid ink weight value. </div>
                    </div>
                    <div *ngIf="info?.colorCorrectionInfo?.inkWeight && info?.colorCorrectionInfo?.inkWeight!==info?.definedColorCorrectionInfo?.inkWeight" id="ink-weight-tooltip" class="tooltiptext">
                      <span class="tooltip-info tooltip-content border-style">
                        Default value: {{info?.definedColorCorrectionInfo?.inkWeight ? info?.definedColorCorrectionInfo?.inkWeight : 'Not Set' }}
                      </span>
                      <span class="tooltip-info highlight tooltip-content">
                        New value: {{info?.colorCorrectionInfo?.inkWeight}}
                      </span>
                    </div>
                  </div>
                  <div class="content tooltip" id="tac-tooltip-wrapper">
                    <span class="title" [ngClass]="{'edit-title' : editMode && !isStressJob }">Total Area Coverage
                      <i *ngIf="info?.colorCorrectionInfo?.totalAreaCoverage && info?.colorCorrectionInfo?.totalAreaCoverage!==info?.definedColorCorrectionInfo?.totalAreaCoverage" class="material-icons warning-icon">&#xf083;</i>
                    </span>
                    <span *ngIf="info?.colorCorrectionInfo.totalAreaCoverage && (!editMode || info.isWebWeave || isStressJob)" class="info">
                      {{info?.colorCorrectionInfo.totalAreaCoverage}}
                    </span>
                    <span *ngIf="!info?.colorCorrectionInfo.totalAreaCoverage && (!editMode || info.isWebWeave || isStressJob)" class="info">
                      N/A
                    </span>
                    <div [hidden]="isStressJob" *ngIf="(info.isNewJob && editMode) && !info.isWebWeave" class="field gutter">
                      <pkg-search-auto-complete [disableLabel]="true" [isDropDown]="true" [disableType]="true"
                        [details]="getTotalAreaCoverageDetails(info)" (detailsEventEmitter)="selectTotalAreaCoverage($event, info)">
                      </pkg-search-auto-complete>
                      <div class="settings error"
                        [hidden]="selectedItemExistsInOptions(getTotalAreaCoverageDetails(info).textValue, getTotalAreaCoverageDetails(info))">
                        Select a valid total area coverage. </div>
                    </div>
                    <div *ngIf="info?.colorCorrectionInfo?.totalAreaCoverage && info?.colorCorrectionInfo?.totalAreaCoverage!==info?.definedColorCorrectionInfo?.totalAreaCoverage" id="tac-tooltip" class="tooltiptext">
                      <span class="tooltip-info tooltip-content border-style">
                        Default value: {{info?.definedColorCorrectionInfo?.totalAreaCoverage ? info?.definedColorCorrectionInfo?.totalAreaCoverage : 'Not Set' }}
                      </span>
                      <span class="tooltip-info highlight tooltip-content">
                        New value: {{info?.colorCorrectionInfo?.totalAreaCoverage}}
                      </span>
                    </div>
                  </div>
                  <div class="content tooltip" id="print-mode-tooltip-wrapper">
                    <span class="title">Print Mode
                      <i *ngIf="info?.colorCorrectionInfo?.printMode && info?.colorCorrectionInfo?.printMode!==info?.definedColorCorrectionInfo?.printMode" class="material-icons warning-icon">&#xf083;</i>
                    </span>
                    <span class="info">{{printMode}}
                    </span>
                    <div *ngIf="info?.colorCorrectionInfo?.printMode && info?.colorCorrectionInfo?.printMode!==info?.definedColorCorrectionInfo?.printMode" id="print-mode-tooltip" class="tooltiptext">
                      <span class="tooltip-info tooltip-content border-style">
                        Default value: {{info?.definedColorCorrectionInfo?.printMode ? info?.definedColorCorrectionInfo?.printMode : 'Not Set' }}
                      </span>
                      <span class="tooltip-info highlight tooltip-content">
                        New value: {{info?.colorCorrectionInfo?.printMode}}
                      </span>
                    </div>
                  </div>
                  <div class="content tooltip" id="ink-colors-tooltip-wrapper">
                    <span class="title">Ink Colors
                      <i *ngIf="info?.colorCorrectionInfo?.inkColors && info?.colorCorrectionInfo?.inkColors!==info?.definedColorCorrectionInfo?.inkColors" class="material-icons warning-icon">&#xf083;</i>
                    </span>
                    <span class="info">{{info?.colorCorrectionInfo.inkColors}}
                    </span>
                    <div *ngIf="info?.colorCorrectionInfo?.inkColors && info?.colorCorrectionInfo?.inkColors!==info?.definedColorCorrectionInfo?.inkColors" id="ink-colors-tooltip" class="tooltiptext">
                      <span class="tooltip-info tooltip-content border-style">
                        Default value: {{info?.definedColorCorrectionInfo?.inkColors ? info?.definedColorCorrectionInfo?.inkColors : 'Not Set' }}
                      </span>
                      <span class="tooltip-info highlight tooltip-content">
                        New value: {{info?.colorCorrectionInfo?.inkColors}}
                      </span>
                    </div>
                  </div>
                  <div class="content tooltip" id="press-type-tooltip-wrapper">
                    <span class="title">Press Type
                      <i *ngIf="info?.colorCorrectionInfo?.pressType && info?.colorCorrectionInfo?.pressType!==info?.definedColorCorrectionInfo?.pressType" class="material-icons warning-icon">&#xf083;</i>
                    </span>
                    <span class="info">{{info?.colorCorrectionInfo.pressType}}
                    </span>
                    <div *ngIf="info?.colorCorrectionInfo?.pressType && info?.colorCorrectionInfo?.pressType!==info?.definedColorCorrectionInfo?.pressType" id="press-type-tooltip" class="tooltiptext">
                      <span class="tooltip-info tooltip-content border-style">
                        Default value: {{info?.definedColorCorrectionInfo?.pressType ? info?.definedColorCorrectionInfo?.pressType : 'Not Set' }}
                      </span>
                      <span class="tooltip-info highlight tooltip-content">
                        New value: {{info?.colorCorrectionInfo?.pressType}}
                      </span>
                    </div>
                  </div>
                  <div class="content tooltip" id="opv-tooltip-wrapper">
                    <span class="title">Over Print Varnish
                      <i *ngIf="info?.colorCorrectionInfo?.overPrintVarnish && info?.colorCorrectionInfo?.overPrintVarnish!==info?.definedColorCorrectionInfo?.overPrintVarnish" class="material-icons warning-icon">&#xf083;</i>
                    </span>
                    <span class="info">{{info?.colorCorrectionInfo.overPrintVarnish}}
                    </span>
                    <div *ngIf="info?.colorCorrectionInfo?.overPrintVarnish && info?.colorCorrectionInfo?.overPrintVarnish!==info?.definedColorCorrectionInfo?.overPrintVarnish" id="opv-tooltip" class="tooltiptext">
                      <span class="tooltip-info tooltip-content border-style">
                        Default value: {{info?.definedColorCorrectionInfo?.overPrintVarnish ? info?.definedColorCorrectionInfo?.overPrintVarnish : 'Not Set'}}
                      </span>
                      <span class="tooltip-info highlight tooltip-content">
                        New value: {{info?.colorCorrectionInfo?.overPrintVarnish}}
                      </span>
                    </div>
                  </div>
                  <div class="content tooltip" id="substrate-tooltip-wrapper">
                    <span class="title">Substrate
                      <i *ngIf="info?.colorCorrectionInfo?.substrateWeight && info?.colorCorrectionInfo?.substrateWeight!==info?.definedColorCorrectionInfo?.substrateWeight" class="material-icons warning-icon">&#xf083;</i>
                    </span>
                    <span class="info">{{info?.colorCorrectionInfo.substrateWeight}}
                    </span>
                    <div *ngIf="info?.colorCorrectionInfo?.substrateWeight && info?.colorCorrectionInfo?.substrateWeight!==info?.definedColorCorrectionInfo?.substrateWeight" id="substrate-tooltip" class="tooltiptext">
                      <span class="tooltip-info tooltip-content border-style">
                        Default value: {{info?.definedColorCorrectionInfo?.substrateWeight ? info?.definedColorCorrectionInfo?.substrateWeight : 'Not Set'}}
                      </span>
                      <span class="tooltip-info highlight tooltip-content">
                        New value: {{info?.colorCorrectionInfo?.substrateWeight}}
                      </span>
                    </div>
                  </div>
                  <div class="content tooltip" id="speed-tooltip-wrapper">
                    <span class="title">
                      Speed (fpm)
                      <i *ngIf="info?.colorCorrectionInfo?.speed && info?.colorCorrectionInfo?.speed!==info?.definedColorCorrectionInfo?.speed" class="material-icons warning-icon">&#xf083;</i>
                    </span>
                    <span class="info">
                      {{info?.colorCorrectionInfo.speed}}
                    </span>
                    <div *ngIf="info?.colorCorrectionInfo?.speed && info?.colorCorrectionInfo?.speed!==info?.definedColorCorrectionInfo?.speed" id="speed-tooltip" class="tooltiptext">
                      <span class="tooltip-info tooltip-content border-style">
                        Default value: {{info?.definedColorCorrectionInfo?.speed ? info?.definedColorCorrectionInfo?.speed : 'Not Set' }}
                      </span>
                      <span class="tooltip-info highlight tooltip-content">
                        New value: {{info?.colorCorrectionInfo?.speed}}
                      </span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="mlpa-lane__controls"
          *pkgHasRole="[role.Scheduler, role.Admin, role.Support, role.Structure]; and ((info.isNewJob && editMode) && !info.isWebWeave) && !laneToggle[i] && mlpaLanes.length > 3">
          <div class="move-controls">
            <button (click)="moveUp(info)" class="icon-button" type="button" title="Move MLPA Lane Up"
              style="margin-bottom: 6px;">
              <i class="material-icons">&#xE316;</i>
            </button>
            <button (click)="moveDown(info)" class="icon-button" type="button" title="Move MLPA Lane Down">
              <i class="material-icons">&#xE313;</i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<ng-container *ngIf="loadingCharValues">
  <pkg-loader></pkg-loader>
  <div class="loading_overlay"></div>
</ng-container>
