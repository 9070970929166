<div *ngIf="showModal" class="modal-container">
  <div class="modal modal--small">
    <div class="modal__header">
      <h3>Edit User</h3>
      <button class="icon-button" (click)="close()" type="button">
        <i class="material-icons">&#xE5CD;</i>
      </button>
    </div>
    <div class="modal__content">
      <div class="add-user-container">
        <div class="user">
          <pkg-user-details [user]="user" [assets]="assets" [userAssets]="userAssets" [userRole]="editRole"
            (roleSelectEmitter)="onRoleSelect($event)"
            (assetSelectEmitter)="onAssetSelect($event)"
            (assetDeSelectEmitter)="onAssetDeSelect($event)"
            (assetSelectAllEmitter)="onAssetSelectAll($event)"
            (assetDeSelectAllEmitter)="onAssetDeSelectAll($event)">
          </pkg-user-details>
          <div *ngIf="loading" class="loading-message">
            <img class="logo" src="assets/small-logo-dark.svg" [ngStyle]="getLogoStyle" alt="loading logo" />
            <span class="loading"></span>
          </div>
          <div *ngIf="loading" class="loading_overlay"></div>
        </div>
      </div>
    </div>
    <div class="modal__controls">
      <button class="button button--green" (click)="save()" type="button" [disabled]="!(userRoleChnaged || userAssetsChanged)">
        <i class="material-icons">&#xE876;</i>
        <span>Save</span>
      </button>
      <button class="button" (click)="close()" type="button">
        <i class="material-icons">&#xE5C9;</i>
        <span>Cancel</span>
      </button>
      <button class="button button--red control--right" (click)="onDelete()" type="button">
        <i class="material-icons">&#xE5CD;</i>
        <span>Delete User</span>
      </button>
    </div>
  </div>
</div>