import { StatusDependencyResults } from './status-dependency-results.interface';
import { Convert } from '../convert';

export class StatusResults {
  name: string;
  environment: string;
  version: string;
  machineName: string;
  processorCount: number;
  operatingSystemVersion: string;
  startDate: Date;
  memoryUsageInBytes: number;
  maxMemoryUsageInBytes: number;
  numberOfThreads: number;
  totalProcessorTime: string; // todo should this be timestamp etc.
  isHealthy: boolean;
  dependencies: StatusDependencyResults[] = [];

  get memoryUsageInBytesNumber(): number {
    return this.memoryUsageInBytes
      ? Convert.getConvertedBytesNumber(this.memoryUsageInBytes, 2)
      : Convert.getConvertedBytesNumber(0, 2);
  }

  get memoryUsageInBytesUnit(): string {
    return this.memoryUsageInBytes
      ? Convert.getConvertedBytesUnit(this.memoryUsageInBytes, 2)
      : Convert.getConvertedBytesUnit(0, 2);
  }

  get maxMemoryUsageInBytesNumber(): number {
    return this.maxMemoryUsageInBytes
      ? Convert.getConvertedBytesNumber(this.maxMemoryUsageInBytes, 2)
      : Convert.getConvertedBytesNumber(0, 2);
  }

  get maxMemoryUsageInBytesUnit(): string {
    return this.maxMemoryUsageInBytes
      ? Convert.getConvertedBytesUnit(this.maxMemoryUsageInBytes, 2)
      : Convert.getConvertedBytesUnit(0, 2);
  }

  constructor(data: StatusResults) {
    Object.assign(this, data);
  }
}
