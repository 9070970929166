import { MultiSelectDropDownSetting } from '@shared/multi-select-dropdown/multi-select-dropdown-settings';

export const getMultiSelectDropdownSettings = (cssClass: string = '', noDataLabel: string = 'No Assets Found', badgeShowLimit?: number, selectValuesText?: string) => {
  const settings: MultiSelectDropDownSetting = {
    autoPosition: false,
    classes: cssClass,
    enableSearchFilter: true,
    escapeToClose: true,
    labelKey: 'name',
    primaryKey: 'id',
    noDataLabel,
    searchPlaceholderText: 'Search Assets',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    singleSelection: false,
    text: selectValuesText ?? 'Select Assets',
    badgeShowLimit
  };
  return settings;
};
