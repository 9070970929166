import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { ProductDesign } from './product-design.interface';
import { MlpaJobOptionAsset } from '@shared/mlpa-job-options/mlpa-job-option-asset';
import { environment } from '../../../environments/environment';
import { Preview } from '@shared/lane-preview/preview.interface';
import { isObject } from 'rxjs/internal/util/isObject';
import { trigger, style, state, transition, animate } from '@angular/animations';
import { ProductDesignOffline } from './product-design-offline.interface';

@Component({
  selector: 'pkg-product-design',
  templateUrl: './product-design.component.html',
  styleUrls: ['./product-design.component.scss'],
  animations: [
    trigger('accordion', [
      state('close', style({
        opacity: 0, height: '0px', display: 'none'
      })),
      state('open', style({
        height: '*', display: 'flex'
      })),
      transition('close <=> open', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
    ])
  ]
})
export class ProductDesignComponent implements OnInit, ProductDesign {
  @Input() assets: MlpaJobOptionAsset[];
  @Input() design?: ProductDesign;
  @Input() offlineDesign?: ProductDesignOffline;
  @Output() designEmitter = new EventEmitter<ProductDesign | ProductDesignOffline>();
  programNumber: string;
  setupNumber: string;
  orderNumber: string;
  designNumber: string;
  designDescription: string;
  shortDesignDescription: string;
  orderDueDate: Date;
  assetName: string;
  customerName: string;
  customerAddress: string;
  customerCity: string;
  customerZip: string;
  customerOrderNumber: string;
  customerPartNumber: string;
  customerPurchaseOrderNumber: string;
  designWidthInInches: number;
  designLengthInInches: number;
  designNumberUp: number;
  designNumberOfPages: number;
  orderQuantity: number;
  orderQuantityProduced: number;
  corrugatorName: string;
  paperDescription: string;
  fluteDescription: string;
  linerDescription: string;
  mediumDescription: string;
  productDescription: string;
  coatingDescription: string;
  colorProfileName: string;
  blanketNumber: string;
  isSample: boolean;
  isMockup: boolean;
  isDistortion: boolean;
  showDetails = false;
  flip: boolean;
  thumbnail: any;
  printing: boolean;
  source: Preview;
  pressType: string;
  substrateWeight: string;
  printMode: string;
  inkColors: string;
  inkWeight: string;
  overPrintVarnish: string;
  speed: string;
  totalAreaCoverage: string;
  enableColorCorrection: boolean;
  requiredRollWidthInInches: number;

  constructor() { }

  ngOnInit(): void {
    for (const key in this.design) {
      if (this.design.hasOwnProperty(key)) {
        this[key] = this.design[key];
      }
    }

    if (this.design == null && this.offlineDesign != null) {
      this.designDescription = this.offlineDesign.file.baseName;
      this.designNumber = this.offlineDesign.number;
      this.designWidthInInches = this.offlineDesign.widthInInches;
      this.designLengthInInches = this.offlineDesign.lengthInInches;
      this.assetName = 'T1100 Digital Roll Printer';
    }

    this._setThumbnail();
  }

  add(): void {
    if (this.offlineDesign != null) {
      this.designEmitter.emit(this.offlineDesign);
    }

    if (this.design != null) {
      this.designEmitter.emit(this.design);
    }
  }

  toggleDetails(): void {
    this.showDetails = !this.showDetails;
  }

  toggleThumbNail($event: Event): void {
    $event.stopPropagation();
    this._setThumbnail();
    this.flipCard();
  }

  flipCard(): void {
    this.flip = !this.flip;
  }

  isObject(arg: any): any {
    return isObject(arg);
  }

  private _setThumbnail(): void {
    if (!this.assets) {
      return;
    }
    const asset = this.assets.find(p => p.name === this.assetName);
    if (!asset) {
      return;
    }
    this.source = {} as Preview;
    this.source.preview = `${environment.tartanApiUrl}assets/${asset.id}/pdfs/${this.designNumber}/thumbnail?rotationInDegrees=90`;
    this.source.designDescription = this.designDescription;
    this.source.designNumber = this.designNumber;
    this.source.height = this.designWidthInInches;
    this.source.width = this.designLengthInInches;
  }
}
