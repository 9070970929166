import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Holiday } from './holiday.interface';
import { HolidayService } from './holiday.service';

@Component({
  selector: 'pkg-holiday',
  templateUrl: './holiday.component.html',
  styleUrls: ['./holiday.component.scss']
})
export class HolidayComponent implements OnInit {
  currentHoliday: Holiday;
  showHalloween = true;
  showThanksgivingHat = this._router.url === '/login';
  showChristmas = this._router.url === '/login';

  constructor(private readonly _router: Router, private readonly _holidayService: HolidayService) { }

  ngOnInit(): void {
    this.currentHoliday = this._holidayService.getCurrentHoliday();

    this._router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.showHalloween = route.urlAfterRedirects !== '/login' && route.urlAfterRedirects !== '/unauthorized';
        this.showThanksgivingHat = this.showChristmas = route.urlAfterRedirects === '/login';
      }
    });
  }

  getRandomValue(min = 3, max = 1): number {
    return Math.random() * (max - min) + min;
  }
}
