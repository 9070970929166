import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Subject, Subscription, timer, of } from 'rxjs';
import { map, debounce, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { KeyCode } from '../keycode.enum';


@Component({
  selector: 'pkg-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Input() placeholder = 'Search';
  @Output() termSearchedEmitter = new EventEmitter();
  term: string;
  isLoading: boolean;
  keyUp = new Subject<KeyboardEvent>();
  results = 0;
  private $search: Subscription;

  constructor() { }

  ngOnInit(): void {
    this.$search = this.keyUp.pipe(
      map(event => event),
      debounce((event) => {
        const value = (event.target as HTMLInputElement).value;
        if (event.keyCode === KeyCode.Enter || value === '') {
          return timer(0);
        } else {
          return timer(500);
        }
      }),
      distinctUntilChanged(),
      ).subscribe((term) => {
        this.isLoading = true;
        const searchTerm = (term.target as HTMLInputElement).value;
        if (!!searchTerm && searchTerm !== '') {
          this.termSearchedEmitter.emit(searchTerm);
          return searchTerm;
        }
        this.results = 0;
        this.isLoading = false;
        this.termSearchedEmitter.emit(null);
      });
  }

  clear() {
    this.term = '';
    this.termSearchedEmitter.emit(null);
    this.isLoading = false;
  }

  setResults(result: number) {
    this.results = result;
  }

  setLoading(flag: boolean) {
    this.isLoading = flag;
  }
}
