import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pkg-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {

  public isLoading = false;

  constructor() { }

  setLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  ngOnInit(): void {
  }

}
