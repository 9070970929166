import { Role } from './role';

export class User {
  id: string;
  domain: string;
  username: string;
  ntLogin: string;
  firstName: string;
  middleInitial: string;
  lastName: string;
  displayName: string;
  company: string;
  emailAddress: string;
  photoUrl: string;
  phoneNumber: string;
  mobilePhoneNumber: string;
  role: Role;
  assetIds: string[];
  assetNames: string[] = [];
}
