import { Component, OnInit, forwardRef, Input, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AssetAttributes } from 'src/app/shared/asset-attributes';

@Component({
  selector: 'pkg-asset-color-picker',
  templateUrl: './asset-color-picker.component.html',
  styleUrls: ['./asset-color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AssetColorPickerComponent),
      multi: true
    }
  ]
})
export class AssetColorPickerComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input()
  assetAttributes: AssetAttributes[];
  selectedAssetAttribute: AssetAttributes = { lightThemeColor: '', darkThemeColor: '' };
  dropDownActive = false;

  constructor() {}

  onChange = (assetAttribute: AssetAttributes) => { };
  onTouch: any = () => { };

  ngOnInit(): void {
    if (this.assetAttributes === null || this.assetAttributes === undefined) {
      throw new TypeError('The input ‘assetAttributes’ is required');
    }
  }
  ngOnChanges(): void {
    if (this.assetAttributes === null || this.assetAttributes === undefined) {
      throw new TypeError('The input ‘assetAttributes’ is required');
    }
  }
  writeValue(obj: AssetAttributes): void {
    this.selectedAssetAttribute = obj;
    this.onChange(this.selectedAssetAttribute);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  toggleDropDown() {
    this.dropDownActive = !this.dropDownActive;
  }
  onAssetAttributeSelect(assetAttribute: AssetAttributes) {
    this.dropDownActive = false;
    this.writeValue(assetAttribute);
  }
  onNoneSelect() {
    const assetAttribute = { lightThemeColor: '', darkThemeColor: '' };
    this.dropDownActive = false;
    this.writeValue(assetAttribute);
  }
  getStyles(assetAttribute: AssetAttributes) {
    let backgroundStyles: any = '';
    if (assetAttribute.lightThemeColor === this.selectedAssetAttribute?.lightThemeColor) {
      backgroundStyles = {
        background: 'var(--hover)'
      };
      return backgroundStyles;
    }
    return backgroundStyles;
  }
}
