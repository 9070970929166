import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PagedResult } from '@shared/paged-result';
import { PaperType, PaperTypeUpdateRequest } from './paper-type';

@Injectable()
export class PaperTypeService {
  constructor(private readonly http: HttpClient) { }

  /**
   * Invoke server api to get Paper Types
   *
   * @param pageNumber Current page number.
   * @param term Search term.
   * @param aggregateFlagBits Material type flag.
   * @returns An instance of observable paginated paper types.
   */
    getPaperTypes(pageNumber: number, term: string, aggregateFlagBits: number): Observable<PagedResult<PaperType>> {
    const params = new HttpParams()
      .set('pageSize', '24')
      .set('pageNumber', `${pageNumber}`)
      .set('term', `${term}`)
      .set('flag', `${aggregateFlagBits}`);

      return this.http.get<PagedResult<PaperType>>(`${environment.tartanApiUrl}paper-types`, { params });
  }

  /**
   * Invoke server api to update paper types for their chosen material types
   *
   * @param updatedPaperTypes An array of modified Paper types
   *
   */
  updatePaperTypes(updatedPaperTypes: PaperType[]): Observable<any> {
    const paperTypes: PaperTypeUpdateRequest[] = [];
    updatedPaperTypes.forEach((item) => {
      paperTypes.push({
        id: item.id,
        materialType: item.materialType
      });
    });

    const updatePaperTypesCommand = {
      PaperTypes: paperTypes
    };

    return this.http.patch(`${environment.tartanApiUrl}paper-types`, updatePaperTypesCommand);
  }
}


