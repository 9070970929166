import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { MultiSelectDropDownSetting } from './multi-select-dropdown-settings';

@Component({
  selector: 'pkg-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss']
})
export class MultiSelectDropdownComponent implements OnInit, OnChanges {

  @Input() dropdownList = [];
  @Input() selectedItems = [];
  @Input() dropdownSettings: MultiSelectDropDownSetting;

  @Output() itemSelectEmitter = new EventEmitter<any>();
  @Output() itemDeSelectEmitter = new EventEmitter<any>();
  @Output() selectAllEmitter = new EventEmitter<any>();
  @Output() deselectAllEmitter = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    if (this.dropdownSettings === null || this.dropdownSettings === undefined) {
      throw new TypeError('The input ‘dropdownSettings’ is required');
    }
  }

  ngOnChanges(): void {
    if (this.dropdownSettings === null || this.dropdownSettings === undefined) {
      throw new TypeError('The input ‘dropdownSettings’ is required');
    }
  }

  onItemSelect() {
    this.itemSelectEmitter.emit(this.selectedItems);
  }
  onItemDeSelect() {
    this.itemDeSelectEmitter.emit(this.selectedItems);
  }
  onSelectAll() {
    this.selectAllEmitter.emit(this.selectedItems);
  }
  onDeSelectAll() {
    this.deselectAllEmitter.emit(this.selectedItems);
  }
}
