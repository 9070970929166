import { MlpaLane } from '../mlpa-lane';
import { DesignType } from '../design-type.enum';
import { LaneProperties } from './lane-properties.interface';
import { deepCopyProperties } from '@shared/pkg-helper';

export class OfflineGraphic extends MlpaLane {
  laneProperties: LaneProperties;
  constructor(laneProperties: LaneProperties) {
    super(laneProperties.mlpaLaneApi);

    this.laneProperties = laneProperties;

    // if an id exists this is an edit so just return and do no more.
    if (!!laneProperties.mlpaLaneApi && laneProperties.mlpaLaneApi.id !== '') {
      return;
    }

    // loop through properties to perform a deep copy of properties
    deepCopyProperties(this, laneProperties.initialProperties);

    // if the isClone property is null/undefined or false
    if (laneProperties.initialProperties.isClone == null || laneProperties.initialProperties.isClone === false) {
      this.setInitialLaneProperties(laneProperties.initialProperties, DesignType.GRAPHIC);

      // remap due to overrided values.
      deepCopyProperties(this, laneProperties.design);

      // override values
      this.numberOfRibbons = 1;
      this.laneNumber = 0;
      this.rotationInDegrees = 90;
      this.isTransposed = false;
      if (!!laneProperties.design) {
        this.orderDueDate = laneProperties.design.orderDueDate;
      }
      this.isProcessing = false;
      this.isProcessed = false;


      this.designNumber = this.laneProperties.offlineDesign.number;
      this.designDescription = this.laneProperties.offlineDesign.number;
      this.setTransposedValues();
    }
  }
  get totalWidthInInches(): number {
    return this.designWidthInInches * this.numberOfRibbons;
  }
}
