import { Component } from '@angular/core';

import { AlertsService } from './alerts.service';

@Component({
  selector: 'pkg-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent {

  constructor(public readonly service: AlertsService) { }

  close(id: number): void {
    this.service.remove(id);
  }
}
