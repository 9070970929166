<div class="mlpa-jobs-search">
  <div class="mlpa-job-search-container">
    <div class="assets-multi-select" *ngIf="showAssetsFilter">
      <div class="assets-multi-select__inner">
        <div class="printer-icon">
          <i class="material-icons-outlined" title="Select an asset to filter.">&#xE1BD;</i>
        </div>
        <div class="asset-selector">
          <pkg-multi-select-dropdown [dropdownList]="userAssets" [selectedItems]="selectedAssets"
            [dropdownSettings]="userAssetsDropdownSettings" (itemSelectEmitter)="onAssetSelect($event)"
            (itemDeSelectEmitter)="onAssetDeSelect($event)" (selectAllEmitter)="onAssetSelectAll($event)"
            (deselectAllEmitter)="onAssetDeSelectAll($event)"></pkg-multi-select-dropdown>
        </div>
      </div>
    </div>
    <div class="mlpa-jobs-search__inner">
      <span *ngIf="(!term || term == '') && !isLoading" class="material-icons search-icon"
        title="Not quite 100 zeroes">&#xE8B6;</span>
      <span *ngIf="(term && term !== '')" class="material-icons clear-icon"
        (click)="clear(); setFiltersToTrue(); close();" title="Clear">&#xE14C;</span>
      <input class="mlpa-jobs-search__input-ga" [(ngModel)]="term" (keydown.enter)="keyUp.next($event)"
        (keyup)="keyUp.next($event)" type="text" id="term" placeholder="Search for MLPA jobs..." />
      <span class="loading" [class.visible]="isLoading"></span>
      <span *ngIf="term" class="result-status" [class.visible]="term">{{results | number}} Results</span>
      <span *ngIf="isFiltered" class="filter-status" [class.visible]="isFiltered">filtered</span>
      <span (click)="toggleFilter()" class="filter-icon material-icons">&#xE429;</span>
    </div>

    <div *ngIf="showFilter" class="filter-options">

      <div [ngStyle]="mlpaJobStatusStyle" class="mlpa-job-status">
        <span class="title">
          Mlpa Job Status:
        </span>
        <div class="status-toggle" (click)="toggleStatus(); searchWithFilter()">
          <i *ngIf="!statusAllOn" class="material-icons off">&#xE9F5;</i>
          <i *ngIf="statusAllOn" class="material-icons on">&#xE9F6;</i>
        </div>

        <div class="queued-status"
          (click)="statusState?.status['queued'] = !statusState?.status['queued']; searchWithFilter()">
          <div class="mlpa-job-status-toggle toggle">
            <span class="toggle-section left"></span>
            <span class="toggle-section right">
              <span title="The PDF files have been saved and queued for processing">
                <span [ngClass]="{'collapsed-status': !statusState?.status['queued']}"
                  class="material-icons status-icon status-icon--queued"></span>
              </span>
            </span>
          </div>
          <label class="label">Queued</label>
        </div>

        <div class="processing-status"
          (click)="statusState?.status['processing'] = !statusState?.status['processing']; searchWithFilter()">
          <div class="mlpa-job-status-toggle toggle">
            <span class="toggle-section left"></span>
            <span class="toggle-section right">
              <span title="The PDF files are being processed">
                <span [ngClass]="{'collapsed-status': !statusState?.status['processing']}"
                  class="material-icons status-icon status-icon--processing"></span>
              </span>
            </span>
          </div>
          <label class="label">Processing</label>
        </div>

        <div class="processed-status"
          (click)="statusState?.status['processed'] = !statusState?.status['processed']; searchWithFilter()">
          <div class="mlpa-job-status-toggle toggle">
            <span class="toggle-section left"></span>
            <span class="toggle-section right">
              <span title="The PDF files have been processed">
                <span [ngClass]="{'collapsed-status': !statusState?.status['processed']}"
                  class="material-icons status-icon status-icon--processed"></span>
              </span>
            </span>
          </div>
          <label class="label">Processed</label>
        </div>

        <div class="generated-status"
          (click)="statusState?.status['generated'] = !statusState?.status['generated']; searchWithFilter()">
          <div class="mlpa-job-status-toggle toggle">
            <span class="toggle-section left"></span>
            <span class="toggle-section right">
              <span title="The XML files have been generated">
                <span [ngClass]="{'collapsed-status': !statusState?.status['generated']}"
                  class="material-icons status-icon status-icon--generated"></span>
              </span>
            </span>
          </div>
          <label class="label">Generated</label>
        </div>

        <div class="submitted-status"
          (click)="statusState?.status['submitted'] = !statusState?.status['submitted']; searchWithFilter()">
          <div class="mlpa-job-status-toggle toggle">
            <span class="toggle-section left"></span>
            <span class="toggle-section right">
              <span title="The job files have been submitted to the press">
                <span [ngClass]="{'collapsed-status': !statusState?.status['submitted']}"
                  class="material-icons status-icon status-icon--submitted"></span>
              </span>
            </span>
          </div>
          <label class="label">Submitted</label>
        </div>

        <div class="failed-status"
          (click)="statusState?.status['failed'] = !statusState?.status['failed']; searchWithFilter()">
          <div class="mlpa-job-status-toggle toggle">
            <span class="toggle-section left"></span>
            <span class="toggle-section right">
              <span title="An error occurred in one of the steps">
                <span [ngClass]="{'collapsed-status': !statusState?.status['failed']}"
                  class="material-icons status-icon status-icon--failed"></span>
              </span>
            </span>
          </div>
          <label class="label">Failed</label>
        </div>

        <div class="special-status"
          (click)="statusState?.status['special'] = !statusState?.status['special']; searchWithFilter()">
          <div class="mlpa-job-status-toggle toggle">
            <span class="toggle-section left"></span>
            <span class="toggle-section right">
              <span title="Special jobs for web layouts">
                <span [ngClass]="{'collapsed-status': !statusState?.status['special']}"
                  class="material-icons status-icon status-icon--weblayout"></span>
              </span>
            </span>
          </div>
          <label class="label">Special</label>
        </div>

        <div class="stress-status"
          (click)="statusState?.status['stress'] = !statusState?.status['stress']; searchWithFilter()">
          <div class="mlpa-job-status-toggle toggle">
            <span class="toggle-section left"></span>
            <span class="toggle-section right">
              <span title="Stress jobs">
                <span [ngClass]="{'collapsed-status': !statusState?.status['stress']}"
                  class="material-icons status-icon status-icon--chevron"></span>
              </span>
            </span>
          </div>
          <label class="label">Stress</label>
        </div>
      </div>

      <div *ngIf="term != null && term !==''" [ngStyle]="jobPropertiesStyle" class="job-properties-status">
        <span class="title">Job Properties: </span>
        <div class="job-properties-toggle" (click)="toggleJobProperties(); searchWithFilter()">
          <i *ngIf="!jobPropertiesAllOn" class="material-icons off">&#xE9F5;</i>
          <i *ngIf="jobPropertiesAllOn" class="material-icons on">&#xE9F6;</i>
        </div>
        <div class="programNumber" (change)="searchWithFilter()">
          <label class="checkbox">Program Number
            <input type="checkbox" [(ngModel)]="statusState?.jobProperties.programNumber">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="orderNumber" (change)="searchWithFilter()">
          <label class="checkbox">Order Number
            <input type="checkbox" [(ngModel)]="statusState?.jobProperties.orderNumber">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="setupNumber" (change)="searchWithFilter()">
          <label class="checkbox">Setup Number
            <input type="checkbox" [(ngModel)]="statusState?.jobProperties.setupNumber">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="designNumber" (change)="searchWithFilter()">
          <label class="checkbox">Design Number
            <input type="checkbox" [(ngModel)]="statusState?.jobProperties.designNumber">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="name" (change)="searchWithFilter()">
          <label class="checkbox">Name
            <input type="checkbox" [(ngModel)]="statusState?.jobProperties.name">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="uniqueId" (change)="searchWithFilter()">
          <label class="checkbox">Unique Id
            <input type="checkbox" [(ngModel)]="statusState?.jobProperties.uniqueId">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="designDescription" (change)="searchWithFilter()">
          <label class="checkbox">Design Description
            <input type="checkbox" [(ngModel)]="statusState?.jobProperties.designDescription">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="colorProfile" (change)="searchWithFilter()">
          <label class="checkbox">Color Profile
            <input type="checkbox" [(ngModel)]="statusState?.jobProperties.colorProfile">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="paperType" (change)="searchWithFilter()">
          <label class="checkbox">Paper Type
            <input type="checkbox" [(ngModel)]="statusState?.jobProperties.paperType">
            <span class="checkmark"></span>
          </label>
        </div>
      </div>

      <div class="sort-order-by">
        <div class="content" (change)="searchWithFilter()">
          <span class="title title__sort-order">Sort Order:</span>
          <select class="sort-order" [(ngModel)]="statusState.sortOrder">
            <option [ngValue]="SortOrder.ASC">Ascending</option>
            <option [ngValue]="SortOrder.DSC">Descending</option>
          </select>
        </div>

        <div class="content" (change)="searchWithFilter()">
          <span class="title title__sort-by">Sort By:</span>
          <select class="sort-by" [(ngModel)]="statusState.sortBy">
            <option [ngValue]="SortBy.CREATED_DATE">Created Date</option>
            <option [ngValue]="SortBy.PROGRAM_NUMBER">Program Number</option>
            <option [ngValue]="SortBy.SETUP_NUMBER">Setup Number</option>
            <option [ngValue]="SortBy.NAME">Name</option>
            <option [ngValue]="SortBy.UNIQUEID">Unique Id</option>
            <option [ngValue]="SortBy.PAPERTYPE">Paper Type</option>
            <option [ngValue]="SortBy.ASSET">Asset Name</option>
          </select>
        </div>

      </div>

      <div class="button-group">
        <button class="button button--green filter-search__button-ga" (click)="searchWithFilter(); close();"
          [disabled]="isLoading">
          <i class="material-icons">&#xE8B6;</i>
          <span>Search</span>
        </button>
        <button class="button" (click)="reset(); searchWithFilter()" [disabled]="isLoading">
          <i *ngIf="isFiltered" class="material-icons">&#xE9F5;</i>
          <i *ngIf="!isFiltered" class="material-icons">&#xE9F6;</i>
          <span>Toggle</span>
        </button>
        <button class="button" (click)="close()">
          <i class="material-icons">&#xE316;</i>
          <span>Close</span>
        </button>
      </div>
    </div>
  </div>
</div>
