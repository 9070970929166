import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[pkgSticky]'
})
export class StickyDirective {

  constructor(private readonly _el: ElementRef) { }

  @HostListener('window:resize') onResize(): void {
    this._stickify();
  }

  @HostListener('window:scroll') scrolling(): void {
    this._stickify();
  }

  private _stickify(): void {
    // Get a reference to the page header
    const header = document.querySelector('.page__header');
    const headerElement = new ElementRef(header);

    // Figure out what the breakpoint should be for stickyness
    const scrollBreakPoint = headerElement.nativeElement.scrollHeight;

    // Get the current scroll position on the page
    const scrollPosition = window.pageYOffset;

    // Get the container element's width
    const containerWidth = this._el.nativeElement.parentElement.parentElement.offsetWidth;

    // Make it sticky, except when the window is less than 768 pixels tall
    if (scrollPosition >= scrollBreakPoint && window.innerHeight >= 768) {
      this._el.nativeElement.classList.add('mlpa-job--fixed');
      this._el.nativeElement.style.width = `${containerWidth}px`;
    } else {
      // Make it not sticky
      this._el.nativeElement.classList.remove('mlpa-job--fixed');
      this._el.nativeElement.style.width = 'auto';
    }
  }
}
