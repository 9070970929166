<div class="page__header header--admin">
  <div class="page__header-actions">
    <h2 class="page__header-title">{{title}} Data Logger {{dataLoggerAsset?.name}}</h2>
  </div>
</div>
<pkg-loader *ngIf="isLoading"></pkg-loader>
<div *ngIf="!isLoading" class="page__content">
  <div *ngIf="dataLoggerAsset" class="asset-detail">
    <form [formGroup]="dataLoggerForm">
      <h2>Data Logger Config</h2>
      <div class="asset-detail__row">
        <div class="asset-setting asset-setting--small">
          <label class="asset-setting__label">
            <span>Name:</span>
          </label>
          <input [class.input-error]="isValid('name')" type="text" formControlName="name" minlength="1" maxlength="255">
          <div class="asset-form error" [hidden]="!isValid('name')">Must not be empty
          </div>
        </div>
        <div class="asset-setting asset-setting--small">
          <label class="asset-setting__label">
            <span>Audience:</span>
          </label>
          <input [class.input-error]="isValid('audience')" type="text" formControlName="audience" min="1" max="32">
          <div class="asset-form error" [hidden]="!isValid('audience')">Should be at least 1-32 character </div>
        </div>
      </div>
      <div class="asset-detail__row">
        <div class="asset-setting asset-setting--small">
          <label class="asset-setting__label">
            <span>Type: </span>
          </label>
          <select formControlName="type" required>
            <option *ngFor="let dataLogger of dataLoggerType | enum" [value]="dataLogger.id">{{dataLogger.name}}</option>
          </select>
          <div class="asset-form error" [hidden]="!isValid('type')">Please select a type. </div>
        </div>
        <div class="asset-setting asset-setting--small">
          <label class="asset-setting__label">
            <span>Mode: </span>
          </label>
          <select formControlName="isInTestMode" required>
            <option [ngValue]="true">Test Mode</option>
            <option [ngValue]="false">Production Mode</option>
          </select>
          <div class="asset-form error" [hidden]="!isValid('isInTestMode')">Please select a mode. </div>
        </div>
        <div class="asset-setting asset-setting--small">
          <label class="asset-setting__label">
            <span>Enabled: </span>
          </label>
          <select formControlName="isEnabled" required>
            <option [ngValue]="true">Enabled</option>
            <option [ngValue]="false">Disabled</option>
          </select>
          <div class="asset-form error" [hidden]="!isValid('isEnabled')">Please select an option.</div>
        </div>
      </div>
      <div class="asset-detail__row">
        <div class="asset-setting asset-setting--small data-logger-controls">
          <button (click)="saveDataLogger()" [disabled]="!dataLoggerForm.valid" class="button button--green">
            <i class="material-icons">&#xE5CA;</i>
            <span>Save</span>
          </button>
          <button (click)="confirmDelete()" *ngIf="dataLoggerAsset?.id" [disabled]="!dataLoggerForm.valid" class="button button--red">
            <i class="material-icons">&#xE92B;</i>
            <span>Delete</span>
          </button>
          <button class="button" (click)="cancel()">
            <i class="material-icons">&#xE5C9;</i>
            <span>Cancel</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<pkg-modal *ngIf="showDelete"
  [header]="'Confirm Delete'"
  [message]="'Are you sure you want to delete the Data Logger: ' + dataLoggerAsset?.name"
  [color]="'red'"
  [confirmMessage]="'Delete'"
  [closeMessage]="'Cancel'"
  (closeEmitter)= "showDelete = false"
  (confirmEmitter)= "delete(); showDelete = false"></pkg-modal>
