import { Component, OnChanges, Input, ElementRef, HostBinding } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'pkg-smooth-height',
  templateUrl: './smooth-height.component.html',
  styleUrls: ['./smooth-height.component.scss'],
  animations: [
    trigger('grow', [
      transition('void <=> *', []),
      transition('* <=> *', [
        style({ height: '{{startHeight}}px', opacity: 0 }),
        animate('.5s ease'),
      ], { params: { startHeight: 0 } })
    ])
  ]
})
export class SmoothHeightComponent implements OnChanges {
  @Input() trigger: string;
  startHeight: number;

  constructor(private readonly _element: ElementRef) { }

  @HostBinding('@grow') get grow(): any {
    return { value: this.trigger, params: { startHeight: this.startHeight } };
  }

  ngOnChanges(): void {
    this.startHeight = this._element.nativeElement.clientHeight;
  }
}
