<div class="site" [ngClass]="pkgThemeService.getTheme()">
  <pkg-navigation></pkg-navigation>
  <section class="site__content">
    <router-outlet>
    </router-outlet>
  </section>
  <ng-container *ngIf="currentHoliday?.name  !== 'Thanksgiving' && currentHoliday !== null">
    <pkg-holiday class='holiday-component' [ngClass]="styleClass"></pkg-holiday>
  </ng-container>
</div>
<pkg-alerts></pkg-alerts>
<pkg-compatibility-warning></pkg-compatibility-warning>
<pkg-svg-icons></pkg-svg-icons>
