<div class="page__header header--admin">
  <h2 class="page__header-title">Assets</h2>
  <div class="page__header-controls">
    <div class="asset-header">
      <div class="card asset-header__search">
        <label for="search"><i class="material-icons">&#xE8B6;</i></label>
        <input [(ngModel)]="keyword" type="text" id="search" placeholder="Search for an asset...">
      </div>
    </div>
  </div>
</div>
<div class="page__content">
  <h3 class="title">Printers</h3>
  <ul class="asset-list">
    <ng-container *ngIf="assets.length > 0 && !assetsLoading">
      <li *ngFor="let asset of filteredAssets">
        <div class="card asset">
          <div class="asset__details" [class.asset__details--disabled]="!asset.isEnabled">
            <div class="asset__icon">
              <i *ngIf="!asset.isEnabled" class="material-icons matrial-icons--disabled">&#xE612;</i>
              <i class="material-icons">&#xE8AD;</i>
            </div>
            <div class="asset__name">{{asset.name}}</div>
          <section class="status-icons__container">
              <span title="mode" class="asset-mode status-chip" [ngClass]="testModeClass(asset)" [class.asset__test-mode--disabled]="!asset.isEnabled">
                <ng-container *ngIf="asset.isInTestMode">TEST</ng-container>
                <ng-container *ngIf="!asset.isInTestMode">PRODUCTION</ng-container>
              </span>
              <span *ngIf="asset.visionSystemId!=null" title="{{asset.visionSystemName}}" class="material-icons status-chip status-chip--visibility"></span>
          </section>
          </div>
          <div class="asset__controls">
            <button class="button button--primary button--small" (click)="editAsset(asset)" type="button">
              <i class="material-icons">&#xE3C9;</i>
              <span>Edit</span>
            </button>
          </div>
        </div>
      </li>
    </ng-container>
    <div *ngIf="assetsLoading" class="loading"></div>
    <li title="Add Printer">
      <div class="card asset placeholder" (click)="createAsset()">
        <div class="asset__details">
          <div class="asset__icon">
            <i class="material-icons">&#xE148;</i>
          </div>
        </div>
      </div>
    </li>
  </ul>

  <h3 class="title">Vision Systems</h3>
  <ul class="asset-list">
    <ng-container *ngIf="visionSystems.length > 0 && !visionSystemsLoading">
      <li *ngFor="let visionSystem of filteredVisionSystems">
        <div class="card asset">
          <div class="asset__details" [class.asset__details--disabled]="!visionSystem?.isEnabled">
            <div class="asset__icon">
              <i *ngIf="!visionSystem?.isEnabled" class="material-icons matrial-icons--disabled">&#xE612;</i>
              <i class="material-icons">&#xE8F4;</i>
            </div>
            <div class="asset__name">{{visionSystem?.name}}</div>
           <section class="status-icons__container">
              <span title="mode" class="asset-mode status-chip" [ngClass]="testModeClass(visionSystem)" [class.asset__test-mode--disabled]="!visionSystem.isEnabled">
                <ng-container *ngIf="visionSystem.isInTestMode">TEST</ng-container>
                <ng-container *ngIf="!visionSystem.isInTestMode">PRODUCTION</ng-container>
              </span>
           </section>
          </div>
          <div class="asset__controls">
            <button class="button button--primary button--small" (click)="editVisionSystem(visionSystem)" type="button">
              <i class="material-icons">&#xE3C9;</i>
              <span>Edit</span>
            </button>
          </div>
        </div>
      </li>
    </ng-container>
    <div *ngIf="visionSystemsLoading" class="loading"></div>
    <li title="Add Vision System">
      <div class="card asset placeholder" (click)="createVisionSystem()">
        <div class="asset__details">
          <div class="asset__icon">
            <i class="material-icons">&#xE148;</i>
          </div>
        </div>
      </div>
    </li>
  </ul>

  <h3 class="title">Data Loggers</h3>
  <ul class="asset-list">
    <ng-container *ngIf="dataLoggers.length > 0 && !dataLoggersLoading">
      <li *ngFor="let dataLogger of filteredDataLoggers">
        <div class="card asset">
          <div class="asset__details" [class.asset__details--disabled]="!dataLogger?.isEnabled">
            <div class="asset__icon">
              <i *ngIf="!dataLogger?.isEnabled" class="material-icons matrial-icons--disabled">&#xE612;</i>
              <img [class.disabled]="!dataLogger?.isEnabled" class="data-logger" alt="data-logger" src="./assets/roll-map.svg" />
            </div>
            <div class="asset__name">{{dataLogger?.name}}</div>
            <div class="asset__test-mode" [ngClass]="testModeClass(dataLogger)" [class.asset__test-mode--disabled]="!dataLogger?.isEnabled">
              <ng-container *ngIf="dataLogger?.isInTestMode">Test Mode</ng-container>
              <ng-container *ngIf="!dataLogger?.isInTestMode">Production Mode</ng-container>
            </div>
          </div>
          <div class="asset__controls">
            <button class="button button--primary button--small" (click)="editDataLogger(dataLogger)" type="button">
              <i class="material-icons">&#xE3C9;</i>
              <span>Edit</span>
            </button>
          </div>
        </div>
      </li>
    </ng-container>
    <div *ngIf="dataLoggersLoading" class="loading"></div>
    <li title="Add Roll">
      <div class="card asset placeholder" (click)="createDataLogger()">
        <div class="asset__details">
          <div class="asset__icon">
            <i class="material-icons">&#xE148;</i>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
