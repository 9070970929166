import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Asset } from '@shared/asset';
import { environment } from '../../../../environments/environment';

@Injectable()
export class AssetService {
  constructor(private readonly _http: HttpClient) { }

  getAll(): Observable<Asset[]> {
    return this._http.get<Asset[]>(`${environment.tartanApiUrl}assets`);
  }

  get(assetId: string): Observable<Asset> {
    return this._http.get<Asset>(`${environment.tartanApiUrl}assets/${assetId}`);
  }

  editAsset(asset: Asset): Observable<Asset> {
    return this._http.put<Asset>(`${environment.tartanApiUrl}assets/${asset.id}`, asset);
  }

  addAsset(asset: Asset): Observable<Asset> {
    return this._http.post<Asset>(`${environment.tartanApiUrl}assets/`, asset);
  }
}
