<div class="page__header header--admin">
  <div class="page__header-actions">
    <h2 class="page__header-title">{{title}} Color Management System {{colorManagementSystem?.name}}</h2>
  </div>
</div>
<div class="page__content">
  <pkg-loader *ngIf="loading"></pkg-loader>
  <div *ngIf="!loading && colorManagementSystem" class="color-management-system-detail">
    <form [formGroup]="colorManagementSystemForm" (submit)="saveColorManagementSystem()">
      <h2>Color Management System Config</h2>
      <div class="color-management-system-detail__row">
        <div class="color-management-system-setting color-management-system-setting--small">
          <label class="color-management-system-setting__label">
            <span>Name:</span>
          </label>
          <input [class.input-error]="isValid('name')" type="text" formControlName="name" minlength="4" maxlength="255">
          <div class="color-management-system-form error" [hidden]="!isValid('name')">Must not be empty, and be at least
            4 characters.
          </div>
        </div>
        <div class="color-management-system-setting color-management-system-setting--small">
          <label class="color-management-system-setting__label">
            <span>Audience:</span>
          </label>
          <input [class.input-error]="isValid('audience')" type="text" formControlName="audience" minlength="4"
            maxlength="255">
          <div class="color-management-system-form error" [hidden]="!isValid('audience')">Must not be empty, and be at
            least
            4 characters.
          </div>
        </div>
        <div class="color-management-system-setting color-management-system-setting--small">
          <label class="color-management-system-setting__label">
            <span>JobPriority:</span>
          </label>
          <input [class.input-error]="isValid('jobPriority')" type="number" formControlName="jobPriority" minlength="1"
            maxlength="10">
          <div class="color-management-system-form error" [hidden]="!isValid('jobPriority')">Must not be empty, and be
            within 1 to 10 range.
          </div>
        </div>
      </div>
      <div class="color-management-system-detail__row">
        <div class="color-management-system-setting color-management-system-setting--small">
          <label class="color-management-system-setting__label">
            <span>Type:</span>
          </label>
          <select formControlName="type" required>
            <option [ngValue]="1">GMG ColorServer</option>
          </select>
          <div class="color-management-system-form error" [hidden]="!isValid('type')">Please select a type.
          </div>
        </div>
        <div class="color-management-system-setting color-management-system-setting--small">
          <label class="color-management-system-setting__label">
            <span>Mode: </span>
          </label>
          <select formControlName="isInTestMode" required>
            <option [ngValue]="true">Test Mode</option>
            <option [ngValue]="false">Production Mode</option>
          </select>
          <div class="color-management-system-form error" [hidden]="!isValid('isInTestMode')">Please select a mode.
          </div>
        </div>
        <div class="color-management-system-setting color-management-system-setting--small">
          <label class="color-management-system-setting__label">
            <span>Enabled: </span>
          </label>
          <select formControlName="isEnabled" required>
            <option [ngValue]="true">Enabled</option>
            <option [ngValue]="false">Disabled</option>
          </select>
          <div class="color-management-system-form error" [hidden]="!isValid('isEnabled')">Please select an option.
          </div>
        </div>
      </div>
      <div class="color-management-system-detail__row color-management-system-detail__row--header">
        Characterization Value Templates <small>Template</small>
      </div>
      <div class="color-management-system-detail__row">
        <div class="color-management-system-setting color-management-system-setting--large">
          <label class="color-management-system-setting__label">
            <span>Template Name:</span>
            <small>Template</small>
          </label>
          <input [class.input-error]="isValid('templateName')" type="text" formControlName="templateName" minlength="1"
            maxlength="510">
          <div class="color-management-system-form error" [hidden]="!isValid('templateName')">Must not be empty. </div>
        </div>
        <div class="color-management-system-setting color-management-system-setting--large">
          <label class="color-management-system-setting__label">
            <span>Color Template Name:</span>
            <small>Template</small>
          </label>
          <input [class.input-error]="isValid('colorTemplateName')" type="text" formControlName="colorTemplateName"
            minlength="1" maxlength="510">
          <div class="color-management-system-form error" [hidden]="!isValid('colorTemplateName')">Must not be empty.
          </div>
        </div>
        <div class="color-management-system-setting color-management-system-setting--large">
          <label class="color-management-system-setting__label">
            <span>Normalization Template Name:</span>
            <small>Template</small>
          </label>
          <input [class.input-error]="isValid('normalizationTemplateName')" type="text" formControlName="normalizationTemplateName"
            minlength="1" maxlength="510">
          <div class="color-management-system-form error" [hidden]="!isValid('normalizationTemplateName')">Must not be empty.
          </div>
        </div>
      </div>
      <div class="color-management-system-detail__row color-management-system-detail__row--header">
        Source File Paths For Color Correction
      </div>
      <div class="color-management-system-detail__row">
        <div class="color-management-system-setting color-management-system-setting--large">
          <label class="color-management-system-setting__label">
            <span>Path To Source Pdfs:</span>
          </label>
          <input [class.input-error]="isValid('pathToSourcePdfs')" type="text" formControlName="pathToSourcePdfs" minlength="1"
            maxlength="510">
          <div class="color-management-system-form error" [hidden]="!isValid('pathToSourcePdfs')">Must not be empty. </div>
        </div>
      </div>
      <div class="color-management-system-detail__row color-management-system-detail__row--header">
        Color Corrected File Paths
      </div>
      <div class="color-management-system-detail__row">
        <div class="color-management-system-setting color-management-system-setting--large">
          <label class="color-management-system-setting__label">
            <span>Path To Color Corrected Pdfs:</span>
          </label>
          <input [class.input-error]="isValid('pathToColorCorrectedPdfs')" type="text" formControlName="pathToColorCorrectedPdfs" minlength="1"
            maxlength="510">
          <div class="color-management-system-form error" [hidden]="!isValid('pathToColorCorrectedPdfs')">Must not be empty. </div>
        </div>
      </div>
      <div class="color-management-system-detail__row">
        <div class="color-management-system-setting color-management-system-setting--small color-management-system-controls">
          <button type="submit" [disabled]="!colorManagementSystemForm.valid" class="button button--green">
            <i class="material-icons">&#xE5CA;</i>
            <span>Save</span>
          </button>
          <button type="button" (click)="confirmDelete()" *ngIf="colorManagementSystem?.id" [disabled]="!colorManagementSystemForm.valid" class="button button--red">
            <i class="material-icons">&#xE92B;</i>
            <span>Delete</span>
          </button>
          <button type="button" class="button" (click)="cancel()">
            <i class="material-icons">&#xE5C9;</i>
            <span>Cancel</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<pkg-modal *ngIf="showDelete"
  [header]="'Confirm Delete'"
  [message]="'Are you sure you want to delete the Color Management System: ' + colorManagementSystem?.name"
  [color]="'red'"
  [confirmMessage]="'Delete'"
  [closeMessage]="'Cancel'"
  (closeEmitter)= "showDelete = false"
  (confirmEmitter)= "delete(); showDelete = false"></pkg-modal>