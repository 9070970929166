import { Injectable } from '@angular/core';
import { DataLoggerAsset } from '@shared/data-logger-asset';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { PagedResult } from '@shared/paged-result';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataLoggerService {

  constructor(private readonly http: HttpClient) { }

  getAll(): Observable<PagedResult<DataLoggerAsset>> {
    return this.http.get<PagedResult<DataLoggerAsset>>(`${environment.tartanApiUrl}data-loggers`);
  }

  get(dataLoggerId: string): Observable<DataLoggerAsset> {
    return this.http.get<DataLoggerAsset>(`${environment.tartanApiUrl}data-loggers/${dataLoggerId}`);
  }

  editDataLogger(dataLogger: DataLoggerAsset) {
    const payload = dataLogger.buildForSave();
    return this.http.put(`${environment.tartanApiUrl}data-loggers/${dataLogger.id}`, payload);
  }

  addDataLogger(dataLogger: DataLoggerAsset) {
    const payload = dataLogger.buildForSave();
    return this.http.post(`${environment.tartanApiUrl}data-loggers`, payload);
  }

  searchDataLogger(dataLoggerIds: string[], pageNumber: number, pageSize: number) {
    const params = new HttpParams()
      .set('dataLoggerIds', `${dataLoggerIds}`)
      .set('pageSize', `${pageSize}`)
      .set('pageNumber', `${pageNumber}`);

    return this.http.get<PagedResult<DataLoggerAsset>>(`${environment.tartanApiUrl}data-loggers/search`, { params });
  }

  deleteDataLogger(id: string) {
    return this.http.delete(`${environment.tartanApiUrl}data-loggers/${id}`);
  }
}
