import { MlpaLane } from '../mlpa-lane';
import { LaneProperties } from './lane-properties.interface';
import { MlpaLaneApi } from '@shared/mlpa-lane-api';
import { DesignType } from '../design-type.enum';
import { deepCopyProperties } from '@shared/pkg-helper';

export class UnknownLane extends MlpaLane {
  constructor(laneProperties: LaneProperties, mlpaLaneApi?: MlpaLaneApi) {
    super(mlpaLaneApi);
    // loop through properties to perform a deep copy of properties
    deepCopyProperties(this, laneProperties.initialProperties);

    // if the isClone property is null/undefined or false
    if (laneProperties.initialProperties.isClone == null || laneProperties.initialProperties.isClone === false) {
      laneProperties.initialProperties.designWidthInInches = 0;
      this.setInitialLaneProperties(laneProperties.initialProperties, DesignType.UNKNOWN);
      // override values.
      this.quantity = 0;
      this.designLengthInInches = 12;
    }
    this.colorCorrectionInfo.isColorCorrectionEnabled = false;
  }

  get totalWidthInInches(): number {
    return this.calculatedTotalWidthInInches;
  }
}
