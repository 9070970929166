import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { PkgThemeService } from '@shared/theme/pkg-theme.service';

@Component({
  selector: 'pkg-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  get releaseNumber(): string {
    return environment.releaseNumber;
  }

  get getLogoStyle() {
    const backgroundStyles = {
      filter: this._pkgThemeService.getTheme() === 'light-theme' ?
      'invert(17%) sepia(40%) saturate(2919%) hue-rotate(173deg) brightness(90%) contrast(103%)' :
      'invert(100%) sepia(47%) saturate(21%) hue-rotate(102deg) brightness(105%)'
    };
    return backgroundStyles;
  }

  constructor(
    private readonly _router: Router,
    private readonly _pkgThemeService?: PkgThemeService) { }

  ngOnInit(): void {
  }

  goHome(): void {
    this._router.navigate(['/home']);
  }

}
