import { Component, OnInit, OnDestroy } from '@angular/core';
import { ColorManagementSystem, ColorManagementSystemType } from '@shared/color-management-system';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, of } from 'rxjs';
import { AlertsService } from '@shared/alerts/alerts.service';
import { takeUntil, map, switchMap } from 'rxjs/operators';
import { ColorManagementSystemService } from '@app/pages/admin/shared/color-management-system.service';
import { AlertType } from '@shared/alerts/alert-type.enum';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'pkg-color-management-system-edit',
  templateUrl: './color-management-system-edit.component.html',
  styleUrls: ['./color-management-system-edit.component.scss']
})
export class ColorManagementSystemEditComponent implements OnInit, OnDestroy {

  colorManagementSystem: ColorManagementSystem;
  colorManagementSystemForm: FormGroup;
  title = 'Edit';
  isAddColorManagementSystemRoute: boolean = this.router.url === '/admin/colormanagementsystem/add';
  loading = true;
  showDelete = false;
  private unsubscribe$ = new Subject();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly colorManagementSystemService: ColorManagementSystemService,
    private readonly alertsService: AlertsService,
    private readonly formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.unsubscribe$), switchMap((params) => {
      if (this.isAddColorManagementSystemRoute) {
        this.title = 'Add';
        return of(new ColorManagementSystem(null, ''));
      } else {
        return this.colorManagementSystemService.get(params.get('colorManagementSystemId'));
      }
    })).subscribe(colorManagementSystem => {
      this.colorManagementSystem = colorManagementSystem;
      this.initializeForm();
      this.loading = false;
    }, (err) => {
      this.alertsService.add('Failed to load color management system.', err, AlertType.Error);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initializeForm() {
    // Init form values
    this.colorManagementSystemForm = this.formBuilder.group({
      name: [this.colorManagementSystem.name, [Validators.required, Validators.pattern('^.{4,255}$')]],
      audience: [this.colorManagementSystem.audience, [Validators.required, Validators.pattern('^.{4,255}$')]],
      jobPriority: [this.colorManagementSystem.jobPriority, [Validators.required, Validators.min(1), Validators.max(10)]],
      type: [this.colorManagementSystem.type ?? ColorManagementSystemType.ColorServer, [Validators.required]],
      templateName: [this.colorManagementSystem.templateName, [Validators.required, Validators.maxLength(510)]],
      colorTemplateName: [this.colorManagementSystem.colorTemplateName, [Validators.required, Validators.maxLength(510)]],
      normalizationTemplateName: [this.colorManagementSystem.normalizationTemplateName, [Validators.required, Validators.maxLength(510)]],
      pathToSourcePdfs: [this.colorManagementSystem.pathToSourcePdfs, [Validators.required, Validators.maxLength(510)]],
      pathToColorCorrectedPdfs: [this.colorManagementSystem.pathToColorCorrectedPdfs, [Validators.required, Validators.maxLength(510)]],
      isEnabled: [!!this.colorManagementSystem.isEnabled ? this.colorManagementSystem.isEnabled : false, Validators.required],
      isInTestMode: [!!this.colorManagementSystem.isInTestMode ? this.colorManagementSystem.isInTestMode : false, Validators.required]
    });
  }

  isValid(field: string): boolean {
    return !this.colorManagementSystemForm.get(field).valid && this.colorManagementSystemForm.get(field).touched;
  }

  saveColorManagementSystem() {
    // Set values from form
    this.colorManagementSystem = Object.assign({}, this.colorManagementSystem, this.colorManagementSystemForm.value);

    if (this.isAddColorManagementSystemRoute) {
      this.addColorManagementSystem();
    } else {
      this.editColorManagementSystem();
    }
  }

  addColorManagementSystem() {
    this.loading = true;
    this.colorManagementSystemService.add(this.colorManagementSystem)
      .subscribe(result => {
        this.alertsService.add('Saved', `Color management system added.`, AlertType.Success);
        this.loading = false;
        this.router.navigate(['/admin/colormanagementsystem']);
      }, (error) => {
        this.loading = false;
        this.showError(error);
      });
  }

  editColorManagementSystem() {
    this.loading = true;
    this.colorManagementSystemService.edit(this.colorManagementSystem)
      .subscribe(result => {
        this.alertsService.add('Saved', `Color management system updated.`, AlertType.Success);
        this.loading = false;
        this.router.navigate(['/admin/colormanagementsystem']);
      }, (error) => {
        this.loading = false;
        this.showError(error);
      });
  }

  confirmDelete() {
    this.showDelete = true;
  }

  delete() {
    this.loading = true;
    this.colorManagementSystemService.delete(this.colorManagementSystem.id)
      .subscribe(result => {
        this.loading = false;
        this.alertsService.add('Deleted Color Management System', `Color Management System: ${this.colorManagementSystem.name}`, AlertType.Success);
        this.router.navigate(['/admin/colormanagementsystem']);
      }, (err) => {
        this.loading = false;
        this.alertsService.add('Failed to deleted Color Management System', `Color Management System: ${this.colorManagementSystem.name}`, AlertType.Success);
      });
  }

  cancel() {
    this.router.navigate(['/admin/colormanagementsystem']);
  }

  private showError(error: HttpErrorResponse): void {
    this.alertsService.add('Failed to save', error.error.message, AlertType.Error);
  }

}
