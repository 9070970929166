import { DesignType } from '../design-type.enum';
import { MlpaLane } from '../mlpa-lane';
import { LaneProperties } from './lane-properties.interface';
import { deepCopyProperties } from '@shared/pkg-helper';

export class EmptyLane extends MlpaLane {
  constructor(laneProperties: LaneProperties) {
    super(laneProperties.mlpaLaneApi);
    // loop through properties to perform a deep copy of properties
    deepCopyProperties(this, laneProperties.initialProperties);

    laneProperties.initialProperties.designWidthInInches = 0;
    this.setInitialLaneProperties(laneProperties.initialProperties, DesignType.EMPTY);
    this.isTransposed = false;
    this.originalNumberOfPages = this.designNumberOfPages;
    this.calculateNumberUps(laneProperties);
    this.calculateEachesFromLineBreak(laneProperties);
    this.colorCorrectionInfo.isColorCorrectionEnabled = false;
  }

  calculateEachesFromLineBreak(laneProperties: LaneProperties): void {
    const lineBreakInFeet = laneProperties.initialProperties.lineBreakInFeet;
    if (lineBreakInFeet > 0) {
      const additionalEaches = this.designLengthInInches / 12;

      // logic to handle multipage for webweaves
      if (this.designNumberOfPages > 1) {
        // set the quantity to be 1 since its multipage
        this.quantity = 1;
        this.designNumberOfPages = this.designNumberOfPages + Math.ceil(lineBreakInFeet / additionalEaches);
      } else {
        this.quantity = this.quantity + Math.ceil(lineBreakInFeet / additionalEaches);
      }
    }
  }

  calculateNumberUps(laneProperties: LaneProperties): void {
    if (laneProperties?.nearestLane?.designNumberUp > 0) {
      this.quantity = this.quantity / (laneProperties?.nearestLane?.designNumberUp * laneProperties.nearestLane?.numberOfRibbons);
    }
  }

  get totalWidthInInches(): number {
    return this.calculatedTotalWidthInInches;
  }
}
