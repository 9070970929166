export class MlpaLaneColorCorrectionInfo {
  isColorCorrectionEnabled: boolean;
  isColorCorrectionProcessing: boolean;
  isColorCorrectionProcessed: boolean;
  colorCorrectionProcessingPercentage: number;
  printMode: string;
  inkColors: string;
  overPrintVarnish: string;
  substrateWeight: string;
  speed: string;
  pressType: string;
  inkWeight: string;
  totalAreaCoverage: string;
}
