<div class="sticky">
  <div class="page__header header--dashboard">
    <pkg-material-type-search (paperTypesEventEmitter)="setResults($event)"
      (termSearchedEmitter)="setSearchedTerm($event)"
      (materialTypeSelectionEmitter)="setMaterialTypesSelection($event)"></pkg-material-type-search>
  </div>

  <div *ngIf="foundPaperTypes" class="page-content card">
    <div class="paper-types-container-card">
      <div class="save-changes-container" *ngIf="foundPaperTypes">
        <ng-container>
          <button title="Save" (click)="savePaperType()" [disabled]="this.updatedPaperTypes?.length == 0"
            class="button button--green button--small button-fab">
            <span class="material-icons">&#xE161;</span>
          </button>
        </ng-container>
      </div>
      <div class="paper-types-scrollable-vew paper-type-container" pkgInfinite (loadData)="loadMorePaperTypes()">
        <div *ngFor="let paperType of paperTypes" class="row">
          <div class="paper-type-info">
            <div class="paper-type-info__text">
              <span class="paper-type">{{paperType.name}}</span>
            </div>
            <div>
              <div *ngIf="paperType.materialType==1" class="status-chip status-chip--text">Default</div>
              <div *ngIf="paperType.materialType==2" class="status-chip status-chip--text material-type-kraft-chip">
                Kraft</div>
            </div>
            <div>
              <select class="info" [(ngModel)]="paperType.materialType" (change)="materialTypeChanged(paperType);">
                <option *ngFor="let material of materialTypes | enum" [ngValue]="material.id">{{material.name}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="btn-load-more">
          <button *ngIf="!hasLoadedAllPaperTypes && paperTypes?.length > 0"
            [disabled]="paperTypesLoading || hasLoadedAllJobs" [style.margin]='"auto"' class="button button--primary"
            (click)="loadMorePaperTypes()">
            <span>Load more Paper types.</span>
            <span *ngIf="paperTypesLoading" class="loading"></span>
          </button>
        </div>
      </div>

    </div>
  </div>
  <div *ngIf="!foundPaperTypes" class="no-paper-types-message">
    <span class="material-icons">&#xE645;</span>
    <div class="mlpa-message">
      <div class="mlpa-message__title">No Results</div>
      <div>No Paper types were found that match the search criteria.</div>
    </div>
  </div>
  <div *ngIf="(paperTypes?.length === 0 && paperTypesLoading) || isSaving" class="loading-message">
    <img class="logo" src="assets/small-logo-dark.svg" [ngStyle]="getLogoStyle" alt="loading logo" />
    <span class="loading"></span>
  </div>
  <div *ngIf="isSaving" class="loading_overlay"></div>
</div>
