import { Component, OnInit, OnDestroy, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MlpaJobsStats } from './mlpa-jobs-stats';
import { interval, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import { formatDate } from '@angular/common';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'pkg-mlpa-jobs-stats',
  templateUrl: './mlpa-jobs-stats.component.html',
  styleUrls: ['./mlpa-jobs-stats.component.scss']
})
export class MlpaJobsStatsComponent implements OnInit, OnDestroy {
  @Output() isLoading = new EventEmitter<boolean>(true);
  public mlpaJobsStats: MlpaJobsStats;

  colors = {
    successful:       '#388E3C',
    failed:           '#C1272D',
    webWeave:         '#8E24AA',
    smartLine:        '#B02ED3',
    empty:            '#C058DC',
    queued:           '#999999',
    processing:       '#66A2CC',
    processed:        '#0077CC',
    created:          '#0077CC',
    createdHover:     '#66A2CC',
    generated:        '#F4511E',
    generatedHover:   '#F39173',
    submitted:        '#388E3C',
    submittedHover:   '#5ECA63'
  };

  chartData: ChartDataSets[] = [{ data: [], label: '' }];

  chartLabels: Label[];

  chartLegend = true;

  chartType = 'line';

  chartPlugins = [];

  chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    title: {
      display: false
    },
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    legend: {
      display: false
    },
    scales: {
      xAxes: [{
        ticks: {
          fontFamily: 'Source Sans Pro',
          fontStyle: 'normal',
          fontSize: 16,
          fontColor: '#777777',
          minRotation: 60,
          maxRotation: 90
        },
        gridLines: { color: '#EFEFEF' }
      }],
      yAxes: [{
        ticks: {
          fontFamily: 'Source Sans Pro',
          fontStyle: 'normal',
          fontSize: 16,
          fontColor: '#777777'
        },
        gridLines: { color: '#EFEFEF' },
      }],
    },
    elements: {
    },
    layout: {
      padding: {
        left: 10, right: 20, top: 20, bottom: 0
      }
    },
  };

  private _timedSubscription: Subscription;

  constructor(private readonly _http: HttpClient) { }

  ngOnInit(): void {
    this._timedSubscription = interval(30000).pipe(startWith(0)).subscribe(() => {
      this._loadStats();
    });
  }

  ngOnDestroy(): void {
    if (this._timedSubscription) {
      this._timedSubscription.unsubscribe();
    }
  }

  private _loadStats(): void {
    this.isLoading.emit(true);
    this._http.get<MlpaJobsStats>(`${environment.tartanApiUrl}mlpa-jobs/stats`).subscribe(
      data => {
        this.mlpaJobsStats = new MlpaJobsStats(data);
        this._refreshChart();
        this.isLoading.emit(false);
      },
      error => {
        // this.alerts.add('Failed to retreive MLPA job stats', error.error.message, AlertType.Error);
        this.isLoading.emit(false);
      }
    );
  }

  private _refreshChart(): void {
    // No data, don't refresh
    if (!this.mlpaJobsStats) {
      return;
    }

    // Store data arrays
    const labels: Label[] = [];
    const createdJobs: number[] = [];
    const submittedJobs: number[] = [];
    const generatedJobs: number[] = [];

    // Build arrays for created jobs
    for (const jobCreated of this.mlpaJobsStats.numberOfJobsCreatedOverLast30Days) {
      const date = formatDate(jobCreated.date, 'MMM d', 'en-us');
      labels.push(date);
      createdJobs.push(jobCreated.value);
    }

    // Build arrays for submitted jobs
    for (const jobSubmitted of this.mlpaJobsStats.numberOfJobsSubmittedOverLast30Days) {
      submittedJobs.push(jobSubmitted.value);
    }

    // Build arrays for generated jobs
    for (const jobGenerated of this.mlpaJobsStats.numberOfJobsGeneratedOverLast30Days) {
      generatedJobs.push(jobGenerated.value);
    }

    // Set data and labels
    this.chartData = [
      {
        data: createdJobs,
        label: 'Created',
        fill: false,
        borderColor: this.colors.created,
        borderWidth: 2,
        pointHitRadius: 10,
        pointBackgroundColor: this.colors.created,
        pointHoverBackgroundColor: this.colors.createdHover,
        pointBorderColor: this.colors.created,
        pointHoverBorderColor: this.colors.createdHover,
        pointBorderWidth: 1,
        pointHoverBorderWidth: 2,
        pointStyle: 'circle'
        // steppedLine: 'middle'
      },
      {
        data: generatedJobs,
        label: 'Generated',
        fill: false,
        borderColor: this.colors.generated,
        borderWidth: 2,
        pointHitRadius: 10,
        pointBackgroundColor: this.colors.generated,
        pointHoverBackgroundColor: this.colors.generatedHover,
        pointBorderColor: this.colors.generated,
        pointHoverBorderColor: this.colors.generatedHover,
        pointBorderWidth: 1,
        pointHoverBorderWidth: 2,
        pointStyle: 'circle'
        // steppedLine: 'middle'
      },
      {
        data: submittedJobs,
        label: 'Submitted',
        fill: false,
        borderColor: this.colors.submitted,
        borderWidth: 2,
        pointHitRadius: 10,
        pointBackgroundColor: this.colors.submitted,
        pointHoverBackgroundColor: this.colors.submittedHover,
        pointBorderColor: this.colors.submitted,
        pointHoverBorderColor: this.colors.submittedHover,
        pointBorderWidth: 1,
        pointHoverBorderWidth: 2,
        pointStyle: 'circle'
        // steppedLine: 'middle'
      }
    ];
    this.chartLabels = labels;
  }
}
