export enum MaterialTypes {
  Default = 1,
  Kraft   = 2
}

export class MaterialType {
  value: number;
  name: string;
  isActive: boolean;
}
