import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MlpaJob } from '../../../mlpa/mlpa-job/mlpa-job';
import { MlpaJobService } from '../../../mlpa/mlpa-job/mlpa-job.service';
import { Router } from '@angular/router';


@Component({
  selector: 'pkg-mlpa-job-modal',
  templateUrl: './mlpa-job-modal.component.html',
  styleUrls: ['./mlpa-job-modal.component.scss'],
})
export class MlpaJobModalComponent implements OnInit {
  @Input() showConfirm = false;
  @Input() showDelete = false;
  @Input() mlpaJob: MlpaJob;

  @Output() delete = new EventEmitter();
  @Output() submitted = new EventEmitter();

  @Output() closeConfirm = new EventEmitter();
  @Output() closeDelete = new EventEmitter();

  isSubmitting = false;
  isDeleting = false;
  constructor(private readonly _mlpaJobService: MlpaJobService, private readonly _router: Router) { }

  ngOnInit(): void {
  }

  closeSubmit(): void {
    this.showConfirm = false;
    this.showDelete = false;
    this.closeConfirm.emit(false);
    this.closeDelete.emit(false);
  }

  confirmSubmit(): void {
    this.isSubmitting = true;
    this._mlpaJobService.submit(this.mlpaJob).subscribe((result) => {
      // if completed
      if (result) {
        this.isSubmitting = false;
        this.submitted.emit(this.isSubmitting);
      }

      this.showConfirm = false;
      this.closeConfirm.emit(false);
    },
      () => {
        this.isSubmitting = false;
        this.submitted.emit(this.isSubmitting);
        this.showConfirm = false;
        this.closeConfirm.emit(false);
      });
  }

  confirmDelete(): void {
    this.isDeleting = true;
    this._mlpaJobService.deleteJob(this.mlpaJob).subscribe((result) => {
      if (result) {
        this.isDeleting = false;
        this.delete.emit();
      }

      this.showDelete = false;
      this.closeDelete.emit(false);
    });
  }
}
