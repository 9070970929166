import { PrintProcessorPayload } from './print-processor-payload.model';
import { PrintProcessorType } from './print-processor-type.enum';
import { StatusResults } from './status-page/status-results';

export class PrintProcessor {
  id: string;
  name: string;
  audience: string;
  type: PrintProcessorType;
  isEnabled: boolean;
  isInTestMode: boolean;
  createdDate: Date;
  createdBy: string;
  updatedBy: string;
  updatedDate: Date;
  healthStatus: StatusResults;

  constructor(id = null, name = null) {
    this.id = id;
    this.name = name;
  }

  buildForSave(): PrintProcessorPayload {
    const { name, audience, type, isEnabled, isInTestMode } = this;

    return { name, audience, type, isEnabled, isInTestMode };
  }
}
