<div *ngIf="show" class="modal-container">
  <div *ngIf="show" class="modal modal--small">
    <div class="modal__header">
      <h3>Confirm Clone</h3>
      <button class="icon-button" (click)="close()">
        <i class="material-icons">&#xE5CD;</i>
      </button>
    </div>
    <div class="modal__content">
      <p class="submit-details">Are you sure you want to clone the MLPA Job, <strong>{{mlpaJob.name}}</strong>?</p>
    </div>
    <div class="modal__controls">
      <button class="button button--green" (click)="clone()" type="button confirm-clone__button-ga" [disabled]="showSaving">
        <i class="material-icons">&#xE2C6;</i>
        <span>Clone</span>
      </button>
      <button class="button" (click)="edit()" type="button edit-clone__button-ga" [disabled]="showSaving">
        <i class="material-icons">&#xE3C9;</i>
        <span>Edit</span>
      </button>
      <button class="button" (click)="close()" type="button" [disabled]="showSaving">
        <i class="material-icons">&#xE5C9;</i>
        <span>Cancel</span>
      </button>
      <div *ngIf="showSaving" class="modal__loading">
        <div class="loading loading--small"></div>
        <span>Saving MLPA</span>
      </div>
    </div>
  </div>
</div>
