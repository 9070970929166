import { Directive, Input, OnInit, ViewContainerRef, TemplateRef } from '@angular/core';
import { Role } from '../../pages/admin/shared/role';
import { AuthService } from '../../auth/auth.service';


enum Operation {
  AND = 'and',
  OR = 'or',
}


@Directive({
  selector: '[pkgHasRole]'
})
export class HasRoleDirective {
  isVisible = false;
  context: any = '';
  hasRole: boolean;

  operation = Operation.AND;

  constructor(private readonly _authService: AuthService,
    private readonly _viewContainerRef: ViewContainerRef,
    private readonly _templateRef: TemplateRef<any>) { }

  @Input()
  set pkgHasRole(roles: Role[]) {
    // if it has the role then show it, for now we wrap auth.role
    // in an array until the future users can have more than 1 role
    this._authService.tokenReady$.subscribe((ready) => {
      if (ready) {
        this._checkIfRoleIsValid(roles);
      }
    });

    this._checkIfRoleIsValid(roles);
  }

  @Input()
  set pkgHasRoleAnd(condition) {
    this.context = condition;
    this.operation = Operation.AND;
    this._updateView();
  }

  @Input()
  set pkgHasRoleOr(condition) {
    this.context = condition;
    this.operation = Operation.OR;
    this._updateView();
  }

  private _checkIfRoleIsValid(roles: Role[]): void {
    if (this._authService.user != null && [this._authService.user.role].some(role => roles.includes(role))) {
      if (!this.isVisible) {
        this.hasRole = true;
      }
    } else {
      this.hasRole = false;
    }
    this._updateView();
  }

  private _updateView(): void {
    if (this.context !== '') {
      if (this.operation === Operation.AND) {
        if (this.context && this.hasRole) {
          if (!this.isVisible) {
            this.isVisible = true;
            this._viewContainerRef.createEmbeddedView(this._templateRef);
          }
        } else {
          this.isVisible = false;
          this._viewContainerRef.clear();
        }
      } else {
        if (this.context || this.hasRole) {
          if (!this.isVisible) {
            this.isVisible = true;
            this._viewContainerRef.createEmbeddedView(this._templateRef);
          }
        } else {
          this.isVisible = false;
          this._viewContainerRef.clear();
        }
      }
    } else {
      if (this.hasRole && this.context === '') {
        if (!this.isVisible) {
          this.isVisible = true;
          this._viewContainerRef.createEmbeddedView(this._templateRef);
        }
      } else {
        this.isVisible = false;
        this._viewContainerRef.clear();
      }
    }
  }
}

