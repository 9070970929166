import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MlpaJobView } from './view-mlpa-jobs/mlpa-job-view';
import { PagedResult } from '@shared/paged-result';
import { MlpaJobApi } from '@shared/mlpa-job-api';
import { MlpaJobsSearchService } from '@shared/mlpa-jobs-search/mlpa-jobs-search.service';
import { interval, Subject, of, forkJoin } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { MlpaJobsSearchComponent } from '@shared/mlpa-jobs-search/mlpa-jobs-search.component';
import { MlpaJobSettings } from '../../mlpa/mlpa-job-settings/mlpa-job-settings';
import { takeUntil, catchError } from 'rxjs/operators';
import { SearchFilter } from '@shared/mlpa-jobs-search/search-filter.interface';
import { MlpaService } from '../mlpa/shared/mlpa.service';
import { MlpaJobOptionsService } from '../../../app/shared/mlpa-job-options/mlpa-job-options.service';
import { AlertsService } from '../../../app/shared/alerts/alerts.service';
import { AlertType } from '../../../app/shared/alerts/alert-type.enum';
import { MlpaJob } from '../../../app/mlpa/mlpa-job/mlpa-job';
import { getTotalLengthOfLanes } from '../../../app/mlpa/mlpa-helper';
import { MlpaStateService } from '../mlpa/shared/mlpa-state.service';
import { MlpaLaneType } from '../../../app/mlpa/mlpa-lane/mlpa-lane-type.enum';
import { ColorManagementSystemSettings } from '@app/mlpa/color-management-system-settings/color-management-system-settings';

@Component({
  selector: 'pkg-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {

  @ViewChild(MlpaJobsSearchComponent, { static: true }) mlpaJobSearchComponent: MlpaJobsSearchComponent;
  selectedJob: MlpaJobView;
  loading: any;
  showLoadMore: boolean;
  hasLoadedAllJobs: any;
  currentPageNumber: number;
  mlpaJobs: MlpaJobView[] = [];
  isSearching: boolean;
  searchResults: PagedResult<MlpaJobApi>;
  searchedTerm = '';
  foundJobs: boolean;
  jobSettings: MlpaJobSettings;
  colorManagementSystemSettings: ColorManagementSystemSettings;
  mlpaJob: MlpaJob;
  detailsIsFullScreen = false;
  searchFilter: SearchFilter;
  totalWidthInInches: number;
  mlpaLaneType = MlpaLaneType;
  private _unsubscribe$ = new Subject();

  constructor(
    private readonly _mlpaService: MlpaService,
    private _mlpaJobSearchService: MlpaJobsSearchService,
    public readonly _authService: AuthService,
    private readonly _mlpaJobOptionsService: MlpaJobOptionsService,
    private readonly _alerts: AlertsService,
    private readonly _mlpaLaneState: MlpaStateService,
    private readonly _cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this._mlpaJobSearchService.searchFilter$
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((result) => {
        this.searchFilter = result;
        // close the panel and unselect the job since we are changing the filter.
        this.selectedJob = null;
        // filters were reset so get the original list when loading.
        if (result === null) {
          this._getJobs();
        }
      });

    interval(7000).pipe(takeUntil(this._unsubscribe$)).subscribe(() => this._checkJobStatus());

    // Get job options from service
    this._mlpaJobOptionsService.getMlpaJobOptions(false).subscribe(mlpaJobOptionAssets => {
      this.jobSettings = new MlpaJobSettings(mlpaJobOptionAssets);

    }, (err) => {
      this._alerts.add('Failed to load MLPA job options', err.error.message, AlertType.Error);
    });

    // Get color management systems
    const colorManagementSystems = this._mlpaJobOptionsService.getColorManagementSystems().pipe(catchError((error) => {
      this._alerts.add('Failed to load Color Management Systems', 'Please try again', AlertType.Error);
      return of(undefined);
    }));

    // Get characterization values
    const characterizationValues = this._mlpaJobOptionsService.getCharacterizationValues().pipe(catchError((error) => {
      this._alerts.add('Failed to load Characterization Values', 'Please try again', AlertType.Error);
      return of(undefined);
    }));

    forkJoin([colorManagementSystems, characterizationValues]).subscribe((results) => {
      this.colorManagementSystemSettings = new ColorManagementSystemSettings(results[0].results, results[1]);
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
    this._mlpaJobSearchService.searchFilterSubject$.next(null);
  }

  toggleDetailsFull(): void {
    this.detailsIsFullScreen = !this.detailsIsFullScreen;
  }
  setSelectedJob($event: MlpaJobView): void {
    // check which preview is currently active.
    if ($event != null) {
      if ($event.isCurrentPreviewButton) {
        $event.mlpaLanes.forEach((lane, index) => {
          $event.mlpaLanes[index].isCurrentPreviewButton = false;
        });
      } else if ($event.isCurrentPreviewButton === false) {
        for (let index = 0; index <= $event.mlpaLanes.length - 1; index++) {
          const lanePreview = $event.mlpaLanes[index].isCurrentPreviewButton;
          if (lanePreview === true) {
            $event.isCurrentPreviewButton = false;
            break;
          } else {
            $event.isCurrentPreviewButton = true;
          }
        }
      }
    }
    this.selectedJob = $event;
    this.mlpaJob = new MlpaJob($event);
    // Set color management system name(readonly) on preview
    if (!!this.selectedJob &&
      !!this.selectedJob.colorCorrectionInfo?.colorManagementSystemId &&
      !!this.colorManagementSystemSettings.colorManagementSystems &&
      !this.selectedJob.colorManagementSystemName) {
      this.selectedJob.colorManagementSystemName = this.colorManagementSystemSettings.colorManagementSystems.find(a =>
        a.id === this.selectedJob.colorCorrectionInfo.colorManagementSystemId).name;
    }
  }

  getJobSettings(mlpaJob: MlpaJob): MlpaJobSettings {
    const jobSettings = new MlpaJobSettings(this.jobSettings.assets);
    jobSettings.selectAsset(mlpaJob.assetName, mlpaJob.paperTypeName, mlpaJob.paperWidthInInches);
    let marginWidthInInches = 0;
    if (mlpaJob.mlpaLanes[1].laneType === MlpaLaneType.Graphic) {
      marginWidthInInches = mlpaJob.marginWidthInInches;
    }
    jobSettings.marginWidthInInches = marginWidthInInches;
    return jobSettings;
  }

  getColorManagementSystemSettings(mlpaJob: MlpaJob): ColorManagementSystemSettings {
    const colorManagementSystemSettings = new ColorManagementSystemSettings(this.colorManagementSystemSettings.colorManagementSystems, this.colorManagementSystemSettings.characterizationValues);
    colorManagementSystemSettings.selectColorManagementSystem(mlpaJob.colorCorrectionInfo?.colorManagementSystemId);
    return colorManagementSystemSettings;
  }

  getTotalWidthInInches(mlpaJob: MlpaJob): number {
    const totalWidthInInches = getTotalLengthOfLanes(mlpaJob.mlpaLanes);
    return totalWidthInInches;
  }

  scrollToTop(element: Element): void {
    element.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  setSearchedTerm(searchTerm: string): void {
    this.searchedTerm = searchTerm;

    if (this.searchedTerm == null) {
      this.selectedJob = null;
      this.detailsIsFullScreen = false;
    }
  }

  refreshJobs(): void {
    this.mlpaJobSearchComponent.clear();
  }

  setMlpaJobs(mlpaJobsResult: PagedResult<MlpaJobApi>): void {
    this.mlpaJobs = [];
    if (mlpaJobsResult == null && this.searchedTerm != null) {
      this.foundJobs = false;
      this.detailsIsFullScreen = false;
      this.selectedJob = null;
      return;
    }
    this.currentPageNumber = 0;
    if (!!mlpaJobsResult) {
      this.searchResults = mlpaJobsResult;
      this.isSearching = true;
      this.foundJobs = true;
      mlpaJobsResult.results.forEach(job => {
        this.mlpaJobs.push(new MlpaJobView(job));
      });

      if (this.searchResults.totalCount === this.searchResults.resultsCount) {
        this.hasLoadedAllJobs = true;
      } else {
        this.hasLoadedAllJobs = false;
        this.loading = false;
      }

      return;
    }
    this.isSearching = false;
    this._getJobs();
  }

  loadMoreJobs(): void {
    if (this.loading || this.hasLoadedAllJobs) {
      return;
    }

    if (this.isSearching) {

      if (this.currentPageNumber === this.searchResults.totalPages) {
        this.hasLoadedAllJobs = true;
        return;
      }

      this.currentPageNumber = this.currentPageNumber === 0 ? 1 : this.currentPageNumber;
      this.currentPageNumber = this.currentPageNumber + 1;
      this._getSearchJobs(this.currentPageNumber, this.searchResults.pageSize);
    } else {
      this._getJobs(this.currentPageNumber + 1);
    }
  }

  private _getJobs(pageNumber: number = 1): void {
    this.loading = true;
    this.foundJobs = true;
    this.showLoadMore = true;
    if (this.searchFilter == null) {
      this._mlpaService.getJobs(pageNumber).pipe(takeUntil(this._unsubscribe$)).subscribe(data => {
        this._setResultedJobs(data);
      }, () => {
        this.loading = false;
      });
    } else {
      this._mlpaJobSearchService.searchMlpaJob(this.searchedTerm, pageNumber, this.searchResults.pageSize, this.searchFilter)
        .pipe(takeUntil(this._unsubscribe$)).subscribe((data) => {
          this._setResultedJobs(data);
        }, () => {
          this.loading = false;
        });
    }
  }

  private _getSearchJobs(pageNumber: number, pageSize: number): void {
    this.loading = true;
    this.foundJobs = true;
    this.showLoadMore = true;
    if (this.searchFilter == null) {
      this._mlpaJobSearchService.searchMlpaJob(this.searchedTerm, pageNumber, pageSize)
        .pipe(takeUntil(this._unsubscribe$))
        .subscribe(data => {
          this._setResultedJobs(data);
        }, () => {
          this.loading = false;
        });
    } else {
      this._mlpaJobSearchService.searchMlpaJob(this.searchedTerm, pageNumber, this.searchResults.pageSize, this.searchFilter)
        .pipe(takeUntil(this._unsubscribe$)).subscribe((data) => {
          this._setResultedJobs(data);
        }, () => {
          this.loading = false;
        });
    }
  }

  private _setResultedJobs(data: PagedResult<MlpaJobApi>): void {
    this.currentPageNumber = data.pageNumber;
    this.searchResults = data;
    data.results.forEach(r => this.mlpaJobs.push(new MlpaJobView(r)));
    if (data.pageNumber === data.totalPages) {
      this.hasLoadedAllJobs = true;
    } else {
      this.hasLoadedAllJobs = false;
    }
    this.loading = false;
  }

  private _checkJobStatus(): void {
    // Get valid Job IDs and check for status updates
    const jobIds = [];
    this.mlpaJobs.forEach(job => {
      if (job.isValidForStatusUpdates()) {
        jobIds.push(job.id);
      }
    });

    if (jobIds.length === 0) {
      return;
    }

    // Loop through each Job ID, get the latest info from the API, and then update the status
    jobIds.forEach(id => {
      this._mlpaService.getMlpaJob(id).pipe(takeUntil(this._unsubscribe$)).subscribe(data => {
        const mlpaJobToUpdate = this.mlpaJobs.find(mlpaJob => mlpaJob.id === data.id);
        if (mlpaJobToUpdate) {
          mlpaJobToUpdate.updateStatus(data);
        }
      });
    });
  }
}
