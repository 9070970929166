import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';

export const authConfig: AuthConfig = {
    issuer: 'https://auth.kochid.com',
    redirectUri: window.location.origin + '/login',
    silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
    clientId: environment.clientId,
    responseType: 'code',
    scope: 'orgInfo loginAccountInfo',
    disableAtHashCheck: true,
};
