import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { Theme } from './theme.enum';
import { ConfigStrategy } from '../config/config-strategy.enum';

@Injectable({
  providedIn: 'root'
})
export class PkgThemeService {
  currentTheme: string;
  constructor(private readonly _configService: ConfigService) {
    const currentThemeFromService = this._configService.getConfigValue(
      'currentTheme', ConfigStrategy.LOCAL_STORAGE
    );

    this.currentTheme =
      currentThemeFromService === '' || currentThemeFromService == null
        ? this.setTheme(Theme.LIGHT_THEME)
        : currentThemeFromService;
  }

  toggleTheme(): void {
    if (this.currentTheme == null) {
      this.setTheme(Theme.LIGHT_THEME);
    }

    if (this.currentTheme === Theme.LIGHT_THEME) {
      this.setTheme(Theme.DARK_THEME);
    } else {
      this.setTheme(Theme.LIGHT_THEME);
    }
  }

  setTheme(theme: Theme): void {
    this.currentTheme = theme;
    this._configService.setByValue(
      'currentTheme',
      this.currentTheme,
      ConfigStrategy.LOCAL_STORAGE
    );

    this.appendClassToBody(this.currentTheme);
  }

  getTheme(): string {
    if (this.currentTheme == null) {
      this.appendClassToBody(Theme.LIGHT_THEME);
      return Theme.LIGHT_THEME;
    }
    this.appendClassToBody(this.currentTheme);
    return this.currentTheme;
  }

  appendClassToBody(theme: string): void {
    document.body.classList.value = theme;
  }
}
