import { Component, OnInit } from '@angular/core';
import { DataLoggerAsset, DataLoggerType } from '@shared/data-logger-asset';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertsService } from '../../../../app/shared/alerts/alerts.service';
import { of } from 'rxjs';
import { DataLoggerService } from '../shared/data-logger.service';
import { AlertType } from '@shared/alerts/alert-type.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'pkg-data-logger-edit',
  templateUrl: './data-logger-edit.component.html',
  styleUrls: ['./data-logger-edit.component.scss']
})
export class DataLoggerEditComponent implements OnInit {
  dataLoggerAsset: DataLoggerAsset = new DataLoggerAsset();
  dataLoggerForm: FormGroup;
  title = 'Edit';
  dataLoggerType = DataLoggerType;
  isAddDataLoggerRoute: boolean = this.router.url === '/admin/datalogger/add';
  isLoading = true;
  showDelete = false;
  constructor(private readonly dataLoggerService: DataLoggerService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly alertsService: AlertsService,
    private readonly formBuilder: FormBuilder) { }

  ngOnInit() {
    this.initializeForm();
    this.route.paramMap.pipe(
      switchMap((params) => {
        if (this.isAddDataLoggerRoute) {
          this.title = 'Add';
          return of(new DataLoggerAsset(null, ''));
        } else {
          return this.dataLoggerService.get(params.get('dataLoggerId'));
        }
      }))
      .subscribe((data) => {
        this.dataLoggerAsset = data;
        this.initializeForm();
        this.isLoading = false;
      }, (err) => {
        this.isLoading = false;
        this.alertsService.add('Failed to load data Logger', 'Please try again later', AlertType.Error);
      });
  }

  initializeForm() {
    this.dataLoggerForm = this.formBuilder.group({
      name: [this.dataLoggerAsset.name, [Validators.required, Validators.pattern('^.{4,255}$')]],
      audience: [this.dataLoggerAsset.audience, Validators.required],
      type: [!!this.dataLoggerAsset.type ? this.dataLoggerAsset.type : this.dataLoggerType.SmartLine, Validators.required],
      isEnabled: [!!this.dataLoggerAsset.isEnabled ? this.dataLoggerAsset.isEnabled : false, Validators.required],
      isInTestMode: [!!this.dataLoggerAsset.isInTestMode ? this.dataLoggerAsset.isInTestMode : false, Validators.required]
    });
  }

  isValid(field: string) {
    return !this.dataLoggerForm.get(field).valid && this.dataLoggerForm.get(field).touched;
  }

  cancel() {
    this.router.navigate(['/admin/asset']);
  }

  saveDataLogger() {
    if (this.dataLoggerAsset instanceof DataLoggerAsset) {
      this.dataLoggerAsset = Object.assign(this.dataLoggerAsset, this.dataLoggerForm.value);
    } else {
      const copy = Object.assign(this.dataLoggerAsset, this.dataLoggerForm.value) as DataLoggerAsset;
      this.dataLoggerAsset = new DataLoggerAsset(copy.id, copy.name);
      this.dataLoggerAsset = Object.assign(this.dataLoggerAsset, copy);
    }

    if (this.isAddDataLoggerRoute) {
      this.addDataLogger();
      return;
    }

    if (this.dataLoggerAsset.id) {
      this.editDataLogger();
    } else {
      this.alertsService.add(
        'Failed to save',
        `There is not an id associated with this Data Logger, (Invalid entry ${this.dataLoggerAsset.id})`,
        AlertType.Error);
    }
  }

  confirmDelete() {
    this.showDelete = true;
  }
  delete() {
    this.isLoading = true;
    this.dataLoggerService.deleteDataLogger(this.dataLoggerAsset.id).subscribe((result) => {
      this.isLoading = false;
      this.alertsService.add('Deleted Data Logger', `Data Logger: ${this.dataLoggerAsset.name}`, AlertType.Success);
      this.router.navigate(['/admin/asset']);
    }, (err) => {
      this.isLoading = false;
      this.alertsService.add('Failed to delete Data Logger', `Data Logger: ${this.dataLoggerAsset.name}`, AlertType.Error);
    });
  }

  private addDataLogger() {
    this.isLoading = true;
    this.dataLoggerService.addDataLogger(this.dataLoggerAsset)
      .subscribe((complete) => {
        this.isLoading = false;
        this.alertsService.add('Saved', `Data Logger Added.`, AlertType.Success);
        this.router.navigate(['/admin/asset']);
      }, (error) => {
        this.isLoading = false;
        this.showError(error);
      });
  }


  private editDataLogger() {
    this.isLoading = true;
    this.dataLoggerService.editDataLogger(this.dataLoggerAsset)
      .subscribe((complete) => {
        this.isLoading = false;
        this.alertsService.add('Saved', `Data Logger Updated.`, AlertType.Success);
        this.router.navigate(['/admin/asset']);
      }, (error) => {
        this.isLoading = false;
        this.showError(error);
      });
  }


  private showError(error: HttpErrorResponse) {
    this.alertsService.add('Failed to save', error.error.message, AlertType.Error);
  }
}
