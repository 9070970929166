import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, Subscription, throwError, BehaviorSubject } from 'rxjs';

import { environment } from '../../environments/environment';
import { User } from '../pages/admin/shared/user';
import { TokenService } from './token.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from './auth.config';
import { AlertsService } from '@shared/alerts/alerts.service';
import { AlertType } from '@shared/alerts/alert-type.enum';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { IAuthService } from './auth.interface';
import { filter } from 'rxjs/operators';

@Injectable()
export class AuthService implements IAuthService, OnDestroy {
  user: User;
  userSubject = new BehaviorSubject<User>(null);
  readonly user$: Observable<User> = this.userSubject.asObservable();
  tokenSubscription: Subscription;
  tokenRefresh: Subscription;
  discoveryDocument: Subscription;
  discoveryError: Subscription;
  windowsTokenTimer: Subscription;
  windowsTokenRefresh: Subscription;
  kochIdTokenRefresh: Subscription;
  public tokenReady$ = new Subject<boolean>();
  public isAuthenticating$ = new Subject<boolean>();
  public oAuthReady$ = new BehaviorSubject<boolean>(false);
  get redirectUrl(): string {
    return this._url ? this._url : '/home';
  }
  set redirectUrl(url: string) {
    this._url = url;
  }

  private _url: string;
  private _subject = new Subject<boolean>();
  private _idMethod = 'idMethod';
  private _kochIdMethod = 'KochId';
  private _kochIdStarted = 'kochIdStarted';
  private _windowsIdMethod = 'WindowsAuth';

  constructor(
    private readonly _http: HttpClient,
    private readonly _tokenService: TokenService,
    private readonly _oAuthService: OAuthService,
    private readonly _alerts: AlertsService,
    private readonly _router: Router) {
    this._oAuthService.configure(authConfig);
    this._oAuthService.setStorage(localStorage);
    this._oAuthService.loadDiscoveryDocumentAndTryLogin();

    this.tokenSubscription = this._oAuthService.events.pipe(
      filter(f => f.type === 'token_received'))
      .subscribe(() => {
        this.setKochIdToken();
      });

    this.discoveryError = this._oAuthService.events
      .subscribe(e => {
        if (e.type === 'discovery_document_load_error') {
          this._alerts.add('KochID Error', `Error loading Discovery Document: ${e['reason']['message']}`, AlertType.Error);
          this.oAuthReady$.next(false);
        }

        if (e.type === 'token_refresh_error') {
          this._oAuthService.logOut();
        }
      });

    this.discoveryDocument = this._oAuthService.events.pipe(
      filter(f => f.type === 'discovery_document_loaded'))
      .subscribe(() => this.oAuthReady$.next(true));

    if (this._oAuthService.hasValidAccessToken()) {
      this.setKochIdToken();
    }
  }

  ngOnDestroy(): void {
    this.tokenRefresh.unsubscribe();
    this.tokenSubscription.unsubscribe();
    this.discoveryDocument.unsubscribe();
    this.discoveryError.unsubscribe();
    this.userSubject.unsubscribe();
  }

  autoLogin(): string {
    if (this._oAuthService.hasValidAccessToken()) {
      this.setKochIdToken();
      return 'Valid Token';
    }

    switch (localStorage.getItem(this._idMethod)) {
      case this._kochIdMethod:
        if (!localStorage.getItem(this._kochIdStarted)) {
          return 'Koch';
        }
        break;
      case this._windowsIdMethod:
        return 'Windows';
      default:
        return null;
    }

  }
  getKochId(): void {
    localStorage.setItem(this._idMethod, this._kochIdMethod);
    localStorage.setItem(this._kochIdStarted, (true).toString());
    this._oAuthService.initLoginFlow();
  }
  setKochIdToken(): void {
    this.isAuthenticating$.next(true);
    this._tokenService.reset();

    if (this.kochIdTokenRefresh) {
      this.kochIdTokenRefresh.unsubscribe();
    } else {
      this.kochIdTokenRefresh = null;
    }

    const expiresIn = (this._oAuthService.getAccessTokenExpiration() - Date.now()) / 1000;
    this.kochIdTokenRefresh = this._tokenService.set(this._oAuthService.getAccessToken(), expiresIn)
      .subscribe(() => {
        this._oAuthService.refreshToken();
      });
    if (!this.user) {
      this.login().toPromise().then((data) => {
        this.user = data;
        this.userSubject.next(data);
        this.tokenReady$.next(true);
        this.isAuthenticating$.next(false);
      });
    }
  }
  getToken(): Observable<any> {
    localStorage.setItem(this._idMethod, this._windowsIdMethod);

    // reset the token related properties so subscriptions and such don't double up
    this._tokenService.reset();
    if (this.windowsTokenRefresh) {
      this.windowsTokenRefresh.unsubscribe();
    } else {
      this.windowsTokenRefresh = null;
    }

    if (this.windowsTokenTimer) {
      this.windowsTokenTimer.unsubscribe();
    } else {
      this.windowsTokenTimer = null;
    }

    // get the new token
    return this._http.get(`${environment.tartanUrl}oauth/token`, { withCredentials: true })
      // Catch errors on token retrieval and alert the user with a cryptic message
      .pipe(catchError(err => {
        this._alerts.add('Token error', err['message'], AlertType.Error);
        return throwError(err);
      }), tap(data => {
        // save the new token
        this.windowsTokenTimer = this._tokenService.set(data['token'], data['lifetimeInSeconds'])
          .subscribe(() => {
            // get a new token when this one expires
            this.windowsTokenRefresh = this.getToken().subscribe();
          });
      }));
  }
  login(): Observable<User> {
    return this._http.get<User>(`${environment.tartanApiUrl}users/login`)
      .pipe(catchError((err, caught) => {
        if (err['status'] >= 400 && err['status'] < 500) {
          this._router.navigate(['unauthorized']);
        }
        return throwError(caught);
      }), tap(data => {
        this.userSubject.next(data);
        this.user = data;
      }));
  }

  logOut(): void {
    localStorage.removeItem(this._idMethod);
    localStorage.removeItem(this._kochIdStarted);
    this._tokenService.reset();
    this.user = null;
    this._oAuthService.logOut();
    this._router.navigate(['login']);
  }

  sendStatus(status: boolean): void {
    this._subject.next(status);
  }

  clearStatus(): void {
    this._subject.next();
  }

  getStatus(): Observable<boolean> {
    return this._subject.asObservable();
  }
}
