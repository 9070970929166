import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AlertType } from '@shared/alerts/alert-type.enum';
import { AlertsService } from '@shared/alerts/alerts.service';
import { MultiSelectDropDownSetting } from '@shared/multi-select-dropdown/multi-select-dropdown-settings';
import { PkgThemeService } from '@shared/theme/pkg-theme.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AssetService } from '../shared/asset.service';
import { Role } from '../shared/role';
import { User } from '../shared/user';
import { UserService } from '../shared/user.service';

@Component({
  selector: 'pkg-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  @Output() delete  = new EventEmitter<string>();
  showModal: boolean;
  user: User;
  editRole: Role;
  roles = Role;
  assets = [];
  userAssets = [];
  userAssetsChanged = false;
  userRoleChnaged = false;
  loading: boolean;
  userAssetsDropdownSettings: MultiSelectDropDownSetting;
  private unsubscribe$ = new Subject();

  get getLogoStyle() {
    const backgroundStyles = {
      filter: this._pkgThemeService.getTheme() === 'light-theme' ?
      'invert(17%) sepia(40%) saturate(2919%) hue-rotate(173deg) brightness(90%) contrast(103%)' :
      'invert(100%) sepia(47%) saturate(21%) hue-rotate(102deg) brightness(105%)'
    };
    return backgroundStyles;
  }

  constructor(@Inject(DOCUMENT) private readonly document,
    private readonly userService: UserService,
    private readonly alerts: AlertsService,
    private readonly assetService: AssetService,
    private readonly _pkgThemeService: PkgThemeService) { }

  open(user: User): void {
    this.user = user;
    this.editRole = user.role;
    this.getAssets();
    this.showModal = true;
    this.document.body.classList.add('show-modal');
  }
  save() {
    this.user.assetIds = this.userAssets.map(({ id }) => (id));
    this.userService.edit(this.user.id, +this.editRole, this.user.assetIds)
      .subscribe(data => {
        this.alerts.add('User Details Saved', `The details for ${this.user.firstName} has been saved successfully`, AlertType.Success);
        this.user.role = +this.editRole;
        this.user.assetNames = this.userAssets.map(({ name }) => (name));
        this.close();
      }, (err: HttpErrorResponse) => {
        this.alerts.add('User Save Failed', err.error.message, AlertType.Error);
      });
  }
  onDelete(): void {
    this.userService.delete(this.user.id)
      .subscribe(data => {
        this.alerts.add('User Deleted', `${this.user.firstName} has been removed from the application`, AlertType.Success);
        this.delete.emit(this.user.id);
        this.close();
      }, (err: HttpErrorResponse) => {
        this.alerts.add('User Delete Failed', err.message, AlertType.Error);
      });
  }
  close(): void {
    this.editRole = null;
    this.user = null;
    this.assets = [];
    this.userAssets = [];
    this.userRoleChnaged = false;
    this.userAssetsChanged = false;
    this.showModal = false;
    this.document.body.classList.remove('show-modal');
  }
  getAssets() {
    this.loading = true;
    this.assetService.getAll().pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.assets = data.map(({ id, name }) => ({ id, name }));
      this.userAssets = this.assets
        .filter(asset => this.user.assetIds
            .some(id => id === asset.id));
      this.loading = false;
      this.userAssetsChanged = false;
    },
      (err) => {
        this.alerts.add('Failed to load printers', 'Please try again as we could not get any printers', AlertType.Error);
        this.loading = false;
      });
  }

  onRoleSelect(role: Role) {
    this.editRole = role;
    this.userRoleChnaged = true;
  }
  onAssetSelect(assets: any[]) {
    this.userAssets = assets;
    this.userAssetsChanged = true;
  }
  onAssetDeSelect(assets: any[]) {
    this.userAssets = assets;
    this.userAssetsChanged = true;
  }
  onAssetSelectAll(assets: any[]) {
    this.userAssets = assets;
    this.userAssetsChanged = true;
  }
  onAssetDeSelectAll(assets: any[]) {
    this.userAssets = assets;
    this.userAssetsChanged = true;
  }
  ngOnInit() {
  }
}
