<div>
  Tartan is offline for maintenance.
  Sorry for the inconvenience.
  We'll be back online soon!
</div>>

<div class="fullscreen">
    <div class="fullscreen__container">
      <div class="fullscreen__card">
        <div class="fullscreen__header">
            Offline for maintenance.
        </div>
        <div class="fullscreen__content">
            Sorry for the inconvenience, we'll be back online soon.
        </div>
        <div class="fullscreen__footer">
          <button class="button button--primary" (click)="goHome()" type="button">
            <i class="material-icons">&#xE88A;</i>
            <span>Go home</span>
          </button>
          <div class="fullscreen__status-code">
            503 Service Unavailable
          </div>
        </div>
      </div>
      <div class="fullscreen__logo-stack">
        <div class="fullscreen__logo-container">
          <img class="fullscreen__logo" src="../assets/full-logo-dark.svg" [ngStyle]="getLogoStyle" alt="Tartan + Hummingbird Logo" />
        </div>
        <span class="fullscreen__release-number">v{{releaseNumber}}</span>
      </div>
    </div>
  </div>
