import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MlpaJobSettings } from '../../../app/mlpa/mlpa-job-settings/mlpa-job-settings';
import { MlpaLane } from '../../../app/mlpa/mlpa-lane/mlpa-lane';
import { MlpaJobView } from '../../../app/pages/home/view-mlpa-jobs/mlpa-job-view';
import { MlpaService } from '../../../app/pages/mlpa/shared/mlpa.service';
import { MlpaJobApi } from '../mlpa-job-api';
import { MlpaJobPropertiesFlag, OrderFlag, StatusFlags } from '../mlpa-jobs-search/filter-flags';
import { MlpaJobsSearchComponent } from '../mlpa-jobs-search/mlpa-jobs-search.component';
import { MlpaJobsSearchService } from '../mlpa-jobs-search/mlpa-jobs-search.service';
import { SearchFilter } from '../mlpa-jobs-search/search-filter.interface';
import { PagedResult } from '../paged-result';

@Component({
  selector: 'pkg-job-selector',
  templateUrl: './job-selector.component.html',
  styleUrls: ['./job-selector.component.scss']
})
export class JobSelectorComponent implements OnInit {
  @ViewChild(MlpaJobsSearchComponent, { static: true }) mlpaJobSearchComponent: MlpaJobsSearchComponent;
  selectedJob: MlpaJobView;
  loading: boolean;
  showLoadMore: boolean;
  hasLoadedAllJobs: any;
  currentPageNumber: number;
  mlpaJobs: MlpaJobView[] = [];
  isSearching: boolean;
  searchResults: PagedResult<MlpaJobApi>;
  searchedTerm = '';
  foundJobs: boolean;
  jobSettings: MlpaJobSettings;
  mlpaLanes: MlpaLane[];
  detailsIsFullScreen = false;
  searchFilter: SearchFilter;
  previousSelectedJob: MlpaJobView;

  private unsubscribe$ = new Subject();
  constructor(@Inject(MAT_DIALOG_DATA) public data: { dialog: MatDialog }, private readonly mlpaService: MlpaService, private readonly mlpaJobSearchService: MlpaJobsSearchService) { }

  ngOnInit(): void {
    this.searchFilter = {
      jobProperties : MlpaJobPropertiesFlag.ALL,
      status : StatusFlags.SUBMITTED,
      orderBy : OrderFlag.DSC,
      sortBy: StatusFlags.SUBMITTED,
      assetIds: []
    };
    this.getJobs();
  }

  setMlpaJobs(mlpaJobsResult: PagedResult<MlpaJobApi>): void {
    this.mlpaJobs = [];
    if (mlpaJobsResult == null && this.searchedTerm != null) {
      this.foundJobs = false;
      this.detailsIsFullScreen = false;
      this.selectedJob = null;
      return;
    }
    this.currentPageNumber = 0;
    if (!!mlpaJobsResult) {
      this.searchResults = mlpaJobsResult;
      this.isSearching = true;
      this.foundJobs = true;
      mlpaJobsResult.results.forEach(job => {
        this.mlpaJobs.push(new MlpaJobView(job));
      });

      if (this.searchResults.totalCount === this.searchResults.resultsCount) {
        this.hasLoadedAllJobs = true;
      } else {
        this.hasLoadedAllJobs = false;
        this.loading = false;
      }

      return;
    }
    this.isSearching = false;
    this.getJobs();
  }

  setSearchedTerm(searchTerm: string): void {
    this.searchedTerm = searchTerm;

    if (this.searchedTerm == null) {
      this.selectedJob = null;
      this.detailsIsFullScreen = false;
    }
  }

  refreshJobs(): void {
    this.mlpaJobSearchComponent.clear();
  }

  selectJob(job: MlpaJobView): void {
    if (this.selectedJob === job) {
      this.previousSelectedJob = this.selectedJob;
      this.selectedJob = null;
      return;
    }
    this.previousSelectedJob = this.selectedJob;
    this.selectedJob = job;
    this.data.dialog.getDialogById('jobselector').close({selectedJob: this.selectedJob});
  }

  loadMoreJobs(): void {
    if (this.loading || this.hasLoadedAllJobs) {
      return;
    }

    if (this.isSearching) {

      if (this.currentPageNumber === this.searchResults.totalPages) {
        this.hasLoadedAllJobs = true;
        return;
      }

      this.currentPageNumber = this.currentPageNumber === 0 ? 1 : this.currentPageNumber;
      this.currentPageNumber = this.currentPageNumber + 1;
      this.getSearchJobs(this.currentPageNumber, this.searchResults.pageSize);
    } else {
      this.getJobs(this.currentPageNumber + 1);
    }
  }

  private getSearchJobs(pageNumber: number, pageSize: number): void {
    this.loading = true;
    this.foundJobs = true;
    this.showLoadMore = true;
    if (this.searchFilter == null) {
      this.mlpaJobSearchService.searchMlpaJob(this.searchedTerm, pageNumber, pageSize)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(data => {
          this.setResultedJobs(data);
        }, () => {
          this.loading = false;
        });
    } else {
      this.mlpaJobSearchService.searchMlpaJob(this.searchedTerm, pageNumber, this.searchResults.pageSize, this.searchFilter)
        .pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
          this.setResultedJobs(data);
        }, () => {
          this.loading = false;
        });
    }
  }

  private setResultedJobs(data: PagedResult<MlpaJobApi>): void {
    this.currentPageNumber = data.pageNumber;
    this.searchResults = data;
    data.results.forEach(r => this.mlpaJobs.push(new MlpaJobView(r)));
    if (data.pageNumber === data.totalPages) {
      this.hasLoadedAllJobs = true;
    } else {
      this.hasLoadedAllJobs = false;
    }

    this.loading = false;
  }

  private getJobs(pageNumber: number = 1): void {
    this.loading = true;
    this.foundJobs = true;
    this.showLoadMore = true;
    if (this.searchFilter == null) {
      this.mlpaService.getJobs(pageNumber).pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
        this.setResultedJobs(data);
      }, () => {
        this.loading = false;
      });
    } else {
      const pageSize = !!this.searchResults?.pageSize ? this.searchResults.pageSize : 20;
      this.mlpaJobSearchService.searchMlpaJob(this.searchedTerm, pageNumber, pageSize, this.searchFilter)
        .pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
          this.setResultedJobs(data);
        }, () => {
          this.loading = false;
        });
    }
  }
}
