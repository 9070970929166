import { Directive, ElementRef, HostListener, Output, EventEmitter, Input } from '@angular/core';

@Directive({
  selector: '[pkgInfinite]'
})
export class InfiniteDirective {
  @Output() loadData = new EventEmitter<boolean>();
  @Input() direction = 'vertical';
  constructor(private readonly _el: ElementRef<HTMLElement>) { }

  @HostListener('scroll') scrolling(): void {
    if (this.direction === 'vertical') {
      if (!!this._el) {
        const element = this._el.nativeElement;
        const location = element.scrollTop;
        const height = element.scrollHeight - element.clientHeight;
        if (location >= height - 80) {
          this.loadData.emit(true);
        }
      } else {
        const location = window.pageYOffset + window.innerHeight;
        const height = this._el.nativeElement.offsetHeight;
        if (location >= height - 80) {
          this.loadData.emit(true);
        }
      }
    } else {
      if (!!this._el) {
        const element = this._el.nativeElement;
        const location = element.scrollLeft;
        const width = element.scrollWidth - element.clientWidth;
        if (location >= width - 80) {
          this.loadData.emit(true);
        }
      } else {
        const location = window.pageXOffset + window.innerWidth;
        const width = this._el.nativeElement.offsetWidth;
        if (location >= width - 80) {
          this.loadData.emit(true);
        }
      }
    }
  }
}
