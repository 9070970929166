import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CalendarDateFormatter, CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
// import {DragulaModule} from 'ng2-dragula';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthModule} from './auth/auth.module';
import {LoginComponent} from './auth/login/login.component';
import {ErrorComponent} from './error/error.component';
import {MlpaJobActionGroupComponent} from './mlpa/mlpa-job-details/mlpa-job-action-group/mlpa-job-action-group.component';
import {MlpaJobDetailsComponent} from './mlpa/mlpa-job-details/mlpa-job-details.component';
import {MlpaLaneInfoComponent} from './mlpa/mlpa-job-details/mlpa-lane-info/mlpa-lane-info.component';
import {ProductDesignSearchComponent} from './mlpa/product-design-search/product-design-search.component';
import {ProductDesignSearchService} from './mlpa/product-design-search/product-design-search.service';
import {ProductDesignComponent} from './mlpa/product-design/product-design.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {OfflineComponent} from './offline/offline.component';
import {AdminModule} from '@pages/admin/admin.module';
import {VisionSystemEditComponent} from '@pages/admin/vision-system-edit/vision-system-edit.component';
import {CloneMlpaJobsComponent} from '@pages/home/clone-mlpa-jobs/clone-mlpa-jobs.component';
import {HomeComponent} from '@pages/home/home.component';
import {ViewMlpaJobsComponent} from '@pages/home/view-mlpa-jobs/view-mlpa-jobs.component';
import {BuildMlpaComponent} from '@pages/mlpa/build-mlpa/build-mlpa.component';
import {MlpaCloneGuard} from '@pages/mlpa/shared/mlpa-clone.guard';
import {MlpaEditGuard} from '@pages/mlpa/shared/mlpa-edit.guard';
import {MlpaStateService} from '@pages/mlpa/shared/mlpa-state.service';
import {MlpaGuard} from '@pages/mlpa/shared/mlpa.guard';
import {MlpaService} from '@pages/mlpa/shared/mlpa.service';
import {StickyDirective} from '@pages/mlpa/shared/sticky.directive';
import {PrintComponent} from '@pages/print/print.component';
// import {MwlScheduleListViewComponent} from '@pages/schedule/mwl-schedule-list-view/mwl-schedule-list-view.component';
// import {ScheduleListItemComponent} from '@pages/schedule/mwl-schedule-list-view/schedule-list-item/schedule-list-item.component';
// import {ScheduleComponent} from '@pages/schedule/schedule.component';
// import {ScheduleService} from '@pages/schedule/schedule.service';
import {StatsComponent} from '@pages/stats/stats.component';
import {ConfigService} from '@shared/config/config.service';
import {HolidayService} from '@shared/holiday/holiday.service';
import {MlpaJobOptionsService} from '@shared/mlpa-job-options/mlpa-job-options.service';
import {NavigationComponent} from '@shared/navigation/navigation.component';
import {SharedModule} from '@shared/shared.module';
import {UnauthorizedComponent} from './unauthorized/unauthorized.component';
import {RollMapGuard} from '@pages/rollmap/shared/roll-map.guard';

export const momentAdapterFactory = (): DateAdapter => adapterFactory(moment);


@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    HomeComponent,
    LoginComponent,
    ViewMlpaJobsComponent,
    StickyDirective,
    BuildMlpaComponent,
    OfflineComponent,
    NotFoundComponent,
    ErrorComponent,
    ProductDesignComponent,
    ProductDesignSearchComponent,
    CloneMlpaJobsComponent,
    MlpaJobDetailsComponent,
    MlpaLaneInfoComponent,
    StatsComponent,
    UnauthorizedComponent,
    VisionSystemEditComponent,
    PrintComponent,
    MlpaJobActionGroupComponent
  ],
  exports: [
    AppComponent,
    NavigationComponent,
    HomeComponent,
    LoginComponent,
    ViewMlpaJobsComponent,
    StickyDirective,
    BuildMlpaComponent,
    OfflineComponent,
    NotFoundComponent,
    ErrorComponent,
    ProductDesignComponent,
    ProductDesignSearchComponent,
    CloneMlpaJobsComponent,
    MlpaJobDetailsComponent,
    MlpaLaneInfoComponent,
    StatsComponent,
    UnauthorizedComponent,
    VisionSystemEditComponent,
    PrintComponent,
    MlpaJobActionGroupComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AdminModule,
    AppRoutingModule,
    AuthModule.forRoot(),
    SharedModule.forRoot(),
    BrowserAnimationsModule,
    // DragulaModule.forRoot(),
    CalendarModule.forRoot(
      {provide: DateAdapter, useFactory: momentAdapterFactory},
      {dateFormatter: CalendarDateFormatter}
    ),
    MatDialogModule,
  ],
  providers: [
    MlpaService,
    MlpaStateService,
    MlpaJobOptionsService,
    ProductDesignSearchService,
    MlpaGuard,
    MlpaCloneGuard,
    MlpaEditGuard,
    RollMapGuard,
    ConfigService,
    HolidayService,
    // ScheduleService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

declare module '@angular/core' {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}
