import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { MlpaService } from '../shared/mlpa.service';
import { AlertsService } from '@shared/alerts/alerts.service';
import { AlertType } from '@shared/alerts/alert-type.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { MlpaJobSettings } from '../../../mlpa/mlpa-job-settings/mlpa-job-settings';
import { MlpaLane } from '../../../mlpa/mlpa-lane/mlpa-lane';
import { ProductDesign } from '../../../mlpa/product-design/product-design.interface';
import { ProductDesignSearchService } from '../../../mlpa/product-design-search/product-design-search.service';
import { ProductDesignSearchComponent } from '../../../mlpa/product-design-search/product-design-search.component';
import { MlpaLaneType } from '../../../mlpa/mlpa-lane/mlpa-lane-type.enum';
import { MlpaJob } from '../../../mlpa/mlpa-job/mlpa-job';
import { MlpaJobOptionsService } from '@shared/mlpa-job-options/mlpa-job-options.service';
import { ConfigService } from '@shared/config/config.service';
import { Graphic } from '../../../mlpa/mlpa-lane/lane-types/graphic';
import { LaneProperties, InitialLaneProperties } from '../../../mlpa/mlpa-lane/lane-types/lane-properties.interface';
import { WebWeave } from '../../../mlpa/mlpa-lane/lane-types/webweave';
import { MlpaJobView } from '../../home/view-mlpa-jobs/mlpa-job-view';
import { recalculateMlpaJobProperties, checkLaneLengths, checkPaperWidths, updateMlpaJobProperties, checkColorProfiles, createLaneFromView, getTotalLengthOfLanes } from '../../../mlpa/mlpa-helper';
import { deepCopyProperties } from '@shared/pkg-helper';
import { MlpaJobState } from '../../../mlpa/mlpa-job-state';
import { MlpaStateService } from '../shared/mlpa-state.service';
import { FormControl } from '@angular/forms';
import { MlpaLaneState } from '../../../mlpa/mlpa-lane-state';
import { withLatestFrom, catchError, switchMap, finalize } from 'rxjs/operators';
import { ProductDesignOffline } from '../../../mlpa/product-design/product-design-offline.interface';
import { OfflineGraphic } from '../../../mlpa/mlpa-lane/lane-types/offline-graphic';
import { Role } from '../../admin/shared/role';
import { AuthService } from '../../../auth/auth.service';
import * as _ from 'lodash';
import { MlpaJobDetailsComponent } from '../../../../app/mlpa/mlpa-job-details/mlpa-job-details.component';
import { ColorManagementSystemSettings } from '@app/mlpa/color-management-system-settings/color-management-system-settings';
import { MlpaJobCharacterizationValue, DesignCharacterizationResponse, MlpaCharacterizationValue } from '@shared/mlpa-job-options/mlpa-job-option-characterization-value';
import { of, forkJoin, Observable } from 'rxjs';
import { PagedResult } from '@shared/paged-result';
import { AssetType } from '@shared/asset-type.enum';
import { startCase } from 'lodash';

@Component({
  selector: 'pkg-build-mlpa',
  templateUrl: './build-mlpa.component.html',
  styleUrls: ['./build-mlpa.component.scss']
})
export class BuildMlpaComponent implements OnInit, OnDestroy {
  @ViewChild(ProductDesignSearchComponent, { static: true }) productDesignSearchComponent: ProductDesignSearchComponent;
  @ViewChild(MlpaJobDetailsComponent) mlpaJobDetailsComponent: MlpaJobDetailsComponent;
  mlpaJobId: string;
  showWelcome = true;
  hasSearched: boolean;
  isEdit: boolean;
  searching: boolean;
  productDesigns = new Array<ProductDesign>();
  productDesignsOffline = new Array<ProductDesignOffline>();
  hasLoadedAllLanes: boolean;
  currentPageNumber: number;
  jobSettings: MlpaJobSettings;
  colorManagementSystemSettings: ColorManagementSystemSettings;
  mlpaLanes = new Array<MlpaLane>();
  isQrCodeEnabled = false;
  mlpaJob: MlpaJob;
  mlpaJobView: MlpaJobView;
  showBuilder: boolean;
  showLoadMore: boolean;
  isLoading = true;
  loadingJobDetails: boolean;
  jobNameFormControl = new FormControl();
  isClonePage = this._router.url.includes('clone');
  isEditPage = this._router.url.includes('edit');
  isInitialDesign: boolean;
  mlpaJobViewSavedState: MlpaJobView;
  redirectUrl: string;
  role = Role;
  isSpecial = false;
  totalWidthInInches: number;
  isKiwiSearch = false;
  mlpaLaneType = MlpaLaneType;
  assetType = AssetType;
  selectedAssetType: AssetType = AssetType.T1100;
  isDesignAddedToJob: boolean;
  assetTypeChangeCount = 0;


  get isMultiPage(): boolean {
    for (const lane of this.mlpaLanes) {
      if (lane.designNumberOfPages > 1) {
        return true;
      }
    }
    return false;
  }

  get title(): string {
    if (!this.mlpaJobId && !this.isClonePage) {
      return 'Create New MLPA Job';
    }

    if (this.mlpaJobId || this.isEditPage && !this.isClonePage) {
      return 'Edit MLPA Job';
    }

    if (this.isClonePage) {
      return 'Edit MLPA Clone Job';
    }

  }
  get designStyle(): string {
    const navIsCollapsed = this._configService.getConfigValue('navigationCollapsed');
    return navIsCollapsed ? 'collapsed product-designs' : 'expanded product-designs';
  }

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _searchService: ProductDesignSearchService,
    private readonly _mlpaService: MlpaService,
    private readonly _mlpaStateService: MlpaStateService,
    private readonly _mlpaJobOptionsService: MlpaJobOptionsService,
    private readonly _alerts: AlertsService,
    private readonly _router: Router,
    private readonly _configService: ConfigService,
    private readonly _authService: AuthService,
    private _cd: ChangeDetectorRef,
    private _elementRef: ElementRef) { }


  ngOnInit(): void {
    this._route.paramMap.pipe(withLatestFrom(this._route.queryParamMap)).subscribe(params => {
      const mlpaJobId = params[0].get('id');
      this.redirectUrl = params[1].get('redirect');
      if (mlpaJobId) {
        if (!this.isClonePage) {
          this.productDesignSearchComponent.closeSearch();
        }
        this.mlpaJobId = mlpaJobId;
      }
      this._searchService.clear();

      this._importData().then(()=> {

      // Get job options
      const jobOptions = this._mlpaJobOptionsService.getMlpaJobOptions(false).pipe(catchError((error) => {
        this._alerts.add('Failed to load MLPA job options', 'Please try again', AlertType.Error);
        return of(undefined);
      }));

      // Get color management systems
      const colorManagementSystems = this._mlpaJobOptionsService.getColorManagementSystems().pipe(catchError((error) => {
        this._alerts.add('Failed to load Color Management Systems', 'Please try again', AlertType.Error);
        return of(undefined);
      }));

      // Get characterization values
        const characterizationValues = this._mlpaJobOptionsService.getCharacterizationValues().pipe(catchError((error) => {
        this._alerts.add('Failed to load Characterization Values', 'Please try again', AlertType.Error);
        return of(undefined);
      }));

     forkJoin([jobOptions, colorManagementSystems, characterizationValues]).subscribe((results) => {
          this.jobSettings = new MlpaJobSettings(results[0]);
          this._mlpaStateService.setByValue('jobSettings', this.jobSettings);

          this.colorManagementSystemSettings = new ColorManagementSystemSettings(results[1].results, results[2]);
          this._mlpaStateService.setByValue('colorManagementSystemSettings', this.colorManagementSystemSettings);

          if (mlpaJobId) {
            this._buildMlpaJob(mlpaJobId);
            this.isLoading = true;
          }
      },
        (error) =>
        this._alerts.add(error, 'Please try again', AlertType.Error)
        );
    });
   });
  }

  ngOnDestroy(): void {
    this._mlpaStateService.setMlpaJobState({} as MlpaJobState);
    this._mlpaStateService.setMlpaLaneState({} as MlpaLaneState);
  }

  searchProductDesigns(event: any): void {
    // Reset various component properties
    const { pageNumber, isKiwi } = event;
    this.searching = true;
    this.hasSearched = true;
    this.showWelcome = false;
    this.hasLoadedAllLanes = false;
    this.productDesigns = [];
    this.productDesignsOffline = [];

    this._findProductDesigns(pageNumber, isKiwi);
    this.showBuilder = false;
  }
  scrollToTop(element: Element): void {
    if (this.showBuilder) {
      element.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
  loadMoreProductDesigns(): void {
    // Ignore the event if the search results are not being displayed
    if (!this.hasSearched || this.showBuilder) {
      return;
    }
    // Return if the designs are currently loading or there are no more designs to fetch
    if (this.searching || this.hasLoadedAllLanes) {
      return;
    }
    this.searching = true;
    this.hasSearched = true;

    this._findProductDesigns(this.currentPageNumber + 1, this.isKiwiSearch);
  }
  addProductDesignToJob(design: ProductDesign): void {
    this.isSpecial = false;
    // Check to see if there is an existing job.
    if (!this.mlpaJob) {
      this.mlpaJob = new MlpaJob();
    }

    if (!this.mlpaJobView) {
      this.isInitialDesign = true;
    } else {
      this.isInitialDesign = false;
    }

    // Pick initial job settings from design
    this.setJobSettingsFromProductDesign(design.assetName, design.paperDescription, design.requiredRollWidthInInches);

    // Set default color management system
    this.setDefaultColorManagementSystem();

    // Set press type, since press type is read only and dependant on asset selection
    if (!design.pressType && design.pressType === '') {
      this.colorManagementSystemSettings.jobCharacterizationValues.pressType = this.jobSettings.asset?.name.split(' ')[0];
    }

    // Lookup color profile from job settings
    const colorProfile = this.jobSettings.lookupColorProfile(design.colorProfileName);
    const colorProfileId = colorProfile ? colorProfile.id : undefined;
    const colorProfileName = colorProfile ? colorProfile.name : undefined;

    // Create lane from product design/open order data
    const graphicProperties: LaneProperties = {
      initialProperties: {
        quantity: design.orderQuantity,
        designLengthInInches: design.designLengthInInches,
        designWidthInInches: design.designWidthInInches,
        designNumberOfPages: design.designNumberOfPages,
        laneNumber: this.mlpaLanes.length + 1,
        colorProfileId,
        colorProfileName,
        designColorProfileName: design.colorProfileName
      },
      design
    };
    const mlpaLane = new Graphic(graphicProperties);

    // If color correction is enabled by default
    if (design.enableColorCorrection) {
      // Set the job characterization values if it doesn't exist
      if (!this.colorManagementSystemSettings.validateJobCharacterizationValues()) {
        // Pick initial job characterization values from design
        const jobCharacterizationValues: MlpaJobCharacterizationValue = {
          overPrintVarnish: design.overPrintVarnish,
          pressType: design.pressType !== '' ? design.pressType : this.jobSettings.asset?.name.split(' ')[0],
          speed: design.speed,
          substrate: design.substrateWeight,
          printMode: design.printMode,
          inkColors: design.inkColors
        };
        // Set job characterization values
        this.colorManagementSystemSettings.setJobCharacterizationValues(jobCharacterizationValues);
      }

      // Build alert message
      let alertMessage = '';

      // Compare the default characterization values with job characterization values and notify if there is any difference
      if (this.colorManagementSystemSettings.jobCharacterizationValues.speed &&
        mlpaLane.colorCorrectionInfo.speed !== this.colorManagementSystemSettings.jobCharacterizationValues.speed) {
        mlpaLane.colorCorrectionInfo.speed = this.colorManagementSystemSettings.jobCharacterizationValues.speed;
        alertMessage += `&#8226; Speed updated from <b>${design.speed}</b> to <b>${mlpaLane.colorCorrectionInfo.speed}</b>.<br>`;
      }
      if (this.colorManagementSystemSettings.jobCharacterizationValues.overPrintVarnish &&
        mlpaLane.colorCorrectionInfo.overPrintVarnish !== this.colorManagementSystemSettings.jobCharacterizationValues.overPrintVarnish) {
        mlpaLane.colorCorrectionInfo.overPrintVarnish = this.colorManagementSystemSettings.jobCharacterizationValues.overPrintVarnish;
        alertMessage += `&#8226; Over Print Varnish updated from <b>${design.overPrintVarnish}</b> to <b>${mlpaLane.colorCorrectionInfo.overPrintVarnish}</b>.<br>`;
      }
      if (this.colorManagementSystemSettings.jobCharacterizationValues.pressType &&
        mlpaLane.colorCorrectionInfo.pressType !== this.colorManagementSystemSettings.jobCharacterizationValues.pressType) {
        mlpaLane.colorCorrectionInfo.pressType = this.colorManagementSystemSettings.jobCharacterizationValues.pressType;
        alertMessage += `&#8226; Press Type updated from <b>${design.pressType}</b> to <b>${mlpaLane.colorCorrectionInfo.pressType}</b>.<br>`;
      }
      if (this.colorManagementSystemSettings.jobCharacterizationValues.substrate &&
        mlpaLane.colorCorrectionInfo.substrateWeight !== this.colorManagementSystemSettings.jobCharacterizationValues.substrate) {
        mlpaLane.colorCorrectionInfo.substrateWeight = this.colorManagementSystemSettings.jobCharacterizationValues.substrate;
        alertMessage += `&#8226; Substrate Weight updated from <b>${design.substrateWeight}</b> to <b>${mlpaLane.colorCorrectionInfo.substrateWeight}</b>.<br>`;
      }
      if (this.colorManagementSystemSettings.jobCharacterizationValues.printMode &&
        mlpaLane.colorCorrectionInfo.printMode !== this.colorManagementSystemSettings.jobCharacterizationValues.printMode) {
        mlpaLane.colorCorrectionInfo.printMode = this.colorManagementSystemSettings.jobCharacterizationValues.printMode;
        alertMessage += `&#8226; Print mode updated from <b>${design.printMode}</b> to <b>${mlpaLane.colorCorrectionInfo.printMode}</b>.<br>`;
      }
      if (this.colorManagementSystemSettings.jobCharacterizationValues.inkColors &&
        mlpaLane.colorCorrectionInfo.inkColors !== this.colorManagementSystemSettings.jobCharacterizationValues.inkColors) {
        mlpaLane.colorCorrectionInfo.inkColors = this.colorManagementSystemSettings.jobCharacterizationValues.inkColors;
        alertMessage += `&#8226; Ink colors updated from <b>${design.inkColors}</b> to <b>${mlpaLane.colorCorrectionInfo.inkColors}</b>.<br>`;
      }
      if (alertMessage.length > 0) {
        this._alerts.add(`Updated Characterization Values of Design# ${mlpaLane.designNumber}`, `${alertMessage}`, AlertType.Info);
      }

      // Select the color profile based on Ink Weight characterization value
      if (!!design.inkWeight && design.inkWeight !== '') {
        // search color profiles based on ink weight value
        const colorProfiles = this.jobSettings.searchColorProfile(design.inkWeight);
        // if no matching color profiles are found, then prompt a message
        if (!colorProfiles || !colorProfiles.length) {
          this._alerts.add(`Color Profile Not Found for Design# ${design.designNumber}`, 'No matching color profile found for ink weight: ' + design.inkWeight, AlertType.Info);
        } else if (!!colorProfiles && colorProfiles.length > 1) {
          // if more than one matching color profiles are found, then allow users to select from the result list
          mlpaLane.colorProfileId = '';
          mlpaLane.colorProfileName = '';
          this._alerts.add(`Multiple Color Profiles Found for Design# ${design.designNumber}`, 'Please select a valid color profile for ink weight: ' + design.inkWeight, AlertType.Info);
        } else if (!!colorProfiles && colorProfiles.length === 1) {
          // if only one matching color profile is found, then select that color profile
          mlpaLane.colorProfileId = colorProfiles[0].id;
          mlpaLane.colorProfileName = colorProfiles[0].name;
        }
      }
      // If any color correction enabled lane with missing char values, update them
      if (!this.isInitialDesign) {
        this.mlpaJobView.colorManagementSystemSettings = this.colorManagementSystemSettings;
        this.mlpaJobView.refreshLaneCharacterizationValues();
      }

      // Alert if any of the char values are still missing
      const characterizationValues: MlpaCharacterizationValue = {
        inkColors: mlpaLane.colorCorrectionInfo.inkColors,
        speed: mlpaLane.colorCorrectionInfo.speed,
        overPrintVarnish: mlpaLane.colorCorrectionInfo.overPrintVarnish,
        printMode: mlpaLane.colorCorrectionInfo.printMode,
        pressType: mlpaLane.colorCorrectionInfo.pressType,
        substrate: mlpaLane.colorCorrectionInfo.substrateWeight,
        inkWeight: mlpaLane.colorCorrectionInfo.inkWeight,
        totalAreaCoverage: mlpaLane.colorCorrectionInfo.totalAreaCoverage
      };
      if (!this.colorManagementSystemSettings.validateCharacterizationValues(characterizationValues)) {
        this._alerts.add(`Could not find the design characterization values for Design# ${mlpaLane.designNumber}`,
          'Please select the missing characterization values from the dropdown options',
          AlertType.Error);
      }
    }

    if (mlpaLane.fixInvalidQuantity()) {
      this._alerts.add(
        'Quantity Updated',
        `Lane ${mlpaLane.designNumber} was ${design.orderQuantity} units. With a Number Up of ${mlpaLane.designNumberUp}. We can't print that. So we changed it to ${mlpaLane.quantity} units. Enjoy!`,
        AlertType.Info);
    }

    // If the asset is set, check for source PDF
    if (this.jobSettings.asset) {
      this._mlpaService.checkIfSourcePdfExists(this.jobSettings.asset.id, mlpaLane.designNumber).subscribe((resp: HttpResponse<any>) => {
        // Get source PDF size from response headers
        const sourcePdfSizeInBytes = resp.headers.get('content-length');
        mlpaLane.sourcePdfSizeInBytes = sourcePdfSizeInBytes ? parseInt(sourcePdfSizeInBytes, 10) : 0;
        this._updateView();
        this.isLoading = false;
      }, err => {
        const details = `Lane ${mlpaLane.designNumber} does not have a valid source PDF.`;
        this._alerts.add(
          'PDF Validation',
          details,
          AlertType.Error);
        this._mlpaStateService.addWarningMessages(details);

        // remove the last job that was added.
        const index = _.findIndex(this.mlpaLanes, mlpaLane);
        if (index > 0) {
          this.removeLaneFromJob(index + 1);
        }

        this.isLoading = false;
        this.showBuilder = false;
      });
    }

    // If this is a new job
    if (this.mlpaLanes.length === 0) {
      this.mlpaJob.programNumber = design.programNumber;
      this.mlpaJob.setupNumber = design.setupNumber;
      this.mlpaJob.blanketNumber = design.blanketNumber;

      // Add web weave lanes
      const webWeaveProperties: LaneProperties = {
        initialProperties: {
          quantity: design.orderQuantity,
          designLengthInInches: design.designLengthInInches,
          designNumberOfPages: design.designNumberOfPages,
          colorProfileId,
          colorProfileName,
          designColorProfileName: design.colorProfileName
        } as InitialLaneProperties
      };

      this.mlpaLanes.push(new WebWeave(webWeaveProperties));
      this.mlpaLanes.push(new WebWeave(webWeaveProperties));
    } else {
      this.reCopyMlpaLanes();
    }

    // Add the new lane between the web weaves
    this.mlpaLanes.splice(this.mlpaLanes.length - 1, 0, mlpaLane);

    // Recalculate job properties
    this._updateView();
    this.mlpaJobView.designNumber = design.designNumber;
    this._mlpaStateService.setByValue('mlpaJobView', this.mlpaJobView);
    // Check for missing asset
    if (!this.jobSettings.asset) {
      this._alerts.add('Asset not valid', 'Please select a valid asset in job settings.', AlertType.Error);
    }

    // Check for missing paper type
    if (!this.jobSettings.paperType || !this.jobSettings.paperWidthInInches) {
      this._alerts.add('Paper type not valid', 'Please select a valid paper type in job settings.', AlertType.Error);
    }

    // Check for missing paper width
    if (!this.jobSettings.paperWidthInInches) {
      this._alerts.add('Paper width not valid', 'Please select a valid paper width in job settings.', AlertType.Error);
    }

    // Check for missing color profile
    if (design.enableColorCorrection && !!design.inkWeight && design.inkWeight !== '' && !mlpaLane.hasValidColorProfile) {
      this._mlpaStateService.setMlpaLaneState({ hasValidColorProfiles: false, jobSettings: this.jobSettings });
      this._alerts.add('Color profile not valid', `Please select a valid color profile for lane ${mlpaLane.laneNumber - 1} and ink weight ${design.inkWeight}.`, AlertType.Error);
    } else if (!mlpaLane.hasValidColorProfile) {
      this._mlpaStateService.setMlpaLaneState({ hasValidColorProfiles: false, jobSettings: this.jobSettings });
      this._alerts.add('Color profile not valid', `Please select a valid color profile for lane ${mlpaLane.laneNumber - 1}.`, AlertType.Error);
    }

    if (this.isInitialDesign) {
      this.mlpaJobViewSavedState = {} as MlpaJobView;
      deepCopyProperties(this.mlpaJobViewSavedState, this.mlpaJobView);
    }

    this._mlpaStateService.setByValue('editMode', true);
    if (this.mlpaLanes.length < 0) {
      this.isDesignAddedToJob = false;
    } else {
      this.isDesignAddedToJob = true;
    }
  }

  addProductOfflineDesignToJob(offlineDesign: ProductDesignOffline): void {
    this.isSpecial = true;

    // Check to see if there is an existing job.
    if (!this.mlpaJob) {
      this.mlpaJob = new MlpaJob();
    }

    if (!this.mlpaJobView) {
      this.isInitialDesign = true;
    } else {
      this.isInitialDesign = false;
    }

    // Default to T1100
    this.setJobSettingsFromProductDesign('T1100 Digital Roll Printer', '');
    this.jobSettings.selectPaperType(this.jobSettings.asset.paperTypes[0].name, offlineDesign.requiredRollWidthInInches);

    this.setDefaultColorManagementSystem();

    // Lookup color profile from job settings
    const colorProfile = this.jobSettings.lookupColorProfile(this.jobSettings.colorProfiles[0].name);
    const colorProfileId = colorProfile ? colorProfile.id : undefined;
    const colorProfileName = colorProfile ? colorProfile.name : undefined;

    // Create lane from product design/open order data
    const graphicProperties: LaneProperties = {
      initialProperties: {
        quantity: 1,
        designLengthInInches: offlineDesign.lengthInInches,
        designWidthInInches: offlineDesign.widthInInches,
        designNumberOfPages: offlineDesign.numberOfPages,
        laneNumber: this.mlpaLanes.length + 1,
        colorProfileId,
        colorProfileName,
        designColorProfileName: colorProfileName,
        isSpecial: true
      },
      offlineDesign
    };

    const mlpaLane = new OfflineGraphic(graphicProperties);

    if (mlpaLane.fixInvalidQuantity()) {
      this._alerts.add(
        'Quantity Updated',
        `Lane ${mlpaLane.designNumber} was 1 units. With a Number Up of ${mlpaLane.designNumberUp}. We can't print that. So we changed it to ${mlpaLane.quantity} units. Enjoy!`,
        AlertType.Info);
    }

    // If the asset is set, check for source PDF
    if (this.jobSettings.asset) {
      this._mlpaService.checkIfSourcePdfExists(this.jobSettings.asset.id, mlpaLane.designNumber).subscribe((resp: HttpResponse<any>) => {
        // Get source PDF size from response headers
        const sourcePdfSizeInBytes = resp.headers.get('content-length');
        mlpaLane.sourcePdfSizeInBytes = sourcePdfSizeInBytes ? parseInt(sourcePdfSizeInBytes, 10) : 0;
        this._updateView();
        this.isLoading = false;
      }, err => {
        const details = `Lane ${mlpaLane.designNumber} does not have a valid source PDF.`;
        this._alerts.add(
          'PDF Validation',
          details,
          AlertType.Error);
        this._mlpaStateService.addWarningMessages(details);

        // remove the last job that was added.
        const index = _.findIndex(this.mlpaLanes, mlpaLane);
        if (index > 0) {
          this.removeLaneFromJob(index + 1);
        }
      });
    }

    // If this is a new job
    if (this.mlpaLanes.length === 0) {
      this.mlpaJob.programNumber = 'N/A';
      this.mlpaJob.setupNumber = 'N/A';
      this.mlpaJob.blanketNumber = 'N/A';

      // Add web weave lanes
      const webWeaveProperties: LaneProperties = {
        initialProperties: {
          quantity: 1,
          designLengthInInches: offlineDesign.lengthInInches,
          designNumberOfPages: 1,
          colorProfileId: null,
          colorProfileName: null,
          designColorProfileName: null
        } as InitialLaneProperties
      };

      this.mlpaLanes.push(new WebWeave(webWeaveProperties));
      this.mlpaLanes.push(new WebWeave(webWeaveProperties));
    } else {
      this.reCopyMlpaLanes();
    }

    // Add the new lane between the web weaves
    this.mlpaLanes.splice(this.mlpaLanes.length - 1, 0, mlpaLane);

    // Recalculate job properties
    this._updateView();
    this.mlpaJobView.designNumber = offlineDesign.number;
    this._mlpaStateService.setByValue('mlpaJobView', this.mlpaJobView);
    // Check for missing asset
    if (!this.jobSettings.asset) {
      this._alerts.add('Asset not valid', 'Please select a valid asset in job settings.', AlertType.Error);
    }

    // Check for missing paper type
    if (!this.jobSettings.paperType || !this.jobSettings.paperWidthInInches) {
      this._alerts.add('Paper type not valid', 'Please select a valid paper type in job settings.', AlertType.Error);
    }

    // Check for missing paper width
    if (!this.jobSettings.paperWidthInInches) {
      this._alerts.add('Paper width not valid', 'Please select a valid paper width in job settings.', AlertType.Error);
    }

    // Check for missing color profile
    if (!mlpaLane.hasValidColorProfile) {
      this._mlpaStateService.setMlpaLaneState({ hasValidColorProfiles: false, jobSettings: this.jobSettings });
      this._alerts.add('Color profile not valid', `Please select a valid color profile for lane ${mlpaLane.laneNumber - 1}.`, AlertType.Error);
    }

    if (this._authService.user.role === Role.Structure) {
      this.mlpaJobView.isSpecial = true;
      this.isSpecial = true;
    }

    if (this.isInitialDesign) {
      this.mlpaJobViewSavedState = {} as MlpaJobView;
      deepCopyProperties(this.mlpaJobViewSavedState, this.mlpaJobView);
    }

    this._mlpaStateService.setByValue('editMode', true);
    this.isLoading = false;
  }

  setJobSettingsFromProductDesign(assetName: string, paperTypeName: string, paperWidth?: number): void {
    // If no asset has been selected, use the new design to set the job settings
    if (!this.jobSettings.asset || this.mlpaLanes.length === 0) {
      this.jobSettings.selectAsset(assetName, paperTypeName, paperWidth);
    }

    // Adjust the margin based on Paper width
    this.jobSettings.setDefaultMarginWidth();
  }
  setJobSettingsFromMlpaJob(assetName: string, paperTypeName: string, paperWidthInInches: number, hasQrCode: boolean): void {
    this.jobSettings.selectAsset(assetName, paperTypeName, paperWidthInInches);
    this.jobSettings.hasQrCode = hasQrCode;
  }

  setDefaultColorManagementSystem() {
    if (!this.colorManagementSystemSettings.colorManagementSystem) {
      if (!!this.colorManagementSystemSettings.colorManagementSystems && this.colorManagementSystemSettings.colorManagementSystems.length > 0) {
        const colorManagementSystemId = this.colorManagementSystemSettings.colorManagementSystems[0].id;
        this.colorManagementSystemSettings.selectColorManagementSystem(colorManagementSystemId);
      }
    }
  }

  setColorManagementSystemSettingsFromMlpaJob(colorManagementSystemId: string) {
    this.colorManagementSystemSettings.selectColorManagementSystem(colorManagementSystemId);
  }

  removeLaneFromJob(laneNumber?: number): void {
    if (this.mlpaLanes.length <= 0) {
      if (this.isClonePage) {
        this._router.navigate(['/home']);
      }

      return;
    }
    this.isDesignAddedToJob = false;

    // recopy the view to the mlpalane.
    this.reCopyMlpaLanes();

    // if there is not an event that is passed then the delete all is triggered.
    if (laneNumber == null) {

      if (this.isClonePage) {
        this._router.navigate(['/home']);
      }

      this.mlpaLanes = [];
      this.mlpaJobView = undefined;

      // clear job characterization values
      this.colorManagementSystemSettings.clearJobCharacterizationValues();

      this._mlpaStateService.setMlpaJobState({} as MlpaJobState);
      this._mlpaStateService.setMlpaLaneState({} as MlpaLaneState);

      this.toggleJobBuilder();
    } else {
      // check the the lane size.
      const removeIndex = this.mlpaLanes.findIndex(o => o.laneNumber === laneNumber);
      this.mlpaLanes.splice(removeIndex, 1);

      // If the only items left are the web weaves, clear everything out
      if (this.mlpaLanes.length <= 2) {
        if (this.isClonePage) {
          this._router.navigate(['/home']);
        }

        this.mlpaLanes = [];
        this.mlpaJobView = undefined;
        this._mlpaStateService.setMlpaLaneState({ hasValidColorProfiles: false, jobSettings: this.jobSettings });

        // clear job characterization values
        this.colorManagementSystemSettings.clearJobCharacterizationValues();
        this.toggleJobBuilder();
      } else {
        // Recalculate job properties
        this.mlpaLanes = recalculateMlpaJobProperties(this.mlpaLanes, this.jobSettings, this._alerts);
      }
      this._updateView();
    }

    if (this._mlpaStateService.getValueFromJob('editMode') === false) {
      this.mlpaJobViewSavedState = {} as MlpaJobView;
      deepCopyProperties(this.mlpaJobViewSavedState, this.mlpaJobView);
    }

    this._mlpaStateService.setByValue('editMode', false);
  }

  cancelAndRestore(): void {
    this.mlpaJobView = {} as MlpaJobView;
    this._mlpaStateService.setByValue('editMode', false);
    deepCopyProperties(this.mlpaJobView, this.mlpaJobViewSavedState);
    this.reCopyMlpaLanes();
    this._updateView();
  }

  reCopyMlpaLanes(): void {
    const copyOfPreviousLane = {};
    deepCopyProperties(copyOfPreviousLane, this.mlpaLanes);

    this.mlpaLanes = [];
    // copy view settings to the lane view
    this.mlpaJobView.mlpaLanes.forEach((viewLane, index) => {
      const lane = createLaneFromView(viewLane.laneType, viewLane);
      if (copyOfPreviousLane[index] != null && lane.laneType === MlpaLaneType.Graphic && copyOfPreviousLane[index].laneType === MlpaLaneType.Graphic) {
        lane.designLengthInInches = copyOfPreviousLane[index].designLengthInInches;
        lane.designWidthInInches = copyOfPreviousLane[index].designWidthInInches;
      }

      if (copyOfPreviousLane[index] != null && copyOfPreviousLane[index].laneType !== lane.laneType) {
        if (lane.isTransposed) {
          lane.toggleTransposedValues();
        }
      }
      this.mlpaLanes.push(lane);
    });
  }
  updateFromJobDetails(event: { mlpaJobView: MlpaJobView; jobSettings: MlpaJobSettings; colorManagementSystemSettings: ColorManagementSystemSettings; jobNameControl: FormControl }): void {
    this.jobNameFormControl = event.jobNameControl;
    this._mlpaStateService.resetWarningMessages();
    // the name and asset are not copied over so see why it is not.
    this.mlpaJob._mlpaLanes.forEach((lane, index) => {
      deepCopyProperties(this.mlpaJob._mlpaLanes[index], event.mlpaJobView.mlpaLanes[index]);
      this.mlpaJob._mlpaLanes[index].assetName = event.jobSettings.asset.name;
      if (lane.isWebWeave) {
        this.mlpaJob._mlpaLanes[index] = createLaneFromView(event.mlpaJobView.mlpaLanes[index].laneType, event.mlpaJobView.mlpaLanes[index]);
      }
    });

    deepCopyProperties(this.mlpaJob, event.mlpaJobView);
    deepCopyProperties(this.mlpaJobView, event.mlpaJobView);

    this.mlpaJob.assetId = event.jobSettings.asset.id;
    this.mlpaJob.assetName = event.jobSettings.asset.name;
    this.mlpaJob.paperTypeId = event.jobSettings.paperType.id;
    this.mlpaJob.paperTypeName = event.jobSettings.paperType.name;
    this.mlpaJob.paperWidthInInches = event.jobSettings.paperWidthInInches;
    if (event.mlpaJobView.showJobCharacterizationValues) {
      this.mlpaJob.colorCorrectionInfo.colorManagementSystemId = event.colorManagementSystemSettings.colorManagementSystem.id;
      this.mlpaJob.colorCorrectionInfo.substrateWeight = event.colorManagementSystemSettings.jobCharacterizationValues.substrate;
      this.mlpaJob.colorCorrectionInfo.speed = event.colorManagementSystemSettings.jobCharacterizationValues.speed;
      this.mlpaJob.colorCorrectionInfo.overPrintVarnish = event.colorManagementSystemSettings.jobCharacterizationValues.overPrintVarnish;
      this.mlpaJob.colorCorrectionInfo.pressType = event.colorManagementSystemSettings.jobCharacterizationValues.pressType;
      this.mlpaJob.colorCorrectionInfo.printMode = event.colorManagementSystemSettings.jobCharacterizationValues.printMode;
      this.mlpaJob.colorCorrectionInfo.inkColors = event.colorManagementSystemSettings.jobCharacterizationValues.inkColors;
    }
    this.jobSettings = event.jobSettings;
    this.colorManagementSystemSettings = event.colorManagementSystemSettings;
    this.totalWidthInInches = getTotalLengthOfLanes(this.mlpaLanes);
    this._mlpaStateService.setByValue('totalWidthInInches', this.totalWidthInInches);
    this.setupLinkedJob();
    this._updateView();

    checkLaneLengths(this.mlpaJob.mlpaLanes, this._alerts, this._mlpaStateService);
    checkPaperWidths(this.mlpaJob, this._alerts, this._mlpaStateService);
    checkColorProfiles(this.mlpaJob, this._alerts, this._mlpaStateService);

    // copy the saved version as the new initial state.
    this.mlpaJobViewSavedState = {} as MlpaJobView;
    deepCopyProperties(this.mlpaJobViewSavedState, this.mlpaJobView);
    this._mlpaStateService.setByValue('editMode', false);
  }

  setupLinkedJob(): void {
    // Keep the old linked job.
    if (this.mlpaJob?.mlpaLanes[1]?.laneType === MlpaLaneType.Stress) {
      return;
    }

    if (this.jobSettings.hasQrCode) {
      const copyOfLinkedMlpaJobs = [] as MlpaJob[];
      // Keep a copy of the old linked jobs, in the future if we have more we would want to append probably.
      if (this.mlpaJob.linkedMlpaJobs.length > 0) {
        deepCopyProperties(copyOfLinkedMlpaJobs, this.mlpaJob.linkedMlpaJobs);
        this.mlpaJob.linkedMlpaJobs = [];
      }
      this.mlpaJob.linkedMlpaJobs = [this.mlpaJob.createLinkedQrCodeJob(this.jobSettings)];
    } else {
      // Remove them if there are any
      this.mlpaJob.linkedMlpaJobs = [];
    }
  }
  resizeWidth(): string {
    const navIsCollapsed = this._configService.getConfigValue('navigationCollapsed');
    if (navIsCollapsed) {
      return 'calc(100% - 70px)';
    } else {
      return 'calc(100% - 202px)';
    }
  }

  toggleJobBuilder(): void {
    if (this.isClonePage) {
      this.showBuilder = true;
      return;
    }
    this.showBuilder = !this.showBuilder;
  }

  onAssetTypeChange(elem: Element, assetType: AssetType): void {
    if (assetType === AssetType.T1100 && elem.className !== 'page__header') {
      this.productDesignSearchComponent.clear();
      this.productDesigns = [];
      this.productDesignsOffline = [];
      this.mlpaLanes = [];
      this.showWelcome = true;
      this.hasSearched = false;
      elem.className = 'page__header';
    } else {
      this.productDesignSearchComponent.clear();
      this.productDesigns = [];
      this.productDesignsOffline = [];
      this.mlpaLanes = [];
      this.showWelcome = true;
      this.hasSearched = false;
      this.productDesignSearchComponent.showKiwiSearch = false;
      elem.className = 'page__header_plex';
      this.assetTypeChangeCount = this.assetTypeChangeCount + 1;
      if (this.assetTypeChangeCount === 1) {
        // Import paper types TODO: Return data about what was added from API
        this._mlpaService.importPaperTypes(this.assetType.T1195).subscribe((complete) => {
        }, (error) => {
          this._alerts.add('Paper Types Import', 'Paper types failed to import.', AlertType.Error);
        });
      }
    }
  }

  private _updateView(): void {
    // Make this a method, setup mlpalaneview
    if (this.mlpaLanes.length > 0) {
      this.totalWidthInInches = getTotalLengthOfLanes(this.mlpaLanes);
      this.mlpaJob = updateMlpaJobProperties(this.mlpaJob,
        this.mlpaJobId,
        this.mlpaLanes,
        this.jobSettings,
        this.colorManagementSystemSettings,
        this.totalWidthInInches,
        this._alerts,
        this.jobNameFormControl,
        this.isClonePage);
    }

    // We want to copy what the user placed in the input fields.
    if (this.mlpaJobView != null) {
      // recopy job values
      this.mlpaJob.programNumber = this.mlpaJobView.programNumber;
      this.mlpaJob.setupNumber = this.mlpaJobView.setupNumber;
      this.mlpaJob.name = this.mlpaJobView.name !== '' ? this.mlpaJobView.name : this.mlpaJob.name;
    }
    this.mlpaJob.mlpaLanes = recalculateMlpaJobProperties(this.mlpaJob.mlpaLanes, this.jobSettings, this._alerts);
    this.mlpaJobView = new MlpaJobView(this.mlpaJob);
    this.mlpaJobView.jobSettings = this.jobSettings;
    this.mlpaJobView.colorManagementSystemSettings = this.colorManagementSystemSettings;
    this.mlpaJobView.isNewJob = true;
    this.totalWidthInInches = getTotalLengthOfLanes(this.mlpaLanes);
    const jobState = {
      mlpaJob: this.mlpaJob,
      mlpaJobView: this.mlpaJobView,
      jobSettings: this.jobSettings,
      colorManagementSystemSettings: this.colorManagementSystemSettings,
      isValidForSubmission: this._mlpaStateService.getValueFromJob('isValidForSubmission'),
      editMode: this._mlpaStateService.getValueFromJob('editMode'),
      totalWidthInInches: this.totalWidthInInches
    } as MlpaJobState;
    this._mlpaStateService.setMlpaJobState(jobState);
    this.mlpaJob.name = this.mlpaJob.name != null && this.mlpaJob.name !== '' ? this.mlpaJob.name : this.mlpaJob.buildName();
  }

  private _findProductDesigns(pageNumber: number, isKiwi = false): void {
    this.showLoadMore = true;
    this.isKiwiSearch = isKiwi;
    if (isKiwi) {
      this._searchService.findProductDesigns(pageNumber).subscribe(
        data => {
          data.results.forEach(productDesign => this.productDesignsOffline.push(productDesign));
          this.searching = false;
          this.currentPageNumber = data.pageNumber;
          if (data.pageNumber === data.totalPages) {
            this.hasLoadedAllLanes = true;
          }
        },
        err => {
          this.searching = false;
        }
      );

    } else {
      this._searchService.findOpenOrders(pageNumber, this.selectedAssetType).subscribe(
        data => {
          data.results.forEach(productDesign => this.productDesigns.push(productDesign));
          this.searching = false;
          this.currentPageNumber = data.pageNumber;
          if (data.pageNumber === data.totalPages) {
            this.hasLoadedAllLanes = true;
          }
        },
        err => {
          this.searching = false;
        }
      );
    }
  }

  private async _importData(): Promise<void> {
    // Import paper types TODO: Return data about what was added from API
    this._mlpaService.importPaperTypes(this.selectedAssetType).subscribe((complete) => {
    }, (error) => {
      this._alerts.add('Paper Types Import', 'Paper types failed to import.', AlertType.Error);
    });

    // Import color profiles TODO: Return data about what was added from API
    this._mlpaService.importColorProfiles().subscribe((complete) => {
    }, (error) => {
      this._alerts.add('Color Profiles Import', 'Color profiles failed to import.', AlertType.Error);
    });

    // Import characterization values
    this._mlpaService.importCharacterizationValues().subscribe((complete) => {
    }, (error) => {
      this._alerts.add('Characterization Values Import', 'Characterization Values failed to update and may not contain newer values.', AlertType.Error);
    });

  }

  private _buildMlpaJob(mlpaJobId: string): void {
    this.isDesignAddedToJob = true;
    this.loadingJobDetails = true;
    this._mlpaService.getMlpaJob(mlpaJobId).pipe(switchMap(mlpaJobApi => {

      if (this._router.url.includes('clone')) {
        this.mlpaJob = MlpaJob.cloneFromApi(mlpaJobApi);
        this.mlpaJobId = undefined;
        this.mlpaJob.IsColorCorrected = false;

        this.mlpaJob.mlpaLanes.forEach(mlpaLane => {
          mlpaLane.colorCorrectionInfo.isColorCorrectionProcessing = false;
          mlpaLane.colorCorrectionInfo.isColorCorrectionProcessed = false;
          mlpaLane.isProcessing = false;
          mlpaLane.isProcessed = false;
        });
      } else {
        // Create job from API
        this.mlpaJob = new MlpaJob(mlpaJobApi);
      }

      // Add lanes to component
      this.mlpaLanes = this.mlpaJob.mlpaLanes;

      // Set job settings
      this.setJobSettingsFromMlpaJob(this.mlpaJob.assetName, this.mlpaJob.paperTypeName, this.mlpaJob.paperWidthInInches, this.mlpaJob.isParentMlpaJob);

      // Binding asset type dropdown & change the color based on asset type
      this.selectedAssetType = this.jobSettings?.asset?.assetType;
      if (this.selectedAssetType === this.assetType.T1195) {
        this._elementRef.nativeElement.querySelector(`.page__header`).className = 'page__header_plex';
      }

      // Find first non-graphic lane
      const nonGraphicLane = this.mlpaLanes.find(mlpaLane => mlpaLane.laneType !== MlpaLaneType.Graphic && mlpaLane.laneType !== MlpaLaneType.Stress);

      // Set job settings web weave type
      this.jobSettings.laneType = nonGraphicLane ? nonGraphicLane.laneType : MlpaLaneType.Unknown;

      // Set Color Management System settings
      if (!!this.mlpaJob.colorCorrectionInfo?.colorManagementSystemId && this.mlpaJob.colorCorrectionInfo?.colorManagementSystemId !== '') {
        this.setColorManagementSystemSettingsFromMlpaJob(this.mlpaJob.colorCorrectionInfo.colorManagementSystemId);
      } else {
        this.setDefaultColorManagementSystem();
      }

      // Set other job settings
      this.jobSettings.marginWidthInInches = mlpaJobApi.marginWidthInInches;
      this.jobSettings.lineBreakLengthInInches = mlpaJobApi.lineBreakLengthInInches;

      if (this.mlpaJob.showJobCharacterizationValues) {
        const jobCharacterizationValues: MlpaJobCharacterizationValue = {
          overPrintVarnish: this.mlpaJob.colorCorrectionInfo.overPrintVarnish,
          pressType: this.mlpaJob.colorCorrectionInfo.pressType,
          speed: this.mlpaJob.colorCorrectionInfo.speed,
          substrate: this.mlpaJob.colorCorrectionInfo.substrateWeight,
          printMode: this.mlpaJob.colorCorrectionInfo.printMode,
          inkColors: this.mlpaJob.colorCorrectionInfo.inkColors
        };

        // Set job characterization values
        this.colorManagementSystemSettings.setJobCharacterizationValues(jobCharacterizationValues);
      }

      // Get designs numbers of the color correction enabled lanes
      let designNumbers = [];
      this.mlpaLanes.forEach(mlpaLane => {
        if (mlpaLane.laneType === MlpaLaneType.Graphic && mlpaLane.colorCorrectionInfo.isColorCorrectionEnabled) {
          designNumbers.push(mlpaLane.designNumber);
        }
      });

      // Remove duplicates
      designNumbers = [...new Set(designNumbers)];
      if (designNumbers.length > 0) {
        return this._mlpaJobOptionsService.getAllDesignCharacterizationValues(designNumbers, this.jobSettings?.asset?.assetType);
      } else {
        return of(new PagedResult<DesignCharacterizationResponse>());
      }
    }),
      catchError(err => {
        this._alerts.add('No MLPA Job Found', 'We could not find the MLPA Job you were looking for', AlertType.Error);
        this.loadingJobDetails = false;
        this._router.navigate(['/home']);
        return of(new PagedResult<DesignCharacterizationResponse>());
      })).subscribe((response) => {
        if (response.results?.length > 0) {
          // Updated the default characterization values to all the color correction enabled lanes
          response.results.map(result => {
            this.mlpaLanes.forEach(mlpaLane => {
              if (mlpaLane.laneType === MlpaLaneType.Graphic && mlpaLane.colorCorrectionInfo.isColorCorrectionEnabled && mlpaLane.designNumber === result.designNumber) {
                mlpaLane.definedColorCorrectionInfo.overPrintVarnish = result.overPrintVarnish;
                mlpaLane.definedColorCorrectionInfo.pressType = result.pressType;
                mlpaLane.definedColorCorrectionInfo.substrateWeight = result.substrateWeight;
                mlpaLane.definedColorCorrectionInfo.speed = result.speed;
                mlpaLane.definedColorCorrectionInfo.printMode = result.printMode;
                mlpaLane.definedColorCorrectionInfo.inkColors = result.inkColors;
                mlpaLane.definedColorCorrectionInfo.totalAreaCoverage = result.totalAreaCoverage;
                mlpaLane.definedColorCorrectionInfo.inkWeight = result.inkWeight;
              }
            });
          });
        }

        // Recalculate job properties
        this.mlpaLanes = recalculateMlpaJobProperties(this.mlpaLanes, this.jobSettings, this._alerts);

        this._updateView();
        this._mlpaStateService.setByValue('editMode', true);

        this.mlpaJobViewSavedState = {} as MlpaJobView;
        deepCopyProperties(this.mlpaJobViewSavedState, this.mlpaJobView);

        this.toggleJobBuilder();
        this.isLoading = false;
        this.loadingJobDetails = false;
        this.isSpecial = this.mlpaJob.isSpecial;
      }, (err) => {
        this.loadingJobDetails = false;
        this._alerts.add('Could not find the design characterization values', 'Please try after some time', AlertType.Error);
      });
  }

  private startCase(value: string): string {
    return startCase(value);
  }

}
