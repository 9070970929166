import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MlpaJobOptionAsset } from './mlpa-job-option-asset';
import { MlpaJobOptionColorManagementSystem } from './mlpa-job-option-color-management-system';
import { PagedResult } from '@shared/paged-result';
import { MlpaCharacterizationValue, MlpaCharacterizationValues, DesignCharacterizationResponse, DesignCharValuesValidationResponse } from './mlpa-job-option-characterization-value';
import { AssetType } from '@shared/asset-type.enum';

@Injectable()
export class MlpaJobOptionsService {

  constructor(private readonly _http: HttpClient) { }

  public getMlpaJobOptions(includeDisabled: boolean = false): Observable<MlpaJobOptionAsset[]> {
    const params = new HttpParams().set('includeDisabled', `${includeDisabled}`);
    return this._http.get<MlpaJobOptionAsset[]>(`${environment.tartanApiUrl}mlpa-jobs/options`, { params });
  }

  public getColorManagementSystems(): Observable<PagedResult<MlpaJobOptionColorManagementSystem>> {
    return this._http.get<PagedResult<MlpaJobOptionColorManagementSystem>>(`${environment.tartanApiUrl}color-management`);
  }

  public getCharacterizationValues(): Observable<MlpaCharacterizationValues[]> {
    return this._http.get<MlpaCharacterizationValues[]>(`${environment.tartanApiUrl}characterization/variables`);
  }

  public getDesignCharacterizationValues(designNumber: string, assetType: AssetType): Observable<DesignCharacterizationResponse> {
    const params = new HttpParams().set('assetType', assetType.toString());
    return this._http.get<DesignCharacterizationResponse>(`${environment.tartanApiUrl}characterization/${designNumber}`, { params });
  }

  public getAllDesignCharacterizationValues(designNumbers: string[], assetType: AssetType): Observable<PagedResult<DesignCharacterizationResponse>> {
    let params = new HttpParams();
    designNumbers.forEach(desigNumber => {
      params = params.append('designNumbers', desigNumber);
    });
    params = params.append('assetType', assetType.toString());
    return this._http.get<PagedResult<DesignCharacterizationResponse>>(`${environment.tartanApiUrl}characterization`, { params });
  }

  public checkCharacterizationValues(
    colorManagementSystemId: string,
    designNumber: string,
    laneNumber: number,
    mlpaCharaceterizationValue: MlpaCharacterizationValue): Observable<DesignCharValuesValidationResponse> {
    const params = new HttpParams()
      .set('colorManagementSystemId', `${colorManagementSystemId}`)
      .set('designNumber', `${designNumber}`)
      .set('laneNumber', `${laneNumber}`)
      .set('substrateWeight', `${mlpaCharaceterizationValue.substrate}`)
      .set('printMode', `${mlpaCharaceterizationValue.printMode}`)
      .set('pressType', `${mlpaCharaceterizationValue.pressType}`)
      .set('inkColors', `${mlpaCharaceterizationValue.inkColors}`)
      .set('inkWeight', `${mlpaCharaceterizationValue.inkWeight}`)
      .set('overPrintVarnish', `${mlpaCharaceterizationValue.overPrintVarnish}`)
      .set('speed', `${mlpaCharaceterizationValue.speed}`)
      .set('totalAreaCoverage', `${mlpaCharaceterizationValue.totalAreaCoverage}`);

    return this._http.get<DesignCharValuesValidationResponse>(`${environment.tartanApiUrl}color-management/${colorManagementSystemId}/profileTemplate`, { params });
  }

}
