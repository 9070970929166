<div *ngIf="showSearch" class="product-design-search">
  <div class="product-design-search__inputs">
    <ng-container *ngIf="!showKiwiSearch">
      <pkg-search-auto-complete *ngFor="let details of autoCompleteDetails | slice:0:4" [details]="details" [assetType]="assetType"></pkg-search-auto-complete>

      <div [@accordion]="showAdvanced ? 'open': 'close'" [hidden]="!showAdvanced" class="advanced-fields">
        <pkg-search-auto-complete *ngFor="let details of autoCompleteDetails | slice:4" [details]="details" [assetType]="assetType"></pkg-search-auto-complete>
        <div class="search-field">
          <label class="search-field__label" for="duedate">
            <span>Due Date</span>
            <span [hidden]="!maxDueDate" class="search-field__tag">less than</span>
          </label>
          <input [(ngModel)]="maxDueDate" type="date" id="duedate">
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="showKiwiSearch">
      <pkg-search-auto-complete class="non-kiwi-search" [details]="nonKiwiSearchDetails"></pkg-search-auto-complete>
    </ng-container>
  </div>
  <div class="cache-warning">
    <i *ngIf="!showKiwiSearch" class="material-icons">&#xE422;</i>
    <span *ngIf="!showKiwiSearch">Plex data is cached, it may take up to 5 minutes for changes to be available</span>
    <i *ngIf="showKiwiSearch" class="material-icons">&#xE1AF;</i>
    <span *ngIf="showKiwiSearch">Kiwiplan search is <strong>DISABLED</strong> you can only search by Design Number</span>
  </div>
  <div class="product-design-search__controls">
    <button class="button button--green product-search__button-ga" (click)="onSearch()" [disabled]="!hasSearchSelections()">
      <i class="material-icons">&#xE8B6;</i>
      <span>Search</span>
    </button>
    <button class="button" (click)="clear()">
      <i class="material-icons">&#xE888;</i>
      <span>Clear</span>
    </button>
    <div class="order-search_controls--right">
      <button *ngIf="!showKiwiSearch" class="button" (click)="toggleAdvanced()">
        <i *ngIf="!showAdvanced" class="material-icons">&#xEb48;</i>
        <i *ngIf="showAdvanced" class="material-icons">&#xE336;</i>
        <span *ngIf="!showAdvanced">Advanced</span>
        <span *ngIf="showAdvanced">Basic</span>
      </button>
      <button class="button" (click)="closeSearch()">
        <i class="material-icons">&#xE316;</i>
        <span>Close</span>
      </button>
    </div>
  </div>
</div>
<div *ngIf="!showSearch" class="search-details" (click)="editSearch()">
  <div *ngIf="!hasSearchSelections" class="search-details__empty">
    <i class="material-icons">&#xE8B6;</i>
    <div>
      <div class="empty__title">No search criteria selected</div>
      <div class="empty__info">Click here to expand your search criteria</div>
    </div>
  </div>
  <div class="search-details__criteria">
    <ng-container *ngFor="let details of autoCompleteDetails">
      <div *ngIf="details.textValue" class="search-details__info" (click)="$event.stopPropagation()">
        <i class="info__clear material-icons" (click)="removeSearchCriteria(details, $event)">&#xE888;</i>
        <span class="info__title">{{details.name}}:</span>
        <ng-container *ngIf="!details.isExactSearch">
          <span class="info__value">...{{details.textValue}}...</span>
          <span class="info__type">{{details.searchType}}</span>
        </ng-container>
        <ng-container *ngIf="details.isExactSearch">
          <span class="info__value info__value--green">{{details.textValue}}</span>
          <span class="info__type info__type--green">{{details.searchType}}</span>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf=showKiwiSearch>
      <div *ngIf="nonKiwiSearchDetails?.textValue" class="search-details__info" (click)="$event.stopPropagation()">
        <i class="info__clear material-icons" (click)="removeSearchCriteria(nonKiwiSearchDetails, $event)">&#xE888;</i>
        <span class="info__title">{{nonKiwiSearchDetails?.name}}:</span>
        <ng-container *ngIf="!nonKiwiSearchDetails?.isExactSearch">
          <span class="info__value">...{{nonKiwiSearchDetails?.textValue}}...</span>
          <span class="info__type">{{nonKiwiSearchDetails?.searchType}}</span>
        </ng-container>
        <ng-container *ngIf="nonKiwiSearchDetails?.isExactSearch">
          <span class="info__value info__value--green">{{nonKiwiSearchDetails?.textValue}}</span>
          <span class="info__type info__type--green">{{nonKiwiSearchDetails?.searchType}}</span>
        </ng-container>
      </div>
    </ng-container>
    <div *ngIf="maxDueDate" class="search-details__info" (click)="$event.stopPropagation()">
      <i class="info__clear material-icons" (click)="removeDueDateCriteria($event)">&#xE888;</i>
      <span class="info__title">Due Date:</span>
      <span class="info__value info__value--orange">{{maxDueDate | date: 'MM/dd/yyyy'}}</span>
      <span class="info__type info__type--orange">less</span>
    </div>
    <div *ngIf="maxWidth" class="search-details__info" (click)="$event.stopPropagation()">
      <i class="info__clear material-icons" (click)="removeWidthCriteria($event)">&#xE888;</i>
      <span class="info__title">Width:</span>
      <span class="info__value info__value--orange">{{maxWidth}}</span>
      <span class="info__type info__type--orange">less</span>
    </div>
  </div>
  <div class="search-details__expand" (click)="editSearch()">
    <i class="material-icons">&#xE313;</i>
  </div>
</div>
