import { Component, OnInit, Input } from '@angular/core';
import { MlpaJobView } from '../../../../pages/home/view-mlpa-jobs/mlpa-job-view';

@Component({
  selector: 'pkg-mlpa-base-body',
  templateUrl: './mlpa-base-body.component.html',
  styleUrls: ['./mlpa-base-body.component.scss']
})
export class MlpaBaseBodyComponent implements OnInit {
 @Input() job: MlpaJobView;
  constructor() { }

  ngOnInit(): void {
  }

}
