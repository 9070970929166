export enum StatusFlags {
  QUEUED = 1,
  PROCESSING = 2,
  PROCESSED = 4,
  GENERATED = 8,
  SUBMITTED = 16,
  FAILED = 32,
  SPECIAL = 64,
  STRESS = 128,
  ALL = QUEUED | PROCESSING | PROCESSED | GENERATED | SUBMITTED | FAILED | SPECIAL | STRESS
}

export enum MlpaJobPropertiesFlag {
  PROGRAMNUMBER = 1,
  ORDERNUMBER = 2,
  SETUPNUMBER = 4,
  DESIGNNUMBER = 8,
  CREATEDDATE = 16,
  NAME = 32,
  UNIQUEID = 64,
  DESIGNDESCRIPTION = 128,
  COLORPROFILE = 256,
  PAPERTYPE = 512,
  ASSET = 1024,

  // tslint:disable-next-line: no-bitwise
  ALL = PROGRAMNUMBER | ORDERNUMBER | SETUPNUMBER | DESIGNNUMBER | CREATEDDATE | NAME | UNIQUEID | DESIGNDESCRIPTION | COLORPROFILE | PAPERTYPE | ASSET
}

export enum OrderFlag {
  ASC = 1,
  DSC = 2
}
