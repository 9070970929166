<section class="site__menu" [class.collapsed]="isCollapsed">
  <header class="site__header" [class.header-collapsed]="isCollapsed">
    <a [routerLink]="['/home']" [routerLinkActive]="['activated']">
      <img *ngIf="!isCollapsed" class="site__header-logo" [ngStyle]="getLogoStyle" src="../assets/full-logo-dark.svg" />
      <img *ngIf="isCollapsed" class="site__header-logo logo-collapsed" [ngStyle]="getLogoStyle" src="../assets/small-logo-dark.svg" />
    </a>
    <div class="chips" [class.test-chip-collapsed]="isCollapsed">
      <span *ngIf="isTesting" class="material-icons status-chip status-chip--testing" title="Test Environment"></span>
      <span *ngIf="!isCollapsed" class="status-chip status-chip--text" title="Version Number">v{{ versionNumber }}</span>
    </div>
  </header>
  <div class="toggle dark theme-picker" [class.collapsed]="!isCollapsed" title="Enable/Disable Dark Mode" (change)="_pkgThemeService.toggleTheme()">
    <label class="switch switch-small">
      <input type="checkbox" [(ngModel)]="toggleLaneState" />
      <span class="slider slider-small material-icons"></span>
    </label>
  </div>
  <div *ngIf="authService?.user" class="site__profile" [class.collapsed]="isCollapsed">
    <div class="toggle dark theme-picker" [class.collapsed]="isCollapsed" title="Enable/Disable Dark Mode" (change)="_pkgThemeService.toggleTheme()">
      <label class="switch switch-small">
        <input type="checkbox" [(ngModel)]="toggleLaneState" />
        <span class="slider slider-small material-icons"></span>
      </label>
    </div>
    <ng-container *ngIf="authService?.user?.photoUrl; else generated">
      <img class="site__user-img" *ngIf="!showGeneratedProfile" (error)="showGeneratedProfile = true" [src]="authService?.user?.photoUrl" title="{{ authService?.user?.displayName }}" alt="{{ authService?.user?.displayName }}" />
      <div class="site__user-img" *ngIf="showGeneratedProfile" [ngStyle]="{ 'background-color': tempPhotoColor }" title="{{ authService?.user?.displayName }}">
        <span>{{ (authService?.user?.displayName?.split(' '))[0][0]
          }}{{ (authService?.user?.displayName?.split(' '))[1][0] }}</span>
      </div>
    </ng-container>
    <ng-template #generated>
      <div class="site__user-img" [ngStyle]="{ 'background-color': tempPhotoColor }" title="{{ authService?.user?.displayName }}">
        <span>{{ (authService?.user?.displayName?.split(' '))[0][0]
          }}{{ (authService?.user?.displayName?.split(' '))[1][0] }}</span>
      </div>
    </ng-template>
  </div>
  <ng-container *ngIf="authService?.user && !isCollapsed">
    <div class="status-profile-details" title="{{ authService?.user?.displayName }}">
      <span class="site__user-name">{{ authService?.user?.displayName }}</span>
      <span title="{{ authService?.user.emailAddress }}" class="site__user-email">{{ authService?.user?.emailAddress }}</span>
    </div>
  </ng-container>
  <nav *ngIf="authService.user" class="site__nav" [class.nav-collapsed]="isCollapsed" (click)="toggleNavigation()">
    <ul>
      <li class="site__nav-link">
        <a [routerLink]="['/home']" [routerLinkActive]="['activated']">
          <i class="material-icons-outlined">&#xE88A;</i>
          <span *ngIf="!isCollapsed">Home</span>
        </a>
      </li>
      <li *ngIf="showMlpa" class="site__nav-link">
        <a [routerLink]="['/mlpa']" [routerLinkActive]="['activated']">
          <i class="material-icons-outlined">&#xE03C;</i>
          <span *ngIf="!isCollapsed">Create MLPA</span>
        </a>
      </li>
     <!-- <li *ngIf="showSchedule" class="site__nav-link">
        <a [routerLink]="['/schedule']" [routerLinkActive]="['activated']">
          <i class="material-icons-outlined">today</i>
          <span *ngIf="!isCollapsed">Schedule MLPA</span>
        </a>
      </li>-->
      <li *ngIf="showRollMap" class="site__nav-link">
        <a [routerLink]="['/rollmap']" [routerLinkActive]="['activated']">
          <div class="roll-map">
            <svg version="1.1" class="roll-map-nav" id="roll-map-nav" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
              <use href="#roll-map"></use>
            </svg>
            <svg version="1.1" class="roll-map-nav-activated" id="roll-map-nav-activated" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
              <use href="#roll-map-activated"></use>
            </svg>
          </div>
          <span *ngIf="!isCollapsed">Roll Map</span>
        </a>
      </li>
      <ng-container *ngIf="showAdmin">
        <li class="site__nav-title">
          <span *ngIf="!isCollapsed">Admin</span>
        </li>
        <li class="site__nav-link">
          <a [routerLink]="['/admin/user']" [routerLinkActive]="['activated']">
            <i class="material-icons-outlined">&#xE03F;</i>
            <span *ngIf="!isCollapsed">Users</span>
          </a>
        </li>
        <li class="site__nav-link">
          <a [routerLink]="['/admin/asset']" [routerLinkActive]="['activated']">
            <i class="material-icons-outlined">&#xE8AD;</i>
            <span *ngIf="!isCollapsed">Assets</span>
          </a>
        </li>
        <li class="site__nav-link">
          <a [routerLink]="['/admin/colormanagementsystem']" [routerLinkActive]="['activated']">
            <i class="material-icons-outlined">&#xe243;</i>
            <span *ngIf="!isCollapsed">Color Management</span>
          </a>
        </li>
        <li *ngIf="showPrintProcessor" class="site__nav-link">
          <a [routerLink]="['/admin/printprocessor']" [routerLinkActive]="['activated']">
            <i class="material-icons-outlined">&#xEF6E;</i>
            <span *ngIf="!isCollapsed">Print Processor</span>
          </a>
        </li>
        <li class="site__nav-link">
          <a [routerLink]="['/admin/papertypes']" [routerLinkActive]="['activated']">
            <i class="material-icons-outlined">&#xe8d4;</i>
            <span *ngIf="!isCollapsed">Paper Types</span>
          </a>
        </li>
        <li class="site__nav-link">
          <a [routerLink]="['/stats']" [routerLinkActive]="['activated']">
            <i class="material-icons-outlined">&#xE6DF;</i>
            <span *ngIf="!isCollapsed">Stats</span>
          </a>
        </li>
        <li class="site__nav-link">
          <a [routerLink]="['status-page']" [routerLinkActive]="['activated']">
            <i class="material-icons-outlined">&#xEB43;</i>
            <span *ngIf="!isCollapsed">Health</span>
          </a>
        </li>
      </ng-container>
      <li class="site__nav-link">
        <a (click)="showConfirm = true">
          <i class="material-icons-outlined">&#xE898;</i>
          <span *ngIf="!isCollapsed">Logout</span>
        </a>
      </li>
    </ul>
  </nav>
  <div *ngIf="authService?.user" class="site__nav-footer" [class.collapsed]="isCollapsed">
    <div class="site__collapse-nav" [class.hamburger-collapsed]="isCollapsed" (click)="toggleMenu()">
      <div class="site__collapse-hamburger" [class.collapsed]="isCollapsed">
        <div class="icon-container">
          <span class="material-icons nav-toggle-arrow">&#xe5e0;</span>
        </div>
      </div>
    </div>
  </div>
</section>
<div *ngIf="isCollapsed" class="site__toggle-nav">
  <button class="button button-fab button--blue" (click)="toggleMenu()">
    <span class="material-icons">&#xE3C7;</span>
  </button>
</div>
<div>
<pkg-modal *ngIf="showConfirm" (closeEmitter)="showConfirm = false" (confirmEmitter)="logout(); showConfirm = false"></pkg-modal>
