import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertsService } from '@shared/alerts/alerts.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { AlertType } from '@shared/alerts/alert-type.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { PrintProcessor } from '@shared/print-processor.model';
import { PrintProcessorService } from '@app/pages/admin/shared/print-processor.service';
import { PrintProcessorType } from '@shared/print-processor-type.enum';

@Component({
  selector: 'pkg-print-processor-edit',
  templateUrl: './print-processor-edit.component.html',
  styleUrls: ['./print-processor-edit.component.scss']
})
export class PrintProcessorEditComponent implements OnInit {
  printProcessor: PrintProcessor = new PrintProcessor();
  PrintProcessorForm: FormGroup;
  title = 'Edit';
  isAddPrintProcessorRoute: boolean = this.router.url === '/admin/printprocessor/add';
  printProcessorType = PrintProcessorType;
  isLoading = true;
  showDelete = false;
  constructor(private readonly printProcessorService: PrintProcessorService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly alertsService: AlertsService,
    private readonly formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.initializeForm();
    this.route.paramMap.pipe(switchMap((params) => {
        if (this.isAddPrintProcessorRoute) {
          this.title = 'Add';
          return of(new PrintProcessor(null, ''));
        } else {
          return this.printProcessorService.get(params.get('printProcessorId'));
        }
      }))
      .subscribe((data) => {
        this.printProcessor = data;
        this.initializeForm();
        this.isLoading = false;
      }, (err) => {
        this.isLoading = false;
        this.alertsService.add('Failed to load print processor', 'Please try again or contact the system administrator', AlertType.Error);
      });
  }

  initializeForm(): void {
    this.PrintProcessorForm = this.formBuilder.group({
      name: [this.printProcessor.name, [Validators.required, Validators.pattern('^.{4,255}$')]],
      audience: [this.printProcessor.audience, Validators.required],
      type: [!!this.printProcessor.type ? this.printProcessor.type : this.printProcessorType.PlanetPress, Validators.required],
      isEnabled: [!!this.printProcessor.isEnabled ? this.printProcessor.isEnabled : false, Validators.required],
      isInTestMode: [!!this.printProcessor.isInTestMode ? this.printProcessor.isInTestMode : false, Validators.required]
    });
  }

  isValid(field: string): boolean {
    return !this.PrintProcessorForm.get(field).valid && this.PrintProcessorForm.get(field).touched;
  }

  cancel(): void {
    this.router.navigate(['/admin/printprocessor']);
  }

  savePrintProcessor(): void {
    if (this.printProcessor instanceof PrintProcessor) {
      this.printProcessor = Object.assign(this.printProcessor, this.PrintProcessorForm.value);
    } else {
      const copy = Object.assign(this.printProcessor, this.PrintProcessorForm.value) as PrintProcessor;
      this.printProcessor = new PrintProcessor(copy.id, copy.name);
      this.printProcessor = Object.assign(this.printProcessor, copy);
    }

    if (this.isAddPrintProcessorRoute) {
      this._addPrintProcessor();
      return;
    }

    if (this.printProcessor.id) {
      this._editPrintProcessor();
    } else {
      this.alertsService.add(
        'Failed to save',
        `There is not an id associated with this Print Processor, (Invalid entry ${this.printProcessor.id})`,
        AlertType.Error);
    }
  }

  confirmDelete(): void {
    this.showDelete = true;
  }
  delete() {
    this.isLoading = true;
    this.printProcessorService.deletePrintProcessor(this.printProcessor.id).subscribe((result) => {
      this.isLoading = false;
      this.alertsService.add('Deleted Print Processor', `Print Processor: ${this.printProcessor.name}`, AlertType.Success);
      this.router.navigate(['/admin/printprocessor']);
    }, (err) => {
      this.isLoading = false;
      this.alertsService.add('Failed to delete Print Processor', `Print Processor: ${this.printProcessor.name}`, AlertType.Error);
    });
  }

  private _addPrintProcessor(): void {
    this.isLoading = true;
    this.printProcessorService.addPrintProcessor(this.printProcessor)
      .subscribe((complete) => {
        this.isLoading = false;
        this.alertsService.add('Saved', `Print Processor added.`, AlertType.Success);
        this.router.navigate(['/admin/printprocessor']);
      }, (error) => {
        this.isLoading = false;
        this._showError(error);
      });
  }


  private _editPrintProcessor(): void {
    this.isLoading = true;
    this.printProcessorService.editPrintProcessor(this.printProcessor)
      .subscribe((complete) => {
        this.isLoading = false;
        this.alertsService.add('Saved', `Print Processor updated.`, AlertType.Success);
        this.router.navigate(['/admin/printprocessor']);
      }, (error) => {
        this.isLoading = false;
        this._showError(error);
      });
  }


  private _showError(error: HttpErrorResponse): void {
    this.alertsService.add('Failed to save', error?.error?.message, AlertType.Error);
  }
}
