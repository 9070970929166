import { mergeWith, has, cloneDeep } from 'lodash';

export const deepCopyProperties = (destination: any, main: any): void => {
  mergeWith(destination, main, (objectValue, sourceValue, key, object, source) => {
    if (has(source, key) && objectValue != null && typeof objectValue !== typeof (Function)) {
      return cloneDeep(sourceValue);
    }
  });
};

export const enumToSelectorEntity = (enumValue: Record<string, unknown>, defaultSelection: boolean = true): any[] =>
  Object.keys(enumValue).map(Number).filter(Boolean).map(key => ({ selected: defaultSelection, key, label: enumValue[key] }));

