import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pkg-card-progress-bar',
  templateUrl: './card-progress-bar.component.html',
  styleUrls: ['./card-progress-bar.component.scss']
})
export class CardProgressBarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
