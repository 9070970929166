<div class="container">
  <div class="page__header">
    <span class="material-icons">&#xEB43;</span>
    <span class="page-heading">Tartan Health</span>
    <span class="filler"></span>
    <span *ngIf="loading" class="loading"></span>
  </div>
  <div class="page__content">
    <div class="status-page-header">
      <img class="hummingbird-logo" src="../assets/full-logo-dark.svg" [ngStyle]="getLogoStyle" alt="Tartan + Hummingbird Logo" />
      <div class="status-page-header-info" [class.loading-size]="loading">
        <h2>{{ titleName }}</h2>
        <div *ngIf="loading" class="health-loading-message">{{ loadingMessage }}</div>
      </div>
    </div>
    <div class="status-page-details">
      <div class="active-events">
        <pkg-card>
          <pkg-card-header>
            <div class="card-header-details">
              <span class="alt-title">Active events</span>
              <span class="status-chip status-chip--text"
                [hidden]="!refreshedDateTime">{{ refreshedDateTime | date: 'medium'}}</span>
            </div>
          </pkg-card-header>
          <pkg-card-body>
            <div class="content">
              <span *ngIf="activeEvents.length < 1" class="info"> No degraded or unhealthy services detected. If you're
                experiencing issues, please <a href="mailto:GRP_PKGITTeamAppDev@GAPAC.com">contact
                  support</a>.</span>
              <ng-container *ngIf="activeEvents.length > 0">
                <div class="issue-container">
                  <ul class="issue-list" *ngFor="let event of activeEvents">
                    <li class="issue info">Service degradation detected in {{ event.statusName }} - {{ event.dependencyName }}
                    </li>
                  </ul>
                </div>
              </ng-container>
            </div>
          </pkg-card-body>
        </pkg-card>
      </div>
      <div class="status-service-details">
        <pkg-card [style.height]="showMore[i] ? 'unset' : '100%'"
          *ngFor="let status of apiStatusResults; let i = index">
          <pkg-card-header>
            <div class="card-header-details">
              <span class="material-icons {{getHealthStatus(status)}}"></span>
              <span class="title">{{status?.name}}</span>
              <span class="status-chip status-chip--text" [title]='status?.version'>{{status?.version}}</span>
            </div>
          </pkg-card-header>
          <pkg-card-body (click)="toggleShowMore(i)">
            <div class="extra-details" [@accordion]="showMore[i] ? 'open': 'close'" [hidden]="!showMore[i]">
              <div class="card-body-details">
                <div class="content">
                  <span class="title">Environment</span>
                  <span class="info" [title]='status?.environment'
                    style="text-transform: uppercase;">{{status?.environment}}</span>
                </div>

                <div class="content">
                  <span class="title">Machine Name</span>
                  <span class="info" [title]='status?.machineName'>{{status?.machineName}}</span>
                </div>

                <div class="content">
                  <span class="title">Operating System</span>
                  <span class="info" [title]='status?.operatingSystemVersion'>{{status?.operatingSystemVersion}}</span>
                </div>

                <div class="content">
                  <span class="title">Threads</span>
                  <span class="info" [title]='status?.numberOfThreads'>{{status?.numberOfThreads}}</span>
                </div>

                <div class="content">
                  <span class="title">Memory Usage</span>
                  <span class="info"
                    [title]='status?.memoryUsageInBytes'>{{status?.memoryUsageInBytesNumber | number: '1.2-3'}}
                    {{status?.memoryUsageInBytesUnit}}</span>
                </div>

                <div class="content">
                  <span class="title">Max Memory Usage</span>
                  <span class="info"
                    [title]='status?.maxMemoryUsageInBytes'>{{status?.maxMemoryUsageInBytesNumber | number: '1.2-3'}}
                    {{status?.maxMemoryUsageInBytesUnit}}</span>
                </div>

                <div class="content">
                  <span class="title">Processor Count</span>
                  <span class="info" [title]='status?.processorCount'>{{status?.processorCount}}</span>
                </div>

                <div class="content">
                  <span class="title">Processor Time</span>
                  <span class="info" [title]='status?.totalProcessorTime'>{{status?.totalProcessorTime}}</span>
                </div>

                <div class="content">
                  <span class="title">Start Date</span>
                  <span class="info" [title]='status?.startDate'>{{status?.startDate | date:'medium'}}</span>
                </div>

              </div>
              <div class="card-body-details">
                <span class="alt-title heading">Dependencies</span>
                <div class="dependency" *ngFor="let dependency of status?.dependencies; let i = index">
                  <div class="content">
                    <span class="material-icons" [ngClass]="dependency.isHealthy ? 'healthy' : 'unhealthy'"
                      [title]="dependency.message"></span>
                    <span class="info">{{dependency.name}}</span>
                  </div>
                </div>
              </div>
            </div>
          </pkg-card-body>
          <pkg-card-footer [class.expanded]="showMore[i]" (click)="toggleShowMore(i)">
            <div class="order-search_controls--right">
              <button class="button">
                <i *ngIf="!showMore[i]" class="material-icons">&#xE313;</i>
                <i *ngIf="showMore[i]" class="material-icons">&#xE316;</i>
                <span *ngIf="showMore"></span>
                <span *ngIf="!showMore"></span>
              </button>
            </div>
          </pkg-card-footer>
        </pkg-card>
      </div>
    </div>
  </div>
  <div class="page__footer">
    <span class="material-icons healthy"></span>
    <span class="footer-heading">Healthy</span>
    <span class="material-icons degraded"></span>
    <span class="footer-heading">Degraded</span>
    <span class="material-icons unhealthy"></span>
    <span class="footer-heading">Unhealthy</span>
    <span class="material-icons advisory"></span>
    <span class="footer-heading">Advisory</span>
  </div>
</div>
