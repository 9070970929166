/*
The T1195 asset does not work without Quality Mode.
Quality mode (standard, high-primary-standard) is used to send to the press through JDF.
Press Type (Mode-P, Mode-GP) is used to send to the GMG for color correction.
Quality mode and Press Type are one and the same with different names:
Mode-P -> standard
Mode-GP -> high-primary-standard
*/

export class qualityModes{
  name: string;
  value: string;
}
export const getQualityModes= ()=> {
  const qualityModeAttributes = new Array<qualityModes>();

  const qualityModesAttribute1 = new qualityModes();
  qualityModesAttribute1.name = 'Mode-P';
  qualityModesAttribute1.value = 'standard';

  const qualityModesAttribute2 = new qualityModes();
  qualityModesAttribute2.name = 'Mode-GP';
  qualityModesAttribute2.value = 'high-primary-standard';

  const qualityModesAttribute3 = new qualityModes();
  qualityModesAttribute3.name = 'Mode-S';
  qualityModesAttribute3.value = 'Mode-S';

  qualityModeAttributes.push(qualityModesAttribute1);
  qualityModeAttributes.push(qualityModesAttribute2);
  qualityModeAttributes.push(qualityModesAttribute3);

  return qualityModeAttributes;
};
