import { MlpaLaneView } from './mlpa-lane-view';
import { MlpaJobApi } from '@shared/mlpa-job-api';
import { environment } from '../../../../environments/environment';
import { MlpaJob } from '../../../mlpa/mlpa-job/mlpa-job';
import { MlpaJobSettings } from '../../../mlpa/mlpa-job-settings/mlpa-job-settings';
import { deepCopyProperties } from '@shared/pkg-helper';
import { MlpaLane } from '../../../mlpa/mlpa-lane/mlpa-lane';
import { MlpaLaneApi } from '@shared/mlpa-lane-api';
import { MlpaLaneType } from '../../../mlpa/mlpa-lane/mlpa-lane-type.enum';
import { AssetAttributes } from 'src/app/shared/asset-attributes';
import { ColorManagementSystemSettings } from '@app/mlpa/color-management-system-settings/color-management-system-settings';
import { MlpaJobColorcorrectionInfo } from '@app/mlpa/mlpa-job/mlpa-job-color-correction-info';

export class MlpaJobView {
  id: string;
  name: string;
  parentMlpaJobId: number;
  parentClonedMlpaJobId: string;
  isParentMlpaJob: boolean;
  isLinkedMlpaJob: boolean;
  uniqueId: string;
  programNumber: string;
  setupNumber: string;
  blanketNumber: string;
  assetName: string;
  assetAttributes: AssetAttributes;
  paperTypeName: string;
  marginWidthInInches: number;
  lineBreakLengthInInches: number;
  paperWidthInInches: number;
  totalWidthInInches: number;
  wasteWidthInInches: number;
  totalLengthInInches: number;
  showPreview: boolean;
  isCurrentPreviewButton = false;
  wasteLengthInInches: number;
  isGenerated: boolean;
  isSubmitted: boolean;
  isProcessing: boolean;
  isProcessed: boolean;
  isColorCorrecting: boolean;
  isColorCorrected: boolean;
  isColorCorrectionInQueue: boolean;
  colorCorrectionProcessingPercentage: number;
  isOk: boolean;
  errors: string;
  mlpaLanes: MlpaLaneView[] = [];
  linkedMlpaJobs: MlpaJobView[] = [];
  createdDate: Date;
  updatedDate: Date;
  showLanes: boolean;
  orderState: string;
  previewState: string;
  showSubmitLoading: boolean;
  showGenerateLoading: boolean;
  designNumber: string;
  isSpecial: boolean;
  warnings: string;
  hasWarnings: boolean;
  isSample: boolean;
  isMockup: boolean;
  isDistortion: boolean;
  colorCorrectionInfo: MlpaJobColorcorrectionInfo;
  colorManagementSystemName: string;
  qualityMode: string;

  get isClone(): boolean {
    return !!this.parentClonedMlpaJobId?.length;
  }

  get isJobCharacterizationValueExist(): boolean {
    return (this.colorCorrectionInfo.pressType.length > 0 &&
      this.colorCorrectionInfo.substrateWeight.length > 0 &&
      this.colorCorrectionInfo.overPrintVarnish.length > 0 &&
      this.colorCorrectionInfo.speed.length > 0 &&
      this.colorCorrectionInfo.printMode.length > 0 &&
      this.colorCorrectionInfo.inkColors.length > 0);
  }

  get lengthInFeet(): string {
    let maxLengthInInches = 0;
    this.mlpaLanes.forEach(o => {
      if (o.totalLengthInInches > maxLengthInInches) {
        maxLengthInInches = o.totalLengthInInches;
      }
    });

    // add linebreak to total length for smartline since the lanes themselves only account for
    // the smartline and not the extra solid webweave after it.
    // when we add additional webweaves in the lanes this logic needs to be revisited.
    if (this.mlpaLanes[0]?.laneType === MlpaLaneType.SmartLine) {
      maxLengthInInches += this.lineBreakLengthInInches;
    }

    return (maxLengthInInches / 12).toFixed(2);
  }
  get hasQrCode(): boolean {
    if (this.linkedMlpaJobs.length === 0) {
      return false;
    } else {
      for (const job of this.linkedMlpaJobs) {
        for (const lane of job.mlpaLanes) {
          if (lane.laneType === MlpaLaneType.QrCode) {
            return true;
          }
        }
      }
      return false;
    }
  }

  get isColorCorrectionEnabled(): boolean {
    if (!!this.mlpaLanes) {
      for (const lane of this.mlpaLanes) {
        if (lane.colorCorrectionInfo.isColorCorrectionEnabled && !lane.isWebWeave) {
          return true;
        }
      }
    }

    return false;
  }

  get showJobCharacterizationValues(): boolean {
    for (const mlpaLane of this.mlpaLanes) {
      if (mlpaLane.laneType === MlpaLaneType.Graphic && mlpaLane.colorCorrectionInfo.isColorCorrectionEnabled) {
        return true;
      }
    }
    return false;
  }

  get hasStressFile(): boolean {
    if (this.mlpaLanes[1]?.laneType === MlpaLaneType.Stress) {
      return true;
    }

    if (this.mlpaLanes[1]?.laneType === MlpaLaneType.Graphic && this.parentMlpaJobId) {
      return true;
    }

    if (this.linkedMlpaJobs.length === 0) {
      return false;
    } else {
      for (const job of this.linkedMlpaJobs) {
        for (const lane of job.mlpaLanes) {
          if (lane.laneType === MlpaLaneType.Stress) {
            return true;
          }
        }
      }

      return false;
    }
  }

  private _isNewJob: boolean;
  get isNewJob(): boolean {
    return this._isNewJob;
  }
  set isNewJob(value: boolean) {
    this._isNewJob = value;

    if (!!this.mlpaLanes) {
      // set all lanes to be new job.
      this.mlpaLanes.forEach((lane) => {
        lane.isNewJob = value;
      });
    }
  }
  private _jobSettings: MlpaJobSettings;
  get jobSettings(): MlpaJobSettings {
    return this._jobSettings;
  }
  set jobSettings(jobSettings: MlpaJobSettings) {
    this._jobSettings = jobSettings;
    // set all lanes to be new job.
    if (!!this.mlpaLanes) {
      this.mlpaLanes.forEach((lane) => {
        lane.jobSettings = jobSettings;
      });
    }
  }
  private _colorManagementSystemSettings: ColorManagementSystemSettings;
  get colorManagementSystemSettings(): ColorManagementSystemSettings {
    return this._colorManagementSystemSettings;
  }
  set colorManagementSystemSettings(colorManagementSystemSettings: ColorManagementSystemSettings) {
    this._colorManagementSystemSettings = colorManagementSystemSettings;
  }
  get preview(): string {
    if (!!this.jobSettings && !!this.jobSettings.asset) {
      // find the asset
      const asset = this.jobSettings.assets.find(p => p.name === this.jobSettings.asset.name);
      // set the source
      if (asset) {
        return `${environment.tartanApiUrl}assets/${asset.id}/pdfs/${this.designNumber}/thumbnail?rotationInDegrees=90`;
      }
    }

    if (this.id) {
      return `${environment.tartanApiUrl}mlpa-jobs/${this.id}/thumbnail`;
    }

    return null;
  }
  get status(): string {
    if (!this.isOk) {
      return 'Failed';
    }
    if (this.isSubmitted) {
      return 'Submitted';
    }
    if (this.isGenerated) {
      return 'Generated';
    }
    if (this.isProcessing) {
      return 'Processing';
    }
    if (this.isColorCorrectionInQueue) {
      return 'Color correction in queue';
    }
    if (this.isColorCorrecting) {
      return 'Color correction processing';
    }
    if (this.isProcessed && !this.isColorCorrectionEnabled) {
      return 'Processed';
    }
    if (this.isProcessed && this.isColorCorrected && this.isColorCorrectionEnabled) {
      return 'Color corrected';
    }
    return 'Queued';
  }
  get statusClass(): string {
    let status = '';
    if (!this.isOk) {
      status = 'status-icon--failed';
    } else if (this.isSubmitted) {
      status = 'status-icon--submitted';
    } else if (this.isGenerated) {
      status = 'status-icon--generated';
    } else if (this.isProcessed && !this.isColorCorrectionEnabled && this.linkedMlpaJobs?.length === 0) {
      status = 'status-icon--processed';
    } else if (this.linkedMlpaJobs[0]?.isProcessed && !this.isColorCorrectionEnabled) {
      status = 'status-icon--processed';
    } else if (this.isProcessing && this.linkedMlpaJobs?.length === 0) {
      status = 'status-icon--processing spin';
    } else if (this.linkedMlpaJobs[0]?.isProcessing) {
      status = 'status-icon--processing spin';
    } else if (this.isColorCorrectionInQueue && this.linkedMlpaJobs?.length === 0) {
      status = 'status-icon--color--inqueue';
    } else if (this.linkedMlpaJobs[0]?.isColorCorrectionInQueue) {
      status = 'status-icon--color--inqueue';
    } else if (this.isProcessed && this.isColorCorrected && this.linkedMlpaJobs?.length === 0 && this.isColorCorrectionEnabled) {
      status = 'status-icon--color--processed';
    } else if (this.linkedMlpaJobs[0]?.isColorCorrected && this.linkedMlpaJobs[0]?.isColorCorrectionEnabled) {
      status = 'status-icon--color--processed';
    } else if (this.isColorCorrecting && this.linkedMlpaJobs?.length === 0) {
      status = 'status-icon--color--processing';
    } else if (this.linkedMlpaJobs[0]?.isColorCorrecting) {
      status = 'status-icon--color--processing';
    }

    if (this.hasWarnings) {
      status = `${status} warning`;
    }

    if (status !== '') {
      return status;
    }

    return 'status-icon--queued';
  }

  constructor(mlpaJob?: MlpaJobApi | MlpaJob) {
    if (!!mlpaJob) {
      // loop through properties to perform a deep copy of properties
      deepCopyProperties(this, mlpaJob);
      this.orderState = 'hide';
      this.previewState = 'hide';
      this.showPreview = false;
      this.setMlpaLanes(mlpaJob.mlpaLanes);
      this.setLinkedJobs(mlpaJob.linkedMlpaJobs);
    }
  }

  get isEligibleForSubmit(): boolean {
    let isEligible = (!this.isSubmitted &&
      !this.isProcessing &&
      !this.isColorCorrecting &&
      this.isOk &&
      ((this.isProcessed && !this.isColorCorrectionEnabled) ||
        (this.isColorCorrected && this.isColorCorrectionEnabled)));

    this.linkedMlpaJobs.forEach(job => {
      if ((!job.isProcessed && !job.isColorCorrectionEnabled) ||
        (!job.isColorCorrected && job.isColorCorrectionEnabled) ||
        job.isSubmitted ||
        job.isProcessing ||
        job.isColorCorrecting ||
        !job.isOk) {
        isEligible = false;
        return;
      }
    });
    return isEligible;
  }

  get isEligibleForEdit(): boolean {
    let isEligible = (!this.isSubmitted &&
      !this.isGenerated &&
      !this.isProcessing &&
      !this.isColorCorrecting &&
      ((this.isProcessed && !this.isColorCorrectionEnabled) ||
        (this.isProcessed && !this.isColorCorrected && this.isColorCorrectionEnabled) ||
        (this.isColorCorrected && this.isColorCorrectionEnabled)));

    this.linkedMlpaJobs.forEach(job => {
      if (job.isSubmitted ||
        job.isGenerated ||
        job.isProcessing ||
        job.isColorCorrecting) {
        isEligible = false;
        return;
      }
    });

    if (this.isNewJob) {
      isEligible = true;
    }

    return isEligible;
  }

  get isEligibleForDelete(): boolean {
    let isEligible = !this.isSubmitted && !this.isGenerated;
    this.linkedMlpaJobs.forEach(job => {
      if (job.isSubmitted || job.isGenerated) {
        isEligible = false;
        return;
      }
    });
    return isEligible;
  }

  setMlpaLanes(mlpaLanes: (MlpaLane | MlpaLaneApi)[]): void {
    this.mlpaLanes = [];
    if (mlpaLanes) {
      mlpaLanes.forEach((lane: MlpaLaneApi | MlpaLane) => {
        this.mlpaLanes.push(new MlpaLaneView(lane));
      });
      this.mlpaLanes.sort((a, b) => a.laneNumber - b.laneNumber);
    }
  }

  refreshLaneCharacterizationValues() {
    if (!!this.colorManagementSystemSettings && !!this.colorManagementSystemSettings.jobCharacterizationValues) {
      this.mlpaLanes.forEach(mlpaLane => {
        if (mlpaLane.laneType === MlpaLaneType.Graphic && mlpaLane.colorCorrectionInfo.isColorCorrectionEnabled) {
          if (!mlpaLane.colorCorrectionInfo.substrateWeight || mlpaLane.colorCorrectionInfo.substrateWeight === '') {
            mlpaLane.colorCorrectionInfo.substrateWeight = this.colorManagementSystemSettings.jobCharacterizationValues.substrate;
          }
          if (!mlpaLane.colorCorrectionInfo.speed || mlpaLane.colorCorrectionInfo.speed === '') {
            mlpaLane.colorCorrectionInfo.speed = this.colorManagementSystemSettings.jobCharacterizationValues.speed;
          }
          if (!mlpaLane.colorCorrectionInfo.overPrintVarnish || mlpaLane.colorCorrectionInfo.overPrintVarnish === '') {
            mlpaLane.colorCorrectionInfo.overPrintVarnish = this.colorManagementSystemSettings.jobCharacterizationValues.overPrintVarnish;
          }
          if (!mlpaLane.colorCorrectionInfo.pressType || mlpaLane.colorCorrectionInfo.pressType === '') {
            mlpaLane.colorCorrectionInfo.pressType = this.colorManagementSystemSettings.jobCharacterizationValues.pressType;
          }
          if (!mlpaLane.colorCorrectionInfo.printMode || mlpaLane.colorCorrectionInfo.printMode === '') {
            mlpaLane.colorCorrectionInfo.printMode = this.colorManagementSystemSettings.jobCharacterizationValues.printMode;
          }
          if (!mlpaLane.colorCorrectionInfo.inkColors || mlpaLane.colorCorrectionInfo.inkColors === '') {
            mlpaLane.colorCorrectionInfo.inkColors = this.colorManagementSystemSettings.jobCharacterizationValues.inkColors;
          }
        }
      });
    }
  }

  setLinkedJobs(linkedMlpaJobs: (MlpaJob | MlpaJobApi)[]): void {
    this.linkedMlpaJobs = [];
    if (linkedMlpaJobs) {
      linkedMlpaJobs.forEach((linkedJob: MlpaJobApi | MlpaJob) => {
        this.linkedMlpaJobs.push(new MlpaJobView(linkedJob));
        // If a linked job has an error, add it to the parent job
        if (!linkedJob.isOk) {
          this.isOk = linkedJob.isOk;
          this.errors += linkedJob.errors;
        }
      });
    }
  }

  isValidForStatusUpdates(): boolean {
    // Valid Jobs have been created/updated in the past hour, have not been processed, and have no errors
    const now = new Date();
    const oneHourAgo = new Date(now.getTime() - (60 * 60 * 1000));
    // Check this job
    const jobUpdatedDate = new Date(this.updatedDate);
    const jobCreatedDate = new Date(this.createdDate);
    if (this.isOk && (!this.isProcessed || !this.isColorCorrected) && (jobCreatedDate >= oneHourAgo || jobUpdatedDate >= oneHourAgo)) {
      return true;
    }
    // Check all linked jobs
    for (const linkedJob of this.linkedMlpaJobs) {
      const linkedJobUpdatedDate = new Date(linkedJob.updatedDate);
      const linkedJobCreatedDate = new Date(linkedJob.createdDate);
      if (linkedJob.isOk && (!linkedJob.isProcessed || !linkedJob.isColorCorrected) && (linkedJobCreatedDate >= oneHourAgo || linkedJobUpdatedDate >= oneHourAgo)) {
        return true;
      }
    }
    // Not valid for updates
    return false;
  }

  updateStatus(newMlpaJob: MlpaJobApi): void {
    // Update job
    this.isProcessed = newMlpaJob.isProcessed;
    this.isProcessing = newMlpaJob.isProcessing;
    this.isColorCorrected = newMlpaJob.isColorCorrected;
    this.isColorCorrecting = newMlpaJob.isColorCorrecting;
    this.isColorCorrectionInQueue = newMlpaJob.isColorCorrectionInQueue;
    this.colorCorrectionProcessingPercentage = newMlpaJob.colorCorrectionProcessingPercentage;
    this.errors = newMlpaJob.errors;
    this.isOk = newMlpaJob.isOk;
    this.warnings = newMlpaJob.warnings;
    this.hasWarnings = newMlpaJob.hasWarnings;
    // Update the job's lanes
    newMlpaJob.mlpaLanes.forEach(sourceMlpaLane => {
      const mlpaLaneToUpdate = this.mlpaLanes.find(p => p.id === sourceMlpaLane.id);
      if (mlpaLaneToUpdate) {
        mlpaLaneToUpdate.updateStatus(sourceMlpaLane);
      }
    });
    // Loop through each linked job and update it's status
    newMlpaJob.linkedMlpaJobs.forEach(sourceMlpaJob => {
      const linkedmlpaJobToUpdate = this.linkedMlpaJobs.find(p => p.id === sourceMlpaJob.id);
      if (linkedmlpaJobToUpdate) {
        // Recursively update linked jobs
        linkedmlpaJobToUpdate.updateStatus(sourceMlpaJob);
      }
    });
  }

  getJobType(): MlpaLaneType {
    return this.mlpaLanes[1]?.laneType;
  }
}
