import { StatusResults } from './status-page/status-results';

export class ColorManagementSystem {
  id: string;
  name: string;
  audience: string;
  jobPriority: number;
  type: ColorManagementSystemType;
  templateName: string;
  colorTemplateName: string;
  normalizationTemplateName: string;
  pathToSourcePdfs: string;
  pathToColorCorrectedPdfs: string;
  isEnabled: boolean;
  isInTestMode: boolean;
  healthStatus: StatusResults;
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }
}

export enum ColorManagementSystemType {
  Unknown,
  ColorServer
}
