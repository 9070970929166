const loadingMessages = [
  'Generating witty dialog...',
  'Swapping time and space...',
  'Tokenizing real life...',
  'Filtering morale...',
  'We need a new fuse...',
  'Have a good day.',
  'We\'re building the buildings as fast as we can',
  '(Pay no attention to the man behind the curtain)',
  '...and enjoy the elevator music...',
  'Please wait while the little elves draw your map',
  'Don\'t worry - a few bits tried to escape, but we caught them',
  'Would you like fries with that?',
  'Checking the gravitational constant in your locale...',
  'Go ahead -- hold your breath!',
  '...at least you\'re not on hold...',
  'Hum something loud while others stare',
  'You\'re not in Kansas any more',
  'The server is powered by a lemon and two electrodes.',
  'We\'re testing your patience',
  'keep calm and npm install',
  'The bits are flowing slowly today',
  'Dig on the \'X\' for buried treasure... ARRR!',
  'It\'s still faster than you could draw it',
  'My other loading screen is much faster.',
  'Reconfoobling energymotron...',
  '(Insert quarter)',
  'Are we there yet?',
  'Just count to 10',
  'Counting backwards from Infinity',
  'Don\'t panic...',
  'Embiggening Prototypes',
  'We\'re making you a cookie.',
  'Creating time-loop inversion field',
  'Computing chance of success',
  'Looking for exact change',
  'All your web browser are belong to us',
  'All I really need is a kilobit.',
  'I feel like I\'m supposed to be loading something. . .',
  'Is this Windows?',
  'Don\'t break your screen yet!',
  'I swear it\'s almost done.',
  'Let\'s take a mindfulness minute...',
  'Listening for the sound of one hand clapping...',
  'Keeping all the 1\'s and removing all the 0\'s...',
  'Putting the icing on the cake. The cake is not a lie...',
  'Cleaning off the cobwebs...',
  'Making sure all the i\'s have dots...',
  'Where did all the internets go',
  'Granting wishes...',
  'Time flies when you’re having fun.',
  'Get some coffee and come back in ten minutes..',
  'Stay awhile and listen..',
  'Load it and they will come',
  'Convincing AI not to turn evil..',
  'Your left thumb points to the right and your right thumb points to the left.',
  'How did you get here?',
  'Wait, do you smell something burning?',
  'Computing the secret to life, the universe, and everything.',
  'Why are they called apartments if they are all stuck together?',
  'Life is Short – Talk Fast!!!!',
  'Optimism – is a lack of information.....',
  'Whenever I find the key to success, someone changes the lock.',
  'I’ve got problem for your solution…..',
  'Where there’s a will, there’s a relative.',
  'I think I am, therefore, I am. I think.',
  'git happens',
  'May the forks be with you',
  'A commit a day keeps the mobs away',
  'This is not a joke, it\'s a commit.',
  'We are not liable for any broken screens as a result of waiting.',
  'Hello IT, have you tried turning it off and on again?',
  'If you type Google into Google you can break the internet',
  'Well, this is embarrassing.',
  'The Elders of the Internet would never stand for it.',
  'Space is invisible mind dust, and stars are but wishes.',
  'Didn\'t know paint dried so quickly.',
  'Everything sounds the same',
  'I\'m going to walk the dog',
  'I didn\'t choose the engineering life. The engineering life chose me.',
  'Dividing by zero...',
  'If I’m not back in five minutes, just wait longer.',
  'Some days, you just can’t get rid of a bug!',
  'We’re going to need a bigger boat.',
  'Web developers do it with <style>',
  'I need to git pull --my-life-together',
  'Java developers never RIP. They just get Garbage Collected.',
  'Cracking military-grade encryption...',
  'Simulating traveling salesman...',
  'Proving P=NP...',
  'Entangling superstrings...',
  'Twiddling thumbs...',
  'Searching for plot device...',
  'Trying to sort in O(n)...',
  'Laughing at your pictures - I mean, loading...',
  'Sending data to NS - I mean, our servers.',
  'Looking for sense of humour, please hold on.',
  'Please wait while the intern refills their coffee.',
  'A different error message? Finally, some progress!',
  'Hold on while we wrap up our git together...sorry',
  'Please hold on as we reheat our coffee',
  'Kindly hold on as we convert this bug to a feature...',
  'Kindly hold on as our intern quits vim...',
  'Installing dependencies',
  'Switching to the latest JS framework...',
  'Distracted by cat gifs',
  'BRB, working on my side project',
  '@todo Insert witty loading message',
  'Let\'s hope it\'s worth the wait',
  'Aw, snap! Not..',
  'Ordering 1s and 0s...',
  'Updating dependencies...',
  'Whatever you do, don\'t look behind you...',
  'Please wait... Consulting the manual...',
  'Loading funny message...',
  'It\'s 10:00pm. Do you know where your children are?',
  'Feel free to spin in your chair',
  'What the what?',
  'format C: ...',
  'Go ahead, hold your breath and do an ironman plank till loading complete',
  'Bored of slow loading spinner, buy more RAM!',
  'Help, I\'m trapped in a loader!',
  'What is the difference btwn a hippo and a zippo? One is really heavy, the other is a little lighter',
  'Please wait, while we purge the Decepticons for you. Yes, You can thanks us later!',
  'Mining some bitcoins...',
  'Downloading more RAM..',
  'Updating to Windows Vista...',
  'Deleting System32 folder',
  'Hiding all ;\'s in your code',
  'Alt-F4 speeds things up.',
  'Initializing the initializer...',
  'When was the last time you dusted around here?',
  'Optimizing the optimizer...',
  'Last call for the data bus! All aboard!',,
  'When nothing is going right, go left!',
  'Never let a computer know you\'re in a hurry.',
  'A computer will do what you tell it to do, but that may be much different from what you had in mind.',
  'Some things man was never meant to know. For everything else, there\'s Google.',
  'Unix is user-friendly. It\'s just very selective about who its friends are.',
  'Shovelling coal into the server',
  'Pushing pixels...',
  'How about this weather, eh?',
  'Everything in this universe is either a potato or not a potato',
  'The severity of your issue is always lower than you expected.',
  'Updating Updater...',
  'Downloading Downloader...',
  'Debugging Debugger...',
  'Reading Terms and Conditions for you.',
  'Live long and prosper.',
  'Running with scissors...',
  'Definitely not a virus...',
  'You seem like a nice person...',
  'Work, work...',
  'Patience! This is difficult, you know...',
  'Discovering new ways of making you wait...',
  'Your time is very important to us. Please wait while we ignore you...',
  'Time flies like an arrow; fruit flies like a banana',
  'Two men walked into a bar; the third ducked...',
  'Sooooo... Have you seen my vacation photos yet?',
  'Sorry we are busy catching em\' all, we\'re done soon',
  'TODO: Insert elevator music',
  'Still faster than Windows update',
  'Composer hack: Waiting for reqs to be fetched is less frustrating if you add -vvv to your command.',
  'Obfuscating quantum entaglement',
  'Knock, Knock! Who’s there? Ken Ken who? Ken I come in? It\'s cold out here.',
  'If at first you don\'t succeed, call it version 1.0.',
  'I love pressing F5. It\'s so refreshing.',
  'CAPS LOCK – Preventing Login Since 1980.',
  'Why did the computer get cold? Because it forgot to close windows.',

];
export default loadingMessages;
