<div class="sticky">
  <div class="page__header header--admin">
    <div class="page__header-actions">
      <h2 class="page__header-title">Color Management System</h2>
    </div>
  </div>
  <div class="page__content">
    <ul class="color-management-system-list">
      <ng-container *ngIf="colorManagementSystems.length > 0 && !colorManagementSystemsLoading">
        <li *ngFor="let colorManagementSystem of colorManagementSystems">
          <div class="card color-management-system">
            <div class="color-management-system__details"
              [class.color-management-system__details--disabled]="!colorManagementSystem.isEnabled">
              <div class="color-management-system__icon">
                <i *ngIf="!colorManagementSystem.isEnabled" class="material-icons matrial-icons--disabled">&#xE612;</i>
                <i class="material-icons">&#xe243;</i>
              </div>
              <div class="color-management-system__name">{{colorManagementSystem.name}}</div>
              <section class="status-icons__container">
                <span title="mode" class="color-management-system-mode status-chip"
                  [ngClass]="testModeClass(colorManagementSystem)"
                  [class.color-management-system__test-mode--disabled]="!colorManagementSystem.isEnabled">
                  <ng-container *ngIf="colorManagementSystem.isInTestMode">TEST</ng-container>
                  <ng-container *ngIf="!colorManagementSystem.isInTestMode">PRODUCTION</ng-container>
                </span>
                <span *ngIf="colorManagementSystem.healthStatus?.isHealthy; else unhealthyStatus" title="Healthy"  class="material-icons status-chip status-chip--healthy">
                </span>
                <ng-template #unhealthyStatus>
                  <span [title]="'Unhealthy: '+ colorManagementSystem.healthStatus?.dependencies[0].message" class="material-icons status-chip status-chip--unhealthy">
                  </span>
                </ng-template>
              </section>
            </div>
            <div class="color-management-system__controls">
              <button class="button button--primary button--small"
                (click)="editColorManagementSystem(colorManagementSystem)" type="button">
                <i class="material-icons">&#xE3C9;</i>
                <span>Edit</span>
              </button>
            </div>
          </div>
        </li>
      </ng-container>
      <div *ngIf="colorManagementSystemsLoading" class="loading"></div>
      <li title="Add Color Management System">
        <div class="card color-management-system placeholder" (click)="createColorManagementSystem()">
          <div class="color-management-system__details">
            <div class="color-management-system__icon">
              <i class="material-icons">&#xE148;</i>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>