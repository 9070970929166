import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { debounce, distinctUntilChanged, map, retryWhen, switchMap, tap } from 'rxjs/operators';
import { KeyCode } from '@shared/keycode.enum';
import { PagedResult } from '@shared/paged-result';
import { enumToSelectorEntity } from '@shared/pkg-helper';
import { MaterialType, MaterialTypes } from '../material-type';
import { PaperType } from '../paper-type';
import { PaperTypeService } from '../paper-type.service';

@Component({
  selector: 'pkg-material-type-search',
  templateUrl: './material-type-search.component.html',
  styleUrls: ['./material-type-search.component.scss']
})
export class MaterialTypeSearchComponent implements OnInit {
  @Output() paperTypesEventEmitter: EventEmitter<PagedResult<PaperType>> = new EventEmitter();
  @Output() termSearchedEmitter: EventEmitter<string> = new EventEmitter();
  @Output() materialTypeSelectionEmitter: EventEmitter<MaterialTypes[]> = new EventEmitter();
  statusAllOn: boolean;
  showFilter: boolean;
  searchTerm: string;
  materialTypeSearchMap = { default: true, kraft: true};
  keyUp = new Subject<KeyboardEvent>();
  isLoading: boolean;
  results = 0;
  materialFilterValues: MaterialType[] = [];

  get isFiltered() {
    // if none of material type filters are selected, do not show 'filtered' badge
    if (this.materialFilterValues === null || this.materialFilterValues.length === 0) {
      return false;
    }

    return this.materialFilterValues.length !== this.materialFilterValues.filter(x => x.isActive).length;
  }

  private $search: Subscription;
  private $filter: Subscription;

  constructor(private readonly paperTypesService: PaperTypeService) {
    this.$search = this.keyUp.pipe(
      map(event => event),
      debounce((event) => {
        const value = (event.target as HTMLInputElement).value;
        if (event.keyCode === KeyCode.Enter || value === '') {
          return timer(0);
        } else {
          return timer(500);
        }
      }),
      distinctUntilChanged(),
      switchMap(term => {
        this.isLoading = true;
        const searchTerm = (event.target as HTMLInputElement).value;
        const filter = this.generateFilter();
        this.termSearchedEmitter.emit(searchTerm);
        return this.search(searchTerm, filter);
      }),
      retryWhen((error) => error.pipe(
          tap(() => {
            this.results = 0;
            this.isLoading = false;
            this.paperTypesEventEmitter.emit(null);
          }))))
      .subscribe((result: PagedResult<PaperType>) => {
        this.isLoading = false;
        this.results = !!result ? result.totalCount : 0;
        this.paperTypesEventEmitter.emit(result);
      });
  }

  search(searchTerm: string, aggregatedFilter: number): Observable<PagedResult<PaperType>> {

    return this.paperTypesService.getPaperTypes(1, searchTerm, aggregatedFilter);
  }

  generateFilter(): number {
    const materialTypeSelection = this.materialFilterValues.filter(x => x.isActive).map(x => x.value);
    // Emit the material selection to parent component so that same filter criteria can be used during pagination.
    this.materialTypeSelectionEmitter.emit(materialTypeSelection);
    return materialTypeSelection.reduce((total, current) => total + current, 0);
  }

  ngOnInit(): void {
    this.statusAllOn = true;
    this.showFilter = false;
    this.searchTerm = '';
    const selectorEntities = enumToSelectorEntity(MaterialTypes, true);
    // By default select all the filters on page load
    for (const material of selectorEntities) {
      this.materialFilterValues.push({
        value: material.key,
        name: material.label,
        isActive: material.selected
      });
    }
    // On page load/init, load all Paper types
    this.searchWithFilter();
  }

  searchWithFilter(): void {
    this.isLoading = true;
    const filter = this.generateFilter();

    if (this.$filter != null) {
      this.$filter.unsubscribe();
    }

    this.$filter = this.search(this.searchTerm, filter).pipe(debounce(() => timer(500)))
      .subscribe((pagedResult: PagedResult<PaperType>) => {
        this.isLoading = false;
        this.results = !!pagedResult ? pagedResult.totalCount : 0;
        this.paperTypesEventEmitter.emit(pagedResult);
      },
        (err) => {
          this.results = 0;
          this.isLoading = false;
          this.paperTypesEventEmitter.emit();
          this.termSearchedEmitter.emit('');
        });
  }

  closeFilter(): void {
    this.showFilter = false;
  }

  toggleFilter(): void {
    this.showFilter = !this.showFilter;
  }

  toggleStatus(): void {
    this.statusAllOn = !this.statusAllOn;
    // If the Toggle All is set to on, set all the filters to On otherwise to Off
    if (this.statusAllOn) {
      this.setFiltersToTrue();
    } else {
      this.setFiltersToFalse();
    }
    this.searchWithFilter();
  }

  toggleMaterialTypeSelection(materialType: string): void {
    // On change of each material type selection in filter, toggle its active state.
    this.materialFilterValues = this.materialFilterValues.map((x) => {
      if (x.name.toLowerCase() === materialType.toLowerCase()) {
        x.isActive = !x.isActive;
      }
      return x;
    });
    this.statusAllOn = this.materialFilterValues.filter(x => !x.isActive).length === 0;
    this.searchWithFilter();
  }

  clear(): void {
    this.searchTerm = '';
    this.termSearchedEmitter.emit(null);
    this.paperTypesEventEmitter.emit(null);
    this.setFiltersToTrue();
    this.close();
    this.searchWithFilter();
  }

  close(): void {
    this.showFilter = false;
  }

  setFiltersToFalse(): void {
    this.statusAllOn = false;
    this.materialTypeSearchMap = {
      default: false,
      kraft: false
    };
    // Unselect all filters
    this.materialFilterValues = this.materialFilterValues.map(x => {
      x.isActive = false; return x;
    });
  }

  setFiltersToTrue(): void {
    this.statusAllOn = true;
    this.materialTypeSearchMap = {
      default: true,
      kraft: true
    };
    // Select all filters
    this.materialFilterValues = this.materialFilterValues.map(x => {
      x.isActive = true; return x;
    });
  }
}
