import { MlpaLane } from '../mlpa-lane';
import { LaneProperties } from './lane-properties.interface';
import { DesignType } from '../design-type.enum';
import { MlpaLaneApi } from '@shared/mlpa-lane-api';
import { deepCopyProperties } from '@shared/pkg-helper';

export class SmartLine extends MlpaLane {
  // This field should be pulled from database in future.
  private readonly _designWidthInInches = 0.59055118110236220; // 15mm
  constructor(laneProperties: LaneProperties, mlpaLaneApi?: MlpaLaneApi) {
    super(mlpaLaneApi);
    // loop through properties to perform a deep copy of properties
    deepCopyProperties(this, laneProperties.initialProperties);

    // if the isClone property is null/undefined or false
    if (laneProperties.initialProperties.isClone == null || laneProperties.initialProperties.isClone === false) {
      laneProperties.initialProperties.designWidthInInches = this._designWidthInInches;
      this.setInitialLaneProperties(laneProperties.initialProperties, DesignType.SMARTLINE);
      this.isTransposed = false;
    }
    this.originalNumberOfPages = this.designNumberOfPages;
    this.calculateNumberOfPages(laneProperties);
    this.colorCorrectionInfo.isColorCorrectionEnabled = false;
  }

  calculateNumberUps(laneProperties: LaneProperties): void {
    if (laneProperties?.nearestLane?.designNumberUp > 0) {
      this.designNumberOfPages = this.designNumberOfPages / (laneProperties?.nearestLane?.designNumberUp * laneProperties.nearestLane?.numberOfRibbons);
    }
  }

  get totalWidthInInches(): number {
    return this.calculatedTotalWidthInInches;
  }
}
