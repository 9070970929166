import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges, AfterViewInit, AfterContentChecked } from '@angular/core';
import { MlpaJobView } from '../../../../app/pages/home/view-mlpa-jobs/mlpa-job-view';
import { Role } from '../../../../app/pages/admin/shared/role';
import { MlpaJobActionOptions } from './mlpa-job-action-options.interface';
import { MlpaLaneType } from '@app/mlpa/mlpa-lane/mlpa-lane-type.enum';

@Component({
  selector: 'pkg-mlpa-job-action-group',
  templateUrl: './mlpa-job-action-group.component.html',
  styleUrls: ['./mlpa-job-action-group.component.scss'],
})
export class MlpaJobActionGroupComponent implements OnInit, OnChanges {
  @Input() mlpaJobView: MlpaJobView;
  @Input() mlpaJobActionOptions: MlpaJobActionOptions;

  @Output() preClone = new EventEmitter();
  @Output() preSubmit = new EventEmitter();
  @Output() preDelete = new EventEmitter();
  @Output() preCancelEdit = new EventEmitter();
  @Output() setPreview = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() cancelNewJob = new EventEmitter();

  isCharacterizationValueExists: boolean;
  isValidating: boolean;
  enableLock = true;
  role = Role;
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.mlpaJobView?.currentValue) {
      this.mlpaJobView = changes?.mlpaJobView?.currentValue;
    }
    if (changes?.mlpaJobActionOptions?.currentValue) {
      this.mlpaJobActionOptions = changes.mlpaJobActionOptions.currentValue;
    }

  }

  ngOnInit(): void {
  }

  updateValidatingStatus(status: boolean): void {
    this.isValidating = status;
  }

  UpdateCharacterizationValueStatus(status: boolean): void {
    this.isCharacterizationValueExists = status;
  }

  isValidForSave(): boolean {
    // loop through the lane to see if its valid.
    const lanes = new Set(this.mlpaJobView.mlpaLanes.map((lane) => {
      if (!this.enableLock || lane.quantity < 0 ||
        lane.numberOfRibbons < 0 ||
        lane.totalLengthInInches < 0 ||
        (lane.colorProfileName != null && lane.colorProfileName.length < 0) ||
        lane.numberOfRibbons < 0) {
        return false;
      } else {
        return true;
      }
    }));

    // For 110" paper width, margin should be either 0" or >= 0.40". In general, margin should be between 0" and paper width.
    if (this.mlpaJobActionOptions.jobSettings.isMarginInvalid()
      || !this.mlpaJobActionOptions.jobSettings.isMarginWithinLimits()) {
      return false;
    }

    // if any of these are false it is not valid.
    return this.mlpaJobView.name.length > 0 &&
      this.mlpaJobView.name.indexOf(' ') < 0 &&
      this.mlpaJobView.programNumber.length > 0 &&
      this.mlpaJobView.setupNumber.length > 0 &&
      this.mlpaJobView.assetName.length > 0 &&
      this.mlpaJobActionOptions.assetDetails?.textValue?.length > 0 &&
      this.mlpaJobActionOptions.paperTypeDetails?.textValue?.length > 0 &&
      this.mlpaJobActionOptions.paperTypeDetails?.textValue?.length > 0 &&
      this.mlpaJobView.marginWidthInInches >= 0 &&
      this.mlpaJobView.lineBreakLengthInInches >= 0 &&
      this.mlpaJobActionOptions.hasValidColorProfile &&
      this.mlpaJobActionOptions.jobSettings.lineBreakLengthInFeet <= 200 &&
      (lanes.size === 1 && lanes.has(true));
  }

  isValidForSaveWithCharValues(): boolean {

    // loop through the lane to see if its valid.
    const lanes = new Set(this.mlpaJobView.mlpaLanes.map((lane) => {
      if (!this.enableLock || lane.quantity < 0 ||
        lane.numberOfRibbons < 0 ||
        lane.totalLengthInInches < 0 ||
        (lane.colorProfileName != null && lane.colorProfileName.length < 0) ||
        lane.numberOfRibbons < 0 ||
        (lane.colorCorrectionInfo.isColorCorrectionEnabled &&
        (lane.colorCorrectionInfo.inkColors === '' ||
        lane.colorCorrectionInfo.totalAreaCoverage === '' ||
        lane.colorCorrectionInfo.inkWeight === '' ||
        lane.colorCorrectionInfo.substrateWeight === '' ||
        lane.colorCorrectionInfo.speed === '' ||
        lane.colorCorrectionInfo.pressType === '' ||
        lane.colorCorrectionInfo.printMode === '' ||
        lane.colorCorrectionInfo.overPrintVarnish === ''))) {
        return false;
      } else {
        return true;
      }
    }));

    // For 110" paper width, margin should be either 0" or >= 0.40". In general, margin should be between 0" and paper width.
    if (this.mlpaJobActionOptions.jobSettings.isMarginInvalid()
      || !this.mlpaJobActionOptions.jobSettings.isMarginWithinLimits()) {
      return false;
    }

    // if any of these are false it is not valid.
    return this.mlpaJobView.name.length > 0 &&
      this.mlpaJobView.programNumber.length > 0 &&
      this.mlpaJobView.setupNumber.length > 0 &&
      this.mlpaJobView.assetName.length > 0 &&
      this.mlpaJobActionOptions.assetDetails?.textValue?.length > 0 &&
      this.mlpaJobActionOptions.paperTypeDetails?.textValue?.length > 0 &&
      this.mlpaJobActionOptions.paperTypeDetails?.textValue?.length > 0 &&
      this.mlpaJobActionOptions.inkColorDetails?.textValue?.length > 0 &&
      this.mlpaJobActionOptions.printModeDetails?.textValue?.length > 0 &&
      this.mlpaJobActionOptions.overPrintVarnishDetails?.textValue?.length > 0 &&
      this.mlpaJobActionOptions.substrateWeightDetails?.textValue?.length > 0 &&
      this.mlpaJobActionOptions.speedDetails?.textValue?.length > 0 &&
      this.mlpaJobView.marginWidthInInches >= 0 &&
      this.mlpaJobView.lineBreakLengthInInches >= 0 &&
      this.mlpaJobActionOptions.hasValidColorProfile &&
      this.mlpaJobActionOptions.jobSettings.lineBreakLengthInFeet <= 200 &&
      (lanes.size === 1 && lanes.has(true));
  }

  isValidForPreSubmitWithCharValues(): boolean {
    // loop through the lane to see if its valid.
    const lanes = new Set(this.mlpaJobView.mlpaLanes.map((lane) => {
      if (lane.quantity < 0 ||
        lane.numberOfRibbons < 0 ||
        lane.totalLengthInInches < 0 ||
        (lane.colorProfileName != null && lane.colorProfileName.length < 0) ||
        lane.numberOfRibbons < 0 ||
        (lane.colorCorrectionInfo.isColorCorrectionEnabled && (lane.colorCorrectionInfo.inkColors === '' ||
        lane.colorCorrectionInfo.inkWeight === '' ||
        lane.colorCorrectionInfo.totalAreaCoverage === '' ||
        lane.colorCorrectionInfo.substrateWeight === '' ||
        lane.colorCorrectionInfo.speed === '' ||
        lane.colorCorrectionInfo.pressType === '' ||
        lane.colorCorrectionInfo.printMode === '')) ||
        (lane.colorCorrectionInfo.isColorCorrectionEnabled && !this.isCharacterizationValueExists)) {
        return false;
      } else {
        return true;
      }
    }));

    // For 110" paper width, margin should be either 0" or >= 0.40". In general, margin should be between 0" and paper width.
    if (this.mlpaJobActionOptions.jobSettings.isMarginInvalid()
      || !this.mlpaJobActionOptions.jobSettings.isMarginWithinLimits()) {
      return false;
    }

    // if any of these are false it is not valid.
    return this.mlpaJobView.name.length > 0 &&
      this.mlpaJobView.programNumber.length > 0 &&
      this.mlpaJobView.setupNumber.length > 0 &&
      this.mlpaJobView.assetName.length > 0 &&
      this.mlpaJobActionOptions.inkColorDetails?.textValue?.length > 0 &&
      this.mlpaJobActionOptions.printModeDetails?.textValue?.length > 0 &&
      this.mlpaJobActionOptions.overPrintVarnishDetails?.textValue?.length > 0 &&
      this.mlpaJobActionOptions.substrateWeightDetails?.textValue?.length > 0 &&
      this.mlpaJobActionOptions.speedDetails?.textValue?.length > 0 &&
      this.mlpaJobActionOptions.assetDetails?.textValue?.length > 0 &&
      this.mlpaJobActionOptions.paperTypeDetails?.textValue?.length > 0 &&
      this.mlpaJobActionOptions.paperTypeDetails?.textValue?.length > 0 &&
      this.mlpaJobView.marginWidthInInches >= 0 &&
      this.mlpaJobView.lineBreakLengthInInches >= 0 &&
      this.mlpaJobActionOptions.hasValidColorProfile &&
      this.mlpaJobActionOptions.jobSettings.lineBreakLengthInFeet <= 200 &&
      (lanes.size === 1 && lanes.has(true));
  }
}
