import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { trigger, style, state, transition, animate } from '@angular/animations';
import { User } from '../shared/user';
import { Role } from '../shared/role';
import { UserService } from '../shared/user.service';
import { AssetService } from '../shared/asset.service';
import { AlertsService } from '@shared/alerts/alerts.service';
import { UserCreateComponent } from '../user-create/user-create.component';
import { UserEditComponent } from '../user-edit/user-edit.component';
import { ActivatedRoute } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { AlertType } from 'src/app/shared/alerts/alert-type.enum';
import { Asset } from 'src/app/shared/asset';
import { PkgThemeService } from '@shared/theme/pkg-theme.service';

@Component({
  selector: 'pkg-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  animations: [
    trigger('accordion', [
      state('close', style({
        opacity: 0, height: '0px', display: 'none'
      })),
      state('open', style({
        height: '*', display: 'flex'
      })),
      transition('close <=> open', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
    ])
  ]
})
export class UserListComponent implements OnInit {
  @ViewChild(UserCreateComponent, { static: true }) userCreateComponent: UserCreateComponent;
  @ViewChild(UserEditComponent, { static: true }) userEditComponent: UserEditComponent;
  users: User[];
  assets = [];
  Role = Role;
  keyword: string;
  showAssets = {};

  get filteredUsers() {
    if (!this.keyword || this.keyword.trim().length === 0) {
      return this.users;
    }

    return this.users.filter(u => u.displayName.toLowerCase().includes(this.keyword.toLowerCase())
      || u.emailAddress.toLowerCase().includes(this.keyword.toLowerCase())
      || u.ntLogin.toLowerCase().includes(this.keyword.toLowerCase())
      || this.Role[u.role].toLowerCase().includes(this.keyword.toLowerCase()));
  }

  get getLogoStyle() {
    const backgroundStyles = {
      filter: this._pkgThemeService.getTheme() === 'light-theme' ?
      'invert(17%) sepia(40%) saturate(2919%) hue-rotate(173deg) brightness(90%) contrast(103%)' :
      'invert(100%) sepia(47%) saturate(21%) hue-rotate(102deg) brightness(105%)'
    };
    return backgroundStyles;
  }

  constructor(
    private readonly route: ActivatedRoute,
    private readonly userService: UserService,
    private readonly assetService: AssetService,
    private readonly alertService: AlertsService,
    private readonly _pkgThemeService?: PkgThemeService) { }

  createUser(): void {
    this.userCreateComponent.open();
  }
  editUser(user: User): void {
    this.userEditComponent.open(user);
  }
  addUserToList(user: User): void {
    this.users.push(user);
  }
  removeUserFromList(userId: string): void {
    const index = this.users.findIndex(u => u.id === userId);

    if (index >= 0) {
      this.users.splice(index, 1);
    }
  }
  roleClass(role: Role): string {
    switch (role) {
      case Role.Viewer:
        return 'role--viewer';
      case Role.Operator:
        return 'role--operator';
      case Role.Scheduler:
        return 'role--scheduler';
      case Role.Admin:
        return 'role--admin';
      case Role.Support:
        return 'role--support';
    }
    return '';
  }

  getUserAssetNames(user: User, assets: Asset[]) {
    return assets.filter(asset => user.assetIds
      .some(id => id === asset.id))
      .map(asset => asset.name);
  }

  ngOnInit() {
    const assets = this.assetService.getAll().pipe(catchError((error) => {
      this.alertService.add('Failed to load the Assets', 'Please try again', AlertType.Error);
      return of(undefined);
    }));
    const users = this.userService.getAll().pipe(catchError((error) => {
      this.alertService.add('Failed to load the Users', 'Please try again', AlertType.Error);
      return of(undefined);
    }));
    this.route.paramMap.subscribe(params => {
      const editUserId = params.get('id');
      // Get a single Observable value containing resolved values of assets and users Observables.
      forkJoin([assets, users]).subscribe((results) => {
        this.assets = results[0];
        // If aasets are not empty, Get the asset names based on the associated assetIds of all the users.
        if (!this.assets.length) {
          this.users = results[1];
        } else {
          this.users = results[1].map(user => {
            user.assetNames = this.getUserAssetNames(user, this.assets);
            return user;
          });
        }
        // If there is a user ID, look for the user in the list and open the edit modal
        if (editUserId) {
          const editUser = this.users.find(u => u.id === editUserId);
          if (editUser) {
            this.userEditComponent.open(editUser);
          }
        }
      });
    });
  }
}
