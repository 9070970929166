import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { UserService } from './shared/user.service';
import { UserListComponent } from './user-list/user-list.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { UserCreateComponent } from './user-create/user-create.component';
import { SharedModule } from '@shared/shared.module';
import { AssetListComponent } from './asset-list/asset-list.component';
import { AssetService } from './shared/asset.service';
import { AssetEditComponent } from './asset-edit/asset-edit.component';
import { AdminGuard } from './admin.guard';
import { VisionService } from './shared/vision.service';
import { MaterialTypeSearchComponent } from './shared/material-type-search/material-type-search.component';
import { MaterialManagementComponent } from './material-management/material-management.component';
import { PaperTypeService } from './shared/paper-type.service';
import { DataLoggerEditComponent } from './data-logger-edit/data-logger-edit.component';
import { UserDetailsComponent } from './shared/user-details/user-details.component';
import { AssetColorPickerComponent } from './shared/asset-color-picker/asset-color-picker.component';
import { ColorManagementSystemEditComponent } from './color-management-system/color-management-system-edit/color-management-system-edit/color-management-system-edit.component';
import { ColorManagementSystemListComponent } from './color-management-system/color-management-system-list/color-management-system-list/color-management-system-list.component';
import { PrintProcessorEditComponent } from './print-processor/print-processor-edit/print-processor-edit.component';
import { PrintProcessorListComponent } from './print-processor/print-processor-list/print-processor-list.component';
import { TempPrintProcessorGuard } from './temp-print-processor.guard';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AdminRoutingModule,
    SharedModule
  ],
  declarations: [
    AdminComponent,
    UserListComponent,
    UserEditComponent,
    UserCreateComponent,
    AssetListComponent,
    AssetEditComponent,
    DataLoggerEditComponent,
    MaterialManagementComponent,
    MaterialTypeSearchComponent,
    UserDetailsComponent,
    AssetColorPickerComponent,
    ColorManagementSystemEditComponent,
    ColorManagementSystemListComponent,
    PrintProcessorEditComponent,
    PrintProcessorListComponent
  ],
  exports: [
    AdminComponent,
    UserListComponent,
    UserEditComponent,
    UserCreateComponent,
    AssetListComponent,
    AssetEditComponent,
    DataLoggerEditComponent,
    MaterialManagementComponent,
    MaterialTypeSearchComponent,
    UserDetailsComponent,
    AssetColorPickerComponent
  ],
  providers: [
    UserService,
    AssetService,
    VisionService,
    AdminGuard,
    TempPrintProcessorGuard,
    PaperTypeService
  ]
})
export class AdminModule { }
