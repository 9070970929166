import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MlpaLaneType } from '../../mlpa-lane/mlpa-lane-type.enum';
import { MlpaLaneView } from '../../../pages/home/view-mlpa-jobs/mlpa-lane-view';
import { environment } from '../../../../environments/environment';
import { SearchAutoComplete } from '../../../search-auto-complete/search-auto-complete';
import { MlpaJobSettings } from '../../mlpa-job-settings/mlpa-job-settings';
import { SelectItem } from '../../../search-auto-complete/select-item';
import { MlpaStateService } from '../../../pages/mlpa/shared/mlpa-state.service';
import { MoveDirection } from '../../mlpa-lane/move-direction.enum';
import * as _ from 'lodash';
import { findLongestShortestLaneLength, getTotalLengthOfLanes, getMaxLaneLength } from '../../mlpa-helper';
import { MlpaJob } from '../../mlpa-job/mlpa-job';
import { MlpaJobView } from '../../../pages/home/view-mlpa-jobs/mlpa-job-view';
import { Role } from '../../../pages/admin/shared/role';
import { Router } from '@angular/router';
import { AlertsService } from '@shared/alerts/alerts.service';
import { AlertType } from '@shared/alerts/alert-type.enum';
import { ColorManagementSystemSettings } from '@app/mlpa/color-management-system-settings/color-management-system-settings';
import { CharacterizationType, MlpaJobCharacterizationValue, MlpaCharacterizationValue, DesignCharacterizationResponse } from '@shared/mlpa-job-options/mlpa-job-option-characterization-value';
import { MlpaJobOptionsService } from '@shared/mlpa-job-options/mlpa-job-options.service';
import { AssetType } from '@shared/asset-type.enum';
import { getQualityModes } from '@shared/quality-mode-config';
import { MlpaJobOptionColorProfile } from '@shared/mlpa-job-options/mlpa-job-option-color-profile';

@Component({
  selector: 'pkg-mlpa-lane-info',
  templateUrl: './mlpa-lane-info.component.html',
  styleUrls: ['./mlpa-lane-info.component.scss']
})
export class MlpaLaneInfoComponent implements OnInit, OnChanges {
  @Input() mlpaLanes: MlpaLaneView[];
  @Input() mlpaJobView: MlpaJobView;
  @Input() isSpecial = false;
  @Input() editMode: boolean;
  @Input() jobSettings: MlpaJobSettings;
  @Input() colorManagementSystemSettings: ColorManagementSystemSettings;
  @Input() assetType: AssetType;
  @Input() printMode: string;
  @Output() deleteEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() setPreviewEmitter: EventEmitter<MlpaLaneView> = new EventEmitter<MlpaLaneView>();
  @Output() selectWebWeaveEmitter: EventEmitter<MlpaLaneType> = new EventEmitter<MlpaLaneType>();
  @Output() enableColorCorrectionEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectLockEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  colorProfileMap: Map<number, SearchAutoComplete> = new Map<number, SearchAutoComplete>();
  inkWeightMap: Map<number, SearchAutoComplete> = new Map<number, SearchAutoComplete>();
  totalAreaCoverageMap: Map<number, SearchAutoComplete> = new Map<number, SearchAutoComplete>();

  editTotalLengthInFeet: number;
  editRotationInDegrees: number;
  laneToggle = {};
  role = Role;
  isPrintPage: boolean;
  mlpaLaneType = MlpaLaneType;
  isMlpaPage = this._router.url === '/mlpa' || this._router.url.startsWith('/mlpa');
  thumbnailType = MlpaLaneType;
  loadingCharValues: boolean;
  assetTypes = AssetType;
  colorProfile: MlpaJobOptionColorProfile;


  get isStressJob(): boolean {
    return this.mlpaJobView?.mlpaLanes[1]?.laneType === MlpaLaneType.Stress;
  }

  constructor(private readonly _mlpaStateService: MlpaStateService,
    private readonly _router: Router,
    private readonly _alertsService: AlertsService,
    private readonly _mlpaJobOptionsService: MlpaJobOptionsService,
    private readonly _alerts: AlertsService
  ) {
  }

  ngOnInit(): void {
    this.isPrintPage = this._router.url.includes('print');

    this._mlpaStateService.mlpaJobState$.subscribe((results) => {
      this.jobSettings = results.jobSettings;
      this.colorManagementSystemSettings = results.colorManagementSystemSettings;
    });

    setTimeout(() => {
      this._mlpaStateService.mlpaLaneState$.subscribe((results) => {
        this.jobSettings = results.jobSettings;
        this.colorProfileMap.clear();
        if (!!this.jobSettings) {
          this._mapColorProfileToDropDownSelectItem();
        }

        this.mlpaLanes.forEach((lane, index) => {
          if (this.mlpaLanes[index].editDisplayTotalWidthInInches == null) {
            this.mlpaLanes[index].editDisplayTotalWidthInInches = this.mlpaLanes[index].displayTotalWidthInInches;
          }
        });
      });
    }, 10);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mlpaLanes) {
      this.colorProfileMap.clear();
      this.inkWeightMap.clear();
      this.totalAreaCoverageMap.clear();
      this.initializeLaneState();

      if (this.mlpaLanes[1].laneType === MlpaLaneType.Stress && !this.isMlpaPage) {
        this.toggleLanes(true);
      }
    }
  }

  initializeLaneState(): void {
    this.laneToggle = {};
    this.mlpaLanes.forEach((lane, index) => {
      if (this.laneToggle[index] == null) {
        this.laneToggle[index] = false;
      }
    });
  }

  getRemainingWebWeave(totalLengthInFeet: number): number {
    const lineBreakInFeet = this.mlpaJobView.lineBreakLengthInInches / 12;
    const maxLengthOfLanes = (getMaxLaneLength(this.mlpaLanes) / 12) + lineBreakInFeet;
    return maxLengthOfLanes - totalLengthInFeet;
  }

  deleteLane(mlpaLane: MlpaLaneView): void {
    this.deleteEmitter.emit(mlpaLane.laneNumber);
  }
  setPreview(mlpaLane: MlpaLaneView): void {
    this.mlpaLanes.forEach((lane, index) => {
      this.mlpaLanes[index].isCurrentPreviewButton = false;
    });
    mlpaLane.isCurrentPreviewButton = true;
    this._getCorrectPreview(mlpaLane);
    this.setPreviewEmitter.emit(mlpaLane);
  }
  getRotateClass(info): string {
    return `mlpa-lane-rotate__icon--${info.rotationInDegrees}`;
  }
  transposeWidth(mlpaLane: MlpaLaneView) {
    const index = _.findIndex(this.mlpaLanes, mlpaLane);
    this.mlpaLanes[index].isTransposed = !mlpaLane.isTransposed;

    // const mlpaJob: MlpaJob = this._mlpaStateService.getValueFromJob('mlpaJob');
    const mlpaJobView: MlpaJobView = this._mlpaStateService.getValueFromJob('mlpaJobView');
    const copyMlpaJob: MlpaJob = new MlpaJob(mlpaJobView);
    copyMlpaJob.copyLanesFromView(mlpaJobView);

    // copy all the design lengths and widths over to the copyJob
    copyMlpaJob._mlpaLanes.forEach((lane, location) => {
      if (lane.laneType === MlpaLaneType.Graphic) {
        copyMlpaJob._mlpaLanes[location].designWidthInInches = this.mlpaLanes[location].designWidthInInches;
        copyMlpaJob._mlpaLanes[location].designLengthInInches = this.mlpaLanes[location].designLengthInInches;

        // tranpose on the lane we are working on
        copyMlpaJob._mlpaLanes[location].isTransposed = this.mlpaLanes[location].isTransposed;
        copyMlpaJob._mlpaLanes[location].setTransposedValues();
      }
    });

    this._mlpaStateService.setLaneStateByValue('mlpaLanes', copyMlpaJob.mlpaLanes);
    this._mlpaStateService.setLaneStateByValue('totalWidthInInches', getTotalLengthOfLanes(copyMlpaJob._mlpaLanes));
  }

  selectColorProfile(selectedItem: SelectItem, lane: MlpaLaneView): void {
    lane.colorProfileId = selectedItem.source.id;
    lane.colorProfileName = selectedItem.source.name;
    this._mlpaStateService.setMlpaLaneState({ hasValidColorProfiles: true, jobSettings: this.jobSettings });
      if (lane.laneType === MlpaLaneType.Graphic && this.assetType === AssetType.T1195) {
        let alertMessage = '';
        this.colorProfile = this.mlpaJobView.jobSettings.colorProfiles.find(colorProfile => colorProfile.name === lane.colorProfileName);
        const qualityMode = this.colorProfile.qualityModes.some(qualitymode => qualitymode === this.getQualityModeFromPrintMode(this.printMode));
        if (!qualityMode) {
          alertMessage += `Lane# <b>${lane.laneNumber}</b></br>
          Color Profile <b>${lane.colorProfileName}</b> supports:&nbsp;`;
          this.colorProfile.qualityModes.forEach(qualitymode => {
          alertMessage += `&#8226;&nbsp;<b>${this.getPrintModeFromQualityMode(qualitymode)}</b>&nbsp;`;
          });
          alertMessage += `</br>`;
          this._alerts.add(`Please select valid Color Profile / Print Mode`, `${alertMessage}`, AlertType.Error);
          this.selectLockEmitter.emit(false);
        } else {
          this.selectLockEmitter.emit(true);
          }
      }
  }

  getPrintModeFromQualityMode(qalityMode: string): string {
    const qualityModeAttributes = getQualityModes().find(qualityMode=> qualityMode.value === qalityMode);
    return qualityModeAttributes?.name;
  }

  getQualityModeFromPrintMode(printMode: string): string {
    const qualityModeAttributes = getQualityModes().find(qualityMode=> qualityMode.name === printMode);
    return qualityModeAttributes?.value;
  }

  selectInkWeight(selectedItem: SelectItem, lane: MlpaLaneView): void {
    const index = _.findIndex(this.mlpaLanes, lane);
    this.mlpaLanes[index].colorCorrectionInfo.inkWeight = selectedItem.value;
    if (lane.colorCorrectionInfo.isColorCorrectionEnabled) {
      this.setColorProfileFromCharacterizationValue(this.mlpaLanes[index]);
    }
  }

  selectTotalAreaCoverage(selectedItem: SelectItem, lane: MlpaLaneView): void {
    const index = _.findIndex(this.mlpaLanes, lane);
    this.mlpaLanes[index].colorCorrectionInfo.totalAreaCoverage = selectedItem.value;
  }

  selectedItemExistsInOptions(selectedItem: string, source: SearchAutoComplete): boolean {
    const value = typeof selectedItem === 'string' ? selectedItem : parseFloat(selectedItem).toFixed(2);
    if (!!selectedItem && !!source) {
      return source.results.filter(result => (result.value === value)).length > 0;
    }
    this._mlpaStateService.setMlpaLaneState({ hasValidColorProfiles: false, jobSettings: this.jobSettings });
    return false;
  }

  toggleLanes(value: boolean): void {
    for (const key of Object.keys(this.laneToggle)) {
      this.laneToggle[key] = value;
    }
  }

  setColorProfiles(lane: MlpaLaneView): void {
    this._mlpaStateService.setMlpaLaneState({ hasValidColorProfiles: true, jobSettings: this._mlpaStateService.getValueFromJob('jobSettings') });
    let colorProfileDetails: SearchAutoComplete;

    if (lane.colorCorrectionInfo.isColorCorrectionEnabled) {
      const colorProfiles = this.jobSettings.searchColorProfile(lane.colorCorrectionInfo.inkWeight);
      if (!!colorProfiles && colorProfiles.length > 0) {
        colorProfileDetails = new SearchAutoComplete(
          'colorProfile',
          '',
          'Select a color profile...',
          { data: colorProfiles.map(result => ({ id: result.id, value: result.name, source: result } as SelectItem)) }
        );
      } else {
        colorProfileDetails = new SearchAutoComplete(
          'colorProfile',
          '',
          'Select a color profile...',
          { data: this._mapColorProfileToDropDownSelectItem() }
        );
      }
    } else {
      colorProfileDetails = new SearchAutoComplete(
        'colorProfile',
        '',
        'Select a color profile...',
        { data: this._mapColorProfileToDropDownSelectItem() }
      );
    }

    colorProfileDetails.textValue = lane.colorProfileName != null ? lane.colorProfileName : '';

    // see if the color profile is a valid one.
    const foundColorProfile = this.jobSettings.colorProfiles.find((cp) => cp.name === lane.colorProfileName) != null;
    this._mlpaStateService.setMlpaLaneState({ hasValidColorProfiles: foundColorProfile, jobSettings: this._mlpaStateService.getValueFromJob('jobSettings') });

    // set the lane map
    this.colorProfileMap.set(lane.laneNumber, colorProfileDetails);
  }

  setInkWeightDetails(lane: MlpaLaneView): void {
    const inkWeightDetails = new SearchAutoComplete(
      'inkWeight',
      '',
      'Enter an ink weight...',
      { data: this._mapInkWeightsToDropDownSelectItem() }
    );

    inkWeightDetails.textValue = lane.colorCorrectionInfo.inkWeight != null ? lane.colorCorrectionInfo.inkWeight : '';

    this.inkWeightMap.set(lane.laneNumber, inkWeightDetails);
  }

  setTotalAreaCoverageDetails(lane: MlpaLaneView): void {
    const totalAreaCoverageDetails = new SearchAutoComplete(
      'totalAreaCoverage',
      '',
      'Enter a total coverage area value...',
      { data: this._mapTotalAreaCoverageToDropDownSelectItem() }
    );

    totalAreaCoverageDetails.textValue = lane.colorCorrectionInfo.totalAreaCoverage != null ? lane.colorCorrectionInfo.totalAreaCoverage : '';

    this.totalAreaCoverageMap.set(lane.laneNumber, totalAreaCoverageDetails);
  }

  setNumberUp(newQuantity: number, mlpaLane: MlpaLaneView): void {
    let dimension;

    if (mlpaLane.isTransposed) {
      dimension = mlpaLane.designWidthInInches;
    } else {
      dimension = mlpaLane.designLengthInInches;
    }

    const index = _.findIndex(this.mlpaLanes, mlpaLane);
    this.mlpaLanes[index].designNumberUp = newQuantity <= 1 ? 1 : newQuantity;
    this.editQuantity(mlpaLane.quantity, this.mlpaLanes[index]);
    this.editTotalLengthInFeet = ((mlpaLane.quantity / (mlpaLane.numberOfRibbons * mlpaLane.designNumberUp)) * dimension) / 12;

    this.editLength(this.editTotalLengthInFeet, this.mlpaLanes[index]);
  }

  editQuantity(newQuantity: number, mlpaLane: MlpaLaneView): void {
    let dimension;
    if (mlpaLane.isTransposed) {
      dimension = mlpaLane.designWidthInInches;
    } else {
      dimension = mlpaLane.designLengthInInches;
    }

    const index = _.findIndex(this.mlpaLanes, mlpaLane);
    const factor = mlpaLane.numberOfRibbons * mlpaLane.designNumberUp;
    // if the value submitted doesn't factor in evenly, then change it to the next legal value
    if (newQuantity % factor !== 0) {
      newQuantity = (Math.floor(newQuantity / factor) + 1) * mlpaLane.designNumberUp;
    }
    this.mlpaLanes[index].quantity = newQuantity;
    this.mlpaLanes[index].totalLengthInInches = ((newQuantity / (mlpaLane.numberOfRibbons * mlpaLane.designNumberUp)) * dimension);
  }

  optimizeUp(lane: MlpaLaneView): void {
    const values = findLongestShortestLaneLength(this.mlpaLanes);
    this.editLength(values.longestLaneInInches / 12, lane);
  }
  optimizeDown(lane: MlpaLaneView): void {
    const values = findLongestShortestLaneLength(this.mlpaLanes);
    this.editLength(values.shortestLaneInInches / 12, lane);
  }

  editLength(newLength: number, mlpaLane: MlpaLaneView): void {
    let dimension;
    const firstLane = 0;
    const lastLane = this.mlpaLanes.length - 1;

    if (mlpaLane.isTransposed) {
      dimension = mlpaLane.designWidthInInches;
    } else {
      dimension = mlpaLane.designLengthInInches;
    }
    // figure out how many are printed with one unit of width
    const howManyWeCanPrintInOneDesignWidth = mlpaLane.numberOfRibbons * mlpaLane.designNumberUp;

    // figure out how many can be printed *completely* (as opposed to partially) given the users' requested length
    const numberOfWholeDesigns = Math.floor((newLength * 12) / dimension);

    // Only update the numbers if the new calculated quantity has changed
    const newQuantity = numberOfWholeDesigns * howManyWeCanPrintInOneDesignWidth;

    const index = _.findIndex(this.mlpaLanes, mlpaLane);

    this.mlpaLanes[index].quantity = newQuantity;

    this.mlpaLanes[index].totalLengthInInches = ((newQuantity / (mlpaLane.numberOfRibbons * mlpaLane.designNumberUp)) * dimension);

    // set the nearest webweave or smartline.
    const middle = lastLane / 2;
    if (index <= middle) {
      this.mlpaLanes[firstLane].totalLengthInInches = this.editTotalLengthInFeet * 12;

      if (this.mlpaLanes[firstLane].isMultiPage) {
        this.mlpaLanes[firstLane].designNumberOfPages = this.mlpaLanes[firstLane].originalNumberOfPages / mlpaLane.designNumberUp;
      } else {
        this.mlpaLanes[firstLane].quantity = this.mlpaLanes[firstLane + 1].quantity / mlpaLane.designNumberUp;
      }

    }

    if (index > middle || this.mlpaLanes.length === 3) {
      this.mlpaLanes[lastLane].totalLengthInInches = this.editTotalLengthInFeet * 12;
      if (this.mlpaLanes[lastLane].isMultiPage) {
        this.mlpaLanes[lastLane].designNumberOfPages = this.mlpaLanes[lastLane].originalNumberOfPages / mlpaLane.designNumberUp;
      } else {
        this.mlpaLanes[lastLane].quantity = this.mlpaLanes[lastLane - 1].quantity / mlpaLane.designNumberUp;
      }
    }

    this.mlpaLanes[firstLane].totalLengthInInches = findLongestShortestLaneLength(this.mlpaLanes).longestLaneInInches;
    this.mlpaLanes[lastLane].totalLengthInInches = findLongestShortestLaneLength(this.mlpaLanes).longestLaneInInches;
  }

  transposeDimensions(mlpaLane: MlpaLaneView): MlpaLaneView {
    const newWidth = mlpaLane.designLengthInInches;
    const newLength = mlpaLane.designWidthInInches;

    this.editTotalLengthInFeet = ((mlpaLane.quantity / (mlpaLane.numberOfRibbons * mlpaLane.designNumberUp)) * newLength) / 12;
    mlpaLane.designWidthInInches = newWidth;
    mlpaLane.designLengthInInches = newLength;

    return mlpaLane;
  }

  getTransposedDimensions(mlpaLane: MlpaLaneView): number {
    const newDesignLengthInInches = mlpaLane.designWidthInInches;
    return newDesignLengthInInches;
  }

  getColorProfileDetails(info: MlpaLaneView): SearchAutoComplete {
    if (!this.colorProfileMap.has(info.laneNumber)) {
      this.setColorProfiles(info);
    }
    return this.colorProfileMap.get(info.laneNumber);
  }

  getInkWeightDetails(info: MlpaLaneView): SearchAutoComplete {
    if (!this.inkWeightMap.has(info.laneNumber)) {
      this.setInkWeightDetails(info);
    }
    return this.inkWeightMap.get(info.laneNumber);
  }

  getTotalAreaCoverageDetails(info: MlpaLaneView): SearchAutoComplete {
    if (!this.totalAreaCoverageMap.has(info.laneNumber)) {
      this.setTotalAreaCoverageDetails(info);
    }
    return this.totalAreaCoverageMap.get(info.laneNumber);
  }

  moveUp(mlpaLane: MlpaLaneView): void {
    this.moveLane({
      mlpaLane,
      direction: MoveDirection.Up
    });
  }
  moveDown(mlpaLane: MlpaLaneView): void {
    this.moveLane({
      mlpaLane,
      direction: MoveDirection.Down
    });
  }

  moveLane(event): void {
    const direction: MoveDirection = event.direction;
    const index = this.mlpaLanes.indexOf(event.mlpaLane);
    const laneNumber = this.mlpaLanes[index].laneNumber;

    if (direction === MoveDirection.Up) {
      if (index === 1) {
        return;
      }

      this.mlpaLanes[index].laneNumber = this.mlpaLanes[index - 1].laneNumber;
      this.mlpaLanes[index - 1].laneNumber = laneNumber;

      this.mlpaLanes.splice(index, 1);
      this.mlpaLanes.splice(index - 1, 0, event.mlpaLane);
    } else {
      if (index === this.mlpaLanes.length - 2) {
        return;
      }

      this.mlpaLanes[index].laneNumber = this.mlpaLanes[index + 1].laneNumber;
      this.mlpaLanes[index + 1].laneNumber = laneNumber;

      this.mlpaLanes.splice(index, 1);
      this.mlpaLanes.splice(index + 1, 0, event.mlpaLane);
    }
  }

  toggleRotation(info: MlpaLaneView): void {
    const index = _.findIndex(this.mlpaLanes, info);
    if (info.rotationInDegrees === 270) {
      this.mlpaLanes[index].rotationInDegrees = 90;
    } else {
      this.mlpaLanes[index].rotationInDegrees = 270;
    }
  }

  toggleTransposed(info: MlpaLaneView): void {
    const index = _.findIndex(this.mlpaLanes, info);
    this.mlpaLanes[index].isTransposed = !this.mlpaLanes[index].isTransposed;

    if (this.mlpaLanes[index].isTransposed) {
      this.mlpaLanes[index].editDisplayTotalWidthInInches = this.mlpaLanes[index].designLengthInInches * this.mlpaLanes[index].numberOfRibbons;
    } else {
      this.mlpaLanes[index].editDisplayTotalWidthInInches = this.mlpaLanes[index].designWidthInInches * this.mlpaLanes[index].numberOfRibbons;
    }

    const originalTotalWidth = getTotalLengthOfLanes(this.mlpaLanes);
    const finalTotalWidth = (originalTotalWidth - this.mlpaLanes[index].displayTotalWidthInInches) + this.mlpaLanes[index].editDisplayTotalWidthInInches;
    this._mlpaStateService.setLaneStateByValue('totalWidthInInches', finalTotalWidth);
    this._mlpaStateService.setLaneStateByValue('mlpaLane', this.mlpaLanes);
  }

  togglePreprocessing(info: MlpaLaneView): void {
    const index = _.findIndex(this.mlpaLanes, info);
    this.mlpaLanes[index].isProcessingEnabled = !this.mlpaLanes[index].isProcessingEnabled;
  }

  toggleColorCorrection(info: MlpaLaneView): void {
    // get the index of the selected lane
    const index = _.findIndex(this.mlpaLanes, info);

    // toggle color correction
    this.mlpaLanes[index].colorCorrectionInfo.isColorCorrectionEnabled = !this.mlpaLanes[index].colorCorrectionInfo.isColorCorrectionEnabled;

    if (!this.mlpaLanes[index].colorCorrectionInfo.isColorCorrectionEnabled) {
      this.setColorProfileFromProductDesign(info);
    } else {
      // check and set job and lane characterization values
      this.setMlpaJobAndLaneCharacterizationValues(info);
    }
  }

  setMlpaJobAndLaneCharacterizationValues(info: MlpaLaneView) {
    // get the index of the selected lane
    const index = _.findIndex(this.mlpaLanes, info);

    // get characterization values of selected lane
    const characterizationValues: MlpaCharacterizationValue = {
      inkColors: info.colorCorrectionInfo.inkColors,
      speed: info.colorCorrectionInfo.speed,
      overPrintVarnish: info.colorCorrectionInfo.overPrintVarnish,
      printMode: info.colorCorrectionInfo.printMode,
      pressType: info.colorCorrectionInfo.pressType,
      substrate: info.colorCorrectionInfo.substrateWeight,
      inkWeight: info.colorCorrectionInfo.inkWeight,
      totalAreaCoverage: info.colorCorrectionInfo.totalAreaCoverage
    };

    // if any of the characterization value is empty
    if (!this.colorManagementSystemSettings.validateCharacterizationValues(characterizationValues)) {
      this.loadingCharValues = true;
      // get design characterization values
      this._mlpaJobOptionsService.getDesignCharacterizationValues(info.designNumber, info.jobSettings?.asset?.assetType)
        .subscribe((result) => {
          // update characterization values and compare with job characterization values for any difference
          this.compareAndUpdateCharacterizationValues(this.mlpaLanes[index], result);
        }, (err) => {
          this._alerts.add(`Could not find the characterization values for Design# ${info.designNumber}`,
            'Please select the missing characterization values from the dropdown options', AlertType.Error);
        }).add(() => {
          this.loadingCharValues = false;
          // If any color correction enabled lane with missing char values, update them
          this.mlpaJobView.refreshLaneCharacterizationValues();
        });
    } else {
      // if the job characterization values are empty
      if (!this.colorManagementSystemSettings.validateJobCharacterizationValues()) {
        // construct job characterization object
        const jobCharacterizationValues: MlpaJobCharacterizationValue = {
          overPrintVarnish: info.colorCorrectionInfo.overPrintVarnish,
          pressType: info.colorCorrectionInfo.pressType !== '' ? info.colorCorrectionInfo.pressType : this.jobSettings.asset?.name.split(' ')[0],
          speed: info.colorCorrectionInfo.speed,
          substrate: info.colorCorrectionInfo.substrateWeight,
          printMode: info.colorCorrectionInfo.printMode,
          inkColors: info.colorCorrectionInfo.inkColors
        };
        // set the job characterization values if it is not set
        this.colorManagementSystemSettings.setJobCharacterizationValues(jobCharacterizationValues);
        // update job characterization values in the view
        this.enableColorCorrectionEmitter.emit();
      }

      // Build error message
      let alertMessage = '';

      // notify user and update if the default value is different from job characterization value
      if (info.colorCorrectionInfo.speed !== this.colorManagementSystemSettings.jobCharacterizationValues.speed) {
        alertMessage += `&#8226; Speed updated from
        <b>${info.colorCorrectionInfo.speed}</b> to <b>${this.colorManagementSystemSettings.jobCharacterizationValues.speed}</b>.<br>`;
        this.mlpaLanes[index].colorCorrectionInfo.speed = this.colorManagementSystemSettings.jobCharacterizationValues.speed;
      }

      if (info.colorCorrectionInfo.overPrintVarnish !== this.colorManagementSystemSettings.jobCharacterizationValues.overPrintVarnish) {
        alertMessage += `&#8226; Over Print Varnish updated from
        <b>${info.colorCorrectionInfo.overPrintVarnish}</b> to <b>${this.colorManagementSystemSettings.jobCharacterizationValues.overPrintVarnish}</b>.<br>`;
        this.mlpaLanes[index].colorCorrectionInfo.overPrintVarnish = this.colorManagementSystemSettings.jobCharacterizationValues.overPrintVarnish;
      }

      if (info.colorCorrectionInfo.pressType !== this.colorManagementSystemSettings.jobCharacterizationValues.pressType) {
        alertMessage += `&#8226; Press Type updated from <b>${info.colorCorrectionInfo.pressType}</b> to <b>${this.colorManagementSystemSettings.jobCharacterizationValues.pressType}</b>.<br>`;
        this.mlpaLanes[index].colorCorrectionInfo.pressType = this.colorManagementSystemSettings.jobCharacterizationValues.pressType;
      }

      if (info.colorCorrectionInfo.substrateWeight !== this.colorManagementSystemSettings.jobCharacterizationValues.substrate) {
        alertMessage += `&#8226; Substrate updated from <b>${info.colorCorrectionInfo.substrateWeight}</b> to <b>${this.colorManagementSystemSettings.jobCharacterizationValues.substrate}</b>.<br>`;
        this.mlpaLanes[index].colorCorrectionInfo.substrateWeight = this.colorManagementSystemSettings.jobCharacterizationValues.substrate;
      }

      if (info.colorCorrectionInfo.printMode !== this.colorManagementSystemSettings.jobCharacterizationValues.printMode) {
        alertMessage += `&#8226; Print Mode updated from <b>${info.colorCorrectionInfo.printMode}</b> to <b>${this.colorManagementSystemSettings.jobCharacterizationValues.printMode}</b>.<br>`;
        this.mlpaLanes[index].colorCorrectionInfo.printMode = this.colorManagementSystemSettings.jobCharacterizationValues.printMode;
      }

      if (info.colorCorrectionInfo.inkColors !== this.colorManagementSystemSettings.jobCharacterizationValues.inkColors) {
        alertMessage += `&#8226; Ink Colors updated from <b>${info.colorCorrectionInfo.inkColors}</b> to <b>${this.colorManagementSystemSettings.jobCharacterizationValues.inkColors}</b>.<br>`;
        this.mlpaLanes[index].colorCorrectionInfo.inkColors = this.colorManagementSystemSettings.jobCharacterizationValues.inkColors;
      }

      if (alertMessage.length > 0) {
        this._alerts.add(`Updated Characterization Values of Design# ${this.mlpaLanes[index].designNumber} and Lane# ${this.mlpaLanes[index].laneNumber}`, `${alertMessage}`, AlertType.Info);
      }
      // set color profile based on ink weight
      this.setColorProfileFromCharacterizationValue(info);
      // If any color correction enabled lane with missing char values, update them
      this.mlpaJobView.refreshLaneCharacterizationValues();
    }
  }

  setMlpaJobAndAllLanesCharacterizationValues() {
    this.loadingCharValues = true;
    // Get designs numbers of all the graphic lanes
    let designNumbers = [];
    this.mlpaLanes.forEach(mlpaLane => {
      if (mlpaLane.laneType === MlpaLaneType.Graphic) {
        designNumbers.push(mlpaLane.designNumber);
      }
    });

    // Remove duplicates
    designNumbers = [...new Set(designNumbers)];

    // Get characterization values for all the design numbers
    if (designNumbers.length > 0) {
      this._mlpaJobOptionsService.getAllDesignCharacterizationValues(designNumbers, this.jobSettings?.asset?.assetType).subscribe((response) => {
        if (response.results.length > 0) {
          // Updated the characterization values to all the graphic lanes
          response.results.map(result => {
            this.mlpaLanes.forEach(mlpaLane => {
              if (mlpaLane.laneType === MlpaLaneType.Graphic && mlpaLane.designNumber === result.designNumber) {
                // update characterization values and compare with job characterization values for any difference
                this.compareAndUpdateCharacterizationValues(mlpaLane, result);
              }
            });
          });
        }
        // If any of the design numbers doesn't have char values
        if (designNumbers.length !== response.results.length) {
          const missingDesignNumbers = designNumbers.filter(d => !response.results.find(r => r.designNumber === d));
          missingDesignNumbers.forEach(designNumber => {
            this._alerts.add(`Could not find the characterization values for Design# ${designNumber}`, 'Please select the missing characterization values from the dropdown options', AlertType.Error);
          });
        }
        // If any color correction enabled lane with missing char values, update them
        this.mlpaJobView.refreshLaneCharacterizationValues();
        this.loadingCharValues = false;
      }, (err) => {
        this.loadingCharValues = false;
        this._alerts.add('Uanle to get the design characterization values', 'Please try after some time', AlertType.Error);
      });
    }
  }

  compareAndUpdateCharacterizationValues(mlpaLane: MlpaLaneView, result: DesignCharacterizationResponse): void {
    // if the job characterization values are empty
    if (!this.colorManagementSystemSettings.validateJobCharacterizationValues()) {
      // construct job characterization object
      const jobCharacterizationValues: MlpaJobCharacterizationValue = {
        overPrintVarnish: result.overPrintVarnish,
        pressType: result.pressType !== '' ? result.pressType : this.jobSettings.asset?.name.split(' ')[0],
        speed: result.speed,
        substrate: result.substrateWeight,
        printMode: result.printMode,
        inkColors: result.inkColors
      };
      // set the job characterization values
      this.colorManagementSystemSettings.setJobCharacterizationValues(jobCharacterizationValues);
      // update job characterization values in the view
      this.enableColorCorrectionEmitter.emit();
    }

    // set default characterization values
    mlpaLane.definedColorCorrectionInfo.inkColors = result.inkColors;
    mlpaLane.definedColorCorrectionInfo.overPrintVarnish = result.overPrintVarnish;
    mlpaLane.definedColorCorrectionInfo.substrateWeight = result.substrateWeight;
    mlpaLane.definedColorCorrectionInfo.speed = result.speed;
    mlpaLane.definedColorCorrectionInfo.pressType = result.pressType;
    mlpaLane.definedColorCorrectionInfo.printMode = result.printMode;

    // Updated lane specific characterization values
    mlpaLane.colorCorrectionInfo.inkWeight = result.inkWeight;
    this.setInkWeightDetails(mlpaLane);
    mlpaLane.colorCorrectionInfo.totalAreaCoverage = result.totalAreaCoverage;
    this.setTotalAreaCoverageDetails(mlpaLane);

    // Build alert message
    let alertMessage = '';

    // Update rest of the characterization values from job characterization values
    if (this.colorManagementSystemSettings.jobCharacterizationValues.overPrintVarnish) {
      mlpaLane.colorCorrectionInfo.overPrintVarnish = this.colorManagementSystemSettings.jobCharacterizationValues.overPrintVarnish;
      if (result.overPrintVarnish !== this.colorManagementSystemSettings.jobCharacterizationValues.overPrintVarnish) {
        alertMessage += `&#8226; Over Print Varnish updated from <b>${result.overPrintVarnish}</b> to <b>${mlpaLane.colorCorrectionInfo.overPrintVarnish}</b>. <br>`;
      }
    }
    if (this.colorManagementSystemSettings.jobCharacterizationValues.pressType) {
      mlpaLane.colorCorrectionInfo.pressType = this.colorManagementSystemSettings.jobCharacterizationValues.pressType;
      if (result.pressType !== this.colorManagementSystemSettings.jobCharacterizationValues.pressType) {
        alertMessage += `&#8226; Press Type updated from <b>${result.pressType}</b> to <b>${mlpaLane.colorCorrectionInfo.pressType}</b>. <br>`;
      }
    }
    if (this.colorManagementSystemSettings.jobCharacterizationValues.substrate) {
      mlpaLane.colorCorrectionInfo.substrateWeight = this.colorManagementSystemSettings.jobCharacterizationValues.substrate;
      if (result.substrateWeight !== this.colorManagementSystemSettings.jobCharacterizationValues.substrate) {
        alertMessage += `&#8226; Substrate updated from <b>${result.substrateWeight}</b> to <b>${mlpaLane.colorCorrectionInfo.substrateWeight}</b>. <br>`;
      }
    }
    if (this.colorManagementSystemSettings.jobCharacterizationValues.speed) {
      mlpaLane.colorCorrectionInfo.speed = this.colorManagementSystemSettings.jobCharacterizationValues.speed;
      if (result.speed !== this.colorManagementSystemSettings.jobCharacterizationValues.speed) {
        alertMessage += `&#8226; Speed updated from <b>${result.speed}</b> to <b>${mlpaLane.colorCorrectionInfo.speed}</b>. <br>`;
      }
    }
    if (this.colorManagementSystemSettings.jobCharacterizationValues.printMode) {
      mlpaLane.colorCorrectionInfo.printMode = this.colorManagementSystemSettings.jobCharacterizationValues.printMode;
      if (result.printMode !== this.colorManagementSystemSettings.jobCharacterizationValues.printMode) {
        alertMessage += `&#8226; Print Mode updated from <b>${result.printMode}</b> to <b>${mlpaLane.colorCorrectionInfo.printMode}</b>. <br>`;
      }
    }
    if (this.colorManagementSystemSettings.jobCharacterizationValues.inkColors) {
      mlpaLane.colorCorrectionInfo.inkColors = this.colorManagementSystemSettings.jobCharacterizationValues.inkColors;
      if (result.inkColors !== this.colorManagementSystemSettings.jobCharacterizationValues.inkColors) {
        alertMessage += `&#8226; Ink Colors updated from <b>${result.inkColors}</b> to <b>${mlpaLane.colorCorrectionInfo.inkColors}</b>. <br>`;
      }
    }
    if (alertMessage.length > 0) {
      this._alerts.add(`Updated Characterization Values of Design# ${mlpaLane.designNumber} and Lane# ${mlpaLane.laneNumber}`, `${alertMessage}`, AlertType.Info);
    }
    // set color profile based on ink weight
    this.setColorProfileFromCharacterizationValue(mlpaLane);
  }

  setColorProfileFromCharacterizationValue(lane: MlpaLaneView) {
    // filter color profiles based on characterization value (ink weight)
    const colorProfiles = this.jobSettings.searchColorProfile(lane.colorCorrectionInfo.inkWeight);
    if (!colorProfiles || !colorProfiles.length) {
      // if there is no matching color profiles found then, fall back to previous color profile
      this.setColorProfileFromProductDesign(lane);
      this._alertsService.add(`Color Profile Not Found for Design# ${lane.designNumber}`,
       'No matching color profile found for ink weight: ' + lane.colorCorrectionInfo.inkWeight,
        AlertType.Info);
      return;
    } else if (!!colorProfiles && colorProfiles.length > 1) {
      // if there are multiple matching color profiles found, then allow user to select the valid one
      lane.colorProfileId = '';
      lane.colorProfileName = '';
      this._alertsService.add(`Multiple Color Profiles Found for Design# ${lane.designNumber}`,
       'Please select a valid color profile for ink weight: ' + lane.colorCorrectionInfo.inkWeight,
        AlertType.Info);
    } else if (!!colorProfiles && colorProfiles.length === 1) {
      // if there is a single matching color profile found, then select it
      lane.colorProfileId = colorProfiles[0].id;
      lane.colorProfileName = colorProfiles[0].name;
    }
    this.setColorProfiles(lane);
  }

  setColorProfileFromProductDesign(lane: MlpaLaneView) {
    const colorProfile = this.jobSettings.lookupColorProfile(lane.designColorProfileName);

    // select the respective color profile for the lane
    lane.colorProfileName = colorProfile ? colorProfile.name : null;
    lane.colorProfileId = colorProfile ? colorProfile.id : null;
    this.setColorProfiles(lane);
  }

  selectWebWeave(laneType: MlpaLaneType): void {
    this.selectWebWeaveEmitter.emit(laneType);
  }

  private _mapColorProfileToDropDownSelectItem(): SelectItem[] {
    // creating an array of select items for the auto complete
    return this.jobSettings.colorProfiles.map(result => ({ id: result.id, value: result.name, source: result } as SelectItem));
  }

  private _mapInkWeightsToDropDownSelectItem(): SelectItem[] {
    // creating an array of select items for the auto complete
    const inkWeights = this.colorManagementSystemSettings.characterizationValues?.find(result => result.characterizationType === CharacterizationType.InkWeight);
    if (!!inkWeights) {
      return inkWeights.values.map(result => ({ id: result, value: result, source: result } as SelectItem));
    }

    // if there isn't an asset we just want to return an empty array
    return [];
  }

  private _mapTotalAreaCoverageToDropDownSelectItem(): SelectItem[] {
    // creating an array of select items for the auto complete
    const totalAreaCoverage = this.colorManagementSystemSettings.characterizationValues?.find(result => result.characterizationType === CharacterizationType.TotalAreaCoverage);
    if (!!totalAreaCoverage) {
      return totalAreaCoverage.values.map(result => ({ id: result, value: result, source: result } as SelectItem));
    }

    // if there isn't an asset we just want to return an empty array
    return [];
  }

  private _getCorrectPreview(lane: MlpaLaneView): void {
    switch (lane.laneType) {
      case MlpaLaneType.QrCode:
        lane.preview = '/assets/QrCode.svg';
        break;
      case MlpaLaneType.SmartLine:
        lane.preview = '/assets/SmartLine.svg';
        break;
      case MlpaLaneType.WebWeave:
        lane.preview = '/assets/WebWeave.svg';
        break;
      case MlpaLaneType.Graphic:
        if (lane.isNewJob) {
          if (lane.jobSettings) {
            // find the asset
            const asset = lane.jobSettings.assets.find(p => p.name === lane.jobSettings.asset.name);
            // set the source
            if (asset) {
              lane.preview = `${environment.tartanApiUrl}assets/${asset.id}/pdfs/${lane.designNumber}/thumbnail?rotationInDegrees=90`;
            }
          }
        } else {
          lane.preview = `${environment.tartanApiUrl}mlpa-lanes/${lane.id}/thumbnail`;
        }

        break;
      case MlpaLaneType.Empty:
        lane.preview = 'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA=';
        break;
      default:
        lane.preview = '/assets/ThumbnailNotFound.svg';
        break;
    }
  }

}
