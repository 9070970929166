import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

/**
 * Force to display a maximum of 2 decimal places if they're present
 */
@Directive({
  selector: '[pkgTwoDigitDecimalNumber]'
})
export class TwoDigitDecimalNumberDirective implements OnInit{

  private regex = new RegExp(/^\d*\.?\d{0,2}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    const current = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = this.handleDecimal(current, position, event.key);

    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

  ngOnInit(): void {
    if(!!this.el.nativeElement.value){
      this.el.nativeElement.value = this.applyTwoDigitRegex(this.el.nativeElement.value);
    }
  }

  /**
   * Convert a number in a string that has more than 2 decimal places to a maximum of 2 decimal places
   *
   * @param value String to apply two digitDecimalRegex to
   * @returns String with only two decimal places shown
   */
  applyTwoDigitRegex(value: string) {
    let result = '';
    const splitString = value.split('');

    for (let index = 0; index < splitString.length; index++) {
      const newStr = this.handleDecimal(result, index, splitString[index]);
      if (newStr && !String(newStr).match(this.regex)) {
        break;
      } else {
        result = newStr;
      }
    }

    return result;
  }

  private handleDecimal(result: string, position: any, char: string): string {
    return [result.slice(0, position), char === '.' ? '.' : char, result.slice(position)].join('');
  }
}
