<pkg-mlpa-jobs-search (mlpaJobsEventEmitter)="setMlpaJobs($event)" (termSearchedEmitter)="setSearchedTerm($event)"></pkg-mlpa-jobs-search>

<div class="job-container" [class.collapsed]="!!selectedJob" [ngClass]="{'details-full' : detailsIsFullScreen}">
  <div class="mlpa-jobs-picker-container" pkgInfinite (loadData)="loadMoreJobs()">
    <div *ngFor="let job of mlpaJobs" class="job-container" [class.collapsed]="!!selectedJob" [class.single]="mlpaJobs.length == 1">
      <pkg-card [style.overflow]="'hidden'" (click)="selectJob(job)" [class.deselected]="job !== selectedJob && previousSelectedJob === job" [class.selected]="job === selectedJob">
        <pkg-card-header>
          <div class="pkg-card-title">
            <pkg-mlpa-status-icons class="status-icons-group" [job]="job"></pkg-mlpa-status-icons>
          </div>
        </pkg-card-header>
        <pkg-card-body>
          <pkg-mlpa-base-body [job]="job"></pkg-mlpa-base-body>
        </pkg-card-body>
      </pkg-card>
    </div>
    <button *ngIf="showLoadMore && mlpaJobs?.length > 0" [disabled]="loading || hasLoadedAllJobs" [style.margin]='"auto"' class="button button--blue" (click)="loadMoreJobs()">
      <span>Load more jobs</span>
      <span *ngIf="loading" class="loading"></span>
    </button>
  </div>
</div>
<pkg-loader *ngIf="loading && mlpaJobs?.length === 0"></pkg-loader>
