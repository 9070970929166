import { OnDestroy, SimpleChanges } from '@angular/core';
import { Component, OnInit, Input, ChangeDetectorRef, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { MlpaJobSettings } from '../../mlpa/mlpa-job-settings/mlpa-job-settings';
import { MlpaLane } from '../../mlpa/mlpa-lane/mlpa-lane';
import { MlpaLaneType } from '../../mlpa/mlpa-lane/mlpa-lane-type.enum';
import { MlpaStateService } from '../../pages/mlpa/shared/mlpa-state.service';

@Component({
  selector: 'pkg-mlpa-job-ruler',
  templateUrl: './mlpa-job-ruler.component.html',
  styleUrls: ['./mlpa-job-ruler.component.scss']
})
export class MlpaJobRulerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() jobSettings: MlpaJobSettings;
  @Input() mlpaLanes: MlpaLane[];
  @Input() totalWidthInInches: number;
  totalWidthInInchesWithoutWebWeave: number;
  hasWebWeave = false;
  availableSpace: number;
  private _jobStateSubscription: Subscription;
  private _laneStateSubscription: Subscription;

  get webwWeaveClass(): string {
    return `width__percentage ${this.mlpaLanes[0].designDescription.toLowerCase()}`;
  }

  get widthColorClass(): string {
    const percent = this.totalWidthInInches / (this.jobSettings.paperWidthInInches - this.jobSettings.marginWidthInInches * 2) * 100;
    if (percent < 20 || percent > 100) {
      return 'width__percentage--red';
    } else if (percent >= 20 && percent <= 79) {
      return '';
    } else {
      return 'width__percentage--green';
    }
  }

  get isOverflowed(): boolean {
    const percent = this.totalWidthInInches / (this.jobSettings.paperWidthInInches - this.jobSettings.marginWidthInInches * 2) * 100;
    if (percent > 100) {
      return true;
    }

    return false;
  }
  constructor(private readonly _mlpaStateService: MlpaStateService, private readonly _cd: ChangeDetectorRef) {
  }

  // An same instance of ruler component is used for multiple selections on home screen,
  // detect changes in selected JobSettings, MlpaJob and totalWidthInInches and update the UI
  ngOnChanges(simpleChanges: SimpleChanges) {
    this.setDimensions();
    this.availableSpace = this._getAvailableSpace();
  }

  ngOnDestroy() {
    this._jobStateSubscription.unsubscribe();
    this._laneStateSubscription.unsubscribe();
  }

  ngOnInit() {
    this.totalWidthInInchesWithoutWebWeave = this.totalWidthInInches;
    this._jobStateSubscription = this._mlpaStateService.mlpaJobState$.subscribe((results) => {
      this.jobSettings = !!results.jobSettings ? results.jobSettings : this.jobSettings;
      this.totalWidthInInches = !!results.totalWidthInInches ? results.totalWidthInInches : this.totalWidthInInches;
      this.totalWidthInInchesWithoutWebWeave = this.totalWidthInInches;
      this.mlpaLanes = !!results.mlpaJob && !!results.mlpaJob.mlpaLanes ? results.mlpaJob.mlpaLanes : this.mlpaLanes;
      this.setDimensions();
      this.availableSpace = this._getAvailableSpace();
    });

    this._laneStateSubscription = this._mlpaStateService.mlpaLaneState$.subscribe((results) => {
      if (!!results.jobSettings) {
        this.jobSettings = results.jobSettings;
        this.mlpaLanes = results.mlpaLane != null ? results.mlpaLane : this.mlpaLanes;
        this.availableSpace = this._getAvailableSpace();
        if (!!results.totalWidthInInches) {
          this.totalWidthInInches = results.totalWidthInInches;
          this.totalWidthInInchesWithoutWebWeave = this.totalWidthInInches;
          this.setDimensions();
          this.availableSpace = this._getAvailableSpace();
        }
      }
    });
  }

  setDimensions(): void {
    // Take off web weaves from total width if there are any.
    const sizeOfWebWaves = this._getTotalSizeOfWebWeaves();
    this.totalWidthInInchesWithoutWebWeave = this.totalWidthInInches - sizeOfWebWaves;
  }

  getWidthPercentage(value): number {
    if (!this.jobSettings && !this.jobSettings.paperWidthInInches) {
      return 0;
    }
    return value / (this.jobSettings.paperWidthInInches - this.jobSettings.marginWidthInInches * 2) * 100;
  }

  private _getTotalSizeOfWebWeaves(): number {
    let size = 0;
    for (const lane of this.mlpaLanes) {
      switch (lane.laneType) {
        case MlpaLaneType.Empty:
        case MlpaLaneType.SmartLine:
        case MlpaLaneType.WebWeave:
          this.hasWebWeave = true;
          size += lane.designWidthInInches;
          break;
        case MlpaLaneType.Stress:
          return 0;
        default:
          break;
      }
    }

    return size;
  }

  private _getAvailableSpace(): number {
    let space = 0;
    if (!!this.jobSettings) {
      space = this.jobSettings.paperWidthInInches - (this.jobSettings.marginWidthInInches * 2) - this.totalWidthInInches;
    }

    return space;
  }
}
