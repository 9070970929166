import { SelectItem } from './select-item';
import { SearchOptions } from './search-options';

export class SearchAutoComplete {
  id: string;
  name: string;
  placeholder: string;
  url: string;
  textValue: string;
  results: SelectItem[];
  term: string;
  isExactSearch: boolean;
  options: SearchOptions;
  get searchType(): string {
    if (this.isExactSearch) {
      return 'is';
    }
    return 'like';
  }

  constructor(id: string, name: string, placeholder: string, options: SearchOptions) {
    this.id = id;
    this.name = name;
    this.placeholder = placeholder;
    this.url = !!options.url ? options.url : null;
    this.term = '';
    this.textValue = '';
    this.results = new Array<SelectItem>();
    this.options = options;

    if (!!options.data) {
      this.results = options.data;
    }
  }

  clear(): void {
    this.term = '';
    this.textValue = '';
    this.isExactSearch = false;
    this.results = [];
  }
}
