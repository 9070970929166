import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { VisionSystemAsset } from '@shared/vision-system-asset';
import { environment } from '../../../../environments/environment';
import { PagedResult } from '@shared/paged-result';

@Injectable({
  providedIn: 'root'
})
export class VisionService {

  constructor(private readonly _http: HttpClient) { }

  getAll(): Observable<PagedResult<VisionSystemAsset>> {
    return this._http.get<PagedResult<VisionSystemAsset>>(`${environment.tartanApiUrl}vision-systems`);
  }

  get(visionSystemId: string): Observable<VisionSystemAsset> {
    return this._http.get<VisionSystemAsset>(`${environment.tartanApiUrl}vision-systems/${visionSystemId}`);
  }

  editVisionSystem(visionSystem: VisionSystemAsset): Observable<VisionSystemAsset> {
    const payload = visionSystem.buildForSave();
    return this._http.put<VisionSystemAsset>(`${environment.tartanApiUrl}vision-systems/${visionSystem.id}`, payload);
  }

  addVisionSystem(visionSystem: VisionSystemAsset): Observable<VisionSystemAsset> {
    const payload = visionSystem.buildForSave();
    return this._http.post<VisionSystemAsset>(`${environment.tartanApiUrl}vision-systems`, payload);
  }

  searchVisionSystem(visionSystemIds: string[], pageNumber: number, pageSize: number): Observable<PagedResult<VisionSystemAsset>> {
    const params = new HttpParams()
      .set('visionSystemIds', `${visionSystemIds}`)
      .set('pageSize', `${pageSize}`)
      .set('pageNumber', `${pageNumber}`);

    return this._http.get<PagedResult<VisionSystemAsset>>(`${environment.tartanApiUrl}vision-systems/search`, { params });
  }

  deleteVisionSystem(id: string): Observable<unknown> {
    return this._http.delete(`${environment.tartanApiUrl}vision-systems/${id}`);
  }
}
