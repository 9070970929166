<div *ngIf="showModal" class="modal-container">
  <div class="modal modal--small">
    <div class="modal__header clearfix">
      <h3>Add New User</h3>
      <button class="icon-button" (click)="close()" type="button">
        <i class="material-icons">&#xE5CD;</i>
      </button>
    </div>
    <div class="modal__content">
      <div *ngIf="!selectedUser">
        <div class="users-search">
          <div class="users-search__inner">
            <span *ngIf="!keyword || keyword == ''" class="material-icons search-icon">&#xE8B6;</span>
            <span *ngIf="(keyword && keyword !== '')" class="material-icons clear-icon" (click)="keyword=''"
              title="Clear">&#xE14C;</span>
            <input [(ngModel)]="keyword" (keyup.enter)="searchKeyword()" type="text" id="keyword"
              placeholder="Search for a user to add...">
            <span class="result-status visible" *ngIf="(!searching && searchResults)">{{searchResults.length}}
              Results</span>
          </div>
          <div class="search-button">
            <button class="button button--green" (click)="searchKeyword()" type="button">
              <i class="material-icons">&#xE8B6;</i>
              <span>Search</span>
            </button>
          </div>
        </div>
        <div class="user-search-results">
          <div *ngIf="!searching && !searchResults && !searchErrorMessage" class="result-message">
            Search for a GP user with their name, email address, or NT login
          </div>
          <div *ngIf="searchErrorMessage" class="result-message">
            {{searchErrorMessage}}
          </div>
          <div *ngIf="searching" class="searching-container">
            <div class="loading"></div>
            <div class="searching__title">Searching the database for "{{keyword}}"</div>
          </div>
          <div class="users-container">
            <div class="user" *ngFor="let result of searchResults">
              <pkg-card>
                <pkg-card-header>
                  <div class="pkg-card-title">
                    <span class="title" [title]="result?.displayName">{{result.displayName}}</span>
                    <button style="margin-left: auto;" title="Add" class="button button--primary button--small button-fab"
                      (click)="selectUser(result)">
                      <span class="material-icons order-icon">&#xE145;</span>
                    </button>
                  </div>
                </pkg-card-header>
                <pkg-card-body>
                  <div class="user-details">
                    <div class="image-container">
                      <div class="content">
                        <img class="user__image" [src]="result.photoUrl" alt="User Photo">
                      </div>
                    </div>
                    <div class="user-details-container">
                      <div class="content">
                        <div class="title">Email Address</div>
                        <div class="info" [title]="result?.emailAddress">
                          {{result?.emailAddress?result.emailAddress:'N/A'}}</div>
                      </div>
                      <div class="content">
                        <div class="title">NT Login</div>
                        <div class="info">{{result?.ntLogin}}</div>
                      </div>
                      <div class="content">
                        <div class="title">Phone Number</div>
                        <div class="info">{{result?.phoneNumber?result.phoneNumber:'N/A'}}</div>
                      </div>
                    </div>
                  </div>
                </pkg-card-body>
              </pkg-card>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="selectedUser">
        <div class="edit-users-container">
          <div class="user">
            <pkg-user-details [user]="selectedUser" [userRole]="selectedRole" [assets]="assets"
              (roleSelectEmitter)="onRoleSelect($event)" 
              (assetSelectEmitter)="onAssetSelect($event)"
              (assetDeSelectEmitter)="onAssetDeSelect($event)" 
              (assetSelectAllEmitter)="onAssetSelectAll($event)"
              (assetDeSelectAllEmitter)="onAssetDeSelectAll($event)">
            </pkg-user-details>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="selectedUser" class="modal__controls">
      <button class="button button--green" (click)="createUser()" type="button">
        <i class="material-icons">&#xE145;</i>
        <span>Create</span>
      </button>
      <button class="button" (click)="close()" type="button">
        <i class="material-icons">&#xE5C9;</i>
        <span>Cancel</span>
      </button>
      <div *ngIf="creating" class="modal__loading">
        <div class="loading loading--small"></div>
        <span>Creating User</span>
      </div>
      <button class="button control--right" (click)="resetUser()" type="button">
        <i class="material-icons">&#xE042;</i>
        <span>Reset</span>
      </button>
    </div>
  </div>
</div>