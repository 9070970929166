import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MlpaJob } from '../../../mlpa/mlpa-job/mlpa-job';
import { DOCUMENT } from '@angular/common';
import { MlpaService } from '../../mlpa/shared/mlpa.service';
import { MlpaJobApi } from '@shared/mlpa-job-api';
import { AlertsService } from '@shared/alerts/alerts.service';
import { AlertType } from '@shared/alerts/alert-type.enum';
import { Router } from '@angular/router';
import { AnalyticsService } from '@shared/analytics.service';
import { MlpaLaneType } from '../../../../app/mlpa/mlpa-lane/mlpa-lane-type.enum';

@Component({
  selector: 'pkg-clone-mlpa-jobs',
  templateUrl: './clone-mlpa-jobs.component.html',
  styleUrls: ['./clone-mlpa-jobs.component.scss']
})
export class CloneMlpaJobsComponent implements OnInit {
  @Output() cloneJob = new EventEmitter<MlpaJobApi>();
  @Output() closeModal = new EventEmitter<any>();
  show: boolean;
  errorMessages = new Array<string>();
  warningMessages = new Array<string>();
  showSaving: boolean;
  mlpaJob: MlpaJob;

  get showMessages(): boolean {
    return this.errorMessages.length > 0 || this.warningMessages.length > 0;
  }
  get hasErrors(): boolean {
    return this.errorMessages.length > 0;
  }

  constructor(
    @Inject(DOCUMENT) private readonly _document,
    private readonly _mlpaService: MlpaService,
    private readonly _alertsService: AlertsService,
    private readonly _router: Router,
    private readonly _analyticsService: AnalyticsService) { }

  ngOnInit(): void {
  }

  open(mlpaJob: MlpaJob): void {
    this.mlpaJob = new MlpaJob();
    this.mlpaJob = mlpaJob;

    this.show = true;
    this._document.body.classList.add('show-modal');
  }

  close(): void {
    this.errorMessages = [];
    this.warningMessages = [];
    this.show = false;
    this.showSaving = false;
    this._document.body.classList.remove('show-modal');
    this.closeModal.emit();
  }
  edit(): void {
    const id = this.mlpaJob?.mlpaLanes[1].laneType !== MlpaLaneType.Stress ? this.mlpaJob?.id : this.mlpaJob?.linkedMlpaJobs[0]?.id;
    this._router.navigate([`/mlpa/${id}/clone`]);
  }

  clone() {
    // If the clone button clicked on Graphic job and has a Stress job attached,
    // first clone the stress job and then move on to linked jobs
    if (!!this.mlpaJob.parentMlpaJobId) {
        this._mlpaService.getMlpaJob(this.mlpaJob.parentMlpaJobId).subscribe((result) => {
          this.mlpaJob = new MlpaJob(result);
          this.doClone();
        });
    } else {
      this.doClone();
    }
  }

  cloneLinked(mlpaJob: MlpaJob, mlpaJobApi: MlpaJobApi): void {
    if (mlpaJob.linkedMlpaJobs.length === 0) {
      return;
    }
    const promises = new Array<Promise<boolean>>();
    promises.push(new Promise((resolve, reject) => {
      mlpaJob.linkedMlpaJobs.forEach(linkedJob => {
        linkedJob = MlpaJob.createCloneFromJob(linkedJob);
        linkedJob._mlpaLanes.forEach((lane, index) => {
          if (lane.laneType === MlpaLaneType.Graphic) {
            linkedJob._mlpaLanes[index].setTransposedValues();
          }
        });
        linkedJob.parentMlpaJobId = mlpaJobApi.id;
        linkedJob.id = null;

        this._mlpaService.saveMlpaJob(linkedJob).subscribe(linkedData => {
          this._alertsService.add('MLPA job saved', `The MLPA job, ${linkedData.name}-${linkedData.uniqueId}, has been saved`, AlertType.Success);
          // recursively clone
          this.cloneLinked(linkedJob, linkedData);
          resolve(true);
        }, async err => {

          // delete the parent job since an error occurred.
          if (mlpaJob?.id != null) {
            await this._mlpaService.deleteMlpaJob(mlpaJob.id).toPromise();
          }
          reject(err);
          this._alertsService.add('Failed to clone MLPA Job', err.error.message, AlertType.Error);
          this.close();
        });
      });
    }));
    Promise.all(promises).then(() => {
      // Update the UI with end results
      this.cloneJob.emit();
      this.show = false;
      this.showSaving = false;
      this._document.body.classList.remove('show-modal');
    });
  }

  private doClone() {
    const mlpaJob = MlpaJob.createCloneFromJob(this.mlpaJob);
    mlpaJob.id = null;
    this.showSaving = true;

    // save the job
    this._mlpaService.saveMlpaJob(mlpaJob).subscribe(data => {
      const mlpaJobApi = data as MlpaJobApi;
      mlpaJob.id = data.id;
      this._alertsService.add('MLPA job saved', `The MLPA job, ${mlpaJobApi.name}-${mlpaJobApi.uniqueId}, has been saved`, AlertType.Success);
      // send data to google analytics
      this._analyticsService.sendJobEvent(data, 'Job Payload', 'Cloning Job');

      // Save each linked job after setting parent job id
      if (mlpaJob.linkedMlpaJobs.length > 0) {
        this.cloneLinked(mlpaJob, mlpaJobApi);
      } else {
        this.cloneJob.emit();
        this.show = false;
        this.showSaving = false;
        this._document.body.classList.remove('show-modal');
      }
    }, err => {
      // Update the UI with end results
      this._alertsService.add('Failed to clone MLPA Job', err.error.message, AlertType.Error);
      this.closeModal.emit();
      this.show = false;
      this.showSaving = false;
      this._document.body.classList.remove('show-modal');
    });
  }
}
