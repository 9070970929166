import { Component, OnInit } from '@angular/core';
import { VisionService } from '../shared/vision.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertsService } from '@shared/alerts/alerts.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { VisionSystemAsset, VisionSystemType } from '@shared/vision-system-asset';
import { AlertType } from '@shared/alerts/alert-type.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'pkg-vision-system-edit',
  templateUrl: './vision-system-edit.component.html',
  styleUrls: ['./vision-system-edit.component.scss']
})
export class VisionSystemEditComponent implements OnInit {
  visionSystem: VisionSystemAsset = new VisionSystemAsset();
  visionForm: FormGroup;
  title = 'Edit';
  isAddVisionRoute: boolean = this.router.url === '/admin/visionsystem/add';
  visionSystemType = VisionSystemType;
  isLoading = true;
  showDelete = false;
  constructor(private readonly visionService: VisionService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly alertsService: AlertsService,
    private readonly formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.initializeForm();
    this.route.paramMap.pipe(switchMap((params) => {
        if (this.isAddVisionRoute) {
          this.title = 'Add';
          return of(new VisionSystemAsset(null, ''));
        } else {
          return this.visionService.get(params.get('visionsystemId'));
        }
      }))
      .subscribe((data) => {
        this.visionSystem = data;
        this.initializeForm();
        this.isLoading = false;
      }, (err) => {
        this.isLoading = false;
        this.alertsService.add('Failed to load vision system', 'Please try again later', AlertType.Error);
      });
  }

  initializeForm(): void {
    this.visionForm = this.formBuilder.group({
      name: [this.visionSystem.name, [Validators.required, Validators.pattern('^.{4,255}$')]],
      audience: [this.visionSystem.audience, Validators.required],
      type: [!!this.visionSystem.type ? this.visionSystem.type : this.visionSystemType.PrintStar, Validators.required],
      isEnabled: [!!this.visionSystem.isEnabled ? this.visionSystem.isEnabled : false, Validators.required],
      isInTestMode: [!!this.visionSystem.isInTestMode ? this.visionSystem.isInTestMode : false, Validators.required]
    });
  }

  isValid(field: string): boolean {
    return !this.visionForm.get(field).valid && this.visionForm.get(field).touched;
  }

  cancel(): void {
    this.router.navigate(['/admin/asset']);
  }

  saveVision(): void {
    if (this.visionSystem instanceof VisionSystemAsset) {
      this.visionSystem = Object.assign(this.visionSystem, this.visionForm.value);
    } else {
      const copy = Object.assign(this.visionSystem, this.visionForm.value) as VisionSystemAsset;
      this.visionSystem = new VisionSystemAsset(copy.id, copy.name);
      this.visionSystem = Object.assign(this.visionSystem, copy);
    }

    if (this.isAddVisionRoute) {
      this._addVisionSystem();
      return;
    }

    if (this.visionSystem.id) {
      this._editVisionSystem();
    } else {
      this.alertsService.add(
        'Failed to save',
        `There is not an id associated with this Vision System, (Invalid entry ${this.visionSystem.id})`,
        AlertType.Error);
    }
  }

  confirmDelete(): void {
    this.showDelete = true;
  }
  delete() {
    this.isLoading = true;
    this.visionService.deleteVisionSystem(this.visionSystem.id).subscribe((result) => {
      this.isLoading = false;
      this.alertsService.add('Deleted Vision System', `Vision System: ${this.visionSystem.name}`, AlertType.Success);
      this.router.navigate(['/admin/asset']);
    }, (err) => {
      this.isLoading = false;
      this.alertsService.add('Failed to delete Vision System', `Vision System: ${this.visionSystem.name}`, AlertType.Error);
    });
  }

  private _addVisionSystem(): void {
    this.isLoading = true;
    this.visionService.addVisionSystem(this.visionSystem)
      .subscribe((complete) => {
        this.isLoading = false;
        this.alertsService.add('Saved', `Vision System added.`, AlertType.Success);
        this.router.navigate(['/admin/asset']);
      }, (error) => {
        this.isLoading = false;
        this._showError(error);
      });
  }


  private _editVisionSystem(): void {
    this.isLoading = true;
    this.visionService.editVisionSystem(this.visionSystem)
      .subscribe((complete) => {
        this.isLoading = false;
        this.alertsService.add('Saved', `Vision System updated.`, AlertType.Success);
        this.router.navigate(['/admin/asset']);
      }, (error) => {
        this.isLoading = false;
        this._showError(error);
      });
  }


  private _showError(error: HttpErrorResponse): void {
    this.alertsService.add('Failed to save', error?.error?.message, AlertType.Error);
  }
}
