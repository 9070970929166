import { AlertType } from './alert-type.enum';

export class Alert {
  id: number;
  title: string;
  message: string;
  type: AlertType;
  get typeClass(): string {
    switch (this.type) {
      case AlertType.Success:
        return 'alert--success';
      case AlertType.Error:
        return 'alert--error';
      default:
        return '';
    }
  }
  get showInfo(): boolean {
    return this.type === AlertType.Info;
  }
  get showSuccess(): boolean {
    return this.type === AlertType.Success;
  }
  get showError(): boolean {
    return this.type === AlertType.Error;
  }

  constructor(id: number, title: string, message: string, type: AlertType) {
    this.id = id;
    this.title = title;
    this.message = message;
    this.type = type;
  }
}
