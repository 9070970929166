import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { HomeComponent } from '@pages/home/home.component';
import { BuildMlpaComponent } from '@pages/mlpa/build-mlpa/build-mlpa.component';
import { MlpaGuard } from '@pages/mlpa/shared/mlpa.guard';
import { LoginComponent } from './auth/login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { OfflineComponent } from './offline/offline.component';
import { ErrorComponent } from './error/error.component';
import { StatsComponent } from '@pages/stats/stats.component';
import { AdminGuard } from '@pages/admin/admin.guard';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { StatusPageComponent } from '@shared/status-page/status-page.component';
import { MlpaCloneGuard } from '@pages/mlpa/shared/mlpa-clone.guard';
// import { ScheduleComponent } from '@pages/schedule/schedule.component';
import { PrintComponent } from '@pages/print/print.component';
import { MlpaEditGuard } from '@pages/mlpa/shared/mlpa-edit.guard';
import { RollMapGuard } from '@pages/rollmap/shared/roll-map.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/login'
  },
  { path: 'login', component: LoginComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'stats',
    component: StatsComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'status-page',
    component: StatusPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'mlpa',
    component: BuildMlpaComponent,
    canActivate: [AuthGuard, MlpaGuard]
  },
  {
    path: 'mlpa/:id',
    component: BuildMlpaComponent,
    canActivate: [AuthGuard, MlpaGuard]
  },
  {
    path: 'mlpa/:id/clone',
    component: BuildMlpaComponent,
    canActivate: [AuthGuard, MlpaCloneGuard]
  },
  {
    path: 'mlpa/:id/edit',
    component: BuildMlpaComponent,
    canActivate: [AuthGuard, MlpaEditGuard]
  },
  // {
  //   path: 'schedule',
  //   component: ScheduleComponent,
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'print/:id',
    component: PrintComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rollmap',
    canActivate: [AuthGuard, RollMapGuard],
    loadChildren: () => import('./pages/rollmap/rollmap.module').then(m => m.RollMapModule)
  },
  {
    path: 'offline',
    component: OfflineComponent
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,  { enableTracing: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
