import { Component, OnInit, Input } from '@angular/core';
import { MlpaJobView } from '../../../../pages/home/view-mlpa-jobs/mlpa-job-view';
import { Role } from '../../../../../app/pages/admin/shared/role';
import { ConfigService } from '../../../../../app/shared/config/config.service';
import { MlpaLaneType } from '../../../../../app/mlpa/mlpa-lane/mlpa-lane-type.enum';

@Component({
  selector: 'pkg-mlpa-status-icons',
  templateUrl: './mlpa-status-icons.component.html',
  styleUrls: ['./mlpa-status-icons.component.scss']
})
export class MlpaStatusIconsComponent implements OnInit {
  @Input() job: MlpaJobView;
  @Input() hideStatus = false;
  @Input() title = null;
  @Input() editMode = false;
  @Input() maxWidth = '100px';
  role = Role;
  constructor(private readonly _configService: ConfigService) { }
  get isCollapsed(): string {
    const navIsCollapsed = this._configService.getConfigValue('navigationCollapsed');
    return navIsCollapsed ? 'iscollapsed' : '';
  }

  get hasStressFile() {
    if (!!this.job?.jobSettings) {
      return this.job.jobSettings.hasStress;
    }

    return this.job?.mlpaLanes[1]?.laneType === MlpaLaneType.Stress ||
      (this.job?.mlpaLanes[1]?.laneType === MlpaLaneType.Graphic && this.job?.parentMlpaJobId);
  }

  get showStressFileForJob(): boolean {
    return this.job?.mlpaLanes[1]?.laneType === MlpaLaneType.Graphic && !!this.job?.parentMlpaJobId;
  }

  get isStressFile(): boolean {
    return this.job?.mlpaLanes[1]?.laneType === MlpaLaneType.Stress;
  }

  get hasQrCode(): boolean {
    return this.job?.hasQrCode || this.job?.linkedMlpaJobs[0]?.hasQrCode;
  }

  get hasWebWeave(): boolean {
    return this.job?.mlpaLanes[0]?.laneType === MlpaLaneType.WebWeave || this.job?.linkedMlpaJobs[0]?.mlpaLanes[0].laneType === MlpaLaneType.WebWeave;
  }

  get hasSmartLine(): boolean {
    return this.job?.mlpaLanes[0]?.laneType === MlpaLaneType.SmartLine || this.job?.linkedMlpaJobs[0]?.mlpaLanes[0].laneType === MlpaLaneType.SmartLine;
  }

  get isMultiPage(): boolean {
    return this.job?.mlpaLanes[0]?.designNumberOfPages > 1 || this.job?.linkedMlpaJobs[0]?.mlpaLanes[0]?.designNumberOfPages > 1;
  }

  get isBlanket(): boolean {
    return !!this.job?.blanketNumber || !!this.job?.linkedMlpaJobs[0]?.blanketNumber;
  }

  get hasDistortion(): boolean {
    return (this.job?.isDistortion && !this.job?.isSpecial) || (this.job?.linkedMlpaJobs[0]?.isDistortion && !this.job?.linkedMlpaJobs[0]?.isSpecial);
  }

  get hasSample(): boolean {
    return (this.job?.isSample && !this.job?.isSpecial) || (this.job?.linkedMlpaJobs[0]?.isSample && !this.job?.linkedMlpaJobs[0]?.isSpecial);
  }

  get hasMockup(): boolean {
    return (this.job?.isMockup && !this.job?.isSpecial) || (this.job?.linkedMlpaJobs[0]?.isMockup && !this.job?.linkedMlpaJobs[0]?.isSpecial);
  }
  ngOnInit(): void { }
}
