import { MlpaLaneApi } from '@shared/mlpa-lane-api';
import { Convert } from '@shared/convert';
import { MlpaLaneType } from '../../../mlpa/mlpa-lane/mlpa-lane-type.enum';
import { deepCopyProperties } from '@shared/pkg-helper';
import { MlpaJobSettings } from '../../../mlpa/mlpa-job-settings/mlpa-job-settings';
import { MlpaLane } from '../../../mlpa/mlpa-lane/mlpa-lane';
import { MlpaLaneColorCorrectionInfo } from '@app/mlpa/mlpa-lane/mlpa-lane-color-correction-info';
import { DesignCharacterizationResponse } from '@shared/mlpa-job-options/mlpa-job-option-characterization-value';

export class MlpaLaneView {
  id: string;
  designDescription: string;
  programNumber: string;
  setupNumber: string;
  orderNumber: string;
  designNumber: string;
  colorProfileName: string;
  designColorProfileName: string;
  colorProfileId: string;
  get totalWidthInInches(): number {
    if (this.isTransposed) {
      return this.designLengthInInches * this.numberOfRibbons;
    }
    return this.designWidthInInches * this.numberOfRibbons;
  }
  designNumberUp: number;
  designNumberOfPages: number;
  originalNumberOfPages: number;
  designWidthInInches: number;
  totalLengthInInches: number;
  preview: string;
  jobSettings: MlpaJobSettings;
  get isWebWeave(): boolean {
    return this.laneType !== MlpaLaneType.Graphic && this.laneType !== MlpaLaneType.QrCode && this.laneType !== MlpaLaneType.Stress;
  }
  get lengthInFeet(): number {
    return parseFloat((this.totalLengthInInches / 12).toFixed(2));
  }
  get isMultiPage(): boolean {
    return this.designNumberOfPages > 1;
  }
  get displayTotalWidthInInches(): number {
    switch (this.laneType) {
      case MlpaLaneType.Empty:
      case MlpaLaneType.SmartLine:
      case MlpaLaneType.WebWeave:
        return this.designWidthInInches;
      case MlpaLaneType.QrCode:
      case MlpaLaneType.Graphic:
      default:
        return this.totalWidthInInches;
    }
  }
  editDisplayTotalWidthInInches: number;
  designLengthInInches: number;
  quantity: number;
  laneNumber: number;
  numberOfRibbons: number;
  laneType: MlpaLaneType;
  sourcePdfSizeInBytes: number;
  processedPdfSizeInBytes: number;
  isProcessing: boolean;
  isProcessed: boolean;
  isProcessingEnabled: boolean;
  colorCorrectionInfo: MlpaLaneColorCorrectionInfo;
  definedColorCorrectionInfo: DesignCharacterizationResponse;
  isTransposed: boolean;
  rotationInDegrees: number;
  errors: string;
  isOk: boolean;
  isNewJob: boolean;
  isCurrentPreviewButton = false;
  get pdfSizeNumber(): number {
    // Return processed PDF size if set
    if (this.processedPdfSizeInBytes) {
      return Convert.getConvertedBytesNumber(this.processedPdfSizeInBytes, 2);
    }
    // Fall back to source PDF size
    return this.sourcePdfSizeInBytes
      ? Convert.getConvertedBytesNumber(this.sourcePdfSizeInBytes, 2)
      : Convert.getConvertedBytesNumber(0, 2);
  }
  get pdfSizeUnit(): string {
    // Return processed PDF size if set
    if (this.processedPdfSizeInBytes) {
      return Convert.getConvertedBytesUnit(this.processedPdfSizeInBytes, 2);
    }
    // Fall back to source PDF size
    return this.sourcePdfSizeInBytes
      ? Convert.getConvertedBytesUnit(this.sourcePdfSizeInBytes, 2)
      : Convert.getConvertedBytesUnit(0, 2);
  }
  get laneColor(): string {
    return `order-lane--${this.laneNumber}`;
  }
  get status(): string {
    if (!this.isOk) {
      return 'Failed';
    }
    if (this.isProcessed && !this.colorCorrectionInfo.isColorCorrectionEnabled) {
      return 'Processed';
    }
    if (this.isProcessing) {
      return 'Processing';
    }
    if (this.colorCorrectionInfo.isColorCorrectionProcessed && this.colorCorrectionInfo.isColorCorrectionEnabled) {
      return 'Color Corrected';
    }
    if (this.colorCorrectionInfo.isColorCorrectionProcessing) {
      return 'Color correcting';
    }
    return 'Queued';
  }
  get statusClass(): string {
    if (!this.isOk) {
      return 'status-icon--failed';
    } else
      if (this.isProcessed && !this.colorCorrectionInfo.isColorCorrectionEnabled) {
        return 'status-icon--processed';
      } else
        if (this.isProcessing) {
          return 'status-icon--processing spin';
        } else
          if (this.colorCorrectionInfo.isColorCorrectionProcessed && this.colorCorrectionInfo.isColorCorrectionEnabled) {
            return 'status-icon--color--processed';
          } else
            if (this.colorCorrectionInfo.isColorCorrectionProcessing) {
              return 'status-icon--color--processing';
            }
    return 'status-icon--queued';
  }

  constructor(mlpaLane: MlpaLaneApi | MlpaLane) {
    // loop through properties to perform a deep copy of properties
    deepCopyProperties(this, mlpaLane);
    if (this.laneType == null) {
      this.laneType = mlpaLane['type'];
    }

    if (mlpaLane.designDescription === '' || mlpaLane.designDescription == null) {
      this.designDescription = mlpaLane.designNumber;
    }
    this.originalNumberOfPages = this.designNumberOfPages;
    this.editDisplayTotalWidthInInches = this.displayTotalWidthInInches;
    this.totalLengthInInches = mlpaLane.totalLengthInInches ?? 0;
  }

  updateStatus(mlpaLane: MlpaLaneApi): void {
    this.sourcePdfSizeInBytes = mlpaLane.sourcePdfSizeInBytes;
    this.processedPdfSizeInBytes = mlpaLane.processedPdfSizeInBytes;
    this.isProcessed = mlpaLane.isProcessed;
    this.isProcessing = mlpaLane.isProcessing;
    this.colorCorrectionInfo.isColorCorrectionProcessed = mlpaLane.colorCorrectionInfo.isColorCorrectionProcessed;
    this.colorCorrectionInfo.isColorCorrectionProcessing = mlpaLane.colorCorrectionInfo.isColorCorrectionProcessing;
    this.colorCorrectionInfo.colorCorrectionProcessingPercentage = mlpaLane.colorCorrectionInfo.colorCorrectionProcessingPercentage;
    this.isTransposed = mlpaLane.isTransposed;
    this.rotationInDegrees = mlpaLane.rotationInDegrees;
    this.isProcessingEnabled = mlpaLane.isProcessingEnabled;
    this.errors = mlpaLane.errors;
    this.isOk = mlpaLane.isOk;

  }

  editLength(newLength: number): void {
    // figure out how many are printed with one unit of width
    const howManyWeCanPrintInOneDesignWidth = this.numberOfRibbons * this.designNumberUp;
    // figure out how many can be printed *completely* (as opposed to partially) given the users' requested length
    const numberOfWholeDesigns = Math.floor((newLength * 12) / this.designLengthInInches);

    // Only update the numbers if the new calculated quantity has changed
    const newQuantity = numberOfWholeDesigns * howManyWeCanPrintInOneDesignWidth;

    this.quantity = newQuantity;
    // TODO: There is a bug here, if the feet value does not change, it will not update the input text value
    // this.editTotalLengthInFeet = ((newQuantity / (this.numberOfRibbons * this.designNumberUp)) * this.designLengthInInches) / 12;
    this.totalLengthInInches = ((newQuantity / (this.numberOfRibbons * this.designNumberUp)) * this.designLengthInInches) * 12;
  }

  toggleTransposedValues(): void {
    const transposedWidth = this.designLengthInInches;
    const transposedLength = this.designWidthInInches;
    this.designWidthInInches = transposedWidth;
    this.designLengthInInches = transposedLength;
  }

}
