import { Injectable } from '@angular/core';
import { MlpaJob } from './mlpa-job';
import { AnalyticsService } from '@shared/analytics.service';
import { AlertsService } from '@shared/alerts/alerts.service';
import { AlertType } from '@shared/alerts/alert-type.enum';
import { MlpaService } from '../../pages/mlpa/shared/mlpa.service';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MlpaLaneType } from '../mlpa-lane/mlpa-lane-type.enum';

@Injectable({
  providedIn: 'root'
})
export class MlpaJobService {

  constructor(private readonly mlpaService: MlpaService,
    private readonly analyticsService: AnalyticsService,
    private readonly alertsService: AlertsService) { }

  submit(mlpaJob: MlpaJob): Observable<boolean> {
    // Submit job
    return this.mlpaService.submitJob(mlpaJob.id, mlpaJob.isSpecial).pipe(catchError((err) => {
      mlpaJob.isOk = false;
      mlpaJob.errors = err.error.message;
      this.showError(err);
      return throwError(err);
    })).pipe(map((result) => {
      // send data to google analytics
      this.analyticsService.sendJobEvent(mlpaJob, 'Job Payload', 'Submitting job to press');
      mlpaJob.isSubmitted = true;
      mlpaJob.isOk = true;
      mlpaJob.errors = null;
      this.alertsService.add('Submitted', `MLPA Job '${mlpaJob.name}-${mlpaJob.uniqueId}' submitted successfully.`, AlertType.Success);

      if (result.hasWarnings) {
        this.alertsService.add('Warning', result.warnings, AlertType.Info);
      }

      if (mlpaJob.linkedMlpaJobs.length === 0) {
        return true;
      }
      // Submit linked jobs
      mlpaJob.linkedMlpaJobs.forEach(linkedMlpaJob => {
        this.mlpaService.submitJob(linkedMlpaJob.id, linkedMlpaJob.isSpecial).subscribe((linkedResult) => {
          linkedMlpaJob.isSubmitted = true;
          linkedMlpaJob.isOk = true;
          linkedMlpaJob.errors = null;
          this.alertsService.add('Submitted', `Linked MLPA Job '${linkedMlpaJob.name}-${linkedMlpaJob.uniqueId}' submitted successfully.`, AlertType.Success);
          if (linkedResult.hasWarnings) {
            this.alertsService.add('Warning', linkedResult.warnings, AlertType.Info);
          }
        }, err => {
          linkedMlpaJob.isOk = false;
          linkedMlpaJob.errors = err.error.message;
          this.showError(err);
        });
      });

      return true;
    }));
  }

  deleteJob(mlpaJob: MlpaJob): Observable<boolean> {
    // Get Job type from center lane
    const jobType: MlpaLaneType = mlpaJob.getJobType();

    switch (jobType) {

      // Delete only Stress/QR code jobs only
      case MlpaLaneType.Stress:
      case MlpaLaneType.QrCode:
        return this.deleteSingleJob(mlpaJob);

      // Delete graphic job and all linked jobs - Stress & QR code
      case MlpaLaneType.Graphic:
        return this.deleteAllLinkedJobs(mlpaJob);

      default:
        return throwError('Unsupported Job type');
    }
  }

  private deleteSingleJob(mlpaJob: MlpaJob): Observable<boolean> {
    return this.mlpaService.deleteMlpaJob(mlpaJob.id).pipe(catchError((err) => {
      this.alertsService.add('Failed to delete MLPA job', err.error.message, AlertType.Error);
      return throwError(false);
    }),map(() => {
      // send data to google analytics
      this.analyticsService.sendJobEvent(mlpaJob, 'Job Payload', 'Deleting job');
      this.alertsService.add('MLPA job deleted', `The MLPA job: ${mlpaJob.name}-${mlpaJob.uniqueId} has been deleted`, AlertType.Success);
      return true;
    }));
  }

  private deleteAllLinkedJobs(mlpaJob: MlpaJob): Observable<boolean> {
    const deleteLinkedJobObservables: Observable<any>[] = [];
    // This job has linked jobs - Qr Code jobs
    if (mlpaJob.linkedMlpaJobs.length > 0) {
      // Build up observables to delete linked QR jobs
      mlpaJob.linkedMlpaJobs.forEach(linkedMlpaJob => {
        deleteLinkedJobObservables.push(this.deleteSingleJob(linkedMlpaJob));
      });
    }

    // Build up observables to delete linked parent job.
    // In this case the parent job is always a Stress job.
    if (!!mlpaJob.parentMlpaJobId) {
        deleteLinkedJobObservables.push(this.mlpaService.deleteMlpaJob(mlpaJob.parentMlpaJobId));
    }

    // If there are pending delete requests for linked jobs
    if (deleteLinkedJobObservables.length > 0) {
      // Wait on all delete job observables to finish
      return forkJoin(deleteLinkedJobObservables).pipe(
        catchError((err) => {
          this.alertsService.add('Failed to delete linked MLPA jobs', err.error.message, AlertType.Error);
          return throwError(err);
        }),
        switchMap(() => {
          this.alertsService.add('Linked MLPA jobs deleted', `The linked MLPA jobs have been deleted`, AlertType.Success);
          // delete the Graphic job
          return this.deleteSingleJob(mlpaJob);
        }));
    } else {
      // delete the Graphic job
      return this.deleteSingleJob(mlpaJob);
    }
  }

  private showError(error: HttpErrorResponse) {
    this.alertsService.add('Failed to submit', error.error.message, AlertType.Error);
  }
}
