// source: https://stackoverflow.com/questions/35750059/select-based-on-enum-in-angular2
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enum'
})
export class EnumPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const keys = [];
    for (const enumMember in value) {
      if (value.hasOwnProperty(enumMember)) {
        const intValue = parseInt(enumMember, 10);
        if (!isNaN(intValue)) {
          keys.push({ id: intValue, name: value[enumMember] });
        }
      }
    }
    return keys;
  }
}
