import { Component, OnInit, Input, OnChanges, SimpleChanges, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { Preview } from '../preview.interface';
import { isObject } from 'rxjs/internal/util/isObject';
import { SecureThumbnailPayload } from '../../secure.pipe';
import { getHeightAndWidthOfImage, getAdjustedHeight } from '../../image/image.util';
import { SafeUrl } from '@angular/platform-browser';
import { AlertsService } from '@shared/alerts/alerts.service';
import { AlertType } from '@shared/alerts/alert-type.enum';

@Component({
  selector: 'pkg-roll-preview',
  templateUrl: './roll-preview.component.html',
  styleUrls: ['./roll-preview.component.scss']
})
export class RollPreviewComponent implements OnInit, OnChanges {
  @Input() source: Preview;
  @Input() size: number;

  showBoundingBoxes: boolean;
  showBoundingBoxesQuery: string;
  shouldFlash: boolean;
  previewThumbnail: SecureThumbnailPayload;
  showPreview: boolean;
  zoomValue = 1;
  rotateValue = 0;
  previewHeight: string;
  initialPreviewHeight = '80px';

  constructor(private readonly _alertService: AlertsService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.source.currentValue) {
      const current = changes.source.currentValue ? changes.source.currentValue.id : null;
      const previous = changes.source.previousValue ? changes.source.previousValue.id : null;
      if (current !== previous) {
        this.previewHeight = this.initialPreviewHeight;
        this.showPreview = false;
      }
    }
  }

  isObject(arg: any) {
    return isObject(arg);
  }

  setScale(value: number) {
    this.zoomValue = value;
  }

  rotate() {
    this.rotateValue += 90;
  }
  evalFlash() {
    // Should flash
    if (this.source.shouldFlash) {
      // This is the first load
      if ((!this.source.previousPreview) || (this.source.preview !== this.source.previousPreview)) {
        this.shouldFlash = false;
        setTimeout(() => {
          this.shouldFlash = true;
        }, 100);
      } else {
        this.shouldFlash = false;
      }
      // Should not flash
    } else {
      this.shouldFlash = false;
    }
  }

  setThumbnail(thumbnail: SecureThumbnailPayload) {
    this.previewThumbnail = thumbnail;
    return true;
  }


  async togglePreview(thumbnailPayload: SecureThumbnailPayload) {
    let originalHeight = parseInt(thumbnailPayload.response.headers.get('x-content-height-in-pixels'), 10);
    let originalWidth = parseInt(thumbnailPayload.response.headers.get('x-content-width-in-pixels'), 10);
    if (originalHeight <= 0 || originalWidth <= 0) {
      // Fallback
      this._alertService.add('Image Problem','unable to get original image dimensions falling back to client side', AlertType.Info);
      const dimensions = await getHeightAndWidthOfImage(thumbnailPayload.preview as string);
      originalHeight = dimensions.height;
      originalWidth = dimensions.width;
    }

    const width = document.querySelector('.background-media').clientWidth;
    const height = getAdjustedHeight(width, originalWidth, originalHeight);
    this.showPreview = !this.showPreview;
    if (this.showPreview) {
      this.previewHeight = `${height}px`;
    } else {
      this.previewHeight = this.initialPreviewHeight;
      this.zoomValue = 1;
      this.rotateValue = 0;
    }
  }

  getBackgroundImage(thumbnail: SecureThumbnailPayload) {
    return `url(${thumbnail.preview})`;
  }
}
