export class VisionSystemAsset {
  id: string;
  name: string;
  audience: string;
  type: VisionSystemType;
  isEnabled: boolean;
  isInTestMode: boolean;
  createdDate: Date;
  createdBy: string;
  updatedBy: string;
  updatedDate: Date;

  constructor(id = null, name = null) {
    this.id = id;
    this.name = name;
  }

  buildForSave(): VisionSystemPayload {
    const { name, audience, type, isEnabled, isInTestMode } = this;

    return { name, audience, type, isEnabled, isInTestMode };
  }
}

export enum VisionSystemType {
  Unknown,
  PrintStar
}

export interface VisionSystemPayload {
  name: string;
  audience: string;
  type: VisionSystemType;
  isEnabled: boolean;
  isInTestMode: boolean;
}
