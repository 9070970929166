export enum KeyCode {
  Backspace = 8,
  Tab = 9,
  Enter = 13,
  Shift = 16,
  Esc = 27,
  Left = 37,
  Up = 38,
  Right = 39,
  Down = 40,
}
