// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  appTitle: '(LOCAL)',
  analyticsKey: '(LOCAL)',
  googleTagManagerKey: '(LOCAL)',
  releaseNumber: '0.0.00+0000000000',
  environmentName: 'local',
  clientId: 'NGRNW5ECQMZQ4Y',
  tartanUrl: 'http://tartan-dev.srv.gapac.com/',
  tartanApiUrl: 'https://tartanapi-dev.srv.gapac.com/',
  tartanApiKey: 'D9FC8598-7419-4AA1-96AA-400DF58480AD',
  warpApiUrl: 'https://warpapi-dev.srv.gapac.com/',
  warpApiKey: '35E508E8-B10B-4DF1-A6B1-DD96F333FF04'
};
