import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AlertType } from '@shared/alerts/alert-type.enum';
import { AlertsService } from '@shared/alerts/alerts.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MultiSelectDropDownSetting } from 'src/app/shared/multi-select-dropdown/multi-select-dropdown-settings';
import { AssetService } from '../shared/asset.service';
import { Role } from '../shared/role';
import { User } from '../shared/user';
import { UserService } from '../shared/user.service';


@Component({
  selector: 'pkg-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {
  @Output() create = new EventEmitter<User>();
  showModal: boolean;
  keyword: string;
  searching: boolean;
  searchErrorMessage: string;
  searchResults: User[];
  roles = Role;
  assets = [];
  selectedUser: User;
  selectedRole: Role;
  selectedAssets = [];
  creating: boolean;
  userAssetsDropdownSettings: MultiSelectDropDownSetting;
  private unsubscribe$ = new Subject();

  constructor(@Inject(DOCUMENT) private readonly document, private readonly userService: UserService, private readonly alerts: AlertsService, private readonly assetService: AssetService) {
  }

  open(): void {
    this.showModal = true;
    this.document.body.classList.add('show-modal');
  }
  searchKeyword(): void {
    if (!this.keyword || this.keyword.trim().length === 0) {
      return;
    }

    this.searching = true;
    this.searchResults = null;
    this.searchErrorMessage = '';
    this.userService.search(this.keyword).subscribe(data => {
      this.searchResults = data;
      this.searching = false;
    }, (err: HttpErrorResponse) => {
      if (err.status === 404) {
        this.searchErrorMessage = `No user was found for "${this.keyword}"`;
      } else {
        this.searchErrorMessage = err.error.message;
      }
      this.searching = false;
    });
  }
  selectUser(result: User): void {
    this.selectedUser = result;
    this.selectedRole = Role.Viewer;
    this.selectedAssets = [];
    this.searchResults = null;
    this.searching = false;
    this.keyword = '';
  }
  resetUser(): void {
    this.selectedUser = null;
    this.selectedRole = null;
    this.selectedAssets = [];
    this.searching = false;
    this.keyword = '';
    this.searchResults = null;
    this.searchErrorMessage = null;
  }
  createUser(): void {
    this.creating = true;
    this.selectedUser.role = this.selectedRole;
    this.selectedUser.assetIds = this.selectedAssets.map(({ id }) => (id));
    this.userService.create(this.selectedUser.emailAddress, this.selectedRole, this.selectedUser.assetIds)
      .subscribe(data => {
        this.creating = false;
        this.selectedUser.id = data.id;
        this.alerts.add('New User Created', `${this.selectedUser.displayName} has been added to the application`, AlertType.Success);
        this.create.emit(this.selectedUser);
        this.close();
      }, (err: HttpErrorResponse) => {
        this.creating = false;
        this.alerts.add('User Create Failed', err.error.message, AlertType.Error);
      });
  }
  close(): void {
    this.resetUser();
    this.showModal = false;
    this.document.body.classList.remove('show-modal');
  }
  getAssets() {
    this.assetService.getAll().pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.assets = data.map(({ id, name }) => ({ id, name }));
    },
      (err) => {
        this.alerts.add('Failed to load printers', 'Please try again as we could not get any printers', AlertType.Error);
      });
  }
  onRoleSelect(role: Role) {
    this.selectedRole = role;
  }
  onAssetSelect(assets: any[]) {
    this.selectedAssets = assets;
  }
  onAssetDeSelect(assets: any[]) {
    this.selectedAssets = assets;
  }
  onAssetSelectAll(assets: any[]) {
    this.selectedAssets = assets;
  }
  onAssetDeSelectAll(assets: any[]) {
    this.selectedAssets = assets;
  }
  ngOnInit() {
    this.getAssets();
  }
}
