<div class="lane-number-container" (click)="toggleLane()">
  <span class="lane-number left"></span>
  <span class="lane-number right">
    <span [ngClass]="{'collapsed-lane': !toggleState}" title="{{job?.status}} {{job?.errors}} {{job?.warnings}}" class="material-icons status-icon {{job?.statusClass}}"></span>
  </span>
</div>
<span class="title">{{job?.name}}-{{job?.uniqueId}}</span>
<span *ngIf="job?.hasQrCode" class="material-icons status-chip status-chip--qrcode" title="Qr Code">
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 -1 30 30" style="enable-background:new 0 -1 30 30;" xml:space="preserve">
    <use href="#qr-code"></use>
  </svg>
</span>
<span *ngIf="job?.mlpaLanes[0]?.laneType == 30" class="material-icons status-chip status-chip--smartline-job" title="Smart Line">
  <svg class="barcode-icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 10" style="enable-background:new 0 0 30 10;" xml:space="preserve">
    <use href="#smart-line"></use>
  </svg>
</span>
<span *ngIf="job?.mlpaLanes[0]?.laneType == 40" class="material-icons status-chip status-chip--solidline-job" title="Web Weave">
  <svg class="barcode-icon" version="1.1" id="web-weave" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 10" style="enable-background:new 0 0 30 10;" xml:space="preserve">
    <use href="#solid-line"></use>
  </svg>
</span>
<span *ngIf="job?.mlpaLanes[0]?.designNumberOfPages > 1" class="material-icons status-chip status-chip--multipage" title="Multipage"></span>
<span *ngIf="job?.blanketNumber" class="material-icons status-chip status-chip--blanket" title="Blanket Required"></span>
<span class="filler"></span>
