<div class="modal-container">
  <div class="modal modal--small">
    <div class="modal__header">
      <h3>{{header}}</h3>
      <button class="icon-button" (click)="close()">
        <i class="material-icons">&#xE5CD;</i>
      </button>
    </div>
    <div class="modal__content">
    <p class="submit-details" [innerHTML]="message"></p>
    </div>
    <div class="modal__controls">
      <button class="button button--{{color}}" (click)="confirm()" type="button">
        <i class="material-icons">&#xE876;</i>
        <span>{{confirmMessage}}</span>
      </button>
      <button class="button" (click)="close()" type="button">
        <i class="material-icons">&#xE5C9;</i>
        <span>{{closeMessage}}</span>
      </button>
    </div>
  </div>
</div>
