<div class="autocomplete">
  <label *ngIf="!disableLabel" class="autocomplete__label" for="{{details.id}}">
    <span>{{details.name}}</span>
    <span [class]="typeClass" [hidden]="!showType">{{details.searchType}}</span>
    <span [class]="typeClass" [hidden]="!tag">{{tag}}</span>
  </label>
  <form autocomplete="off">
    <div class="autocomplete__input">
      <input [(ngModel)]="details.textValue" [ngModelOptions]="{standalone: true}" (keydown)="keydown($event)" (keyup)="keyup($event)" (focus)="focus()" (blur)="selectOnBlur(); close()" id="{{details.id}}" placeholder="{{details.placeholder}}" type="text">
      <div *ngIf="loading" class="loading autocomplete__loading"></div>
    </div>
  </form>
  <div class="autocomplete__results" [hidden]="!showResults" [class.dropdown]="isDropDown">
    <ul>
      <li *ngFor="let result of details.results; index as i" class="autocomplete__result-item" [class.autocomplete__result-item--selected]="result.selected" (mousedown)="select(result, i, true)">
        {{result.value}}
      </li>
      <li *ngIf="!loading && resultsCount === 0" class="autocomplete__no-results">No Results Found</li>
      <li *ngIf="!loading && resultsCount > 0" class="autocomplete__result-info">Displaying {{resultsCount}} of {{totalCount}} Results</li>
    </ul>
  </div>
</div>
