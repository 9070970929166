<div class="page__header header--admin">
  <div class="page__header-actions">
    <h2 class="page__header-title">{{title}} Asset {{asset?.name}}</h2>
  </div>
</div>
<div class="page__content">
  <pkg-loader *ngIf="loading"></pkg-loader>
  <div *ngIf="!loading && asset" class="asset-detail">
    <form [formGroup]="assetForm" (submit)="saveAsset()">
      <h2>Asset Config</h2>
      <div class="asset-detail__row">
        <div class="asset-setting asset-setting--small">
          <label class="asset-setting__label">
            <span>Name:</span>
          </label>
          <input [class.input-error]="isValid('name')" type="text" formControlName="name" minlength="4" maxlength="255">
          <div class="asset-form error" [hidden]="!isValid('name')">Must not be empty, and be at least 4 characters.
          </div>
        </div>
        <div class="asset-setting asset-setting--small">
          <label class="asset-setting__label">
            <span>Unique Id:</span>
          </label>
          <input [class.input-error]="isValid('uniqueId')" type="text" formControlName="uniqueId" minlength="2"
            maxlength="2" pattern="^(?!0{2,}$)[a-zA-Z0-9]{2}$">
          <div class="asset-form error" [hidden]="!isValid('uniqueId')">Should have 2 alphanumeric characters, at least
            one of which must be non-zero. </div>
        </div>
        <div class="asset-setting asset-setting--small">
          <label class="asset-setting__label">
            <span>Mode: </span>
          </label>
          <select formControlName="isInTestMode" required>
            <option [ngValue]="true">Test Mode</option>
            <option [ngValue]="false">Production Mode</option>
          </select>
          <div class="asset-form error" [hidden]="!isValid('isInTestMode')">Please select a mode. </div>
        </div>
      </div>
      <div class="asset-detail__row">
        <div class="asset-setting asset-setting--small">
          <label class="asset-setting__label">
            <span>Enabled: </span>
          </label>
          <select formControlName="isEnabled" required>
            <option [ngValue]="true">Enabled</option>
            <option [ngValue]="false">Disabled</option>
          </select>
          <div class="asset-form error" [hidden]="!isValid('isEnabled')">Please select an option.</div>
        </div>
        <div class="asset-setting asset-setting--small">
          <label class="asset-setting__label">
            <span>Display In Schedule Screen: </span>
          </label>
          <select formControlName="showInSchedule" required>
            <option [ngValue]="true">Show</option>
            <option [ngValue]="false">Hide</option>
          </select>
          <div class="asset-form error" [hidden]="!isValid('showInSchedule')">Please select an option.</div>
        </div>

        <div class="asset-setting asset-setting--small">
          <label class="asset-setting__label">
            <span>Speed (ft/min):</span>
          </label>
          <input [class.input-error]="isValid('speedInFeetPerMinute')" type="number"
            formControlName="speedInFeetPerMinute" min="0">
          <div class="asset-form error" [hidden]="!isValid('speedInFeetPerMinute')">Must be greater than or equal to 0.
          </div>
        </div>
      </div>
      <div class="asset-detail__row">
        <div class="asset-setting asset-setting--small">
          <label class="asset-setting__label">
            <span>Min Width (in):</span>
          </label>
          <input [class.input-error]="isValid('minWidthInInches')" type="number" formControlName="minWidthInInches"
            min="0">
          <div class="asset-form error" [hidden]="!isValid('minWidthInInches')">Must be greater than or equal to 0.
          </div>
        </div>
        <div class="asset-setting asset-setting--small">
          <label class="asset-setting__label">
            <span>Max Width (in):</span>
          </label>
          <input [class.input-error]="isValid('maxWidthInInches')" type="number" formControlName="maxWidthInInches"
            min="0">
          <div class="asset-form error" [hidden]="!isValid('maxWidthInInches')">Must be greater than or equal to 0.
          </div>
        </div>
        <div class="asset-setting asset-setting--small">
          <label class="asset-setting__label">
            <span>Max Number of Lanes:</span>
          </label>
          <input [class.input-error]="isValid('maxNumberOfLanes')" type="number" formControlName="maxNumberOfLanes"
            min="0">
          <div class="asset-form error" [hidden]="!isValid('maxNumberOfLanes')">Must be greater than or equal to 0.
          </div>
        </div>
      </div>

      <div class="asset-detail__row">
        <div class="asset-setting asset-setting--small">
          <label class="asset-setting__label">
            <span>Vision System:</span>
          </label>

          <select formControlName="visionSystemId">
            <option [ngValue]="null">None</option>
            <option *ngFor="let vision of visionSystems" [ngValue]="vision.id">{{vision?.name}}</option>
          </select>
        </div>
        <div class="asset-setting asset-setting--small">
          <label class="asset-setting__label">
            <span>Color Code:</span>
          </label>
          <pkg-asset-color-picker formControlName="attributes" [assetAttributes]="assetAttributes">
          </pkg-asset-color-picker>
        </div>
        <div class="asset-setting asset-setting--small">
          <label class="asset-setting__label">
            <span>Type:</span>
          </label>

          <select formControlName="assetType" required>
            <option [ngValue]="null">Unknown</option>
            <option *ngFor="let type of assetType | enum" [ngValue]="type.id">{{type?.name}}</option>
          </select>
        </div>
      </div>

      <div class="asset-detail__row asset-detail__row--header">
        JDF Options
      </div>
      <div class="asset-detail__row">
        <div class="asset-setting asset-setting--small">
          <label class="asset-setting__label">
            <span>Retention Time:</span>
            <small>Minutes</small>
          </label>
          <input [class.input-error]="isValid('jdfRetainTimeInMinutes')" type="number"
            formControlName="jdfRetainTimeInMinutes" min="0">
          <div class="asset-form error" [hidden]="!isValid('jdfRetainTimeInMinutes')">Must be greater than or equal to
            0. </div>
        </div>
      </div>

      <div class="asset-detail__row asset-detail__row--header">
        Source File Paths <small>Template</small>
      </div>
      <div class="asset-detail__row">
        <div class="asset-setting asset-setting--large">
          <label class="asset-setting__label">
            <span>Lane PDFs:</span>
            <small>MLPA Lane</small>
          </label>
          <input [class.input-error]="isValid('pathToMlpaLaneSourcePdfs')" type="text"
            formControlName="pathToMlpaLaneSourcePdfs" minlength="1" maxlength="510">
          <div class="asset-form error" [hidden]="!isValid('pathToMlpaLaneSourcePdfs')">Must not be empty. </div>
        </div>
        <div class="asset-setting asset-setting--large">
          <label class="asset-setting__label">
            <span>Lane Thumbnails:</span>
            <small>MLPA Lane</small>
          </label>
          <input [class.input-error]="isValid('pathToMlpaLaneThumbnails')" type="text"
            formControlName="pathToMlpaLaneThumbnails" minlength="0" maxlength="510">
          <div class="asset-form error" [hidden]="!isValid('pathToMlpaLaneThumbnails')">Must not be empty. </div>
        </div>
      </div>

      <div class="asset-detail__row asset-detail__row--header">
        Output File Paths <small>Template</small>
      </div>
      <div class="asset-detail__row">
        <div class="asset-setting asset-setting--large">
          <label class="asset-setting__label">
            <span>Job JMFs:</span>
            <small>MLPA Job</small>
          </label>
          <input [class.input-error]="isValid('pathToMlpaJobJmfs')" type="text" formControlName="pathToMlpaJobJmfs"
            minlength="0" maxlength="510">
          <div class="asset-form error" [hidden]="!isValid('pathToMlpaJobJmfs')">Must not be empty. </div>
        </div>
        <div class="asset-setting asset-setting--large">
          <label class="asset-setting__label">
            <span>Lane JDFs:</span>
            <small>MLPA Lane</small>
          </label>
          <input [class.input-error]="isValid('pathToMlpaLaneJdfs')" type="text" formControlName="pathToMlpaLaneJdfs"
            minlength="0" maxlength="510">
          <div class="asset-form error" [hidden]="!isValid('pathToMlpaLaneJdfs')">Must not be empty. </div>
        </div>
        <div class="asset-setting asset-setting--large">
          <label class="asset-setting__label">
            <span>Lane PDFs:</span>
            <small>MLPA Lane</small>
          </label>
          <input [class.input-error]="isValid('pathToMlpaLaneOutputPdfs')" type="text"
            formControlName="pathToMlpaLaneOutputPdfs" minlength="0" maxlength="510">
          <div class="asset-form error" [hidden]="!isValid('pathToMlpaLaneOutputPdfs')">Must not be empty. </div>
        </div>
      </div>

      <div class="asset-detail__row asset-detail__row--header">
        Stress File Paths <small>Template</small>
      </div>
      <div class="asset-detail__row">
        <div class="asset-setting asset-setting--large">
          <label class="asset-setting__label">
            <span>Stress File:</span>
            <small>MLPA Lane</small>
          </label>
          <input [class.input-error]="isValid('pathToStressFile')" type="text" formControlName="pathToStressFile"
            minlength="0" maxlength="510">
          <div class="asset-form error" [hidden]="!isValid('pathToStressFile')">Must not be empty. </div>
        </div>
      </div>

      <div class="asset-detail__row asset-detail__row--header">
        API Paths <small>Template</small>
      </div>
      <div class="asset-detail__row">
        <div class="asset-setting asset-setting--large">
          <label class="asset-setting__label">
            <span>Press API:</span>
            <small>MLPA Job</small>
          </label>
          <input [class.input-error]="isValid('pathToPressApi')" type="text" formControlName="pathToPressApi"
            minlength="0" maxlength="510">
          <div class="asset-form error" [hidden]="!isValid('pathToPressApi')">Must not be empty. </div>
        </div>
      </div>

      <div class="asset-detail__row asset-detail__row--header">
        Source Clip Box <small>Template</small>
      </div>
      <div class="asset-detail__row">
        <div class="asset-setting asset-setting--small">
          <label class="asset-setting__label">
            <span>Enabled: </span>
          </label>
          <select formControlName="isSourceClipBoxEnabled" required>
            <option [ngValue]="true">Enabled</option>
            <option [ngValue]="false">Disabled</option>
          </select>

          <div class="asset-form error" [hidden]="!isValid('isSourceClipBoxEnabled')">Please select a mode.</div>
        </div>

        <div class="asset-setting asset-setting--large">
          <label class="asset-setting__label">
            <span>File Path:</span>
            <small>MLPA Lane</small>
          </label>
          <input [class.input-error]="isValid('pathToSourceClipBoxFile')" type="text"
            formControlName="pathToSourceClipBoxFile" minlength="0" maxlength="510">
          <div class="asset-form error" [hidden]="!isValid('pathToSourceClipBoxFile')">Must not be empty.</div>
        </div>
      </div>

      <div class="asset-detail__row asset-detail__row--header">
        PDF Tokens
      </div>
      <div class="asset-detail__row">
        <div class="asset-setting asset-setting--small">
          <label class="asset-setting__label">
            <span>Lane ID: </span>
            <small>[00000000-0000-0000-0000-000000000000]</small>
          </label>
          <select formControlName="isLaneIdRequired" required>
            <option [ngValue]="false">Optional</option>
            <option [ngValue]="true">Required</option>
          </select>
          <div class="asset-form error" [hidden]="!isValid('isLaneIdRequired')">Please select an option. </div>
        </div>
        <div class="asset-setting asset-setting--small">
          <label class="asset-setting__label">
            <span>PD#, Order#: </span>
            <small>[XXXXXXXXXX-XXXXXXXXXX]</small>
          </label>
          <select formControlName="isDesignNumberOrderNumberRequired" required>
            <option [ngValue]="false">Optional</option>
            <option [ngValue]="true">Required</option>
          </select>
          <div class="asset-form error" [hidden]="!isValid('isDesignNumberOrderNumberRequired')">Please select an option.
          </div>
        </div>
        <div class="asset-setting asset-setting--small">
          <label class="asset-setting__label">
            <span>PD#, Order#, Unique ID: </span>
            <small>[XXXXXXXXXX-XXXXXXXXXX-XXXX]</small>
          </label>
          <select formControlName="isDesignNumberOrderNumberUniqueIdRequired" required>
            <option [ngValue]="false">Optional</option>
            <option [ngValue]="true">Required</option>
          </select>
          <div class="asset-form error" [hidden]="!isValid('isDesignNumberOrderNumberUniqueIdRequired')">Please select an option. </div>
        </div>
      </div>

      <div class="asset-detail__row">
        <div class="asset-setting asset-setting--small">
          <label class="asset-setting__label">
            <span>Lane Letter: </span>
            <small>[L]</small>
          </label>
          <select formControlName="isLaneLetterRequired" required>
            <option [ngValue]="false">Optional</option>
            <option [ngValue]="true">Required</option>
          </select>
          <div class="asset-form error" [hidden]="!isValid('isLaneLetterRequired')">Please select an option. </div>
        </div>
        <div class="asset-setting asset-setting--small">
          <label class="asset-setting__label">
            <span>Customer PO#: </span>
            <small>[YYYYYYYY]</small>
          </label>
          <select formControlName="isCustomerPurchaseOrderNumberRequired" required>
            <option [ngValue]="false">Optional</option>
            <option [ngValue]="true">Required</option>
          </select>
          <div class="asset-form error" [hidden]="!isValid('isCustomerPurchaseOrderNumberRequired')">Please select an option.
          </div>
        </div>
      </div>

      <div class="asset-detail__row">
        <div class="asset-setting asset-setting--small">
          <button [disabled]="!assetForm.valid" class="button button--green">
            <i class="material-icons">&#xE5CA;</i>
            <span>Save</span>
          </button>
        </div>
      </div>
    </form>
  </div>