import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'pkg-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() color = 'green';
  @Input() message = 'Are you sure you want to do what you\'re doing?';
  @Input() confirmMessage = 'Yes';
  @Input() closeMessage = 'Cancel';
  @Input() header = 'Confirm Logout';

  // Add two output event emitters one for confirm and one for close
  @Output() confirmEmitter = new EventEmitter();
  @Output() closeEmitter = new EventEmitter();
  constructor() { }
  ngOnInit(): void { }

  confirm(): void {
    // emit confirm
    this.confirmEmitter.emit();
  }

  close(): void {
    // emit close
    this.closeEmitter.emit();
  }

}
