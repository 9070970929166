import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateReplace'
})
export class DateReplacePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const date: Date = new Date(value);
    const month = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();

    if (month === 11 && day === 31 && year === 0) {
      return args[0];
    }

    return value;
  }

}
