import { MlpaLaneType } from '../mlpa-lane/mlpa-lane-type.enum';
import { MlpaJobOptionAsset } from '@shared/mlpa-job-options/mlpa-job-option-asset';
import { MlpaJobOptionPaperType } from '@shared/mlpa-job-options/mlpa-job-option-paper-type';
import { MlpaJobOptionColorProfile } from '@shared/mlpa-job-options/mlpa-job-option-color-profile';

export class MlpaJobSettings {
  isVisible: boolean;
  assets: MlpaJobOptionAsset[];
  asset: MlpaJobOptionAsset;

  get paperTypes(): MlpaJobOptionPaperType[] {
    return this.asset && this.asset.paperTypes && this.asset.paperTypes.length > 0
      ? this.asset.paperTypes
      : undefined;
  }
  paperType: MlpaJobOptionPaperType;
  get paperWidthsInInches(): number[] {
    return this.paperType && this.paperType.widthsInInches && this.paperType.widthsInInches.length > 0
      ? this.paperType.widthsInInches
      : undefined;
  }
  paperWidthInInches: number;
  get colorProfiles(): MlpaJobOptionColorProfile[] {
    return this.asset && this.asset.colorProfiles && this.asset.colorProfiles.length > 0
      ? this.asset.colorProfiles
      : undefined;
  }
  marginWidthInInches: number;
  lineBreakLengthInInches: number;
  laneType: MlpaLaneType;
  hasQrCode: boolean;
  hasStress: boolean;
  get lineBreakLengthInFeet(): number {
    return Math.ceil(this.lineBreakLengthInInches / 12);
  }
  set lineBreakLengthInFeet(value: number) {
    this.lineBreakLengthInInches = Math.ceil(value) * 12;
  }
  get isValid(): boolean {
    return this.asset && this.paperType && this.paperWidthInInches > 0;
  }
  constructor(assets: MlpaJobOptionAsset[]) {
    this.marginWidthInInches = 0.125;
    this.lineBreakLengthInInches = 0;
    this.laneType = MlpaLaneType.SmartLine;
    this.assets = assets;
  }

  selectAsset(assetName: string, paperTypeName: string, paperWidthInInches: number): void {
    this.isVisible = true;
    if (assetName && this.assets && this.assets.length > 0) {
      this.asset = this.assets.find(a => a.name === assetName);
    } else {
      this.asset = undefined;
    }
    this.selectPaperType(paperTypeName, paperWidthInInches);
  }

  selectPaperType(paperTypeName: string, paperWidthInInches: number): void {
    if (paperTypeName && this.paperTypes && this.paperTypes.length > 0) {
      this.paperType = this.paperTypes.find(p => p.name === paperTypeName);

      // check if the paperWidth is in the paper type
      if (this.paperType != null) {
        paperWidthInInches = this.paperType.widthsInInches.find(pw => pw === paperWidthInInches);
      }
    } else {
      this.paperType = undefined;
      paperWidthInInches = undefined;
    }
    this.selectPaperWidthInInches(paperWidthInInches);
  }

  selectPaperWidthInInches(paperWidthInInches: number): void {
    if (this.paperType && this.paperType.widthsInInches && this.paperType.widthsInInches.length > 0) {
      if (paperWidthInInches && paperWidthInInches > 0) {
        this.paperWidthInInches = paperWidthInInches;
      } else {
        this.paperWidthInInches = Math.max(...this.paperType.widthsInInches);
      }
    } else {
      this.paperWidthInInches = undefined;
    }
  }

  isMarginInvalid(): boolean {
    if (this.paperWidthInInches === 110
      && (this.marginWidthInInches > 0 && this.marginWidthInInches < 0.40)) {
      return true;
    }

    return false;
  }

  isMarginWithinLimits(): boolean {
    return this.marginWidthInInches >= 0 && this.marginWidthInInches <= this.paperWidthInInches;
  }

  setMarginWidth(oldPaperWidthInInches: number): void {
    // Reset margin width only switching between 110" and non-110" paper width
    if (oldPaperWidthInInches !== this.paperWidthInInches && (oldPaperWidthInInches === 110 || this.paperWidthInInches === 110)) {
      if (this.paperWidthInInches === 110) {
        if (this.marginWidthInInches !== 0 && this.marginWidthInInches !== 0.40) {
          this.marginWidthInInches = 0.4;
        }
      } else if (this.marginWidthInInches !== 0) {
        this.marginWidthInInches = 0.125;
      }
    }
  }

  setDefaultMarginWidth(): void {
    if (this.paperWidthInInches === 110) {
      this.marginWidthInInches = 0.4;
    } else {
      this.marginWidthInInches = 0.125;
    }
  }

  lookupColorProfile(colorProfileName: string): MlpaJobOptionColorProfile {
    if (colorProfileName && this.colorProfiles && this.colorProfiles.length > 0) {
      return this.colorProfiles.find(cp => cp.name === colorProfileName);
    } else {
      return undefined;
    }
  }

  searchColorProfile(searchTerm: string): MlpaJobOptionColorProfile[] {
    if (searchTerm && this.colorProfiles && this.colorProfiles.length > 0) {
      return this.colorProfiles.filter(cp => cp.name.includes('inkLimitsOnly_' + searchTerm));
    } else {
      return [];
    }
  }

  verifyColorProfile(colorProfileId: string): MlpaJobOptionColorProfile {
    if (colorProfileId && this.colorProfiles && this.colorProfiles.length > 0) {
      return this.colorProfiles.find(cp => cp.id === colorProfileId);
    } else {
      return undefined;
    }
  }

  clear(): void {
    this.asset = null;
    this.paperType = null;
    this.paperWidthInInches = 0;
    this.marginWidthInInches = 0.125;
    this.lineBreakLengthInInches = 0;
    this.laneType = MlpaLaneType.WebWeave;
    this.isVisible = false;
    this.hasQrCode = false;
  }
}
