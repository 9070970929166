import { MlpaJobOptionColorManagementSystem } from '@shared/mlpa-job-options/mlpa-job-option-color-management-system';
import { MlpaCharacterizationValues, CharacterizationType, MlpaJobCharacterizationValue, MlpaCharacterizationValue } from '@shared/mlpa-job-options/mlpa-job-option-characterization-value';

export class ColorManagementSystemSettings {
  colorManagementSystems: MlpaJobOptionColorManagementSystem[];
  colorManagementSystem: MlpaJobOptionColorManagementSystem;

  characterizationValues: MlpaCharacterizationValues[];

  jobCharacterizationValues: MlpaJobCharacterizationValue = {
    speed: '',
    overPrintVarnish: '',
    substrate: '',
    pressType: '',
    inkColors: '',
    printMode: ''
  };

  constructor(colorManagementSystems: MlpaJobOptionColorManagementSystem[], characterizationValues: MlpaCharacterizationValues[]) {
    this.colorManagementSystems = colorManagementSystems;
    this.characterizationValues = characterizationValues;
  }

  selectColorManagementSystem(colorManagementSystemId: string): void {
    if (colorManagementSystemId && this.colorManagementSystems && this.colorManagementSystems.length > 0) {
      this.colorManagementSystem = this.colorManagementSystems.find(a => a.id === colorManagementSystemId);
    } else {
      this.colorManagementSystem = null;
    }
  }

  selectPressType(pressType: string): void {
    const pressTypes = this.characterizationValues.find(a => a.characterizationType === CharacterizationType.PressType);
    if (pressType && pressTypes && pressTypes.values.length > 0) {
      this.jobCharacterizationValues.pressType = pressTypes.values.find(a => a === pressType);
    } else {
      this.jobCharacterizationValues.pressType = null;
    }
  }

  selectSpeed(speed: string): void {
    const speedDetails = this.characterizationValues.find(a => a.characterizationType === CharacterizationType.Speed);
    if (speed && speedDetails && speedDetails.values.length > 0) {
      this.jobCharacterizationValues.speed = speedDetails.values.find(a => a === speed);
    } else {
      this.jobCharacterizationValues.speed = null;
    }
  }

  selectOverPrintVarnish(overPrintVarnish: string): void {
    const overPrintVarnishDetails = this.characterizationValues.find(a => a.characterizationType === CharacterizationType.OverPrintVarnish);
    if (overPrintVarnish && overPrintVarnishDetails && overPrintVarnishDetails.values.length > 0) {
      this.jobCharacterizationValues.overPrintVarnish = overPrintVarnishDetails.values.find(a => a === overPrintVarnish);
    } else {
      this.jobCharacterizationValues.overPrintVarnish = null;
    }
  }

  selectSubstrate(substrate: string): void {
    const substrateDetails = this.characterizationValues.find(a => a.characterizationType === CharacterizationType.SubstrateWeight);
    if (substrate && substrateDetails && substrateDetails.values.length > 0) {
      this.jobCharacterizationValues.substrate = substrateDetails.values.find(a => a === substrate);
    } else {
      this.jobCharacterizationValues.substrate = null;
    }
  }

  selectInkColors(inkColors: string): void {
    const inkColorDetails = this.characterizationValues.find(a => a.characterizationType === CharacterizationType.InkColors);
    if (inkColors && inkColorDetails && inkColorDetails.values.length > 0) {
      this.jobCharacterizationValues.inkColors = inkColorDetails.values.find(a => a === inkColors);
    } else {
      this.jobCharacterizationValues.inkColors = null;
    }
  }

  selectPrintMode(printMode: string): void {
    const printModeDetails = this.characterizationValues.find(a => a.characterizationType === CharacterizationType.PrintMode);
    if (printMode && printModeDetails && printModeDetails.values.length > 0) {
      this.jobCharacterizationValues.printMode = printModeDetails.values.find(a => a === printMode);
    } else {
      this.jobCharacterizationValues.printMode = null;
    }
  }

  setJobCharacterizationValues(jobCharacterizationValues: MlpaJobCharacterizationValue) {
    if (!this.jobCharacterizationValues.speed || this.jobCharacterizationValues.speed === '') {
      this.selectSpeed(jobCharacterizationValues.speed);
    }
    if (!this.jobCharacterizationValues.substrate || this.jobCharacterizationValues.substrate === '') {
      this.selectSubstrate(jobCharacterizationValues.substrate);
    }
    if (!this.jobCharacterizationValues.overPrintVarnish || this.jobCharacterizationValues.overPrintVarnish === '') {
      this.selectOverPrintVarnish(jobCharacterizationValues.overPrintVarnish);
    }
    if (!this.jobCharacterizationValues.pressType || this.jobCharacterizationValues.pressType === '') {
      this.selectPressType(jobCharacterizationValues.pressType);
    }
    if (!this.jobCharacterizationValues.printMode || this.jobCharacterizationValues.printMode === '') {
      this.selectPrintMode(jobCharacterizationValues.printMode);
    }
    if (!this.jobCharacterizationValues.inkColors || this.jobCharacterizationValues.inkColors === '') {
      this.selectInkColors(jobCharacterizationValues.inkColors);
    }
  }

  validateCharacterizationValues(characterizationValues: MlpaCharacterizationValue): boolean {
    return (characterizationValues.inkColors?.length > 0 &&
      characterizationValues.inkWeight?.length > 0 &&
      characterizationValues.substrate?.length > 0 &&
      characterizationValues.pressType?.length > 0 &&
      characterizationValues.printMode?.length > 0 &&
      characterizationValues.overPrintVarnish?.length > 0 &&
      characterizationValues.speed?.length > 0 &&
      characterizationValues.totalAreaCoverage?.length > 0);
  }

  validateJobCharacterizationValues(): boolean {
    return (this.jobCharacterizationValues.inkColors?.length > 0 &&
      this.jobCharacterizationValues.substrate?.length > 0 &&
      this.jobCharacterizationValues.pressType?.length > 0 &&
      this.jobCharacterizationValues.printMode?.length > 0 &&
      this.jobCharacterizationValues.overPrintVarnish?.length > 0 &&
      this.jobCharacterizationValues.speed?.length > 0);
  }

  clearJobCharacterizationValues() {
    this.jobCharacterizationValues.speed = '';
    this.jobCharacterizationValues.substrate = '';
    this.jobCharacterizationValues.inkColors = '';
    this.jobCharacterizationValues.overPrintVarnish = '';
    this.jobCharacterizationValues.pressType = '';
    this.jobCharacterizationValues.printMode = '';
  }

}
