<ng-container>
  <div *ngIf="isValidating" style="height: 25px; width:25px;" class="loading"></div>
</ng-container>
<ng-container *ngIf="mlpaJobActionOptions?.showSubmit && !mlpaJobView?.isNewJob && !mlpaJobView?.isSpecial">
  <button *ngIf="mlpaJobView?.isEligibleForSubmit && !mlpaJobActionOptions?.editMode"
    (click)="preSubmit.emit(mlpaJobView)" title="Submit"
    class="button button--green button--small button-fab presubmit-detail__button-ga">
    <span class="material-icons">&#xE2C6;</span>
  </button>
</ng-container>
<ng-container *ngIf="mlpaJobView?.isNewJob && mlpaJobActionOptions?.isMlpaPage">
  <button [disabled]="!isValidForSave() && mlpaJobView?.isNewJob"
    *ngIf="mlpaJobView?.isNewJob && !mlpaJobActionOptions?.editMode && !mlpaJobView.isColorCorrectionEnabled" (click)="preSubmit.emit(mlpaJobView)" title="Save"
    class="button button--green button--small button-fab">
    <span class="material-icons">&#xE161;</span>
  </button>
  <button [disabled]="!isValidForPreSubmitWithCharValues() && mlpaJobView?.isNewJob"
    *ngIf="mlpaJobView?.isNewJob && !mlpaJobActionOptions?.editMode && mlpaJobView.isColorCorrectionEnabled" (click)="preSubmit.emit(mlpaJobView)" title="Save"
    class="button button--green button--small button-fab">
    <span class="material-icons">&#xE161;</span>
  </button>
</ng-container>
<ng-container *ngIf="mlpaJobActionOptions?.showEdit">
  <ng-container *pkgHasRole="[role.Structure] and mlpaJobActionOptions?.isSpecial">
    <button *ngIf="mlpaJobView?.isEligibleForEdit && !mlpaJobActionOptions?.editMode" (click)="edit.emit(mlpaJobView)"
      title="Edit" class="button button--small button-fab edit-detail__button-ga">
      <span class="material-icons">&#xE3C9;</span>
    </button>
    <button [disabled]="!isValidForSave()" *ngIf="mlpaJobView?.isEligibleForEdit && mlpaJobActionOptions?.editMode && !mlpaJobView.showJobCharacterizationValues"
      (click)="save.emit(mlpaJobView)" title="Lock Changes" class="button button--small button-fab">
      <span class="material-icons">&#xE897;</span>
    </button>
    <button [disabled]="!isValidForSaveWithCharValues()" *ngIf="mlpaJobView?.isEligibleForEdit && mlpaJobActionOptions?.editMode && mlpaJobView.showJobCharacterizationValues"
    (click)="save.emit(mlpaJobView)" title="Lock Changes" class="button button--small button-fab">
    <span class="material-icons">&#xE897;</span>
  </button>
  </ng-container>
  <ng-container *pkgHasRole="[role.Support, role.Admin, role.Operator, role.Scheduler]">
    <button *ngIf="mlpaJobView?.isEligibleForEdit && !mlpaJobActionOptions?.editMode" (click)="edit.emit(mlpaJobView)"
      title="Edit" class="button button--small button-fab edit-detail__button-ga">
      <span class="material-icons">&#xE3C9;</span>
    </button>
    <button [disabled]="!isValidForSave()" *ngIf="mlpaJobView?.isEligibleForEdit && mlpaJobActionOptions?.editMode && !mlpaJobView.showJobCharacterizationValues"
      (click)="save.emit(mlpaJobView)" title="Lock Changes" class="button button--small button-fab">
      <span class="material-icons">&#xE897;</span>
    </button>
    <button [disabled]="!isValidForSaveWithCharValues()" *ngIf="mlpaJobView?.isEligibleForEdit && mlpaJobActionOptions?.editMode && mlpaJobView.showJobCharacterizationValues"
      (click)="save.emit(mlpaJobView)" title="Lock Changes" class="button button--small button-fab">
      <span class="material-icons">&#xE897;</span>
    </button>
  </ng-container>
</ng-container>

<ng-container
  *pkgHasRole="[role.Admin, role.Support, role.Scheduler, role.Operator, role.Structure]; and mlpaJobActionOptions?.isMlpaPage && !mlpaJobActionOptions?.showEdit && !mlpaJobView?.isSpecial">
  <button *ngIf="!mlpaJobActionOptions?.editMode" (click)="edit.emit(mlpaJobView)" title="Edit"
    class="button button--small button-fab">
    <span class="material-icons">&#xE3C9;</span>
  </button>
  <button [disabled]="!isValidForSave()" *ngIf="mlpaJobActionOptions?.editMode && !mlpaJobView.showJobCharacterizationValues" (click)="save.emit(mlpaJobView)"
    title="Lock Changes" class="button button--small button-fab">
    <span class="material-icons">&#xE897;</span>
  </button>
  <button [disabled]="!isValidForSaveWithCharValues()" *ngIf="mlpaJobActionOptions?.editMode && mlpaJobView.showJobCharacterizationValues" (click)="save.emit(mlpaJobView)"
    title="Lock Changes" class="button button--small button-fab">
    <span class="material-icons">&#xE897;</span>
  </button>
</ng-container>

<ng-container *ngIf="!mlpaJobView?.isNewJob && !mlpaJobView?.isSpecial">
  <button (click)="preClone.emit(mlpaJobView)" title="Clone"
    class="button button--small button-fab clone-detail__button-ga">
    <span class="material-icons">&#xE173;</span>
  </button>
</ng-container>
<ng-container *ngIf="mlpaJobActionOptions?.showDelete">
  <ng-container *pkgHasRole="[role.Support, role.Admin, role.Operator, role.Scheduler]">
    <button *ngIf="mlpaJobView?.isEligibleForDelete && !mlpaJobActionOptions?.editMode && mlpaJobView?.id"
      (click)="preDelete.emit(mlpaJobView)" title="Delete Job"
      class="button button--red button--small button-fab delete-detail__button-ga">
      <span class="material-icons">&#xE92B;</span>
    </button>
    <button
      *ngIf="mlpaJobView?.isEligibleForEdit && !mlpaJobActionOptions?.editMode && !mlpaJobView?.id && !mlpaJobActionOptions?.isClonePage"
      (click)="cancelNewJob.emit(mlpaJobView)" title="Delete Created Job"
      class="button button--red button--small button-fab">
      <span class="material-icons">&#xE92B;</span>
    </button>
    <button
      *ngIf="mlpaJobView?.isEligibleForEdit && !mlpaJobActionOptions?.editMode && !mlpaJobView?.id && mlpaJobActionOptions?.isClonePage"
      (click)="cancelNewJob.emit(mlpaJobView)" title="Cancel Clone Edit"
      class="button button--red button--small button-fab">
      <span class="material-icons">&#xE92B;</span>
    </button>
    <button *ngIf="mlpaJobView?.isEligibleForEdit && mlpaJobActionOptions?.editMode" (click)="preCancelEdit.emit()"
      title="Cancel" class="button button--red button--small button-fab">
      <span class="material-icons">&#xE5CD;</span>
    </button>
  </ng-container>
  <ng-container *pkgHasRole="[role.Structure] and mlpaJobActionOptions?.isSpecial">
    <button *ngIf="mlpaJobView?.isEligibleForDelete && !mlpaJobActionOptions?.editMode && mlpaJobView?.id"
      (click)="preDelete.emit(mlpaJobView)" title="Delete Job"
      class="button button--red button--small button-fab delete-detail__button-ga">
      <span class="material-icons">&#xE92B;</span>
    </button>
    <button
      *ngIf="mlpaJobView?.isEligibleForEdit && !mlpaJobActionOptions?.editMode && !mlpaJobView?.id && !mlpaJobActionOptions?.isClonePage"
      (click)="cancelNewJob.emit(mlpaJobView)" title="Delete Created Job"
      class="button button--red button--small button-fab">
      <span class="material-icons">&#xE92B;</span>
    </button>
    <button
      *ngIf="mlpaJobView?.isEligibleForEdit && !mlpaJobActionOptions?.editMode && !mlpaJobView?.id && mlpaJobActionOptions?.isClonePage"
      (click)="cancelNewJob.emit(mlpaJobView)" title="Cancel Clone Edit"
      class="button button--red button--small button-fab">
      <span class="material-icons">&#xE92B;</span>
    </button>
    <button *ngIf="mlpaJobView?.isEligibleForEdit && mlpaJobActionOptions?.editMode" (click)="preCancelEdit.emit()"
      title="Cancel" class="button button--red button--small button-fab">
      <span class="material-icons">&#xE5CD;</span>
    </button>
  </ng-container>
</ng-container>
<button class="button button--muted-lightBlue button--small button-fab" [disabled]="mlpaJobView?.isCurrentPreviewButton"
  (click)="setPreview.emit(mlpaJobView)" title="Preview">
  <span class="material-icons order-icon">&#xE43F;</span>
</button>