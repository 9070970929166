import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { User } from './user';
import { Role } from './role';
import { UserAsset } from 'src/app/shared/mlpa-jobs-search/user-asset';

@Injectable()
export class UserService {
  constructor(private readonly http: HttpClient) { }

  search(term: string): Observable<User[]> {
    const params = new HttpParams().set('term', term);
    return this.http.get<User[]>(`${environment.tartanApiUrl}users/search`, { params });
  }
  getAll(): Observable<User[]> {
    return this.http.get<User[]>(`${environment.tartanApiUrl}users`);
  }
  create(emailAddress: string, role: Role, assetIds: string[]): Observable<User> {
    const body = { emailAddress, role, assetIds };
    return this.http.post<User>(`${environment.tartanApiUrl}users`, body);
  }
  edit(id: string, role: Role, assetIds: string[]): Observable<User> {
    const body = { id, role, assetIds };
    return this.http.put<User>(`${environment.tartanApiUrl}users/${id}`, body);
  }
  delete(id: string): Observable<any> {
    return this.http.delete(`${environment.tartanApiUrl}users/${id}`);
  }
  getUserAssets(): Observable<UserAsset[]> {
    return this.http.get<UserAsset[]>(`${environment.tartanApiUrl}users/assets`);
  }
}
