<div class="sticky">
  <div class="page__header header--dashboard">
    <pkg-mlpa-jobs-search [showAssetsFilter]="true" (mlpaJobsEventEmitter)="setMlpaJobs($event)"
      (termSearchedEmitter)="setSearchedTerm($event)"></pkg-mlpa-jobs-search>
  </div>
  <div class="page__content">
    <div class="job-container" [class.collapsed]="!!selectedJob" [ngClass]="{'details-full' : detailsIsFullScreen}">
      <div #jobContainer class="mlpa-jobs-picker-container" pkgInfinite (loadData)="loadMoreJobs()">
        <pkg-view-mlpa-jobs [class.collapsed]="!!selectedJob" (deleteEmitter)="refreshJobs()"
          (selectedJobEmitter)="setSelectedJob($event)" [selectedJob]="selectedJob" [mlpaJobs]="mlpaJobs">
        </pkg-view-mlpa-jobs>
        <button *ngIf="showLoadMore && mlpaJobs?.length > 0" [disabled]="loading || hasLoadedAllJobs"
          [style.margin]='"auto"' class="button button--primary" (click)="loadMoreJobs()">
          <span>Load more jobs</span>
          <span *ngIf="loading" class="loading"></span>
        </button>
      </div>
      <div *ngIf="selectedJob" class="show-details-full" (click)="toggleDetailsFull()">
        <button *ngIf="!detailsIsFullScreen" class="button button--flat button--primary"
          title="Expand detail view"><span class="material-icons">&#xE5D0;</span></button>
        <button *ngIf="detailsIsFullScreen" class="button button--flat button--primary" title="Expand detail view"><span
            class="material-icons">&#xE5D1;</span></button>
      </div>
      <div *ngIf="selectedJob" class="hide-details" (click)="selectedJob = null; detailsIsFullScreen = false">
        <button class="button button--flat button--primary" title="Hide detail view"><span
            class="material-icons">&#xE5CC;</span></button>
      </div>
    </div>
    <div *ngIf="selectedJob && foundJobs" class="job-details-container" #detailContainer>
      <div class="mlpa-job-summary">
        <pkg-mlpa-job-ruler [jobSettings]="getJobSettings(mlpaJob)" [mlpaLanes]="mlpaJob.mlpaLanes"
          [totalWidthInInches]="getTotalWidthInInches(mlpaJob)"></pkg-mlpa-job-ruler>
      </div>
      <pkg-mlpa-job-details [jobSettings]="getJobSettings(mlpaJob)" [colorManagementSystemSettings]="getColorManagementSystemSettings(mlpaJob)"
        (deleteEmitter)="refreshJobs()" [isSpecial]="selectedJob?.isSpecial" [mlpaJobView]="selectedJob"
        (setPreviewEmitter)="scrollToTop(detailContainer)"></pkg-mlpa-job-details>

      <ng-container *ngTemplateOutlet="linkedTemplate; context:{ linkedJobs: selectedJob?.linkedMlpaJobs }">
      </ng-container>

      <ng-template #linkedTemplate let-linkedJobs="linkedJobs">
        <ng-container *ngFor="let item of linkedJobs">
          <div class="mlpa-job-summary">
            <pkg-mlpa-job-ruler [jobSettings]="getJobSettings(item)" [mlpaLanes]="item?.mlpaLanes"
              [totalWidthInInches]="getTotalWidthInInches(item)"></pkg-mlpa-job-ruler>
          </div>
          <pkg-mlpa-job-details [jobSettings]="getJobSettings(item)" [colorManagementSystemSettings]="getColorManagementSystemSettings(item)" [mlpaJobView]="item"></pkg-mlpa-job-details>
          <ng-container *ngIf="item?.linkedMlpaJobs.length > 0">
            <ng-container *ngTemplateOutlet="linkedTemplate; context:{ linkedJobs: item?.linkedMlpaJobs }">
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-template>
    </div>
  </div>
  <div *ngIf="!foundJobs" [class.collapsed]="!!selectedJob" class="no-mlpa-message">
    <span class="material-icons">&#xE645;</span>
    <div class="mlpa-message">
      <div class="mlpa-message__title">No Results</div>
      <div>No MLPA jobs were found that match the search criteria.</div>
    </div>
  </div>
  <pkg-loader *ngIf="mlpaJobs?.length === 0 && loading"></pkg-loader>
</div>