export class DataLoggerAsset {
  id: string;
  name: string;
  audience: string;
  isEnabled: boolean;
  type: DataLoggerType;
  isInTestMode: boolean;
  createdDate: Date;
  createdBy: string;
  updatedBy: string;
  updatedDate: Date;

  constructor(id = null, name = null) {
    this.id = id;
    this.name = name;
  }

  buildForSave(): DataLoggerPayload {
    const { name, audience, type, isEnabled, isInTestMode } = this;

    return { name, audience, type, isEnabled, isInTestMode };
  }
}

export interface DataLoggerPayload {
  name: string;
  audience: string;
  type: DataLoggerType;
  isEnabled: boolean;
  isInTestMode: boolean;
}

export enum DataLoggerType {
  Unknown,
  SmartLine,
}

