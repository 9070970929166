import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { PagedResult } from '../paged-result';
import { MlpaJobApi } from '../mlpa-job-api';
import { SearchFilter } from './search-filter.interface';

@Injectable({
  providedIn: 'root'
})
export class MlpaJobsSearchService {
  searchFilterSubject$ = new BehaviorSubject<SearchFilter>(null);
  readonly searchFilter$: Observable<SearchFilter> = this.searchFilterSubject$.asObservable();

  constructor(private readonly _http: HttpClient) { }

  searchMlpaJob(term, pageNumber: number, pageSize: number,
    filter: SearchFilter = null): Observable<PagedResult<MlpaJobApi>> {
    let params = new HttpParams()
      .set('pageNumber', `${pageNumber}`)
      .set('pageSize', `${pageSize}`);


    if (term != null) {
      params = params.set('term', `${term}`);
    }

    if (filter != null) {
      const { status, jobProperties, orderBy, sortBy, assetIds } = filter;
      params = params.set('orderBy', `${orderBy}`);
      params = params.set('sortBy', `${sortBy}`);

      if (status > 0) {
        params = params.set('status', `${status}`);
      }

      if (jobProperties > 0) {
        params = params.set('jobProperties', `${jobProperties}`);
      }

      if (assetIds.length) {
        assetIds.forEach((value, index) => params = params.append('assetIds', value));
      }
    }

    return this._http.get<PagedResult<MlpaJobApi>>(`${environment.tartanApiUrl}mlpa-jobs/search/`, { params });
  }
}
