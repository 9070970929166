import { Directive, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[pkgAfterIf]'
})
export class AfterIfDirective {
  @Output('pkgAfterIf') public init = new EventEmitter<any>();

  constructor() {
    setTimeout(() => {
      this.init.emit(1);
    });
  }
}
