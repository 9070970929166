import { Component, OnInit, OnDestroy } from '@angular/core';
import { PrintProcessor } from '@shared/print-processor.model';
import { AlertsService } from '@shared/alerts/alerts.service';
import { Subject, Subscription, interval } from 'rxjs';
import { takeUntil, startWith } from 'rxjs/operators';
import { AlertType } from '@shared/alerts/alert-type.enum';
import { Router } from '@angular/router';
import { PrintProcessorService } from '@app/pages/admin/shared/print-processor.service';

@Component({
  selector: 'pkg-print-processor-list',
  templateUrl: './print-processor-list.component.html',
  styleUrls: ['./print-processor-list.component.scss']
})
export class PrintProcessorListComponent implements OnInit, OnDestroy {

  printProcessors: PrintProcessor[] = [];
  printProcessorLoading = true;

  timedSubscription: Subscription;
  private unsubscribe$ = new Subject();

  constructor(
    private readonly printProcessorService: PrintProcessorService,
    private readonly router: Router,
    private readonly alertService: AlertsService) { }

  ngOnInit(): void {
    this.printProcessorService.getAll().pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.printProcessors = data.results;
     this.timedSubscription = interval(120000).pipe(startWith(0)).subscribe(() => this.checkHealthStatus());
      this.printProcessorLoading = false;
    },
      (err) => {
        this.alertService.add('Failed to load print processor', 'Please try again as we could not get any print processor', AlertType.Error);
        this.printProcessorLoading = false;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.timedSubscription) {
      this.timedSubscription.unsubscribe();
    }
  }

  testModeClass(printProcessor: PrintProcessor): string {
    if (printProcessor.isInTestMode) {
      return 'test-mode--enabled';
    }
    return 'test-mode--disabled';
  }

  editPrintProcessor(printProcessor: PrintProcessor) {
    this.router.navigate([`admin/printprocessor/${printProcessor.id}`]);
  }

  createPrintProcessor() {
    this.router.navigate([`admin/printprocessor/add`]);
  }

  checkHealthStatus(): void {
    if (this.printProcessors.length === 0) {
      return;
    }

    this.printProcessors.forEach(printProcessor => {
      this.printProcessorService.getHealth(printProcessor.id).pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
        printProcessor.healthStatus = data;
      });
    });
  }

}
