import { DesignType } from '../design-type.enum';
import { MlpaLaneApi } from '@shared/mlpa-lane-api';
import { MlpaLane } from '../mlpa-lane';
import { LaneProperties } from './lane-properties.interface';
import { deepCopyProperties } from '@shared/pkg-helper';
import { MlpaLaneType } from '../mlpa-lane-type.enum';

export class QrCode extends MlpaLane {
  constructor(laneProperties: LaneProperties, mlpaLaneApi?: MlpaLaneApi) {
    super(mlpaLaneApi);
    // loop through properties to perform a deep copy of properties
    deepCopyProperties(this, laneProperties.initialProperties);

    // if the isClone property is null/undefined or false
    if (laneProperties.initialProperties.isClone == null || laneProperties.initialProperties.isClone === false) {
      this.setInitialLaneProperties(laneProperties.initialProperties, DesignType.QRCODE);

      // override values.
      this.designLengthInInches = 24;
      this.quantity = 1;
      this.isTransposed = false;
    }

    // Set the lane type
    this.laneType = MlpaLaneType.QrCode;
    this.colorCorrectionInfo.isColorCorrectionEnabled = false;
  }
  get totalWidthInInches(): number {
    return this.designWidthInInches * this.numberOfRibbons;
  }
}
