import { LaneProperties } from './lane-properties.interface';
import { MlpaLane } from '../mlpa-lane';
import { DesignType } from '../design-type.enum';
import { MlpaLaneApi } from '@shared/mlpa-lane-api';
import { deepCopyProperties } from '@shared/pkg-helper';

export class WebWeave extends MlpaLane {
  // This field should be pulled from database in future.
  private readonly _designWidthInInches = 0.64370078740157480; // 5mm + 0.25" + 5mm
  constructor(laneProperties: LaneProperties, mlpaLaneApi?: MlpaLaneApi) {
    super(mlpaLaneApi);
    // loop through properties to perform a deep copy of properties
    deepCopyProperties(this, laneProperties.initialProperties);
    laneProperties.initialProperties.designWidthInInches = this._designWidthInInches;
    this.setInitialLaneProperties(laneProperties.initialProperties, DesignType.WEBWEAVE);
    this.isTransposed = false;
    this.originalNumberOfPages = this.designNumberOfPages;
    this.calculateNumberOfPages(laneProperties);

    // order matters heres, calculate numberups first then linebreak.
    this.calculateNumberUps(laneProperties);
    this.calculateEachesFromLineBreak(laneProperties);
    this.colorCorrectionInfo.isColorCorrectionEnabled = false;
  }

  calculateEachesFromLineBreak(laneProperties: LaneProperties): void {
    const lineBreakInFeet = laneProperties.initialProperties.lineBreakInFeet;
    if (lineBreakInFeet > 0) {
      const designLengthInFeet = this.designLengthInInches / 12;

      // logic to handle multipage for webweaves
      if (this.designNumberOfPages > 1) {
        // set the quantity to be 1 since its multipage
        this.quantity = 1;
        this.designNumberOfPages = this.designNumberOfPages + Math.ceil(lineBreakInFeet / designLengthInFeet);
      } else {
        this.quantity = this.quantity + Math.ceil(lineBreakInFeet / designLengthInFeet);
      }
    }
  }

  calculateNumberUps(laneProperties: LaneProperties): void {
    if (laneProperties?.nearestLane?.designNumberUp > 0) {
      this.quantity = this.quantity / (laneProperties?.nearestLane?.designNumberUp * laneProperties.nearestLane?.numberOfRibbons);
    }
  }

  get totalWidthInInches(): number {
    return this.calculatedTotalWidthInInches;
  }

}
