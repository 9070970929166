<div *ngIf="isIE && showMessage" class="compatibility">
  <div class="compatibility__heading center">
    <span class="heading__item material-icons">&#xE002;</span>
    <span class="heading__item--title "> Unsupported Browser </span>
    <span class="heading__item material-icons">&#xE002;</span>
  </div>

  <p class="compatibility__message center">
    Tartan probably won't work great in this browser.
  </p>
  <p class="compatibility__message center">
    We generally only support the recent versions of major browsers like:
  </p>
  <p class="compatibility__message center">Chrome, Firefox, Safari, and Edge</p>

  <button class="compatibility__button button" (click)="close()"> Dismiss & Try Anyway </button>
</div>
