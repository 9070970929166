import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { PagedResult } from '../../../shared/paged-result';
import { environment } from '../../../../environments/environment';
import { ColorManagementSystem } from '@shared/color-management-system';
import { StatusResults } from '@shared/status-page/status-results';

@Injectable({
  providedIn: 'root'
})
export class ColorManagementSystemService {

  constructor(private readonly http: HttpClient) { }

  getAll(): Observable<PagedResult<ColorManagementSystem>> {
    return this.http.get<PagedResult<ColorManagementSystem>>(`${environment.tartanApiUrl}color-management`);
  }

  getHealth(colorManagementId: string): Observable<StatusResults> {
    return this.http.get<StatusResults>(`${environment.tartanApiUrl}color-management/${colorManagementId}/health`);
  }

  get(colorManagementId: string): Observable<ColorManagementSystem> {
    return this.http.get<ColorManagementSystem>(`${environment.tartanApiUrl}color-management/${colorManagementId}`);
  }

  edit(colorManagementSystem: ColorManagementSystem): Observable<ColorManagementSystem> {
    return this.http.put<ColorManagementSystem>(`${environment.tartanApiUrl}color-management/${colorManagementSystem.id}`, colorManagementSystem);
  }

  add(colorManagementSystem: ColorManagementSystem): Observable<ColorManagementSystem> {
    return this.http.post<ColorManagementSystem>(`${environment.tartanApiUrl}color-management`, colorManagementSystem);
  }

  delete(id: string) {
    return this.http.delete(`${environment.tartanApiUrl}color-management/${id}`);
  }
}
