import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { withLatestFrom, takeUntil, switchMap } from 'rxjs/operators';
import { MlpaService } from '../mlpa/shared/mlpa.service';
import { MlpaJobView } from '../home/view-mlpa-jobs/mlpa-job-view';
import { Subject } from 'rxjs';
import { MlpaJob } from '../../mlpa/mlpa-job/mlpa-job';
import { AlertsService } from '@shared/alerts/alerts.service';
import { MlpaJobSettings } from '../../mlpa/mlpa-job-settings/mlpa-job-settings';
import { MlpaJobOptionsService } from '@shared/mlpa-job-options/mlpa-job-options.service';
import { AlertType } from '@shared/alerts/alert-type.enum';
import { Preview } from '@shared/lane-preview/preview.interface';

@Component({
  selector: 'pkg-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent implements OnInit, OnDestroy {
  mlpaJobView: MlpaJobView;
  mlpaJob: MlpaJob;
  jobSettings: MlpaJobSettings;
  source: Preview;
  loading = true;
  private _unsubscribe$ = new Subject();
  constructor(private readonly _route: ActivatedRoute,
    private readonly _mlpaService: MlpaService,
    private readonly _alerts: AlertsService,
    private readonly _mlpaJobOptionsService: MlpaJobOptionsService) { }

  ngOnInit(): void {
    this._route.paramMap.pipe(withLatestFrom(this._route.queryParamMap)).subscribe(params => {
      const mlpaJobId = params[0].get('id');
      if (mlpaJobId != null && mlpaJobId !== '') {
        this._setUpPrintJob(mlpaJobId);
      }
    }, (err) => {
      this._alerts.add('Failed to load job for print.', 'Unable to setup print job', AlertType.Error);
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
  private _setUpPrintJob(id: string): void {
    this._mlpaService.getMlpaJob(id).pipe(takeUntil(this._unsubscribe$), switchMap((data) => {
      this.mlpaJobView = new MlpaJobView(data);
      this.mlpaJob = new MlpaJob(data);
      return this._mlpaJobOptionsService.getMlpaJobOptions(false);
    })).subscribe((mlpaJobOptionAssets) => {
      this.jobSettings = new MlpaJobSettings(mlpaJobOptionAssets);
      this.jobSettings.selectAsset(this.mlpaJob.assetName, this.mlpaJob.paperTypeName, this.mlpaJob.paperWidthInInches);

      this.source = {
        id: this.mlpaJobView.id,
        previousPreview: null,
        preview: this.mlpaJobView.preview,
        width: 100,
        height : 100,
        designDescription : '',
        designNumber : this.mlpaJobView.designNumber,
        shouldFlash: true
      };
      this.loading = false;
    }, (err) => {
      this._alerts.add('Failed to load MLPA job options', err.error.message, AlertType.Error);
    });
  }
}
