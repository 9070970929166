import { Component, OnInit, Input, AfterViewInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'pkg-progress-circle',
  templateUrl: './progress-circle.component.html',
  styleUrls: ['./progress-circle.component.scss']
})
export class ProgressCircleComponent implements OnInit, AfterViewInit {
  @Input() percent: number;
  @Input() size: number;
  @ViewChild('progressContainer') el: ElementRef;
  private circle: any;
  private outer: any;
  private text: any;
  private circumference: number;
  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.circle = this.el.nativeElement.querySelector('.progress-ring__circle');
    this.circle.r.baseVal.value = this.size;
    this.circumference = this.size * 2 * Math.PI;

    this.outer = this.el.nativeElement.querySelector('.progress-ring__outer-circle');
    this.outer.r.baseVal.value = this.size;

    this.text = this.el.nativeElement.querySelector('.progress-ring__text');

    this.initialize();
    this.setProgress(this.percent);
    this.setStroke(this.percent);
  }

  initialize() {
    this.circle.style.strokeDasharray = `${this.circumference} ${this.circumference}`;
    this.circle.style.strokeDashoffset = `${this.circumference}`;
  }

  setProgress(percent: number) {
    const offset = this.circumference - percent / 100 * this.circumference;
    this.circle.style.strokeDashoffset = offset.toString();
  }

  setStroke(percent: number) {
    if (percent <= 33) {
      this.circle.style.stroke = '#388E3C';

      // realigns the number so it is centered in the circle.
      if (percent < 10) {
        this.text.setAttribute('x', 51);
      }

      return;
    }

    if (percent <= 66 && percent >= 34) {
      this.circle.style.stroke = '#FFE300';
      return;
    }

    if (percent > 66) {
      this.circle.style.stroke = '#EB3C3C';
      this.outer.style.strokeWidth = 10;
    }

    // realigns the number so it is centered in the circle.
    if (percent > 99) {
      this.text.setAttribute('x', 45);
    }
  }
}
