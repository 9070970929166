<div class="sticky">
  <div class="page__header">
    <div class="users-search">
      <div class="user-search-container">
        <div class="add-user">
          <button (click)="createUser()" class="button">
            <i class="material-icons">&#xE7FE;</i>
            <span>Add a User</span>
          </button>
        </div>
        <div class="users-search__inner">
          <span *ngIf="!keyword || keyword == ''" class="material-icons search-icon">&#xE8B6;</span>
          <span *ngIf="(keyword && keyword !== '')" class="material-icons clear-icon" (click)="keyword=''"
            title="Clear">&#xE14C;</span>
          <input [(ngModel)]="keyword" type="text" id="search" placeholder="Search for a user...">
          <span class="result-status visible" *ngIf="(keyword && keyword !== '')">{{filteredUsers.length}}
            Results</span>
          <span class="filter-status visible" *ngIf="(keyword && keyword !== '')">filtered</span>
        </div>
      </div>
    </div>
  </div>
  <div class="page__content">
    <div class="users-container">
      <div class="user-list">
        <div class="user" *ngFor="let user of filteredUsers;let index = index">
          <pkg-card>
            <pkg-card-header>
              <div class="pkg-card-title">
                <span class="title" [title]="user?.displayName">{{user.displayName}}</span>
                <span style="margin-left: auto;" [title]="Role[user.role]" class="status-chip status-chip--text"
                  [ngClass]="roleClass(user.role)">{{Role[user.role]}}</span>
                <button class="button button--small button-fab" title="Edit" (click)="editUser(user)">
                  <span class="material-icons">&#xE3C9;</span>
                </button>
              </div>
            </pkg-card-header>
            <pkg-card-body [ngClass]="{'add-cursor':user.assetIds.length>0}"
              (click)="user.assetIds.length>0 && showAssets[index]=!showAssets[index]">
              <div class="main-details">
                <div class="image-container">
                  <div class="content">
                    <img class="user__image" [src]="user.photoUrl" alt="User Photo">
                  </div>
                </div>
                <div class="user-details-container">
                  <div class="content">
                    <div class="title">Email Address</div>
                    <div class="info" [title]="user?.emailAddress">{{user?.emailAddress}}</div>
                  </div>
                  <div class="content">
                    <div class="title">NT Login</div>
                    <div class="info">{{user.ntLogin}}</div>
                  </div>
                  <div class="content">
                    <div class="title">Phone Number</div>
                    <div class="info">{{user.phoneNumber?user.phoneNumber:'N/A'}}</div>
                  </div>
                  <div class="content">
                    <span class="status-chip status-chip--text">Assets: {{user.assetIds.length}}</span>
                  </div>
                  <div class="asset-details" [@accordion]="showAssets[index] ? 'open': 'close'"
                    [hidden]="showAssets[index]">
                    <div class="content">
                      <div class="title">Assets</div>
                      <div class="assets-container">
                        <ng-container *ngIf="user.assetNames?.length">
                          <div *ngFor="let asset of user.assetNames" class="status-chip status-chip--text"
                            [title]="asset">
                            {{asset}}</div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </pkg-card-body>
            <pkg-card-footer *ngIf="user.assetIds.length>0" (click)="showAssets[index]=!showAssets[index]">
              <div class="pkg-card-footer">
                <div class="expand-more-details">
                  <span *ngIf="!showAssets[index]" class="material-icons">&#xE5CF;</span>
                  <span *ngIf="showAssets[index]" class="material-icons">&#xE5CE;</span>
                </div>
              </div>
            </pkg-card-footer>
          </pkg-card>
        </div>
      </div>
    </div>
    <div *ngIf="!users" class="loading-message">
      <img class="logo" src="assets/small-logo-dark.svg" [ngStyle]="getLogoStyle" alt="loading logo" />
      <span class="loading"></span>
    </div>
  </div>
  <div *ngIf="(keyword && keyword !== '' && !filteredUsers.length)" class="no-users-message">
    <span class="material-icons">&#xE645;</span>
    <div class="mlpa-message">
      <div class="mlpa-message__title">No Results</div>
      <div>No Users were found that match the search criteria.</div>
    </div>
  </div>
</div>

<pkg-user-create (create)="addUserToList($event)"></pkg-user-create>
<pkg-user-edit (delete)="removeUserFromList($event)"></pkg-user-edit>
