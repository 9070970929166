export class MlpaCharacterizationValues {
  characterizationType: CharacterizationType;
  values: string[];
}

export class MlpaJobCharacterizationValue {
  speed: string;
  pressType: string;
  overPrintVarnish: string;
  substrate: string;
  inkColors: string;
  printMode: string;
}

export class DesignCharacterizationResponse {
  designNumber: string;
  substrateWeight: string;
  printMode: string;
  inkColors: string;
  inkWeight: string;
  overPrintVarnish: string;
  pressType: string;
  speed: string;
  totalAreaCoverage: string;
}

export class DesignCharValuesValidationResponse {
  designNumber: string;
  laneNumber: number;
  isValid: boolean;
}

export class MlpaCharacterizationValue extends MlpaJobCharacterizationValue {
  inkWeight: string;
  totalAreaCoverage: string;
}

export enum CharacterizationType {
  Unknown = 0,
  PressType = 10,
  SubstrateWeight = 20,
  PrintMode = 30,
  InkColors = 40,
  InkWeight = 50,
  /// <summary>
  /// OPV - Over Print Varnish
  /// </summary>
  OverPrintVarnish = 60,
  Speed = 70,
  TotalAreaCoverage = 80
}
