import { Component, OnInit, Input, Output, ChangeDetectionStrategy } from '@angular/core';
import { MlpaJobView } from '../../../../pages/home/view-mlpa-jobs/mlpa-job-view';
import { EventEmitter } from '@angular/core';
import { MlpaJob } from '../../../../mlpa/mlpa-job/mlpa-job';
import { Role } from '../../../../../app/pages/admin/shared/role';

@Component({
  selector: 'pkg-toggle-action',
  templateUrl: './toggle-action.component.html',
  styleUrls: ['./toggle-action.component.scss']
})
export class ToggleActionComponent implements OnInit {
  @Input() job: MlpaJobView;
  @Input() mlpaJob: MlpaJob | MlpaJobView;
  @Input() showActionOptions: boolean;
  @Input() showSubmit: boolean;
  @Input() showEdit: boolean;
  @Input() showDelete: boolean;
  @Output() toggleJobOptions = new EventEmitter<ToggleEvent>();
  @Output() preSubmit = new EventEmitter();
  @Output() preDelete = new EventEmitter();
  @Output() preClone = new EventEmitter();
  @Output() selectJob = new EventEmitter();
  @Output() editJob = new EventEmitter();
  @Output() scrollToJob: any = new EventEmitter();

  role = Role;
  constructor() { }

  ngOnInit(): void {
  }

  onToggleJobOptions($event: Event, job): void {
    const payload = {
      event: $event,
      job
    } as ToggleEvent;

    this.toggleJobOptions.emit(payload);
  }

  onPreSubmit(job): void {
    this.preSubmit.emit(job);
  }

  onPreDelete(job): void {
    this.preDelete.emit(job);
  }

  onPreClone(job): void {
    this.preClone.emit(job);
  }
  onSelectJob(job): void {
    this.selectJob.emit(job);
  }

  onEdit(job): void {
    this.editJob.emit(job);
  }

  onScrollToJob(event: Event, id?: string): void {
    this.scrollToJob.emit({ event, id });
  }

}

export interface ToggleEvent {
  event: Event;
  job: MlpaJob | MlpaJobView;
}
