<span class="title" *ngIf="hideStatus">{{title}}</span>
<ng-container *pkgHasRole="[role.Operator] or !editMode && !hideStatus">
  <ng-container *ngIf="!hasStressFile || isStressFile || showStressFileForJob">
    <span *ngIf="!job?.isNewJob" title="{{job?.status}} {{job?.errors}} {{job?.warnings}}" class="material-icons status-icon {{job?.statusClass}}"></span>
    <span *ngIf="job?.isNewJob" class="title" title="{{job?.name}}">{{job?.name}}</span>
    <span *ngIf="!job?.isNewJob" class="title" title="{{job?.name}}-{{job?.uniqueId}}">{{job?.name}}-{{job?.uniqueId}}</span>
  </ng-container>
  <ng-container *ngIf="hasStressFile && !isStressFile && !showStressFileForJob">
    <span *ngIf="!job?.isNewJob" title="{{job?.status}} {{job?.errors}} {{job?.warnings}}" class="material-icons status-icon {{job?.statusClass}}"></span>
    <span *ngIf="job?.isNewJob" class="title" title="{{job?.name}}">{{job?.name}}</span>
    <span *ngIf="!job?.isNewJob" class="title" title="{{job?.linkedMlpaJobs[0]?.name}}-{{job?.linkedMlpaJobs[0]?.uniqueId}}">{{job?.linkedMlpaJobs[0]?.name}}-{{job?.linkedMlpaJobs[0]?.uniqueId}}</span>
  </ng-container>

  <section [class]="'status-icons__container ' + isCollapsed">
    <span *ngIf="hasSample" class="material-icons status-chip status-chip--sample" title="Sample"></span>
    <span *ngIf="hasMockup" class="material-icons status-chip status-chip--mockup" title="Mockup"></span>
    <span *ngIf="hasDistortion" class="material-icons status-chip status-chip--distortion" title="Distortion"></span>
    <span *ngIf="job?.isSpecial" class="material-icons status-chip status-chip--weblayout" title="Web Layout"></span>
    <span *ngIf="hasStressFile" class="material-icons status-chip status-chip--chevron" title="Stress File"></span>
    <span *ngIf="hasQrCode" class="material-icons status-chip status-chip--qrcode" title="Qr Code">
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 -1 30 30" style="enable-background:new 0 -1 30 30;" xml:space="preserve">
        <use href="#qr-code"></use>
      </svg>
    </span>
    <span *ngIf="hasSmartLine" class="material-icons status-chip status-chip--smartline-job" title="Smart Line">
      <svg class="barcode-icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 10" style="enable-background:new 0 0 30 10;" xml:space="preserve">
        <use href="#smart-line"></use>
      </svg>
    </span>
    <span *ngIf="hasWebWeave" class="material-icons status-chip status-chip--solidline-job" title="Web Weave">
      <svg class="barcode-icon" version="1.1" id="web-weave" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 10" style="enable-background:new 0 0 30 10;" xml:space="preserve">
        <use href="#solid-line"></use>
      </svg>
    </span>
    <span *ngIf="isMultiPage" class="material-icons status-chip status-chip--multipage" title="Multipage"></span>
    <span *ngIf="isBlanket" class="material-icons status-chip status-chip--blanket" title="Blanket Required"></span>
    <span *ngIf="job?.isColorCorrectionEnabled" class="material-icons status-chip status-chip--format_paint_selected" title="Color Correction"></span>
    <span *ngIf="job?.isClone" class="material-icons status-chip status-chip--clone" title="Clone"></span>
    <span class="filler"></span>
  </section>
</ng-container>
