<pkg-card>
  <pkg-card-header>
    <div class="pkg-card-title">
      <span class="title">{{designDescription}}</span>
      <span *ngIf="isSample" class="material-icons status-chip status-chip--sample" title="Sample"></span>
      <span *ngIf="isMockup" class="material-icons status-chip status-chip--mockup" title="Mockup"></span>
      <span *ngIf="isDistortion" class="material-icons status-chip status-chip--distortion" title="Distortion"></span>
      <span *ngIf="designNumberOfPages > 1" class="material-icons status-chip status-chip--multipage"
        title="Multipage"></span>
      <span *ngIf="blanketNumber" class="material-icons status-chip status-chip--blanket"
        title="Blanket Required"></span>
      <span *ngIf="enableColorCorrection" class="material-icons status-chip status-chip--format_paint_selected" title="Color Correction"></span>
      <button style="margin-left: auto;" class="button button--primary button--small button-fab" (click)="add()">
        <span class="material-icons order-icon">&#xE145;</span>
      </button>
    </div>
  </pkg-card-header>
  <div pkg-card-media class="pkg-card-media">
    <pkg-lane-preview [source]="source" [print]="true" [bounds]="true" [backgroundImage]="true"
      [animateBackgroundImage]="true">
    </pkg-lane-preview>
  </div>
  <pkg-card-body *ngIf="design" (click)="toggleDetails()">
    <div class="main-details">
      <div class="content">
        <span class="title">Program Number</span>
        <span *ngIf="programNumber" class="info highlight">{{programNumber}}</span>
        <span *ngIf="!programNumber" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Setup Number</span>
        <span *ngIf="setupNumber" class="info highlight">{{setupNumber}}</span>
        <span *ngIf="!setupNumber" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Order Number</span>
        <span *ngIf="orderNumber" class="info highlight">{{orderNumber}}</span>
        <span *ngIf="!orderNumber" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Design Number</span>
        <span *ngIf="designNumber" class="info highlight">{{designNumber}}</span>
        <span *ngIf="!designNumber" class="info">N/A</span>
      </div>
    </div>
    <div class="extra-details" [@accordion]="showDetails ? 'open': 'close'" [hidden]="!showDetails">
      <div class="content">
        <span class="title">Width (in)</span>
        <span class="info">{{ designWidthInInches | inches }}</span>
      </div>
      <div class="content">
        <span class="title">Length (in)</span>
        <span class="info">{{ designLengthInInches | inches }}</span>
      </div>
      <div class="content">
        <span class="title">Required Width (in)</span>
        <span *ngIf="requiredRollWidthInInches" class="info">{{ requiredRollWidthInInches | inches }}</span>
        <span *ngIf="!requiredRollWidthInInches" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Asset</span>
        <span *ngIf="assetName" class="info">{{assetName}}</span>
        <span *ngIf="!assetName" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Customer</span>
        <span *ngIf="customerName" class="info">{{customerName}}</span>
        <span *ngIf="!customerName" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Number Up</span>
        <span class="info">{{designNumberUp}}</span>
        <span *ngIf="!designNumberUp" class="info">N/A</span>
      </div>
      <div class="content">
        <ng-container *ngIf="designNumberOfPages === 1; else multipage">
          <span class="title">Eaches</span>
          <span class="info">{{orderQuantity | number}}</span>
        </ng-container>
        <ng-template #multipage>
          <span class="title">Pages</span>
          <span class="info">{{designNumberOfPages | number}}</span>
          <span *ngIf="!designNumberOfPages" class="info">N/A</span>
        </ng-template>
      </div>
      <div class="content">
        <span class="title">Corrugator</span>
        <span *ngIf="corrugatorName" class="info">{{corrugatorName}}</span>
        <span *ngIf="!corrugatorName" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Paper Type</span>
        <span *ngIf="paperDescription" class="info">{{paperDescription}}</span>
        <span *ngIf="!paperDescription" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Flute</span>
        <span *ngIf="fluteDescription" class="info">{{fluteDescription}}</span>
        <span *ngIf="!fluteDescription" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Color Profile</span>
        <span *ngIf="colorProfileName" class="info">{{colorProfileName}}</span>
        <span *ngIf="!colorProfileName" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Product Type</span>
        <span *ngIf="productDescription" class="info">{{productDescription}}</span>
        <span *ngIf="!productDescription" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Coating</span>
        <span *ngIf="coatingDescription" class="info">{{coatingDescription}}</span>
        <span *ngIf="!coatingDescription" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Blanket Number</span>
        <span *ngIf="blanketNumber" title="{{blanketNumber}}" class="info highlight">{{blanketNumber}}</span>
        <span *ngIf="!blanketNumber" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Press Type</span>
        <span *ngIf="pressType" title="{{pressType}}" class="info highlight">{{pressType}}</span>
        <span *ngIf="!pressType" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Substrate Weight</span>
        <span *ngIf="substrateWeight" title="{{substrateWeight}}" class="info highlight">{{substrateWeight}}</span>
        <span *ngIf="!substrateWeight" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Print Mode</span>
        <span *ngIf="printMode" title="{{printMode}}" class="info highlight">{{printMode}}</span>
        <span *ngIf="!printMode" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Ink Colors</span>
        <span *ngIf="inkColors" title="{{inkColors}}" class="info highlight">{{inkColors}}</span>
        <span *ngIf="!inkColors" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Ink Weight</span>
        <span *ngIf="inkWeight" title="{{inkWeight}}" class="info highlight">{{inkWeight}}</span>
        <span *ngIf="!inkWeight" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Over Print Varnish</span>
        <span *ngIf="overPrintVarnish" title="{{overPrintVarnish}}" class="info highlight">{{overPrintVarnish}}</span>
        <span *ngIf="!overPrintVarnish" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Speed</span>
        <span *ngIf="speed" title="{{speed}}" class="info highlight">{{speed}}</span>
        <span *ngIf="!speed" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Total Area Coverage</span>
        <span *ngIf="totalAreaCoverage" title="{{totalAreaCoverage}}" class="info highlight">{{totalAreaCoverage}}</span>
        <span *ngIf="!totalAreaCoverage" class="info">N/A</span>
      </div>
    </div>
  </pkg-card-body>
  <pkg-card-body *ngIf="offlineDesign" (click)="toggleDetails()">
    <div class="main-details">
      <div class="content">
        <span class="title">Design Number</span>
        <span *ngIf="designNumber" class="info highlight">{{designNumber}}</span>
        <span *ngIf="!designNumber" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Width (in)</span>
        <span class="info">{{ designWidthInInches | inches }}</span>
      </div>
      <div class="content">
        <span class="title">Length (in)</span>
        <span class="info">{{ designLengthInInches | inches }}</span>
      </div>
      <div class="content">
        <span class="title">Asset</span>
        <span *ngIf="assetName" class="info">{{assetName}}</span>
        <span *ngIf="!assetName" class="info">N/A</span>
      </div>
    </div>
    <div class="extra-details" [@accordion]="showDetails ? 'open': 'close'" [hidden]="!showDetails">
      <div class="content">
        <span class="title">Customer</span>
        <span *ngIf="customerName" class="info">{{customerName}}</span>
        <span *ngIf="!customerName" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Number Up</span>
        <span class="info">{{designNumberUp}}</span>
        <span *ngIf="!designNumberUp" class="info">N/A</span>
      </div>
      <div class="content">
        <ng-container *ngIf="designNumberOfPages === 1; else multipage">
          <span class="title">Eaches</span>
          <span class="info">{{orderQuantity | number}}</span>
          <span *ngIf="!orderQuantity" class="info">N/A</span>
        </ng-container>
        <ng-template #multipage>
          <span class="title">Pages</span>
          <span class="info">{{designNumberOfPages | number}}</span>
          <span *ngIf="!designNumberOfPages" class="info">N/A</span>
        </ng-template>
      </div>
      <div class="content">
        <span class="title">Corrugator</span>
        <span *ngIf="corrugatorName" class="info">{{corrugatorName}}</span>
        <span *ngIf="!corrugatorName" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Paper Type</span>
        <span *ngIf="paperDescription" class="info">{{paperDescription}}</span>
        <span *ngIf="!paperDescription" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Flute</span>
        <span *ngIf="fluteDescription" class="info">{{fluteDescription}}</span>
        <span *ngIf="!fluteDescription" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Color Profile</span>
        <span *ngIf="colorProfileName" class="info">{{colorProfileName}}</span>
        <span *ngIf="!colorProfileName" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Product Type</span>
        <span *ngIf="productDescription" class="info">{{productDescription}}</span>
        <span *ngIf="!productDescription" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Coating</span>
        <span *ngIf="coatingDescription" class="info">{{coatingDescription}}</span>
        <span *ngIf="!coatingDescription" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Blanket Number</span>
        <span *ngIf="blanketNumber" title="{{blanketNumber}}" class="info highlight">{{blanketNumber}}</span>
        <span *ngIf="!blanketNumber" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Press Type</span>
        <span *ngIf="pressType" title="{{pressType}}" class="info highlight">{{pressType}}</span>
        <span *ngIf="!pressType" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Substrate Weight</span>
        <span *ngIf="substrateWeight" title="{{substrateWeight}}" class="info highlight">{{substrateWeight}}</span>
        <span *ngIf="!substrateWeight" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Print Mode</span>
        <span *ngIf="printMode" title="{{printMode}}" class="info highlight">{{printMode}}</span>
        <span *ngIf="!printMode" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Ink Colors</span>
        <span *ngIf="inkColors" title="{{inkColors}}" class="info highlight">{{inkColors}}</span>
        <span *ngIf="!inkColors" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Ink Weight</span>
        <span *ngIf="inkWeight" title="{{inkWeight}}" class="info highlight">{{inkWeight}}</span>
        <span *ngIf="!inkWeight" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Over Print Varnish</span>
        <span *ngIf="overPrintVarnish" title="{{overPrintVarnish}}" class="info highlight">{{overPrintVarnish}}</span>
        <span *ngIf="!overPrintVarnish" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Speed</span>
        <span *ngIf="speed" title="{{speed}}" class="info highlight">{{speed}}</span>
        <span *ngIf="!speed" class="info">N/A</span>
      </div>
      <div class="content">
        <span class="title">Total Area Coverage</span>
        <span *ngIf="totalAreaCoverage" title="{{totalAreaCoverage}}" class="info highlight">{{totalAreaCoverage}}</span>
        <span *ngIf="!totalAreaCoverage" class="info">N/A</span>
      </div>
    </div>
  </pkg-card-body>
  <pkg-card-footer (click)="toggleDetails()">
    <div class="pkg-card-footer">
      <div class="expand-more-details">
        <span *ngIf="!showDetails" class="material-icons">&#xE5CF;</span>
        <span *ngIf="showDetails" class="material-icons">&#xE5CE;</span>
      </div>
    </div>
  </pkg-card-footer>
</pkg-card>
