import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { SearchAutoComplete } from '../../search-auto-complete/search-auto-complete';
import { ProductDesignSearchService } from './product-design-search.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { SearchType } from '../../search-auto-complete/search-type.enum';
import { AuthService } from '../../auth/auth.service';
import { Role } from '../../pages/admin/shared/role';
import { AssetType } from '@shared/asset-type.enum';

@Component({
  selector: 'pkg-product-design-search',
  templateUrl: './product-design-search.component.html',
  styleUrls: ['./product-design-search.component.scss'],
  animations: [
    trigger('accordion', [
      state('close', style({
        opacity: '0',
        overflow: 'hidden',
        height: '0px',
      })),
      state('open', style({
        height: '*',
      })),
      transition('close <=> open', animate('200ms ease-out')),
    ])
  ]
})
export class ProductDesignSearchComponent implements OnInit {
  @Output() search = new EventEmitter<{ pageNumber: number; isKiwi: boolean }>();
  @Input() assetType: AssetType;
  autoCompleteDetails = new Array<SearchAutoComplete>();
  nonKiwiSearchDetails: SearchAutoComplete;
  maxDueDate: Date;
  maxWidth: number;
  showAdvanced = false;
  showKiwiSearch = false;
  showAdvancedState = 'closed';
  showSearch = true;
  role = Role;
  AssetTypes = AssetType;

  constructor(private readonly _productDesignSearch: ProductDesignSearchService, private readonly _authService: AuthService) { }
  ngOnInit(): void {
    if (this._authService.user.role === Role.Structure) {
      this.showKiwiSearch = true;
    }

    this._generateAutoCompleteDetails();
  }

  onSearch(): void {
    this.showSearch = false;
    this._sendSearchEvent();
  }
  editSearch(): void {
    this.showSearch = true;
  }
  clear(): void {
    this.autoCompleteDetails.forEach(a => a.clear());
    this.nonKiwiSearchDetails.clear();
    this.maxDueDate = null;
    this.maxWidth = null;
  }
  removeSearchCriteria(details: SearchAutoComplete, $event: Event): void {
    details.clear();
    this._checkOtherCriteriaAndSearch();
    $event.stopPropagation();
  }
  removeDueDateCriteria($event: Event): void {
    this.maxDueDate = null;
    this._checkOtherCriteriaAndSearch();
    $event.stopPropagation();
  }
  removeWidthCriteria($event: Event): void {
    this.maxWidth = null;
    this._checkOtherCriteriaAndSearch();
    $event.stopPropagation();
  }
  closeSearch(): void {
    this.showSearch = false;
  }

  toggleAdvanced(): void {
    this.showAdvanced = !this.showAdvanced;
    this.showAdvancedState = this.showAdvancedState === 'close' ? 'open' : 'close';
  }

  toggleKiwiSearch(): void {
    this.showKiwiSearch = !this.showKiwiSearch;
    this.showAdvanced = false;
    this.showAdvancedState = 'close';
    this.clear();
  }

  hasSearchSelections(): boolean {
    for (const item of this.autoCompleteDetails) {
      if (item.textValue.length > 0) {
        return true;
      }
    }
    if (this.maxDueDate || this.maxWidth) {
      return true;
    }
    if (this.nonKiwiSearchDetails.textValue.length > 0) {
      return true;
    }
    return false;
  }

  private _generateAutoCompleteDetails(): void {
    this.autoCompleteDetails.push(new SearchAutoComplete('programNumber', 'Program Number', 'Enter a program...', { url: 'program-numbers' }));
    this.autoCompleteDetails.push(new SearchAutoComplete('setupNumber', 'Setup Number', 'Enter a setup number...', { url: 'setup-numbers' }));
    this.autoCompleteDetails.push(new SearchAutoComplete('orderNumber', 'Order Number', 'Enter a order number...', { url: 'order-numbers' }));
    this.autoCompleteDetails.push(new SearchAutoComplete('designNumber', 'Design Number', 'Enter an design number...', { url: 'design-numbers' }));
    this.autoCompleteDetails.push(new SearchAutoComplete('designDescription', 'Design Description', 'Enter an design description...', { url: 'design-descriptions' }));
    this.autoCompleteDetails.push(new SearchAutoComplete('customer', 'Customer', 'Enter a customer...', { url: 'customers' }));
    this.autoCompleteDetails.push(new SearchAutoComplete('corrugator', 'Corrugator', 'Enter a corrugator...', { url: 'corrugators' }));
    this.autoCompleteDetails.push(new SearchAutoComplete('paperType', 'Paper Type', 'Enter a paper type...', { url: 'paper-types' }));

    this.nonKiwiSearchDetails = new SearchAutoComplete(
      'designNumber',
      'Design Number',
      'Enter an design number...',
      { url: 'product-design', type: SearchType.Offline }
    );
  }
  private _checkOtherCriteriaAndSearch(): void {
    // Check if search criteria still exists, if no, expand the search box and do not re-run the search
    let stillHasSearchCriteria = false;
    this.autoCompleteDetails.forEach(a => {
      if (a.textValue) {
        stillHasSearchCriteria = true;
      }
    });
    if (this.maxDueDate || this.maxWidth) {
      stillHasSearchCriteria = true;
    }

    if (stillHasSearchCriteria) {
      this._sendSearchEvent();
    } else {
      this.showSearch = true;
    }
  }

  private _sendSearchEvent(): void {
    const pageNumber = 1;

    if (this.showKiwiSearch) {
      this._productDesignSearch.saveSearchCriteria([this.nonKiwiSearchDetails], this.maxDueDate, this.maxWidth);
    } else {
      this._productDesignSearch.saveSearchCriteria(this.autoCompleteDetails, this.maxDueDate, this.maxWidth);
    }

    this.search.emit({ pageNumber, isKiwi: this.showKiwiSearch });
  }
}
