/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { PkgConfig } from './config.interface';
import { ReplaySubject, Observable } from 'rxjs';
import { ConfigStrategy } from './config-strategy.enum';
import { SecureThumbnailPayload } from '../secure.pipe';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private _config: PkgConfig;
  private readonly _configSubject: ReplaySubject<PkgConfig>;

  private _thumbnails = new Map<string, SecureThumbnailPayload>();
  private readonly _thumbNailSubject = new ReplaySubject<Map<string, SecureThumbnailPayload>>();
  readonly thumbnailCache$ = this._thumbNailSubject.asObservable;

  constructor() {
    this._config = {} as PkgConfig;
    this._configSubject = new ReplaySubject<PkgConfig>();
  }

  setConfig(config: PkgConfig): void {
    this._config = Object.assign(this._config, config);
    this._configSubject.next(this._config);
  }

  setByValue(key: string, value: any, strategy?: ConfigStrategy): void {
    this._config[key] = value;
    this._configSubject.next(this._config);

    if (strategy === ConfigStrategy.LOCAL_STORAGE) {
      localStorage.setItem(key, value);
    }
  }

  setThumbnail(thumbnails: Map<string, SecureThumbnailPayload>): void {
    this._thumbnails = thumbnails;
    this._thumbNailSubject.next(this._thumbnails);
  }

  getThumbnails(): Map<string, SecureThumbnailPayload> {
    return this._thumbnails;
  }

  onChanges(): Observable<PkgConfig> {
    return this._configSubject.asObservable();
  }

  getConfigValue(key: string, strategy?: ConfigStrategy): any {
    if (strategy === ConfigStrategy.LOCAL_STORAGE) {
      return localStorage.getItem(key);
    }
    return this._config[key];
  }
}
