import { Component, OnInit, OnDestroy } from '@angular/core';
import { ColorManagementSystem } from '@shared/color-management-system';
import { ColorManagementSystemService } from '@app/pages/admin/shared/color-management-system.service';
import { AlertsService } from '@shared/alerts/alerts.service';
import { Subject, Subscription, interval } from 'rxjs';
import { takeUntil, startWith } from 'rxjs/operators';
import { AlertType } from '@shared/alerts/alert-type.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'pkg-color-management-system-list',
  templateUrl: './color-management-system-list.component.html',
  styleUrls: ['./color-management-system-list.component.scss']
})
export class ColorManagementSystemListComponent implements OnInit, OnDestroy {

  colorManagementSystems: ColorManagementSystem[] = [];
  colorManagementSystemsLoading = true;

  timedSubscription: Subscription;
  private unsubscribe$ = new Subject();

  constructor(
    private readonly colorManagementSystemService: ColorManagementSystemService,
    private readonly router: Router,
    private readonly alertService: AlertsService) { }

  ngOnInit(): void {
    this.colorManagementSystemService.getAll().pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.colorManagementSystems = data.results;
      this.timedSubscription = interval(120000).pipe(startWith(0)).subscribe(() => this.checkHealthStatus());
      this.colorManagementSystemsLoading = false;
    },
      (err) => {
        this.alertService.add('Failed to load color management systems', 'Please try again as we could not get any color management systems', AlertType.Error);
        this.colorManagementSystemsLoading = false;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.timedSubscription) {
      this.timedSubscription.unsubscribe();
    }
  }

  testModeClass(colorManagementSystem: ColorManagementSystem): string {
    if (colorManagementSystem.isInTestMode) {
      return 'test-mode--enabled';
    }
    return 'test-mode--disabled';
  }

  editColorManagementSystem(colorManagementSystem: ColorManagementSystem) {
    this.router.navigate([`admin/colormanagementsystem/${colorManagementSystem.id}`]);
  }

  createColorManagementSystem() {
    this.router.navigate([`admin/colormanagementsystem/add`]);
  }

  checkHealthStatus(): void {
    if (this.colorManagementSystems.length === 0) {
      return;
    }

    this.colorManagementSystems.forEach(colorManagementSystem => {
      this.colorManagementSystemService.getHealth(colorManagementSystem.id).pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
        colorManagementSystem.healthStatus = data;
      });
    });
  }

}
