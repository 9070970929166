import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../../../auth/auth.service';
import { Role } from '../../admin/shared/role';

@Injectable()
export class RollMapGuard implements CanActivate {
  constructor(private readonly router: Router, private readonly authService: AuthService) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authService.user) {
      // if there is no user, get one
      await this.authService.login().toPromise();
    }

    if (this.authService.user.role === Role.Support
      || this.authService.user.role === Role.Scheduler
      || this.authService.user.role === Role.Admin
      || this.authService.user.role === Role.Operator
      || this.authService.user.role === Role.Viewer) {
      return await Promise.resolve(true);
    }

    this.router.navigate(['/home']);
    return await Promise.resolve(false);
  }
}
