<div class="container-preview">
  <ng-container *ngIf="source?.preview != null; else previewNotFound">
    <div *ngIf="(source?.preview + (showBoundingBoxes ? showBoundingBoxesQuery : '') | secure | async) as thumbnail; else loading">
      <div *ngIf="isObject(thumbnail) && thumbnail.response.status === 200 && setThumbnail(thumbnail)">
        <div [ngStyle]="{'transform': 'scale('+ zoomValue + ') rotate('+ rotateValue+'deg)'}" [id]="source?.designNumber" (click)="togglePreview(thumbnail)" class="background-media preview" [style.height]="previewHeight" [style.background-image]="getBackgroundImage(thumbnail)" (pkgAfterIf)="printable = true">
          <div class="ripple" [class.flash]="shouldFlash"></div>
        </div>
      </div>
      <div *ngIf="isObject(thumbnail) && thumbnail.response.status === 404" class="preview-not-found">
        <i class="material-icons">&#xE3F4;</i>
        <div class="ripple" [class.flash]="shouldFlash"></div>
      </div>
    </div>
    <div *ngIf="showPreview" class="button-group background-image">
      <div class="zoom-in">
        <button class="button button--muted-lightBlue button--small button-fab" title="Print" (click)="rotate()" type="button">
          <i class="material-icons print-icon">&#xE418;</i>
        </button>
      </div>
      <div class="range-slider">
        <pkg-range-slider [min]="0" [max]="2" [value]="1" [step]=".1" [multiplier]="1" (valueEmitted)="setScale($event)"></pkg-range-slider>
      </div>
    </div>
  </ng-container>
  <ng-template #previewNotFound>
    <div class="preview-not-found">
      <i class="material-icons">&#xE3F4;</i>
      <div class="ripple" [class.flash]="shouldFlash"></div>
    </div>
  </ng-template>
  <ng-template #loading>
    <div #previewImage (pkgAfterIf)="evalFlash()">
      <div class="loading thumbnail-loading"></div>
    </div>
  </ng-template>
</div>
