export enum SortBy {
  QUEUED = 'queued',
  PROCESSING = 'processing',
  PROCESSED = 'processed',
  GENERATED = 'generated',
  SUBMITTED = 'submitted',
  FAILED = 'failed',
  PROGRAM_NUMBER = 'programNumber',
  ORDER_NUMBER = 'orderNumber',
  SETUP_NUMBER = 'setupNumber',
  DESIGN_NUMBER = 'designNumber',
  CREATED_DATE = 'createdDate',
  NAME = 'name',
  UNIQUEID = 'uniqueID',
  DESIGNDESCRIPTION = 'designDescription',
  COLORPROFILE = 'colorprofile',
  ASSET = 'asset',
  PAPERTYPE = 'paperType',
  BLANKETNUMBER = 'blanketNumber'
}
