import { Component, Input, ViewChild, ElementRef, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MlpaJobView } from '../../pages/home/view-mlpa-jobs/mlpa-job-view';
// import { MlpaLaneView } from '../../pages/home/view-mlpa-jobs/mlpa-lane-view';
import { MlpaLaneType } from '../../mlpa/mlpa-lane/mlpa-lane-type.enum';
import { PdfService } from '../pdf/pdf.service';
import { AlertsService } from '../alerts/alerts.service';
import { AlertType } from '../alerts/alert-type.enum';
import { isObject } from 'util';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'pkg-job-preview',
  templateUrl: './job-preview.component.html',
  styleUrls: ['./job-preview.component.scss'],
})
export class JobPreviewComponent implements OnInit, OnDestroy {

  @Input() mlpaJob: MlpaJobView;
  @Input() print: boolean;
  @ViewChild('previewContainer') container!: ElementRef;
  @Output() closeJobPreview = new EventEmitter<any>();
  previewHeight = 250;
  webWeaveHeight = 12;
  printing: boolean;
  laneType = MlpaLaneType;
  showBoundingBoxes = false;
  _retrievedImages = 0;

  set retrievedImages(v: number) {
    this._retrievedImages += v;
  }

  get retrievedImages(): number {
    return this._retrievedImages;
  }

  get jobsToDisplay(): Array<MlpaJobView> {
    return new Array(this.mlpaJob, ...this.mlpaJob.linkedMlpaJobs);
  }

  get printable(): boolean {
    // (OLD) show printable when all lane previews are collected
    // let linkedMlpaJobsLaneCount = 0;
    // if (this.mlpaJob.linkedMlpaJobs.length > 0) {
    //   // Get count of all linked job lanes
    //   linkedMlpaJobsLaneCount = this.mlpaJob.linkedMlpaJobs.map(j => j.mlpaLanes.length).reduce((total = 0, a) => total + a);
    // }
    // return this.retrievedImages >= this.mlpaJob.mlpaLanes.length + linkedMlpaJobsLaneCount;
    return this.retrievedImages >= this.mlpaJob.linkedMlpaJobs.length + 1;
  }

  constructor(
    private readonly _pdfService: PdfService,
    private readonly _alertService: AlertsService,
    private readonly _http: HttpClient) { }

  maxDesignLength(job: MlpaJobView): number {
    return job.mlpaLanes.reduce((a, b) => a.designLengthInInches >= b.designLengthInInches ? a : b).designLengthInInches;
  }

  maxLaneLength(job: MlpaJobView): number {
    // (OLD) Accounts for WebWeave length since it could be up to 11.99 inches longer than the longest graphic lane
    // const jobWebWeaveLength =  job.mlpaLanes[0].designLengthInInches;
    // return Math.ceil(this.maxDesignLength(job) / jobWebWeaveLength) * jobWebWeaveLength;
    return this.maxDesignLength(job);
  }

  getJobWidth(): string {
    return `${(this.mlpaJob.paperWidthInInches / this.maxLaneLength(this.mlpaJob)) * this.previewHeight}px`;
  }

  getJobHeight(job: MlpaJobView): string {
    return `${(this.maxDesignLength(job) / this.maxLaneLength(this.mlpaJob)) * this.previewHeight + 10}px`;
  }

  isObject(arg: any): boolean {
    return isObject(arg);
  }

  async printPreview(job: MlpaJobView): Promise<void> {
    this.printing = true;
    const images = this.jobsToDisplay.map((result) => ({ preview: result.preview, totalWidthInInches: result.totalWidthInInches }));
    const listOfRequests = [];
    images.forEach((value) => listOfRequests.push(this._http.get(value.preview, { responseType: 'blob' }).pipe(finalize(() => this.printing = false))));

    forkJoin(listOfRequests).subscribe(async (result) => {
      const source = [];
      result.forEach((data, index) => {
        source.push({ image: data, height: this.maxDesignLength(this.jobsToDisplay[index]) });
      });

      try {
        // total height of all max lanes.
        const height = this.jobsToDisplay.map((index) => this.maxDesignLength(index)).reduce((total, amount) => total + amount);
        const pdf = await this._pdfService.buildPreview(source, this.mlpaJob.paperWidthInInches, height);
        this._pdfService.printImage(pdf);
      } catch (e) {
        this._alertService.add(`Failed to print.`, `Failed to print job: ${job.name}--${job.uniqueId}`, AlertType.Error);
      } finally {
        this.printing = false;
      }
    });
  }

  ngOnInit(): void {
    if (this.mlpaJob) {
      this.mlpaJob.linkedMlpaJobs.forEach(linkedJob => {
        linkedJob.showPreview = true;
      });
    }
  }

  ngOnDestroy(): void {
    if (this.mlpaJob) {
      this.mlpaJob.linkedMlpaJobs.forEach(linkedJob => {
        linkedJob.showPreview = false;
      });
    }
  }
}
