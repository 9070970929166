import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SafeUrl } from '@angular/platform-browser';
import { blobToBase64 } from './image/image.util';
import { ConfigService } from './config/config.service';

@Pipe({
  name: 'secure'
})
export class SecurePipe implements PipeTransform {
  cache = new Map<string, SecureThumbnailPayload>();
  constructor(private readonly _http: HttpClient, private readonly _configService: ConfigService) { }

  transform(url: string): Promise<SecureThumbnailPayload> {
    const result = {} as SecureThumbnailPayload;
    result.preview = url;
    result.response = null;
    if (url == null) {
      return Promise.resolve(null);
    }
    if (url.endsWith('.svg') || url.startsWith('data:image')) {
      return Promise.resolve(result);
    }
    return new Promise<SecureThumbnailPayload>((resolve, reject) => {
      this.cache = this._configService.getThumbnails();
      // Check cache
      if (this.cache.has(url)) {
        resolve(this.cache.get(url));
      } else {
        this._http.get(url, { observe: 'response', responseType: 'blob' }).subscribe(async data => {
          result.preview = await blobToBase64(data.body);
          this.cache.set(url, { preview: result.preview, response: data });
          this._configService.setThumbnail(this.cache);
          result.response = data;
          resolve(result);
        }, error => {
          if (error.status === 404) {
            this.cache.set(url, { preview: null, response: error });
            this._configService.setThumbnail(this.cache);
            resolve({ preview: null, response: error });
          } else {
            reject(error);
          }
        });
      }
    });
  }
}

export interface SecureThumbnailPayload {
  preview: SafeUrl;
  response: HttpResponse<Blob>;
}
