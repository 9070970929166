import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { MlpaJobApi } from '@shared/mlpa-job-api';
import { MlpaJob } from '../../../mlpa/mlpa-job/mlpa-job';
import { PagedResult } from '@shared/paged-result';
import { MlpaSubmitResponse } from './mlpa-submit-reponse.interface';
import { AssetType } from '@shared/asset-type.enum';
import { SelectItem } from '@app/search-auto-complete/select-item';

@Injectable()
export class MlpaService {

  constructor(private readonly _http: HttpClient) { }

  getMlpaJob(mlpaJobId: string): Observable<MlpaJobApi> {
    return this._http.get<MlpaJobApi>(`${environment.tartanApiUrl}mlpa-jobs/${mlpaJobId}`);
  }
  getJobs(pageNumber: number): Observable<PagedResult<MlpaJobApi>> {
    const params = new HttpParams()
      .set('pageSize', '24')
      .set('pageNumber', `${pageNumber}`);
    return this._http.get<PagedResult<MlpaJobApi>>(`${environment.tartanApiUrl}mlpa-jobs`, { params });
  }
  getJobsAutoComplete(value: string): Observable<PagedResult<SelectItem>> {
    const params = new HttpParams().set('term', value);
    return this._http.get<PagedResult<SelectItem>>(`${environment.tartanApiUrl}mlpa-jobs/auto-complete`, { params });
  }
  importPaperTypes(assetType: AssetType): Observable<any> {
    // Imports paper types into the config paper types table.
    return this._http.post(`${environment.tartanApiUrl}paper-types/import`, { assetType });
  }
  importColorProfiles(): Observable<any> {
    // Imports color profiles for all assets
    return this._http.post(`${environment.tartanApiUrl}assets/color-profiles/import`, undefined);
  }
  importCharacterizationValues(): Observable<any> {
    // Import Characterization Values
    return this._http.post(`${environment.tartanApiUrl}characterization/import`, undefined);
  }
  checkIfSourcePdfExists(assetId: string, designNumber: string): Observable<HttpResponse<any>> {
    return this._http.head(`${environment.tartanApiUrl}assets/${assetId}/pdfs/${designNumber}`, { observe: 'response' });
  }
  saveMlpaJob(mlpaJob: MlpaJob): Observable<any> {
    const saveJob = this._buildSaveJob(mlpaJob);

    // If this is an edit, set the job id and call the PUT api. Otherwise create a new mlpa job with a POST
    if (mlpaJob.id) {
      saveJob.id = mlpaJob.id;
      return this._http.put(`${environment.tartanApiUrl}mlpa-jobs/${mlpaJob.id}`, saveJob);
    } else {
      return this._http.post(`${environment.tartanApiUrl}mlpa-jobs`, saveJob);
    }
  }

  saveSpecialMlpaJob(mlpaJob: MlpaJob): Observable<any> {
    const saveJob = this._buildSaveJob(mlpaJob);
    return this._http.post(`${environment.tartanApiUrl}mlpa-jobs/special`, saveJob);
  }

  submitJob(id: string, isSpecial: boolean): Observable<MlpaSubmitResponse> {
    return this._http.post<MlpaSubmitResponse>(`${environment.tartanApiUrl}mlpa-jobs/${id}/submit`, isSpecial);
  }
  deleteMlpaJob(mlpaJobId: string): Observable<any> {
    return this._http.delete(`${environment.tartanApiUrl}mlpa-jobs/${mlpaJobId}`);
  }



  private _buildSaveJob(mlpaJob: MlpaJob): any {
    const saveJob = {
      name: mlpaJob.name,
      parentMlpaJobId: mlpaJob.parentMlpaJobId,
      uniqueId: mlpaJob.uniqueId,
      programNumber: mlpaJob.programNumber,
      setupNumber: mlpaJob.setupNumber,
      blanketNumber: mlpaJob.blanketNumber,
      assetId: mlpaJob.assetId,
      colorCorrectionInfo: {
        colorManagementSystemId: mlpaJob.colorCorrectionInfo?.colorManagementSystemId,
        pressType: mlpaJob.colorCorrectionInfo?.pressType,
        substrateWeight: mlpaJob.colorCorrectionInfo?.substrateWeight,
        printMode: mlpaJob.colorCorrectionInfo?.printMode,
        inkColors: mlpaJob.colorCorrectionInfo?.inkColors,
        overPrintVarnish: mlpaJob.colorCorrectionInfo?.overPrintVarnish,
        speed: mlpaJob.colorCorrectionInfo?.speed
      },
      paperTypeId: mlpaJob.paperTypeId,
      paperWidthInInches: mlpaJob.paperWidthInInches,
      totalWidthInInches: mlpaJob.totalWidthInInches,
      totalLengthInInches: mlpaJob.totalLengthInInches,
      wasteWidthInInches: mlpaJob.wasteWidthInInches,
      wasteLengthInInches: mlpaJob.wasteLengthInInches,
      marginWidthInInches: mlpaJob.marginWidthInInches,
      lineBreakLengthInInches: mlpaJob.lineBreakLengthInInches,
      mlpaLanes: [],
      isSpecial: mlpaJob.isSpecial,
      qualityMode: mlpaJob.qualityMode,
      parentClonedMlpaJobId: mlpaJob.parentClonedMlpaJobId
    };

    mlpaJob._mlpaLanes.forEach(o => {
      const saveLane = {
        colorProfileId: o.colorProfileId,
        programNumber: o.programNumber,
        setupNumber: o.setupNumber,
        orderNumber: o.orderNumber,
        designNumber: o.designNumber,
        laneNumber: o.laneNumber,
        numberOfRibbons: o.numberOfRibbons,
        quantity: o.quantity,
        designNumberUp: o.designNumberUp,
        designNumberOfPages: o.designNumberOfPages,
        designWidthInInches: o.designWidthInInches,
        designLengthInInches: o.designLengthInInches,
        totalWidthInInches: o.totalWidthInInches,
        totalLengthInInches: o.totalLengthInInches,
        isTransposed: o.isTransposed,
        type: o.laneType,
        rotationInDegrees: o.rotationInDegrees,
        isProcessingEnabled: o.isProcessingEnabled,
        colorCorrectionInfo: {
          isColorCorrectionEnabled: o.colorCorrectionInfo.isColorCorrectionEnabled,
          inkWeight: o.colorCorrectionInfo.inkWeight,
          totalAreaCoverage: o.colorCorrectionInfo.totalAreaCoverage
        },
      };
      saveJob.mlpaLanes.push(saveLane);
    });

    if (mlpaJob.linkedMlpaJobs) {
      mlpaJob.linkedMlpaJobs.forEach(j => {
        j.parentMlpaJobId = mlpaJob.id;
      });
    }

    return saveJob;
  }
}
