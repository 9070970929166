import { Injectable } from '@angular/core';
import { Observable, interval } from 'rxjs';

import { Token } from './token';
import { take } from 'rxjs/operators';

@Injectable()
export class TokenService {
  token: Token;
  timer: Observable<any>;
  timerStarted: boolean;

  constructor() { }

  set(id: string, lifetime: number): Observable<any> {
    this.token = new Token(id, lifetime);
    if (!this.timerStarted) {
      this.timerStarted = true;
      this.timer = interval(Math.floor((lifetime * 1000) / 2)).pipe(take(1));
      return this.timer;
    }
  }
  get(): string {
    if (this.token) {
      return this.token.id;
    } else {
      return '';
    }
  }
  reset(): void {
    this.token = null;
    this.timer = undefined;
    this.timerStarted = false;
  }
  isValid(): boolean {
    // TODO: In the future, could check for token expiration as well
    if (this.token) {
      return true;
    }
    return false;
  }
}
