import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { Role } from '../pages/admin/shared/role';
import { MlpaJob } from '../mlpa/mlpa-job/mlpa-job';
import { MlpaJobView } from '../pages/home/view-mlpa-jobs/mlpa-job-view';
import { MlpaJobApi } from './mlpa-job-api';

@Injectable()
export class AnalyticsService {
  dataLayer: any;

  constructor(private readonly _router: Router, private readonly _authService: AuthService) { }
  isScriptLoaded(): boolean {
    return this.dataLayer !== undefined;
  }
  configure(): void {
    // Load gtag script
    this.loadScript();
    // Subscribe to router events
    this._router.events.subscribe(event => this.onRouterEvent(event));
  }
  send(action: string, category: any, data?: any): void {
    this.dataLayer.push(arguments);
  }
  loadScript(): void {
    // Append the google tag manager script.
    const top = document.getElementsByTagName('script')[0];
    const googletagmanager = document.createElement('script');
    googletagmanager.async = true;
    googletagmanager.src = `https://www.googletagmanager.com/gtm.js?id=${environment.googleTagManagerKey}`;
    (googletagmanager as any).addEventListener('load', (event) => this.onScriptLoaded(event), { once: true });
    top.parentNode.insertBefore(googletagmanager, top);
  }
  onScriptLoaded(event: any): void {
    this.dataLayer = (window as any).dataLayer || [];

    this.send('start', new Date().getTime());
    this.send('event', 'gtm.js');

    // Configure tags
    this.send('js', new Date());
    this.send('config', `${environment.analyticsKey}`,
      {
        send_page_view: false,
        custom_map: {
          dimension1: 'user_role',
          dimension2: 'user_id',
          dimension4: 'jobId',
          dimension5: 'jobName',
          dimension6: 'description'
        }
      });
  }

  onRouterEvent(event: any): void {
    // Send page view events for logged in users
    this._authService.user$.subscribe((user) => {
      const _user = !!this._authService.user ? this._authService.user : user;
      if (!!user) {
        if (this.isScriptLoaded() && _user && event instanceof NavigationEnd) {
          this.send('js', new Date());
          this.send('set', 'user_id', _user.id);
          this.send('set', 'user_role', Role[_user.role]);
          this.send('event', 'pageview', { page_path: event.urlAfterRedirects });
        }
      }
    });
  }

  sendJobEvent(job: MlpaJob | MlpaJobView | MlpaJobApi, category: string, description = ''): void {
    if (!!this.dataLayer) {
      // send data to google analytics
      this.send('set', 'jobId', job.id);
      this.send('set', 'jobName', `${job.name}-${job.uniqueId}`);
      this.send('set', 'description', description);
      this.send('event', category);
    }
  }
}
