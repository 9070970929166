<iframe style="width:0;height:0;border: 0;border: none;" title="Logout" src="https://kochid.com/logout"></iframe>
<div *ngIf="!hideLogin" class="loader">
  <div class="loader__container">
    <div class="loader__animation loader__animation--color1" style="animation-delay: 0s"></div>
    <div class="loader__animation loader__animation--color2" style="animation-delay: 1s;"></div>
    <div class="loader__animation loader__animation--color3" style="animation-delay: 2s;"></div>
    <div class="loader__circle">
      <header class="loader__circle-header" [ngClass]="{'windows-auth-secret-mode' : hotKeyIsPressed}">
        <div class="loader__circle-header-container">
          <ng-container *ngIf="currentHoliday?.name !== null">
            <pkg-holiday class='holiday-component' [ngClass]="styleClass"></pkg-holiday>
          </ng-container>
          <img class="loader__logo" src="../assets/full-logo-dark.svg" [ngStyle]="getLogoStyle" alt="Tartan + Hummingbird Logo" />
        </div>
        <span class="loader__release-number">v{{releaseNumber}}</span>
      </header>
      <div *ngIf="!hotKeyIsPressed && !isLoading" class="koch-id-login">
        <button class="button kochId" (click)="loginWithKochId()" [disabled]="!kochIdEnabled">
          <img src="/assets/kochid.svg" title="Login with KochID" alt="KochID">
          <div class="divider vertical"></div>
          Login
        </button>
      </div>
      <div *ngIf="isLoading" class="koch-id-login loading message"></div>
    </div>
  </div>
</div>
<div class="modal-container" *ngIf="showConfirm">
  <div class="modal">
    <div class="modal__header">
      <h3>Are you sure?</h3>
      <button class="icon-button" (click)="showConfirm = false">
        <i class="material-icons">&#xE5CD;</i>
      </button>
    </div>
  </div>
</div>