import { Injectable } from '@angular/core';
import * as jspdf from 'jspdf';
import * as html2canvas from 'html2canvas';
import { blobToBase64, getOrientation } from '../image/image.util';
import { Orientation } from '../image/orientation.enum';

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  constructor() { }

  /**
   *
   *
   * @param image
   * @param width
   * @param height
   * @param [percentage] - Optional value that defines the percentage of the original image.
   *                       If no value is provide 25% is the default. Provide value in decimal format.
   * @returns
   * @memberof PdfService
   */
  async getJspdfFromImage(image: Blob, width: number, height: number, percentage?: number): Promise<jspdf> {
    const base64image = await blobToBase64(image);
    const percent = percentage > 0 ? percentage : 0.25;
    if (base64image == null) {
      throw new Error('Image not found');
    } else {
      const imageWidth = width * percent;
      const imageHeight = height * percent;
      const orientation = getOrientation(imageWidth, imageHeight);

      // instatiate jspdf, and set units to be inches.
      const pdf = new jspdf(orientation, 'px', 'a4');

      // Set the dimension of the pdf to be a little bit more than the image.
      const internalWidth = pdf.internal.pageSize.width = width * (percent + 0.05);
      const internalHeight = pdf.internal.pageSize.height = height * (percent + 0.05);
      const margins = {
        x: (internalWidth - imageWidth) / 2,
        y: (internalHeight - imageHeight) / 2,
        width: imageWidth,
        height: imageHeight
      };

      // add the image to the pdf.
      pdf.addImage(base64image, 'JPEG', margins.x, margins.y, margins.width, margins.height);
      return pdf;
    }
  }


  /**
   *
   *
   * Takes an array of image blobs with its respective heights, and builds a pdf with all the images for a job.
   *
   *
   *
   * @param source
   * @param width
   * @param height
   * @param [percentage]
   * @returns
   * @memberof PdfService
   */
  async buildPreview(source: { image: Blob; height: number }[], width: number, height: number, percentage?: number): Promise<jspdf> {
    if (source == null) {
      throw new Error('Image not found');
    }

    const percent = percentage > 0 ? percentage : 0.25;

    // margin between each images.
    const marginBetweenImage = 0.5;
    const totalHeight = (height + (marginBetweenImage * source.length));
    // instatiate jspdf, and set units to be inches.
    const pdf = new jspdf(Orientation.Portrait, 'in');
    // set the width of the pdf file
    const internalWidth = pdf.internal.pageSize.width = width * (percent + 0.05);
    // get the total height of all the images. And then add some padding.
    const internalHeight = pdf.internal.pageSize.height = totalHeight * (percent + 0.05);

    // set initial starting y position, this value will be incremented in the for loop.
    let initialY = (internalHeight - (totalHeight * percent)) / 2;
    for (const item of source) {
      const imageWidth = internalWidth - 10;
      const margins = {
        x: (internalWidth - imageWidth) / 2,
        y: initialY,
        width: imageWidth,
        height: item.height * percent
      };

      // set the fontsize and write the dimensions of the images.
      pdf.setFontSize(30);
      pdf.text(`${width}"`, (internalWidth / 2) - 0.5, ((internalHeight - (totalHeight * percent)) / 2) - 0.5);
      pdf.text(`${item.height}"`, imageWidth + 5.8, initialY + (margins.height / 2));

      // add the image to the pdf.
      const image = await blobToBase64(item.image);
      pdf.addImage(image, 'JPEG', margins.x, margins.y, margins.width, margins.height);

      initialY += margins.height + marginBetweenImage;
    }

    return pdf;
  }



  getJspdfFromHtml(source: any): Promise<jspdf> {
    return new Promise((resolve, reject) => {
      html2canvas(source.nativeElement).then((canvas) => {
        const imgWidth = 200;
  const pageHeight = 293.50;
  const imgHeight = ((canvas.height * imgWidth) / canvas.width * 1.11);
  let heightLeft = imgHeight;
  let position = 5;
  heightLeft -= pageHeight;
  const doc = new jspdf('p','mm');
  doc.addImage(canvas, 'PNG', 5, position, imgWidth, imgHeight, '', 'FAST');
  while (heightLeft >= 0) {
    position = heightLeft - imgHeight;
    doc.addPage();
    doc.addImage(canvas, 'PNG', 5, position, imgWidth, imgHeight, '', 'FAST');
    heightLeft -= pageHeight;
  }
        resolve(doc);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  /**
   *
   *
   * Opens new tab and prints the document.
   *
   *
   *
   * @param pdf
   * @memberof PdfService
   */
  printImage(pdf: jspdf): void {
    pdf.autoPrint();
    window.open(pdf.output('bloburl'));
  }

  /**
   *
   *
   * Downloads the document in the same tab.
   *
   *
   *
   * @param pdf
   * @memberof PdfService
   *
   * @param fileName
   */
  downloadFile(pdf: jspdf, fileName: string): void {
    pdf.output('save', fileName);
  }
}
