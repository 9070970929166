<ng-container *ngIf="!draggable">
  <div *ngFor="let job of mlpaJobs" class="job-container" [class.collapsed]="!!selectedJob"
    [class.single]="mlpaJobs.length == 1">
    <pkg-card [ngStyle]="getBackgroundStyles(job)" [style.overflow]="'hidden'" (click)="selectJob(job); scrollToJob($event);"
      [class.deselected]="job !== selectedJob && previousSelectedJob === job" [class.selected]="job === selectedJob">
      <pkg-card-header>
        <div class="pkg-card-title">
          <pkg-mlpa-status-icons class="status-icons-group" [job]="job | getMainJob"></pkg-mlpa-status-icons>
          <div *ngIf="!selectedJob &&
                    ((showSubmit) ||
                    (showEdit && job.isEligibleForEdit))" class="action-options">
            <pkg-toggle-action
              [job]="job | getMainJob"
              [mlpaJob]="mlpaJob"
              [showActionOptions]="showActionOptions"
              [showSubmit]="showSubmit"
              [showDelete]="canDelete"
              [showEdit]="showEdit"
              (toggleJobOptions)="toggleJobOptions($event)"
              (preSubmit)="preSubmit($event)"
              (preDelete)="preDelete($event)"
              (preClone)="preClone($event)"
              (selectJob)="selectJob($event)"
              (editJob)="edit($event)"
              (scrollToJob)="scrollToJob($event)">
            </pkg-toggle-action>
          </div>
        </div>
      </pkg-card-header>
      <pkg-card-progress-bar *ngIf="job.isColorCorrecting">
        <div class="card-status-bar-container" title="{{job.colorCorrectionProcessingPercentage * 100}}%">
          <div class="card-status-bar" [style.width.%]="job.colorCorrectionProcessingPercentage * 100"> </div>
        </div>
      </pkg-card-progress-bar>
      <pkg-card-body>
        <pkg-mlpa-base-body [job]="job | getMainJob"></pkg-mlpa-base-body>
      </pkg-card-body>
    </pkg-card>
  </div>
</ng-container>

<!--<div *ngIf="draggable" id="job-pool" [dragula]="'JOBS_ENQUEUE'" [(dragulaModel)]="mlpaJobs" class="draggable-container"
     (dragulaModelChange)="updateQueue($event)">-->
<div *ngIf="draggable" id="job-pool">
  <div *ngFor="let job of mlpaJobs" [id]="getAssetId(job)" class="job-container" [class.single]="mlpaJobs.length == 1">
    <pkg-card [style.overflow]="'hidden'">
      <pkg-card-header>
        <div class="pkg-card-title">
          <span class="material-icons draggable-icon">&#xE945;</span>
          <div class="pkg-card-right-section">
            <pkg-mlpa-status-icons class="status-icons-group" [job]="job | getMainJob"></pkg-mlpa-status-icons>
            <div *ngIf="!selectedJob &&
                      ((showSubmit) ||
                      (showEdit && job.isEligibleForEdit))" class="action-options">
              <pkg-toggle-action [job]="job | getMainJob" [mlpaJob]="mlpaJob" [showActionOptions]="showActionOptions"
                [showSubmit]="showSubmit" [showDelete]="canDelete" [showEdit]="showEdit"
                (toggleJobOptions)="toggleJobOptions($event)" (preSubmit)="preSubmit($event)"
                (preDelete)="preDelete($event)" (preClone)="preClone($event)" (selectJob)="selectJob($event)"
                (editJob)="edit($event)" (scrollToJob)="scrollToJob($event)">
              </pkg-toggle-action>
            </div>
          </div>
        </div>
      </pkg-card-header>
      <pkg-card-body>
        <pkg-mlpa-base-body [job]="job | getMainJob"></pkg-mlpa-base-body>
      </pkg-card-body>
    </pkg-card>
  </div>
</div>

<pkg-mlpa-job-modal (delete)="deleteEmitter.emit()" [mlpaJob]="mlpaJob" [showConfirm]="showConfirm"
  (closeConfirm)="showConfirm = $event" [showDelete]="showDelete" (closeDelete)="showDelete = $event;">
</pkg-mlpa-job-modal>
<pkg-clone-mlpa-jobs (cloneJob)="clone()" (closeModal)="cancelClone()"></pkg-clone-mlpa-jobs>
